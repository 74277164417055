import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import LinearProgress from '@material-ui/core/LinearProgress';

// MUI Icons
import { default as ErrorIcon } from '@material-ui/icons/Error';

/**
 * This is the placeholder component that is shown when unit information is loading (being fetched). It shows the same structure
 * as a populated UnitInfo component, but replaces the data with grey bars.
 * @author JXNS
 */

const BlurredLine = props => {
  return (
    <div>
      <img
        style={{ filter: 'blur(5px)', ...props.style }}
        alt="Loading placeholder"
        src="../img/loaders/smallText.png"
      />
    </div>
  );
};

const BlurredParagraph = props => {
  return (
    <div>
      <img
        style={{ filter: 'blur(5px)', ...props.style }}
        alt="Loading placeholder"
        src="../img/loaders/medium-paragraph.png"
      />
    </div>
  );
};

const UnitInfoPlaceholder = ({ errorString }) => {
  return (
    <div style={{ minHeight: '400px', overflow: 'hidden' }}>
      {!errorString && <LinearProgress mode="indeterminate" color="primary" />}
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          width: '100%',
          left: 0,
          top: '10vh',
          zIndex: 5,
        }}>
        {errorString && (
          <div style={{ width: '90%', margin: 'auto' }}>
            <ErrorIcon style={{ height: 80, width: 80 }} />
            <h2 style={{ color: 'black' }}>Failed to fetch unit details</h2>
            <p>{errorString}</p>
          </div>
        )}
      </div>
      <div>
        <div
          style={{
            background: '#939598',
            filter: 'blur(5px)',
            opacity: '0.5',
            padding: '14px 16px 12px',
            marginBottom: 20,
            borderRadius: '2px 2px 0 0',
          }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <div>
              <BlurredLine style={{ minWidth: '300px' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ padding: '0 1.5em' }}>
                <BlurredLine />
                <BlurredLine />
              </div>
              <div style={{ padding: '0 1.5em' }}>
                <BlurredLine />
                <BlurredLine />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', opacity: '0.3' }}>
          <div style={{ minWidth: 200 }}>
            <BlurredLine style={{ marginBottom: 20 }} />
            <BlurredLine style={{ marginBottom: 30 }} />
            <BlurredLine style={{ marginBottom: 15 }} />
            <BlurredLine style={{ marginBottom: 20 }} />
          </div>
          <div style={{ maxWidth: 300 }}>
            <BlurredParagraph style={{ marginBottom: 20 }} />
            <BlurredParagraph style={{ marginBottom: 25 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitInfoPlaceholder;

//PropTypes declaration
UnitInfoPlaceholder.propTypes = {
  errorString: PropTypes.string,
};
