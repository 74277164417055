import React from 'react';

// MonPlan Components
import MaintenanceConfig from '../../../components/Admin/MaintenanceConfig';

// Network Services
import AdminService from '../../../services/AdminService';

const defaultState = {
  status: null,
  lastModifiedBy: null,
  lastModified: null,
  pageTitle: null,
  pageDescription: null,
  isLoading: false,
  isError: false,
  readOnlyMode: true,
  showModal: false,
  modalLoading: false,
  modalError: false,
  hasSuccesfullyUpdated: false,
};

class MaintenanceConfigContainer extends React.Component {
  state = defaultState;

  componentDidMount = () => {
    this.fetchMaintenanceConfig();
  };

  handleSubmit = () => {
    this.setState({ modalLoading: true, hasSuccesfullyUpdated: true });
    const { pageTitle, pageDescription, status } = this.state;
    AdminService.updateMaintenanceInfo(pageTitle, pageDescription, status)
      .then(data => {
        const pageConfiguration = JSON.parse(data.value);
        const pageState = {
          isLoading: false,
          isError: false,
          readOnlyMode: true,
          modalLoading: false,
          modalError: false,
        };
        this.updateStateWithConfiguration(data, pageConfiguration, pageState);
      })
      .catch(err => {
        this.setState({
          modalLoading: false,
          modalError: true,
        });
      });
  };

  fetchMaintenanceConfig = () => {
    this.setState({ isLoading: true });
    AdminService.getCurrentMaintenanceStatus()
      .then(data => {
        const pageConfiguration = JSON.parse(data.value);
        const pageState = {
          isLoading: false,
          isError: false,
        };
        this.updateStateWithConfiguration(data, pageConfiguration, pageState);
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          isError: true,
        });
      });
  };

  updateStateWithConfiguration = (data, pageConfiguration, pageState) => {
    const { status, lastModified, lastModifiedBy } = data;
    const { pageTitle, pageDescription } = pageConfiguration;

    const loadedState = {
      status: status === 'ENABLED',
      lastModified: lastModified,
      lastModifiedBy: lastModifiedBy,
      pageTitle: pageTitle,
      pageDescription: pageDescription,
      ...pageState,
    };
    this.setState({ ...loadedState, loadedState: loadedState });
  };

  enableWriteMode = () => {
    this.setState({ readOnlyMode: false });
  };

  cancelWriteMode = () => {
    this.setState({ readOnlyMode: true, ...this.state.loadedState });
  };

  handleTitleChange = event => {
    this.setState({
      pageTitle: event.target.value,
    });
  };

  handleDescriptionChange = event => {
    this.setState({
      pageDescription: event.target.value,
    });
  };

  handleToggleChange = event => {
    this.setState({
      status: event.target.checked,
    });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  openWarningModal = () => {
    this.setState({
      hasSuccesfullyUpdated: false,
      showModal: true,
    });
  };

  render() {
    const {
      pageTitle,
      pageDescription,
      readOnlyMode,
      status,
      modalLoading,
      modalError,
      hasSuccesfullyUpdated,
      showModal,
      isLoading,
      isError,
      lastModified,
      lastModifiedBy,
    } = this.state;
    return (
      <MaintenanceConfig
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        readOnlyMode={readOnlyMode}
        status={status}
        lastModified={lastModified}
        lastModifiedBy={lastModifiedBy}
        hasSuccesfullyUpdated={hasSuccesfullyUpdated}
        isLoading={isLoading}
        isError={isError}
        modalLoading={modalLoading}
        modalError={modalError}
        showModal={showModal}
        openWarningModal={this.openWarningModal}
        cancelWriteMode={this.cancelWriteMode}
        enableWriteMode={this.enableWriteMode}
        onToggleChange={this.handleToggleChange}
        onTitleChange={this.handleTitleChange}
        onDescriptionChange={this.handleDescriptionChange}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default MaintenanceConfigContainer;
