import 'whatwg-fetch';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const commonHeaders = () => ({
  Pragma: 'no-cache',
  'Cache-Control': 'no-cache', // required so IE11 does not automatically cache all GET requests
  'X-Requested-With': 'XMLHttpRequest', // Suppress the gray basic auth dialog in the browser on 401
  'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'), // Ensure CSRF token is sent with every request
});

export default class NetworkService {
  static get(url, headers = {}, PLAIN_TEXT_RESPONSE = false) {
    const defaultHeaders = {};
    const options = {
      headers: { ...defaultHeaders, ...headers },
      method: 'GET',
      credentials: 'same-origin',
    };

    return fetch(url, options)
      .then(resp => {
        if (resp.status !== 200) {
          throw new Error(resp.status);
        }
        if (PLAIN_TEXT_RESPONSE) {
          return resp.text();
        }
        return resp.json();
      })
      .catch(err => {
        throw err;
      });
  }

  static post(
    url,
    body = {},
    contentType = 'application/json',
    mode = 'json',
    additionalHeaders = {},
  ) {
    const options = {
      body: mode === 'json' ? JSON.stringify(body) : body,
      headers: {
        'content-type': contentType,
        ...commonHeaders(),
        ...additionalHeaders,
      },
      method: 'POST',
      credentials: 'same-origin',
    };

    return fetch(url, options)
      .then(resp => {
        if (resp.status !== 200 && resp.status !== 201 && resp.status !== 202) {
          throw new Error(resp.status);
        }
        return resp.json();
      })
      .catch(err => {
        return err;
      });
  }

  static delete(url) {
    const options = {
      headers: { 'content-type': 'application/json', ...commonHeaders() },
      method: 'DELETE',

      credentials: 'same-origin',
    };

    return fetch(url, options)
      .then(resp => {
        if (resp.status !== 200) {
          throw new Error(resp.status);
        }
        return resp.json();
      })
      .catch(err => {
        return err;
      });
  }

  static put(
    url,
    body = {},
    contentType = 'application/json',
    mode = 'json',
    additionalHeaders = {},
  ) {
    const options = {
      body: mode === 'json' ? JSON.stringify(body) : body,
      headers: {
        'content-type': contentType,
        ...commonHeaders(),
        ...additionalHeaders,
      },
      method: 'PUT',

      credentials: 'same-origin',
    };

    return fetch(url, options)
      .then(resp => {
        if (resp.status !== 200) {
          throw new Error(resp.status);
        }
        return resp.json();
      })
      .catch(err => {
        return err;
      });
  }
}
