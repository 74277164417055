const FileTypes = {
  CSV: {
    allowedTypes: [
      '.csv',
      'text/csv',
      'application/csv',
      'text/comma-separated-values',
      'application/excel',
      'application/vnd.ms-excel',
    ],
    description: 'Only CSV files',
  },
  FILES: {
    allowedTypes: ['*'],
    description: 'All kinds of files',
  },
  JSON: {
    allowedTypes: ['*.json', 'application/json'],
    description: 'Only JSON files',
  },
};

export default FileTypes;
