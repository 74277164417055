const styles = theme => ({
  disclaimer: {
    backgroundColor: theme.custom.palette.misc.warning,
    color: 'theme.palette.common.white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 48,
    boxSizing: 'border-box',
    padding: theme.spacing.unit,
  },
  logoContainer: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0 1em',
  },
  monashLogo: {
    boxSizing: 'border-box',
    height: 72,
    padding: '24px 16px 0',
  },
  monplanLogo: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    fontFamily: 'Roboto Condensed',
    fontSize: '1.5em',
  },
  appBar: {
    color: theme.palette.primary.contrastText,
    boxShadow: theme.custom.shadows.appBar,
  },
  title: {
    flex: 1,
  },
  account: {
    // backgroundColor: theme.palette.primary.light,
    // minHeight: 100,

    padding: theme.spacing.unit,

    // color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 5,
  },
  popover: { borderRadius: 8, padding: theme.spacing.unit },
});

export default styles;
