import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    paddingTop: 16,
  },
  ratingIcon: {
    padding: 0,
    width: '24px',
    height: '24px',
    color: 'rgb(253, 181, 21)',
  },
  setuGroup: {
    marginLeft: '40px',
  },
  icon: {
    float: 'left',
  },
});

const QuickInfo = ({
  primaryText,
  secondaryText,
  secondaryComponent,
  classes,
  icon,
}) => (
  <div className={classes.root}>
    <div className={classes.icon}>{icon}</div>
    <div
      className={classes.setuGroup}
      aria-label={`${primaryText}${
        secondaryText ? ` ${secondaryText}` : ' no information available'
      }`}>
      <Typography>{primaryText}</Typography>
      {secondaryText && (
        <div>
          <Typography>{secondaryText}</Typography>
        </div>
      )}
      {secondaryComponent && <div>{secondaryComponent}</div>}
    </div>
  </div>
);

QuickInfo.propTypes = {
  avgSetuScore: PropTypes.number,
};

export default withStyles(styles)(QuickInfo);
