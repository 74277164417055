import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const MaintenanceModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>System Under Maintenance</DialogTitle>
    <DialogContent>
      <DialogContentText>
        MonPlan's validation service is currently undergoing maintenance. You
        can still use the application, but you may experience longer than usual
        delays in requests, or may not be able to validate your course.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button aria-label="Okay" onClick={onClose}>
        Okay
      </Button>
    </DialogActions>
  </Dialog>
);

MaintenanceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MaintenanceModal;
