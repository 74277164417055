import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import { COLORS } from '../../constants';

const NoOptionsMessage = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = props => {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      {props.children}
    </MenuItem>
  );
};

const Placeholder = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const SingleValue = props => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = props => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};

const MultiValue = props => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

const Menu = props => {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

class SearchSelect extends Component {
  render() {
    const components = {
      Control,
      Menu,
      MultiValue,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
    };

    const {
      value,
      classes,
      options,
      onChange,
      isDisabled,
      placeholder,
      isMulti,
      textFieldProps,
      autoFocus,
      getSearchSelectRef,
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: COLORS.PRIMARY.main,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <ReactSelect
        autoFocus={autoFocus}
        ref={getSearchSelectRef}
        isMulti={isMulti}
        value={value}
        classes={classes}
        options={options}
        onChange={onChange}
        styles={selectStyles}
        components={components}
        isDisabled={isDisabled}
        placeholder={placeholder}
        textFieldProps={textFieldProps}
      />
    );
  }
}

export default SearchSelect;

SearchSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  classes: PropTypes.object,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  textFieldProps: PropTypes.object,
  autoFocus: PropTypes.bool,
  getSearchSelectRef: PropTypes.func,
};
