const styles = theme => ({
  loaderOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#006CAB',
    zIndex: 99999,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '50%',
  },
  loaderMessage: {
    color: 'white',
  },
});

export default styles;
