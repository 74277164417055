import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import MediaQuery from 'react-responsive';

// MUI Components
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// MUI Icons
import { default as ClearIcon } from '@material-ui/icons/Clear';
import { default as MenuIcon } from '@material-ui/icons/Menu';
import { default as LogInIcon } from '@material-ui/icons/Person';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Constants
import { CORE, ROUTES } from '../../../constants';

// Actions
import { hideActionDrawer, showActionDrawer } from '../../../actions/UIActions';

// Components
import AccountDrawer from '../AccountDrawer';
import MonPlanLink from '../MonPlanLink';
import { TramOutlined } from '@material-ui/icons';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  hideDrawerAndRedirect = link => {
    this.handleCloseAccountPopover();

    if (link) {
      // handle login separately from usual redirects
      if (link === ROUTES.LOGIN) {
        // behaves strangely if browserHistory.push() is used
        window.location.href = `${window.location.origin}${link}`;
      } else {
        browserHistory.push(link);
      }
    }
  };

  handleOpenAccountPopover = event =>
    this.setState({ anchorEl: event.currentTarget });

  handleCloseAccountPopover = () => this.setState({ anchorEl: null });

  render() {
    const {
      user,
      isAuth,
      classes,
      children,
      hideActionDrawer,
      showActionDrawer,
      showingActionDrawer,
    } = this.props;
    const { anchorEl } = this.state;
    let userInitials = '';
    if (isAuth && user.firstName && user.firstName[0]) {
      userInitials += user.firstName[0];
    }
    if (isAuth && user.lastName && user.lastName[0]) {
      userInitials += user.lastName[0];
    }

    let quickLinks = [];

    if (user) {
      if (user.isStudent) {
        quickLinks = [
          { route: ROUTES.LIST_PLANS, label: 'My Plans', external: false },
          {
            route: CORE.EXTERNAL_LINKS.MONASH.WES,
            label: 'WES',
            external: TramOutlined,
          },
        ];
      } else if (user.isAdmin) {
        quickLinks = [
          {
            route: ROUTES.ADMIN_DASHBOARD,
            label: 'Admin Dashboard',
            external: false,
          },
        ];
      }
    }

    return (
      <React.Fragment>
        <AccountDrawer open={showingActionDrawer} onClose={hideActionDrawer}>
          {children}
        </AccountDrawer>
        <div className={classes.padding} />
        {/* <div className={classes.root}> */}
        <div className={classes.disclaimer} tabIndex={0}>
          <Typography
            style={{ color: 'white', fontWeight: '500' }}
            align="center">
            MonPlan is a planning tool only. Selecting units in MonPlan does not
            automatically enrol you in your selected course. To enrol in units,
            you must go to{' '}
            <a
              style={{ color: 'white' }}
              href={CORE.EXTERNAL_LINKS.MONASH.WES}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit">
              WES
            </a>
            .
          </Typography>
        </div>
        <MediaQuery minDeviceWidth={767}>
          <div className={classes.logoContainer}>
            <a href={CORE.EXTERNAL_LINKS.MONASH.HOME}>
              <img
                className={classes.monashLogo}
                src="/img/monashlogo_white.png"
                alt="Monash University Logo"
              />
            </a>
          </div>
        </MediaQuery>
        <AppBar position="sticky" classes={{ root: classes.appBar }}>
          <Toolbar>
            <Tooltip title="Action Menu">
              <IconButton
                aria-label="Open Account/Navigation Drawer"
                color="inherit"
                onClick={showActionDrawer}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <div style={{ flex: 1, marginLeft: 10 }}>
              <MonPlanLink />
            </div>
            {/* <Tooltip title="Got Feedback?">
            <IconButton
              color="inherit"
              component={Link}
              to={CORE.EXTERNAL_LINKS.MONPLAN.FEEDBACK}>
              <FeedbackIcon />
            </IconButton>
          </Tooltip> */}

            {/* ACCOUNT BUTTON */}
            <IconButton
              onClick={this.handleOpenAccountPopover}
              aria-label="User LogIn Icon">
              <Avatar>{isAuth ? userInitials : <LogInIcon />}</Avatar>
            </IconButton>

            {/* ACCOUNT POPOVER */}
            <Popover
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={this.handleCloseAccountPopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{ paper: classes.popover }}>
              <IconButton
                style={{
                  color: 'black',
                  position: 'absolute',
                  top: 10,
                  right: 17,
                  height: 15,
                  width: 15,
                }}
                aria-label="Close account popup"
                onClick={this.handleCloseAccountPopover}>
                <ClearIcon
                  style={{
                    height: 15,
                    width: 15,
                  }}
                />
              </IconButton>
              <div className={classes.account}>
                <div
                  style={{
                    marginRight: 20,
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Avatar>{isAuth ? userInitials : <LogInIcon />}</Avatar>
                </div>
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: 1,
                  }}>
                  {user ? (
                    <b>
                      {`${user.firstName} ${user.lastName}${
                        user.isStaff ? ' (ADVISOR)' : ''
                      }`}
                    </b>
                  ) : (
                    <b />
                  )}

                  <Typography color="inherit">
                    {isAuth ? `${user.emailAddress}` : 'Sign in below'}
                  </Typography>
                  {isAuth && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}>
                      {quickLinks.map((link, i) => {
                        return link.external ? (
                          <a
                            key={`link${i}`}
                            href={link.route}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: '#2e2e2e',
                              textDecoration: 'none',
                              ':hover': {
                                textDecoration: 'underlined',
                              },
                              ':visited': {
                                color: '#2e2e2e',
                              },
                              fontSize: '0.8em',
                              marginRight: 5,
                            }}>
                            {link.label}
                          </a>
                        ) : (
                          <Link
                            key={`link${i}`}
                            to={link.route}
                            style={{
                              color: '#2e2e2e',
                              textDecoration: 'none',
                              ':hover': {
                                textDecoration: 'underlined',
                              },
                              ':visited': {
                                color: '#2e2e2e',
                              },
                              fontSize: '0.8em',
                              marginRight: 5,
                            }}>
                            {link.label}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 10,
                }}>
                {isAuth ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.hideDrawerAndRedirect(ROUTES.LOGOUT)}>
                    Log Out
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.hideDrawerAndRedirect(ROUTES.LOGIN)}>
                    Log In with Monash SSO
                  </Button>
                )}
              </div>
            </Popover>
          </Toolbar>
        </AppBar>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

Header.defaultProps = {
  title: 'Menu',
};

Header.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  user: state.User.user,
  isAuth: state.User.isAuth,
  showingActionDrawer: state.UI.showingActionDrawer,
});

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    hideActionDrawer,
    showActionDrawer,
  };

  return bindActionCreators(actionBundle, dispatch);
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
