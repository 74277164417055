import React from 'react';

// MUI Components
import Snackbar from '@material-ui/core/Snackbar';

export const NotificationBar = ({
  open,
  message,
  duration,
  hideNotification,
}) => (
  <Snackbar
    aria-live={message}
    open={open}
    onClose={hideNotification}
    message={message}
    autoHideDuration={duration}
  />
);

NotificationBar.propTypes = {};

export default NotificationBar;
