import { STATUSES } from '../constants';

/**
 * Commonly used methods for granting/revoking access to functionality
 * based on role/course plan status should go here
 */
export default class PermissionUtils {
  /**
   * Handles any common functionality with checking permissions for the different
   * combinations of user and statuses, note that in some cases this may not apply
   * in all situations, so other utility functions may be needed
   *
   * e.g. cloning a map is functionality that both student and course advisor can
   * always do, but Monash connect can never do.
   *
   */
  static hasWriteAccess(user, status) {
    if (user.isAusAdvisor || user.isMalaysiaStaff)
      return status === STATUSES.LOCKED;

    if (user.isAusStudent || user.isMalaysiaStudent)
      return status === STATUSES.UNLOCKED;

    // Monash connect people currently can only read
    if (user.isMonashConnect)
      return false;

    // Monash South Africa people currently can only read
    if (user.isMsa)
        return false;

    return false;
  }

  static renderAllowedUserActionButtons({
    user,
    status,
    approvalButton,
    saveButton,
    cloneButton,
    requestReviewButton,
    rejectButton,
    reviewButton,
    printButton,
    validateButton,
  }) {
    switch (status) {
      case STATUSES.APPROVED:
        if (user.isAusAdvisor || user.isMalaysiaStaff
            || user.isAusStudent || user.isMalaysiaStudent)
          return [cloneButton, printButton];

        if (user.isMonashConnect)
          return [validateButton, printButton];

        if (user.isMsa)
          return [];

        return [];

      case STATUSES.LOCKED:
        if (user.isAusAdvisor || user.isMalaysiaStaff)
          return [
            saveButton,
            validateButton,
            approvalButton,
            rejectButton,
            printButton,
          ];

        if (user.isAusStudent || user.isMalaysiaStudent)
          return [validateButton, cloneButton, printButton];

        if (user.isMonashConnect)
          return [validateButton, printButton];

        if (user.isMsa)
          return [];

        return [];

      case STATUSES.UNLOCKED:
        if (user.isAusAdvisor || user.isMalaysiaStaff)
          return [validateButton, reviewButton, cloneButton, printButton];

        if (user.isAusStudent || user.isMalaysiaStudent)
          return [saveButton, validateButton, printButton, requestReviewButton, cloneButton];

        if (user.isMonashConnect)
          return [validateButton, printButton];

        if (user.isMsa)
          return [];

        return [];

      default:
        return [];
    }
  }
}
