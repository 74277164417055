import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

// Actions
import { getUser } from '../../../actions/UserActions';
import { fetchTeachingPeriods } from '../../../actions/CourseActions';

// Components
import Footer from '../Footer';
import Loader from '../Loader';
import BrowserWarning from '../BrowserWarning';
import ScrollToTop from '../ScrollToTop';

// Containers
import NotificationBarContainer from '../../../containers/NotificationBarContainer';

// Services
import AnnouncementsService from '../../../services/AnnouncementsService';
import { Snackbar } from '@material-ui/core';
import Annnouncement from '../Announcement/Annnouncement';

class Main extends React.Component {
  state = {
    announcements: [],
    isAnnouncementsLoading: true,
    announcementOpen: true,
  };
  componentDidMount() {
    this.props.fetchTeachingPeriods();
    this.props.getUser();
    this.setState({ isAnnouncementsLoading: true });
    AnnouncementsService.getAllNonExpiredNotifications()
      .then(data => {
        // object spreading instead of mutation of state
        this.setState({
          announcements: [...data],
          isAnnouncementsLoading: false,
        });
      })
      .catch(() => {
        // don't worry about announcements if they error
        this.setState({
          isAnnouncementsLoading: false,
        });
      });
  }

  isLoading() {
    return (
      this.props.userIsLoading ||
      this.props.tpLoading ||
      this.state.isAnnouncementsLoading
    );
  }

  popFromAnnouncementStack = () => {
    const { announcements } = this.state;
    this.setState({ announcementOpen: false });
    setTimeout(() => {
      if (announcements.length > 1) {
        this.setState({
          announcementOpen: true,
          announcements: [...announcements.slice(1)],
        });
      }
    }, 500);
  };

  renderAnnouncements = () => {
    const { announcements, announcementOpen } = this.state;
    if (announcements.length > 0) {
      const topAnnouncement = announcements[0];
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={announcementOpen}
          autoHideDuration={5000}
          onClose={this.popFromAnnouncementStack}>
          <Annnouncement
            onClose={this.popFromAnnouncementStack}
            variant="info"
            message={topAnnouncement.notification}
          />
        </Snackbar>
      );
    }
    return <React.Fragment />;
  };

  render() {
    const { children } = this.props;

    if (this.isLoading()) {
      return <Loader message="Loading your profile" />;
    }

    return (
      <React.Fragment>
        <ScrollToTop />
        <BrowserWarning />
        {children}
        <Footer />
        <NotificationBarContainer />
        {this.renderAnnouncements()}
      </React.Fragment>
    );
  }
}

Main.propTypes = {
  children: PropTypes.element.isRequired,
};

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    getUser,
    fetchTeachingPeriods,
  };

  return bindActionCreators(actionBundle, dispatch);
};

const mapStateToProps = state => ({
  userIsLoading: state.User.isLoading,
  tpLoading: state.TeachingPeriods.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DragDropContext(HTML5Backend)(Main));
