import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Components
import { Typography, withStyles } from '@material-ui/core';

// Constants
import { ROUTES } from '../constants';

// Actions
import { logout } from '../actions/UserActions';

// Components
import Header from '../components/Base/Header';

// Styling
const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: `${4 * theme.spacing.unit}px 0`,
    minHeight: '100vh',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
});

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    window.location.href = `${window.location.origin}${ROUTES.SAML_LOGOUT}`;
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Header />
        <div className={classes.root}>
          <Typography variant="subheading" align="center" gutterBottom>
            Logging you out, please wait...
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    logout,
  };
  return bindActionCreators(actionBundle, dispatch);
};

const LogoutWithStyles = withStyles(styles)(Logout);
export default connect(
  null,
  mapDispatchToProps,
)(LogoutWithStyles);
