// Actions
import * as actions from './index';

/**
 * shows the adding unit UI
 */
export const showAddingUnitUI = () => {
  return {
    type: actions.SHOW_ADD_UNIT_UI,
  };
};

/**
 * hides the adding unit UI
 */
export const hideAddingUnitUI = () => {
  return {
    type: actions.HIDE_ADD_UNIT_UI,
  };
};

/**
 * shows the move unit UI
 */
export const showMoveUnitUI = () => {
  return {
    type: actions.SHOW_MOVE_UNIT_UI,
  };
};

/**
 * hides the move unit UI
 */
export const hideMoveUnitUI = () => {
  return {
    type: actions.HIDE_MOVE_UNIT_UI,
  };
};

/**
 * shows the confirm delete teaching period modal
 */
export const showConfirmDeleteTeachingPeriodUI = () => {
  return {
    type: actions.SHOW_CONFIRM_DELETE_TEACHING_PERIOD_MODAL,
  };
};

/**
 * hides the confirm delete teaching period modal
 */
export const hideConfirmDeleteTeachingPeriodUI = () => {
  return {
    type: actions.HIDE_CONFIRM_DELETE_TEACHING_PERIOD_MODAL,
  };
};

/**
 * shows the confirm decrease study load modal
 */
export const showConfirmDecreaseStudyLoadUI = () => {
  return {
    type: actions.SHOW_CONFIRM_DECREASE_STUDY_LOAD_MODAL,
  };
};

/**
 * hides the confirm decrease study load modal
 */
export const hideConfirmDecreaseStudyLoadUI = () => {
  return {
    type: actions.HIDE_CONFIRM_DECREASE_STUDY_LOAD_MODAL,
  };
};

/**
 * shows the custom unit modal
 */
export const showCustomUnitUI = unitCode => {
  return {
    type: actions.SHOW_CUSTOM_UNIT_MODAL,
    unitCode,
  };
};

/**
 * hides the custom unit modal
 */
export const hideCustomUnitUI = () => {
  return {
    type: actions.HIDE_CUSTOM_UNIT_MODAL,
  };
};

/**
 * Shows the add unit sidebar. Optionally specify a position for the student
 * to add the unit without having to specify it again.
 *
 * @param {number} [teachingPeriodIndex] - the teaching index of the unit slot
 * @param {number} [unitIndex] - the unit index of the unit slot
 */
export const showSidebar = (teachingPeriodIndex, unitIndex) => {
  return {
    type: actions.SHOW_SIDEBAR,
    teachingPeriodIndex,
    unitIndex,
  };
};

/**
 * hides the add unit sidebar
 */
export const hideSidebar = () => {
  return {
    type: actions.HIDE_SIDEBAR,
  };
};

/**
 * Toggles the palette sidebar
 */
export const togglePalette = () => {
  return {
    type: actions.TOGGLE_PALETTE,
  };
};

/**
 * Sets the course to read only, for snapshot viewing purposes this means the user can safely
 * view but not edit a course map
 */
export const setCourseReadOnly = () => {
  return {
    type: actions.SET_COURSE_READ_ONLY,
  };
};

/**
 * This lets the user edit and view a course map
 */
export const setCourseReadAndWrite = () => {
  return {
    type: actions.SET_COURSE_READ_AND_WRITE,
  };
};

/**
 * Shows the unit detail modal.
 */
export const showUnitModal = year => {
  return {
    type: actions.SHOW_UNIT_MODAL,
    year: year
  };
};

/**
 * Hides the unit detail modal.
 */
export const hideUnitModal = () => {
  return {
    type: actions.HIDE_UNIT_MODAL,
  };
};

/**
 * Shows the feedback input.
 */
export const showAddFeedback = () => {
  return {
    type: actions.SHOW_ADD_FEEDBACK_UI,
  };
};

/**
 * Hides the feedback input.
 */
export const hideAddFeedback = () => {
  return {
    type: actions.HIDE_ADD_FEEDBACK_UI,
  };
};

/**
 * Shows the action drawer.
 */
export const showActionDrawer = () => {
  return {
    type: actions.SHOW_ACTION_DRAWER,
  };
};

/**
 * Hides the action drawer.
 */
export const hideActionDrawer = () => {
  return {
    type: actions.HIDE_ACTION_DRAWER,
  };
};

/**
 * Shows the students grades
 */
export const showGrades = () => {
  return {
    type: actions.SHOW_GRADES,
  };
};

/**
 * Hides the students grades
 */
export const hideGrades = () => {
  return {
    type: actions.HIDE_GRADES,
  };
};

/**
 * Shows the validation modal
 */
export const showValidationModal = () => {
  return {
    type: actions.SHOW_VALIDATION_MODAL,
  };
};

/**
 * Hides the validation modal
 */
export const hideValidationModal = () => {
  return {
    type: actions.HIDE_VALIDATION_MODAL,
  };
};

/**
 * Shows the unit select sidebar
 */
export const showUnitSelectSidebar = isRequired => {
  return {
    type: actions.SHOW_UNIT_SELECT_SIDEBAR,
    showingRequiredUnits: isRequired,
  };
};

/**
 * Hides the unit select sidebar
 */
export const hideUnitSelectSidebar = () => {
  return {
    type: actions.HIDE_UNIT_SELECT_SIDEBAR,
  };
};

/**
 * Updates the current AOS name, this is used for the unit select
 * component, wherein a user selects a unit group to view all elective/required
 * units for and it displays what unit group you are viewing in the sidebar
 */
export const setCurrentAOSName = aosName => {
  return {
    type: actions.SET_CURRENT_AOS_NAME,
    aosName,
  };
};

/**
 * Hides the prefer desktop banner that appears at the top of
 * the course plan on mobile devices
 */
export const hidePreferDesktopBanner = () => {
  return {
    type: actions.HIDE_PREFER_DESKTOP_NOTIFICATION,
  };
};
