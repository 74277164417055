import React, { Fragment } from 'react';
import { Link } from 'react-router';
import moment from 'moment';

// MUI Components
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

// MUI Icons
import { default as MaintenanceModeIcon } from '@material-ui/icons/QuestionAnswer';
import { default as EditIcon } from '@material-ui/icons/Edit';
import { default as DeleteIocn } from '@material-ui/icons/Delete';
import { default as UpIcon } from '@material-ui/icons/ArrowUpward';
import { default as DownIcon } from '@material-ui/icons/ArrowDownward';

// Components
import SmallLoader from '../../Base/SmallLoader';
import BasicActionModal from '../../Modals/BasicActionModal';
import TextFieldValidator, {
  getErrorMessage,
} from '../../Text/TextFieldValidator';

// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class EnquiryTypesConfig extends React.Component {
  renderLastModifiedText = () => {
    const { classes, lastModified, lastModifiedBy } = this.props;
    return (
      <div className={classes.textContainer}>
        <span className={classes.greyText}>
          Last Modified by {lastModifiedBy} at{' '}
          {moment(lastModified).format('D/MM/YYYY hh:mm a')}
        </span>
      </div>
    );
  };

  renderUserActionsArea = () => {
    const {
      enquiryTypes,
      classes,
      deleteItemFromArray,
      readOnlyMode,
      newType,
      latestMoveIndex,
      onNewEnquiryTypeChange,
      onSubmitNewType,
      onMoveEnquiryTypeUpward,
      onMoveEnquiryTypeDownward,
      validatorProps,
    } = this.props;
    return (
      <div className={classes.paper}>
        {enquiryTypes.length >= 0 && (
          <List>
            {enquiryTypes.map((item, index) => {
              return (
                <ListItem
                  key={`${item}_${index}`}
                  className={
                    index === latestMoveIndex ? classes.movedItem : undefined
                  }>
                  <ListItemText>{item}</ListItemText>
                  {!readOnlyMode && (
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Move enquiry type upwards"
                        onClick={() => onMoveEnquiryTypeUpward(index)}
                        disabled={index === 0}>
                        <UpIcon />
                      </IconButton>
                      <IconButton
                        aria-label="Move enquiry type downwards"
                        onClick={() => onMoveEnquiryTypeDownward(index)}
                        disabled={index === enquiryTypes.length - 1}>
                        <DownIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        disabled={enquiryTypes.length === 0}
                        onClick={() => deleteItemFromArray(index)}>
                        <DeleteIocn />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })}
            <ListItem>
              <ListItemText>Other</ListItemText>
              {!readOnlyMode && (
                <ListItemSecondaryAction>
                  <span className={classes.greyText}>
                    <em>You cannot move this item</em>
                  </span>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        )}
        {!readOnlyMode && (
          <div>
            <TextFieldValidator value={newType} {...validatorProps}>
              <TextField
                required
                autoFocus
                label="New Enquiry Type"
                onChange={onNewEnquiryTypeChange}
                value={newType}
                style={{ width: '80%' }}
                classes={{ root: classes.textField }}
                aria-label="Enter a new enquiry type"
                FormHelperTextProps={{
                  className: classes.validationHelperText,
                }}
              />
            </TextFieldValidator>
            <Button
              onClick={onSubmitNewType}
              disabled={Boolean(
                getErrorMessage(newType, validatorProps.validators),
              )}>
              Add
            </Button>
          </div>
        )}
      </div>
    );
  };

  // Render Edit Button on Read Mode
  // Render Submit and Cancel Buttons on Write Mode
  renderActionButtons = () => {
    const {
      classes,
      readOnlyMode,
      cancelWriteMode,
      enableWriteMode,
      openWarningModal,
    } = this.props;
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {readOnlyMode && (
              <Button
                color="primary"
                variant="raised"
                style={{ marginBottom: 10 }}
                onClick={enableWriteMode}>
                <EditIcon />
                Edit
              </Button>
            )}
          </div>
        </div>
        {!readOnlyMode && (
          <div
            style={{
              flex: 2,
              justifyContent: 'flex-end',
              display: 'flex',
            }}>
            <Button
              variant="raised"
              className={classes.button}
              onClick={cancelWriteMode}>
              Cancel
            </Button>
            <Button
              variant="raised"
              color="primary"
              className={classes.button}
              onClick={openWarningModal}>
              Submit
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderConfigurationArea = () => {
    const { isLoading, isError, classes } = this.props;

    return (
      <div>
        {!isLoading &&
          !isError && (
            <div>
              {this.renderUserActionsArea()}
              {this.renderActionButtons()}
              {this.renderLastModifiedText()}
              <Link
                to="/admin/history?type=enquiry-types"
                className={classes.textContainer}>
                View log history for Enquiry Types
              </Link>
            </div>
          )}
      </div>
    );
  };

  renderModal = () => {
    const {
      classes,
      modalLoading,
      modalError,
      hasSuccesfullyUpdatedConfiguration,
      showModal,
      onSubmit,
      onClose,
    } = this.props;

    let modalBody = null;
    if (modalLoading) {
      modalBody = <SmallLoader message="Updating configuration..." />;
    } else if (modalError) {
      modalBody = (
        <div className={classes.errorText}>
          Something went wrong! Cannot update, please try again later.
        </div>
      );
    } else if (hasSuccesfullyUpdatedConfiguration) {
      modalBody = <div>Successfully Updated Enquiry types!</div>;
    } else {
      modalBody = (
        <div>
          By doing this action, you are adding/removing enquiry types selectable
          by students. Are you sure you want to continue?
        </div>
      );
    }
    return (
      <BasicActionModal
        body={modalBody}
        action={'I understand, continue.'}
        title="Warning! You are modifying the enquiry types of MonPlan"
        open={showModal}
        onClose={onClose}
        onAction={onSubmit}
        hasAction={
          !modalError && !modalLoading && !hasSuccesfullyUpdatedConfiguration
        }
        disableCloseButton={modalLoading}
      />
    );
  };
  render = () => {
    const { classes, isLoading, isError } = this.props;

    let content = null;
    if (isLoading) {
      content = (
        <SmallLoader
          message={'Fetching latest configuration for enquiry types...'}
        />
      );
    } else if (isError) {
      content = (
        <span className={classes.errorText}>
          Cannot fetch enquiry options status. Please try again later.
        </span>
      );
    } else {
      content = this.renderConfigurationArea();
    }

    return (
      <Fragment>
        {this.renderModal()}
        <div className={classes.iconAndTitle}>
          <MaintenanceModeIcon className={classes.icon} />
          <h3 className={classes.headings}>Enquiry Types</h3>
        </div>
        {content}
      </Fragment>
    );
  };
}

export default withStyles(styles)(EnquiryTypesConfig);
