const teachingPeriods = [
  { text: 'First semester', value: '1' },
  { text: 'Second semester', value: '2' },
  { text: 'Summer semester A', value: 'SA-02' },
  { text: 'Summer semester B', value: 'SB' },
  { text: 'Full-year', value: 'FY' },
  { text: 'Winter semester', value: 'W' },
  { text: 'Other', value: 'O' },
];

export default teachingPeriods;
