import React from 'react';
import { bool, func, number } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { increaseStudyLoad } from '../../actions/CourseActions';

// Components
import OverloadButton from '../../components/Buttons/OverloadButton/OverloadButton';

/**
 * Need as simple wrapper for this because we pass in mobile, numberOfUnits
 * and teachingPeriodIndex externally, and increaseStudyLoad from redux
 * action creators.
 */
const OverloadButtonContainer = ({
  mobile,
  numberOfUnits,
  teachingPeriodIndex,
  increaseStudyLoad,
  isIntermission,
  isStudyAbroad,
  closeMenu,
}) => {
  return (
    <OverloadButton
      closeMenu={closeMenu}
      mobile={mobile}
      numberOfUnits={numberOfUnits}
      teachingPeriodIndex={teachingPeriodIndex}
      increaseStudyLoad={increaseStudyLoad}
      isIntermission={isIntermission}
      isStudyAbroad={isStudyAbroad}
    />
  );
};

/**
 * Bind course action dispatches to OverloadButton
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    increaseStudyLoad,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  null,
  mapDispatchToProps,
)(OverloadButtonContainer);

OverloadButtonContainer.propTypes = {
  mobile: bool,
  numberOfUnits: number,
  teachingPeriodIndex: number,
  increaseStudyLoad: func,
  isIntermission: bool,
  isStudyAbroad: bool,
};
