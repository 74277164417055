import { STATUSES } from '../constants';

export default class CoursePlanOrdering {
  static HAS_STATUS(plan) {
    // ensure the status field exists on a given plan
    return plan && plan.snapshot && plan.snapshot.status;
  }

  static HAS_DATE(plan) {
    // ensure the modification date exists on a given plan
    return plan && plan.snapshot && plan.snapshot.lastModifiedDate;
  }

  static IS_APPROVED(plan) {
    return this.HAS_STATUS(plan) && plan.snapshot.status === STATUSES.APPROVED;
  }

  static IS_LOCKED(plan) {
    return this.HAS_STATUS(plan) && plan.snapshot.snapshot === STATUSES.LOCKED;
  }

  static IS_UNLOCKED(plan) {
    return (
      this.HAS_STATUS(plan) && plan.snapshot.snapshot === STATUSES.UNLOCKED
    );
  }

  /** Utilises https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort */
  static sortForAdvisorView(plans) {
    return plans.sort((a, b) => {
      if (this.IS_APPROVED(a) && !this.IS_APPROVED(b)) {
        return -1;
      } else if (!this.IS_APPROVED(a) && this.IS_APPROVED(b)) {
        return 1;
      }

      if (this.IS_LOCKED(a) && !this.IS_LOCKED(b)) {
        return -1;
      } else if (this.IS_LOCKED(a) && !this.IS_LOCKED(b)) {
        return 1;
      }

      if (this.HAS_DATE(a) && this.HAS_DATE(b)) {
        if (a.snapshot.lastModifiedDate < b.snapshot.lastModifiedDate) {
          return -1;
        } else if (a.snapshot.lastModifiedDate > b.snapshot.lastModifiedDate) {
          return 1;
        }
      }

      return 0;
    });
  }

  stat;
}
