import React, { Component } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// MUI Icons
import { default as CalendarIcon } from '@material-ui/icons/Today';
import { default as LocationIcon } from '@material-ui/icons/LocationOn';
import { default as ShortTextIcon } from '@material-ui/icons/ShortText';
import { default as BookIcon } from '@material-ui/icons/LibraryBooks';

// Utils
import FacultyUtils from '../../../utils/FacultyUtils';

// Constants
import CORE from '../../../constants/core';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Components
import CourseDescription from '../../Course/CourseDescription';
import QuickInfo from './QuickInfo';
import HandbookUtils from '../../../utils/HandbookUtils';

class CourseDetailModal extends Component {
  render() {
    const {
      open,
      onClose,
      fullScreen,
      classes,
      courseInfo,
      startYear,
    } = this.props;
    const {
      courseCode,
      courseName,
      modeAndLocation,
      creditPoints,
      faculty,
      abrTitle,
      durationStr,
    } = courseInfo;

    const facultyColor = FacultyUtils.getFacultyColour(courseInfo.faculty);
    const accessibleCourseCode = courseInfo.courseCode.split('').join(' ');
    const aria =
      faculty && courseName
        ? `Information for course ${accessibleCourseCode}, ${
            courseInfo.courseName
          }. Managed by faculty ${faculty}`
        : CORE.ERROR.NO_DATA;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.MuiDialog,
          paperWidthXs: classes.MuiDialog,
          paperWidthSm: classes.MuiDialog,
        }}>
        <div
          tabIndex="0"
          aria-label={aria}
          className={classes.courseDetailModalTitle}
          style={{ background: facultyColor }}>
          <h3 className={classes.courseName}>
            {courseCode && courseName
              ? `${courseCode} - ${courseName}`
              : CORE.ERROR.NO_DATA}
          </h3>
          <p className={classes.subtitle}>
            Managing Faculty: {faculty ? faculty : CORE.ERROR.NO_DATA}
          </p>
        </div>
        <div className={classes.courseDetailModalContent}>
          <div className={classes.innerContent}>
            <div className={classes.leftContent}>
              <QuickInfo
                icon={<BookIcon />}
                primaryText={'Total Credit Points'}
                secondaryText={
                  creditPoints
                    ? `${courseInfo.creditPoints} Credit Points`
                    : CORE.ERROR.NO_DATA
                }
              />
              <QuickInfo
                icon={<ShortTextIcon />}
                primaryText={'Abbreviated Title'}
                secondaryText={abrTitle ? abrTitle : CORE.ERROR.NO_DATA}
              />
              <QuickInfo
                icon={<CalendarIcon />}
                primaryText={'Course Duration'}
                secondaryText={durationStr ? durationStr : CORE.ERROR.NO_DATA}
              />
              <QuickInfo
                icon={<LocationIcon />}
                primaryText={'Location and Mode'}
                secondaryText={
                  modeAndLocation ? modeAndLocation : CORE.ERROR.NO_DATA
                }
              />
            </div>
            <div className={classes.rightContent}>
              {courseInfo.courseDescription ? (
                <CourseDescription description={courseInfo.courseDescription} />
              ) : (
                <p>No course description available</p>
              )}
            </div>
          </div>
          <div className={classes.actions}>
            <span className={classes.unitLinkContainer}>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Open a new tab to the Monash handbook for your course"
                href={HandbookUtils.getCourseLink(courseCode, startYear)}
                style={{ color: facultyColor }}>
                Handbook
              </Button>
            </span>
            <Button
              style={{ color: facultyColor, float: 'right' }}
              onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

CourseDetailModal.defaultProps = {
  classes: {
    title: 'mui-title',
    content: 'mui-content',
    contentSubsection: 'mui-contentSubsection',
    subheadingContainer: 'mui-subheadingContainer',
    subheading: 'mui-subheading',
    externalLinkButton: 'mui-externalLinkButton',
  },
};

CourseDetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  startYear: PropTypes.number,
  courseInfo: PropTypes.object,
};

export default withStyles(styles)(withMobileDialog()(CourseDetailModal));
