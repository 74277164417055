import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Typography from '@material-ui/core/Typography';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Constants
import { CORE } from '../../../constants';
import MonPlanLink from '../MonPlanLink';

export const Footer = ({ classes }) => (
  <div id="monplan-footer" className={classes.root}>
    <div className={classes.inner}>
      <div className={classes.logoContainer}>
        <a href={CORE.EXTERNAL_LINKS.MONASH.HOME}>
          <img
            className={classes.monashLogo}
            src="/img/monashlogo_white.png"
            alt="Monash University Logo"
          />
        </a>
        <MonPlanLink />
      </div>
      <div style={{ margin: '0.5em 0 1em 0' }}>
        <Typography color="inherit" gutterBottom>
          {CORE.FOOTER.MONPLAN_VERSION}
        </Typography>
      </div>
      <div>
        <Typography color="inherit" gutterBottom className={classes.lightFont}>
          {CORE.FOOTER.AUTHORISED_BY}
          {' Maintained By: eSolutions, Monash University'}
        </Typography>
        <Typography color="inherit" gutterBottom className={classes.lightFont}>
          {CORE.FOOTER.COPYRIGHT}{' '}
          <FooterLink to={CORE.EXTERNAL_LINKS.MONASH.HOME}>
            Monash University
          </FooterLink>
          {' and '}
          <FooterLink to={CORE.EXTERNAL_LINKS.MONASH.COLLEGE}>
            Monash College
          </FooterLink>
          {' - '}
          {CORE.FOOTER.UNI.ABN}
          {' // '}
          {CORE.FOOTER.UNI.CRICOS}
          {' - '}
          <FooterLink to={CORE.EXTERNAL_LINKS.MONASH.ACCESSIBILITY}>
            Accessibility
          </FooterLink>
          {' - '}
          <FooterLink to={CORE.EXTERNAL_LINKS.MONASH.DISCLAIMER_COPYRIGHT}>
            Disclaimer and Copyright
          </FooterLink>
          {' - '}
          <FooterLink to={CORE.EXTERNAL_LINKS.MONASH.PRIVACY}>
            Privacy
          </FooterLink>
        </Typography>
        <Typography color="inherit" gutterBottom className={classes.lightFont}>
          {CORE.FOOTER.ACKNOWLEDGEMENT}{' '}
          <FooterLink to={CORE.EXTERNAL_LINKS.MONASH.INDIGENOUS}>
            Information for Indigenous Australians
          </FooterLink>
        </Typography>
      </div>
    </div>
  </div>
);

Footer.propTypes = {};

export const FooterLink = ({ to, children }) => (
  <a
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: 'inherit' }}>
    {children}
  </a>
);

FooterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default withStyles(styles)(Footer);
