import { EVENT_STATUSES, ENQUIRY_STATUSES } from '../../constants';

export const MOCK_GET_COURSE_PLAN_EVENTS = [
  {
    snapshot: {
      snapshotId: 5764017373052928,
      coursePlanId: 5711898079133696,
      lastModifiedDate: 1546820238947,
      lastModifiedBy: 'ejia0002',
      blob: '',
      status: 'SNAPSHOT_LOCKED',
      locked: null,
      hasUserBeenToWES: null,
      userBeenToWESSource: null,
      dateBeenToWES: null,
      approved: false,
      unlocked: false,
      lockedOrApproved: false,
      unlockedOrApproved: false,
    },
    feedbackList: [],
    enquiries: [],
    events: [
      {
        id: 172148079133696,
        coursePlanId: 5711898079133696,
        statusType: EVENT_STATUSES.APPROVED,
        timestamp: 1546820238947,
        user: 'Darth V',
        snapshotId: 5764017373052928,
      },
    ],
  },
  {
    snapshot: {
      snapshotId: 5135440319348736,
      coursePlanId: 5711898079133696,
      lastModifiedDate: 1546820219352,
      lastModifiedBy: 'ejia0002',
      blob: '',
      status: 'SNAPSHOT_LOCKED',
      locked: null,
      hasUserBeenToWES: null,
      userBeenToWESSource: null,
      dateBeenToWES: null,
      approved: false,
      unlocked: false,
      lockedOrApproved: false,
      unlockedOrApproved: false,
    },
    feedbackList: [
      {
        feedbackId: 5741200225075200,
        snapshotId: 5653625506365440,
        timestamp: 1546820238947,
        user: 'Darth V',
        snapshotFeedback:
          "No, my father didn't fight in the wars. He was a navigator on a spice freighter. That's what your uncle told you. He didn't hold with your father's ideals. Thought he should have stayed here and not gotten involved. You fought in the Clone Wars? Yes, I was once a Jedi Knight the same as your father. I wish I'd known him. He was the best star-pilot in the galaxy, and a cunning warrior. I understand you've become quite a good pilot yourself. And he was a good friend. Which reminds me... I have something here for you. Your father wanted you to have this when you were old enough, but your uncle wouldn't allow it. He feared you might follow old Obi-Wan on some damned-fool idealistic crusade like your father did.",
      },
    ],
    enquiries: [],
    events: [],
  },
  {
    snapshot: {
      snapshotId: 5653625506365440,
      coursePlanId: 5711898079133696,
      lastModifiedDate: 1546818802707,
      lastModifiedBy: 'ejia0006',
      blob: '',
      status: 'SNAPSHOT_LOCKED',
      locked: null,
      hasUserBeenToWES: null,
      userBeenToWESSource: null,
      dateBeenToWES: null,
      approved: false,
      unlocked: false,
      lockedOrApproved: false,
      unlockedOrApproved: false,
    },
    feedbackList: [
      {
        feedbackId: 5698390272770048,
        snapshotId: 5653625506365440,
        timestamp: 1531353033244,
        user: 'Darth V',
        snapshotFeedback:
          "Excellent work! What is it? I'm afraid I'm not quite sure, sir. He says I found her, and keeps repeating, She's here. Well, who...who has he found? Princess Leia. The princess? She's here? Princess? What's going on? Level five. Detention block A A-twenty-three. I'm afraid she's scheduled to be terminated. Oh, no! We've got to do something. What are you talking about? The droid belongs to her. She's the one in the message.. We've got to help her. Now, look, don't get any funny ideas. The old man wants us to wait right here. But he didn't know she was here. Look, will you just find a way back into the detention block?",
      },
      {
        feedbackId: 5741200225075200,
        snapshotId: 5653625506365440,
        timestamp: 1531351033244,
        user: 'Darth V',
        snapshotFeedback:
          "No, my father didn't fight in the wars. He was a navigator on a spice freighter. That's what your uncle told you. He didn't hold with your father's ideals. Thought he should have stayed here and not gotten involved. You fought in the Clone Wars? Yes, I was once a Jedi Knight the same as your father. I wish I'd known him. He was the best star-pilot in the galaxy, and a cunning warrior. I understand you've become quite a good pilot yourself. And he was a good friend. Which reminds me... I have something here for you. Your father wanted you to have this when you were old enough, but your uncle wouldn't allow it. He feared you might follow old Obi-Wan on some damned-fool idealistic crusade like your father did.",
      },
    ],
    enquiries: [
      {
        publicCaseId: '321',
        submissionState: ENQUIRY_STATUSES.ENQUIRY_PUBLIC_CASE_ID_AVAILABLE,
        snapshotId: 5679790782676992,
        timestamp: 1531353033244,
        user: 'studentName',
        message: 'Hey, can you please look at my first year electives',
      },
    ],
    events: [
      {
        id: 172148079133696,
        coursePlanId: 5711898079133696,
        snapshotId: 5653625506365440,
        statusType: EVENT_STATUSES.FORCED_REVIEW,
        timestamp: 1546820238947,
        user: 'Darth V',
      },
      {
        id: 172148079133696,
        coursePlanId: 5711898079133696,
        statusType: EVENT_STATUSES.REJECTED,
        timestamp: 1546820238947,
        snapshotId: 5653625506365440,
        user: 'Darth V',
      },
    ],
  },
];

export const MOCK_FEEDBACK_RESPONSE = [
  {
    feedbackId: 5715785555711488,
    snapshotId: 6196176244178944,
    timestamp: 1531353033244,
    user: 'Eric J.',
    snapshotFeedback: 'Last Comment',
  },
  {
    feedbackId: 5715789755711488,
    snapshotId: 6196176244178944,
    timestamp: 1531351033244,
    user: 'Eric J.',
    snapshotFeedback: 'Should move this to there',
  },
  {
    feedbackId: 5739407210446848,
    snapshotId: 6196176244178944,
    timestamp: 1531350885333,
    user: 'Eric J.',
    snapshotFeedback: 'Also should do this',
  },
];
