import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import IconButton from '@material-ui/core/IconButton';

// MUI Icons
import { default as ArrowIcon } from '@material-ui/icons/ArrowForward';
import TextFieldValidator, {
  getErrorMessage,
} from '../../components/Text/TextFieldValidator';
import { LIMITS } from '../../constants';

class BlankCourseForm extends React.Component {
  inputValid = () => {
    const { coursePlanName } = this.props;
    return (
      coursePlanName !== '' &&
      !getErrorMessage(
        coursePlanName,
        TextFieldValidator.CharacterCount(
          LIMITS.CHARACTER_LIMITS.COURSE_PLAN_NAME,
        ).validators,
      )
    );
  };

  handleSubmit = () => {
    this.props.onSubmit(this.props.coursePlanName);
  };

  render() {
    const { classes } = this.props;

    const inputValid = this.inputValid();

    const iconButtonClass = inputValid
      ? classes.submitArrowButton
      : `${classes.submitArrowButton} ${classes.submitArrowButtonDisabled}`;

    const iconClass = inputValid
      ? classes.submitArrow
      : `${classes.submitArrow} ${classes.submitArrowDisabled}`;

    return (
      <div id="blank-course-form" className={classes.submitArrowContainer}>
        <IconButton
          disabled={!inputValid}
          tabIndex={0}
          aria-label="Next"
          className={iconButtonClass}
          onClick={this.handleSubmit}>
          <ArrowIcon className={iconClass} />
        </IconButton>
      </div>
    );
  }
}

export default BlankCourseForm;

BlankCourseForm.propTypes = {
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
  coursePlanName: PropTypes.string,
};
