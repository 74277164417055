import React from 'react';
import PropTypes from 'prop-types';

import BasicActionModal from '../BasicActionModal';

class ApproveCourseModal extends React.Component {
  render() {
    const { open, onClose, onApprove, isMalaysiaAdviser } = this.props;
    return (
      <BasicActionModal
        open={open}
        onClose={onClose}
        onAction={onApprove}
        title={'Approve Course Plan'}
        body={
          'Are you sure that you want to approve this course plan? Once you approve a course plan, the plan can no' +
          ' longer be edited by the student or yourself. Approvals are final' + (!isMalaysiaAdviser ? ' - and' +
              ' should be treated as legally binding course advice' : '') + '.'
        }
        action={'Approve course plan'}
      />
    );
  }
}

export default ApproveCourseModal;

ApproveCourseModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onApprove: PropTypes.func,
  isMalaysiaAdviser: PropTypes.bool
};
