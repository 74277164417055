import * as actions from '../../actions';

const defaultState = {
  data: [],
  isLoading: false,
  error: null,
};

/**
 * Stores the results from the unit search api
 */
const UnitSearchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_UNITS_PENDING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actions.FETCH_UNITS_FULFILLED:
      return {
        ...state,
        data: action.payload.map(unit => ({
          unitCode: unit.code,
          unitName: unit.title,
          faculty: unit.faculty,
          creditPoints: unit.creditPoints,
        })),
        isLoading: false,
      };

    case actions.FETCH_UNITS_REJECTED:
      return {
        ...state,
        data: [],
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default UnitSearchReducer;
