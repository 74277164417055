import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { ROUTES } from '../constants';

import Main from '../components/Base/Main';
import * as Pages from '../pages';

export default (
  <Route path="/" component={Main}>
    <IndexRoute component={Pages.Landing} />
    <Route path={`${ROUTES.ERROR}/:errorCode`} component={Pages.ErrorPage} />
    <Route path={ROUTES.DISCLAIMER} component={Pages.Disclaimer} />
    <Route path={ROUTES.LIST_PLANS} component={Pages.ListPlans} />
    <Route path={ROUTES.CREATE_NEW_PLAN} component={Pages.NewPlan} />
    <Route
      path={`${ROUTES.EDIT_PLAN}/:coursePlanId`}
      component={Pages.EditPlan}
    />
    <Route path={`${ROUTES.STUDENT_SEARCH}`} component={Pages.StudentSearch} />
    <Route
      path={`${ROUTES.STUDENT_SEARCH}/:studentId`}
      component={Pages.ListPlans}
    />
    <Route
      path={`${ROUTES.PRINT_PLAN}/:coursePlanId`}
      component={Pages.PrintPlan}
    />
    <Route path={ROUTES.LOGOUT} component={Pages.Logout} />

    <Route path={ROUTES.ADMIN_DASHBOARD} component={Pages.AdminDashboard} />
    <Route path={ROUTES.ADMIN_HISTORY} component={Pages.AdminHistory} />
    <Route path="*" component={Pages.Missing} />
  </Route>
);
