const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  table: {
    width: '100%',
    maxWidth: '100%',
  },
  configurationCol: {
    maxWidth: '15%',
  },
});

export default styles;
