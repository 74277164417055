import NetworkService from './NetworkService';
import { MONPLAN_API_URL, MSCV_API_URL } from '../constants';

import DataTransformUtils from '../utils/DataTransformUtils';
import CoreUtils from '../utils/CoreUtils';

import { MOCK_PERIODS_INFO } from './data/handbookMockData';

import {
  MOCK_AVAILABLE_AOS_RESP,
  MOCK_COURSE_STRUCTURE_RESP,
} from './data/mockData';

import {
  MOCK_COURSE_TIMELINE_SINGLE_CHOICE,
  MOCK_COURSE_TIMELINE_MULTI_CHOICE,
  MOCK_COURSE_TIMELINE_SMALL_CHOICE,
  MOCK_D30061_TIMELINE,
  MOCK_MSCV_RESP,
} from './data/mockData';

import { MOCK_RESPONSE_TIME } from './constants';

export default class CourseService {
  static getAllTeachingPeriods() {
    const requestURI = `${MONPLAN_API_URL}/api/handbooks/periods`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CourseService.getAllTeachingPeriods()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_PERIODS_INFO,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI);
  }

  static validateCourse(
    startYear,
    advancedStanding,
    teachingPeriods,
    courseCode,
  ) {
    const requestURI = `${MSCV_API_URL}`;
    const body = {
      ...DataTransformUtils.mscvCourseFormat(
        startYear,
        advancedStanding,
        teachingPeriods,
        courseCode,
      ),
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CourseService.validateCourse()',
        requestURI,
        'POST',
        body,
      );

      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_MSCV_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static getAvailableAOS(courseCode) {
    const requestURI = `${
      window.location.origin
    }/api/coursebuild/aos/${courseCode}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('CourseService.getAvailableAOS()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_AVAILABLE_AOS_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getCourseStructure(courseCode, selectedAOSes) {
    const baseURI = `${
      window.location.origin
    }/api/coursebuild/structure/${courseCode}`;

    let query = '';
    if (selectedAOSes.length > 0) {
      query = '?';
      const queryArgs = selectedAOSes.map((aosCode, i) =>
        i === 0 ? `aos=${aosCode}` : `&aos=${aosCode}`,
      );

      query = query + queryArgs.join('');
    }

    const requestURI = `${baseURI}${query}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('CourseService.getCourseStructure()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_COURSE_STRUCTURE_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getCourseTimelineChoices(path = null) {
    if (process.env.NODE_ENV !== 'production') {
      const str = '$>>S2006>>7174>>7175>>MAJOR';
      const minorPath = '$>>S2006>>7174>>7175>>MINOR';
      return new Promise(resolve => {
        setTimeout(() => {
          if (!path) {
            return resolve(MOCK_COURSE_TIMELINE_SINGLE_CHOICE);
          }

          if (path.length > str.length + 12) {
            return resolve([]);
          }

          if (path.includes(minorPath)) {
            return resolve([]);
          }

          if (path.includes(str)) {
            return resolve(MOCK_COURSE_TIMELINE_SMALL_CHOICE);
          }

          return resolve(MOCK_COURSE_TIMELINE_MULTI_CHOICE);
        }, MOCK_RESPONSE_TIME);
      });
    }

    let query = '';
    if (path) {
      query = `?path=${path}`;
    }

    const requestURI = `${MONPLAN_API_URL}/api/timelines/choices${query}`;
    return NetworkService.get(requestURI);
  }

  static getCourseTimeline(paths, year) {
    if (process.env.NODE_ENV !== 'production') {
      return new Promise(resolve => {
        setTimeout(() => {
          return resolve(MOCK_D30061_TIMELINE);
        }, MOCK_RESPONSE_TIME);
      });
    }

    const baseURI = `${window.location.origin}/api/timelines/timeline`;

    let yearQuery = `?year=${year}`;
    let pathQuery = paths.map(path => `&path=${path}`).join('');
    const query = `${yearQuery}${pathQuery}`;

    const requestURI = `${baseURI}${query}`;

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
}
