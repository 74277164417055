const CORE = {
  GENERAL: {
    SLOGAN: 'The Monash course planner built for students, by students.',
    DISCLAIMER:
      'MonPlan is BETA software, our course plans may have inaccuracies and are subject to change. We currently only support undergraduate courses, and our course plans are based off of 2018 domestic courses at on-shore (Australian) campuses. Please verify your course plan with a faculty course adviser before enrolling in any units. This is not a Monash University initiative - MonPlan is built by students for students.',
    DISCLAIMER_MODAL:
      "You acknowledge by clicking 'Continue' that you understand that MonPlan does not represent legally binding course advice. You should run all decisions by a course adviser. monPlan is a tool used to help in the planning of course progression, and should not be treated as a source of truth.",
  },
  FOOTER: {
    MONPLAN_VERSION: `MonPlan - Version ${process.env.REACT_APP_VERSION}`,
    AUTHORISED_BY:
      'Authorised by: Manager, Curriculum Data and Assessment Support.',
    TEAM_NAME: 'MonPlan Team, Student First',
    COPYRIGHT: `Copyright \u00A9 ${new Date().getFullYear()} `,
    UNI: {
      ABN: 'ABN 12 377 614 012',
      CRICOS: 'CRICOS Provider Number: 00008C / 001857J',
    },
    ACKNOWLEDGEMENT:
      'We acknowledge and pay respects to the Elders and Traditional Owners of the land on which our four Australian campuses stand. ',
  },
  ERROR: {
    NO_DATA: 'No data for this course is available.',
  },
  EXTERNAL_LINKS: {
    MONPLAN: {
      HOME: 'https://monplan.github.io',
      FEEDBACK:
        'https://docs.google.com/forms/d/e/1FAIpQLSfv0_wzybC71xskxYRKkt1MVsZxAvRYjy5Ahx5TexGrmU8RkQ/viewform',
      ISSUE: 'https://servicedeskonline.monash.edu',
    },
    MONASH: {
      HOME: 'https://www.monash.edu',
      COLLEGE: 'https://www.monashcollege.edu.au',
      ACCESSIBILITY: 'https://www.monash.edu/accessibility',
      DISCLAIMER_COPYRIGHT: 'https://www.monash.edu/disclaimer-copyright',
      PRIVACY: 'https://www.monash.edu/privacy',
      INDIGENOUS: 'https://www.monash.edu/about/indigenous',
      WES: 'https://my.monash.edu.au/wes',
      FACULTY_COURSE_ADVICE: 'https://www.monash.edu/faculties',
      HANDBOOK: 'http://www.monash.edu.au/pubs/handbooks/',
      COURSE_ADVICE: 'https://www.monash.edu/students/resources/course-advice',
      SERVICE_DESK: 'https://servicedeskonline.monash.edu',
    },
    MSA: {
      COURSE_EXPIRY:
        'https://www.iiemsa.co.za/assets/study/msa-course-expiry-date.pdf',
      CONTACT_US: 'https://www.iiemsa.co.za/contact-us/',
    },
  },
};

export default CORE;
