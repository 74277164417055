import React, { Component } from 'react';
import { number, bool, array, string, func, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// MUI Components
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// MUI Icons
import { default as WarningIcon } from '@material-ui/icons/Warning';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Actions
import { clearCourse, insertTeachingPeriod } from '../../actions/CourseActions';

import { showSidebar, showGrades, hideGrades } from '../../actions/UIActions';

// Containers
import NoTeachingPeriodContainer from '../NoTeachingPeriodContainer';
import InsertTeachingPeriodButtonContainer from '../InsertTeachingPeriodButtonContainer';

// Components
import Loader from '../../components/Base/Loader';
import TeachingPeriod from '../../components/TeachingPeriod/TeachingPeriod';
import ConfirmDeleteTeachingPeriodModal from '../../components/Modals/ConfirmDeleteTeachingPeriodModal';
import UnitDetailModal from '../../components/Modals/UnitDetailModal';
import InsertTeachingPeriodForm from '../../components/TeachingPeriod/InsertTeachingPeriodForm';
/**
 * PlanInstanceContainer holds a table that allows students to plan their courses by
 * adding, moving and deleting units. It also holds action and status components
 * to give students feedback and ability to change the course structure.
 *
 * @extends React.Component
 */
class PlanInstanceContainer extends Component {
  constructor(props) {
    super(props);
    this.insertTeachingPeriodRef = React.createRef();
    this.state = {
      courseErrorOpen: false,
      courseErrorAnchorEl: null,
      showingInsertTeachingPeriodUI: false,
      screenWidth:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    };
  }

  /**
   * This is necessary for passing down changes in the totals from the parent plan element,
   * it keeps the totals updated.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.courseToLoad && this.state.unlock && nextProps.courseYear) {
      if (nextProps.courseToLoad !== this.state.courseToLoad) {
        this.courseLoad(nextProps.courseToLoad, nextProps.courseYear);
      }
    }
  }

  /**
   * Loads course if it exists.
   *
   * @author Saurabh Joshi
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  /**
   * @author Saurabh Joshi
   */
  componentWillUnmount() {
    const { viewOnly } = this.props;
    if (viewOnly) {
      return;
    }
    /**
     * Remove event listener
     */
    window.removeEventListener('resize', this.updateDimensions);
  }

  handleErrorClick = e => {
    e.stopPropagation();

    this.setState({
      courseErrorOpen: true,
      courseErrorAnchorEl: e.currentTarget,
    });
  };

  handleRequestErrorClose = e => {
    e.stopPropagation();
    this.setState({
      courseErrorOpen: false,
    });
  };

  renderErrorButton = () => {
    const { errors } = this.props;
    const { courseErrorOpen, courseErrorAnchorEl } = this.state;

    return (
      <React.Fragment>
        <IconButton
          onClick={this.handleErrorClick}
          style={{
            width: '40px',
            height: '40px',
            marginRight: '15px',
            color: 'rgba(155, 54, 54, 1)',
          }}
          aria-label="View errors detected with this course">
          <WarningIcon style={{ width: 25, height: 25 }} />
        </IconButton>
        <Popover
          open={courseErrorOpen}
          anchorEl={courseErrorAnchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          anchorPosition={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleRequestErrorClose}>
          <div style={{ maxWidth: 350, paddingRight: 15 }}>
            <p
              style={{
                fontWeight: '550',
                paddingLeft: 15,
                fontSize: '13px',
              }}>
              The following issues were identified:{' '}
            </p>
            <ul style={{ fontSize: '12px' }}>
              {errors.map((d, i) => {
                return <li key={i}>{d.description}</li>;
              })}
            </ul>
          </div>
        </Popover>
      </React.Fragment>
    );
  };

  courseHasErrors = () => {
    const { errors } = this.props;
    return errors.length > 0;
  };

  updateDimensions = () => {
    this.setState({
      screenWidth:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    });
  };

  /**
   * Returns a table of teaching periods as table rows, and in teaching period
   * holds a list of units represented as table cells. It also renders an add button
   * as well as some status labels.
   *
   * @author Saurabh Joshi
   * @returns {ReactElement} PlanInstanceContainer
   */
  render() {
    const {
      classes,
      readOnly,
      hideGrades,
      showGrades,
      showSidebar,
      courseErrors,
      coursePlanLoading,
      showingGrades,
      teachingPeriods,
    } = this.props;

    const { screenWidth, showingInsertTeachingPeriodUI } = this.state;

    if (coursePlanLoading) {
      return <Loader message={'Loading Course Plan'} />;
    }
    const teachingPeriodComponents = teachingPeriods.map(
      (teachingPeriod, i) => {
        let unitErrors = {};
        let tpErrors = [];

        if (
          courseErrors.unit &&
          courseErrors.unit[teachingPeriod.year] &&
          courseErrors.unit[teachingPeriod.year][teachingPeriod.code]
        ) {
          unitErrors =
            courseErrors.unit[teachingPeriod.year][teachingPeriod.code];
        }

        if (
          courseErrors.teachingPeriod &&
          courseErrors.teachingPeriod[teachingPeriod.year] &&
          courseErrors.teachingPeriod[teachingPeriod.year][teachingPeriod.code]
        ) {
          tpErrors =
            courseErrors.teachingPeriod[teachingPeriod.year][
              teachingPeriod.code
            ];
        }

        return (
          <TeachingPeriod
            viewOnly={readOnly}
            key={`${teachingPeriod.year}-${teachingPeriod.code}`}
            index={i}
            isIntermission={teachingPeriod.isIntermission}
            isStudyAbroad={teachingPeriod.isStudyAbroad}
            year={teachingPeriod.year}
            code={teachingPeriod.code}
            units={teachingPeriod.units}
            numberOfUnits={teachingPeriod.numberOfUnits}
            unitErrors={unitErrors}
            errors={tpErrors}
            isPreloaded={teachingPeriod.isPreloaded}
            screenWidth={screenWidth}
          />
        );
      },
    );

    let hasPreloadedData = false;
    for (let i = 0; i < teachingPeriods.length; i++) {
      if (teachingPeriods[i].isPreloaded) {
        hasPreloadedData = true;
        break;
      }
    }

    return (
      <div style={{ borderTop: '1px solid rgb(209, 211, 212)' }}>
        <ConfirmDeleteTeachingPeriodModal />
        <UnitDetailModal />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <div
              style={{
                padding: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: screenWidth < 900 ? 'column' : 'row',
                  justifyContent: screenWidth < 900 ? 'center' : undefined,
                  width: screenWidth < 900 ? '100%' : undefined,
                }}>
                {!readOnly &&
                  teachingPeriods.length > 0 &&
                  !showingInsertTeachingPeriodUI && (
                    <Button
                      onClick={showSidebar}
                      style={{
                        margin: screenWidth < 900 ? '0.5em 0' : undefined,
                      }}
                      color="primary"
                      variant="contained"
                      className={classes.button}>
                      Add Unit
                    </Button>
                  )}
                {!readOnly &&
                  teachingPeriods.length > 0 &&
                  showingInsertTeachingPeriodUI && (
                    <InsertTeachingPeriodForm
                      screenWidth={screenWidth}
                      onSubmitCb={() => {
                        this.setState({ showingInsertTeachingPeriodUI: false });
                      }}
                    />
                  )}
                {!readOnly &&
                  teachingPeriods.length > 0 && (
                    <Button
                      onClick={() =>
                        this.setState({
                          showingInsertTeachingPeriodUI: !this.state
                            .showingInsertTeachingPeriodUI,
                        })
                      }
                      style={{
                        marginLeft: '0.5em',
                      }}>
                      {this.state.showingInsertTeachingPeriodUI
                        ? 'Cancel'
                        : 'Add Teaching Period'}
                    </Button>
                  )}
              </div>
              {!this.state.showingInsertTeachingPeriodUI && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {this.courseHasErrors() && this.renderErrorButton()}
                  {hasPreloadedData && (
                    <FormControlLabel
                      label="Show Grades"
                      control={
                        <Switch
                          checked={showingGrades}
                          onChange={showingGrades ? hideGrades : showGrades}
                          color="primary"
                        />
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {teachingPeriodComponents}
          {teachingPeriods.length === 0 && (
            <NoTeachingPeriodContainer
              key="no-teaching-period"
              viewOnly={readOnly}
              noFloat
              screenWidth={screenWidth}
            />
          )}
        </div>
        {!readOnly && (
          <InsertTeachingPeriodButtonContainer
            insertTeachingPeriodRef={this.insertTeachingPeriodRef}
          />
        )}
      </div>
    );
  }
}

/**
 * Set up any props you want course structure to be passed here
 */
const mapStateToProps = state => {
  return {
    teachingPeriods: state.PlanInstance.teachingPeriods,
    coursePlanLoading: state.CoursePlan.coursePlanLoading,
    coursePlanError: state.CoursePlan.coursePlanError,
    teachingPeriodCodeToInsert:
      state.PlanInstance.teachingPeriodCodeToInsert,
    readOnly: state.UI.readOnly,
    showingGrades: state.UI.showingGrades,
    showingInsertTeachingPeriodUI: state.UI.showingInsertTeachingPeriodUI,
    courseInfo: state.Handbook.courseInfo,
    courseErrors: state.Validation.courseErrors,
    errors: state.Validation.courseErrors.course,
  };
};

/**
 * Set up any functions from the action creators you want to pass in
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    clearCourse,
    showSidebar,
    showGrades,
    hideGrades,
    insertTeachingPeriod,
  };

  return bindActionCreators(actionBundle, dispatch);
};

const TeachingPeriodsContainerWithStyles = withStyles(styles)(
  PlanInstanceContainer,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeachingPeriodsContainerWithStyles);

PlanInstanceContainer.propTypes = {
  viewOnly: bool,
  switchToEditCourse: bool,
  teachingPeriods: array,

  courseErrors: object,

  snapID: string,
  showingInsertTeachingPeriodUI: bool,
  teachingPeriodCodeToInsert: string,
  courseLoading: bool,

  handleEditCoursePlanClick: func,
  clearCourse: func,
  courseInfo: object,

  courseToLoad: string,
  courseYear: number,

  bottom: bool,
};
