import { browserHistory } from 'react-router';

// Actions
import * as actions from './index';
import { setCourseReadOnly, setCourseReadAndWrite } from './UIActions';
import {
  clearCourse,
  fetchCourseInfo,
  fetchCourseStructure,
} from './CourseActions';

// Utils
import PermissionUtils from '../utils/PermissionUtils';
import EnrolmentUtils from '../utils/EnrolmentUtils';

// Services
import EnrolmentService from '../services/EnrolmentService';
import CoursePlanService from '../services/CoursePlanService';
import HandbookService from '../services/HandbookService';
import CourseService from '../services/CourseService';

// Constants
import { ERROR_CODES, ROUTES } from '../constants';

// Models
import UnitFactory from '../models/Unit/UnitFactory';
import EnrolmentYear from '../utils/EnrolmentYear';
import * as CourseActions from './CourseActions';

/**
 * Updates the current ID of the student that the course plan is for.
 * NB: this is only really used if the user is a non-student role
 */
export const updateStudentId = studentId => {
  return {
    type: actions.UPDATE_STUDENT_ID,
    studentId,
  };
};

/**
 * Updates the current name of the student that the course plan is for.
 * NB: this is only really used if the user is a non-student role
 */
export const updateStudentName = studentName => {
  return {
    type: actions.UPDATE_STUDENT_NAME,
    studentName,
  };
};

/**
 * Refreshes the snapshot currently being used in the course plan.
 * Useful for when an API call returns a new snapshot where some
 * state has changed
 */
export const updateSnapshot = snapshot => (dispatch, getState) => {
  const { user } = getState().User;
  const status = snapshot.status;
  dispatch(updateWriteAccess(user, status));
  dispatch({
    type: actions.UPDATE_SNAPSHOT,
    snapshot,
  });
};

/**
 * Updates the course plan info being used
 */
export const updateCoursePlan = coursePlanObj => (dispatch, getState) => {
  const { user } = getState().User;
  const { coursePlan, snapshot, approval, enquiries, studentName, approvalAdviserName } = coursePlanObj;
  const status = coursePlanObj.snapshot.status;
  dispatch(updateWriteAccess(user, status));
  dispatch({
    type: actions.UPDATE_COURSE_PLAN,
    coursePlanName: coursePlan.coursePlanName,
    coursePlanId: coursePlan.coursePlanId,
    snapshotId: snapshot.snapshotId,
    snapshot: snapshot,
    approval: approval,
    enquiries: enquiries,
    studentName: studentName,
    approvalAdviserName: approvalAdviserName,
  });
  dispatch(fetchCoursePlanEvents(coursePlan.coursePlanId));
};

/**
 * Updates the write access of the course plan based on a user's role
 * and the course plan's snapshot status
 */
export const updateWriteAccess = (user, status) => {
  const hasWriteAccess = PermissionUtils.hasWriteAccess(user, status);
  return hasWriteAccess ? setCourseReadAndWrite() : setCourseReadOnly();
};

/**
 * A utility function used to create a plan
 * once the course's data has been processed. This build the course structure
 * and then creates the plan
 */
const createPlan = (
  dispatch,
  getState,
  coursePlanName,
  courseCode,
  selectedAOSes,
  result,
  studentId = null,
  weightedAverageMark,
) => {
  dispatch({
    type: actions.LOAD_NEW_TEACHING_PERIODS,
    value: result.newTeachingPeriods,
  });

  dispatch({
    type: actions.LOAD_NEW_ADVANCED_STANDING,
    value: result.newAdvancedStanding || [],
  });

  const teachingPeriods = getState().PlanInstance.teachingPeriods;
  const advancedStanding = getState().AdvancedStanding.advancedStanding;

  let req;

  if (studentId) {
    req = CoursePlanService.createCoursePlanForStudent(
      teachingPeriods,
      advancedStanding,
      coursePlanName,
      courseCode,
      selectedAOSes,
      studentId,
      weightedAverageMark,
    );
  } else {
    req = CoursePlanService.createCoursePlan(
      teachingPeriods,
      advancedStanding,
      coursePlanName,
      courseCode,
      selectedAOSes,
      weightedAverageMark,
    );
  }

  req
    .then(resp => {
      dispatch({
        type: actions.UPDATE_COURSE_PLAN,
        coursePlanName: resp.coursePlan.coursePlanName,
        coursePlanId: resp.coursePlan.coursePlanId,
        snapshotId: resp.snapshot.snapshotId,
        snapshot: resp.snapshot,
        approval: resp.approval,
        enquiries: resp.enquiries,
        studentName: resp.studentName,
        approvalAdviserName: resp.approvalAdviserName,
      });

      const { user } = getState().User;
      const status = resp.snapshot.status;
      dispatch(updateWriteAccess(user, status));
      const snapshot = JSON.parse(resp.snapshot.blob);
      dispatch({
        type: actions.SET_WAM,
        weightedAverageMark: snapshot.weightedAverageMark,
      });
      browserHistory.push(`/plan/${resp.coursePlan.coursePlanId}`);
    })
    .catch(err => {
      console.error(err);
    });
};

/**
 * Used when a user has indicated they want to use their enrolment
 * data to populate a new course plan. It fetches the unit enrolment
 * data, builds out a course based on that data and then creates a
 * new course plan.
 */
export const submitCourseFormAndPreloadUnits = (
  coursePlanName,
  currentCourseCode,
  currentCourseEnrolment,
  selectedAOSes,
  studentId = null,
) => {
  return function(dispatch, getState) {
    let unitEnrolments = [];

    let unitEnrolmentsRequest, advancedStandingRequest;

    if (studentId) {
      unitEnrolmentsRequest = EnrolmentService.getStudentUnitEnrolments(
        studentId,
      );
      advancedStandingRequest = EnrolmentService.getStudentAdvancedStanding(
        studentId,
      );
    } else {
      unitEnrolmentsRequest = EnrolmentService.getUnitEnrolments();
      advancedStandingRequest = EnrolmentService.getAdvancedStanding();
    }

    const parseEnrolmentAndCreatePlan = (
      unitInfoResponses,
      advancedStandingResponses,
      teachingPeriodOrder,
    ) => {
      // we now have the unit info for each unit and the unit data
      const result = EnrolmentUtils.parseEnrolmentData(
        unitEnrolments,
        unitInfoResponses,
        advancedStandingResponses,
        currentCourseCode,
        teachingPeriodOrder,
      );

      createPlan(
        dispatch,
        getState,
        coursePlanName,
        currentCourseCode,
        selectedAOSes,
        result,
        studentId,
        currentCourseEnrolment.progression.weightedAverageMark,
      );
    };

    unitEnrolmentsRequest
      .then(resp => {
        // Set the start date, defaulting to the current year
        CourseActions.changeStartYear(
          currentCourseEnrolment.enrolmentDate
            ? new Date(currentCourseEnrolment.enrolmentDate).getFullYear()
            : EnrolmentYear(),
        )(dispatch);

        unitEnrolments = resp.map(unit => ({
          status: unit.status,
          grade: unit.grade,
          unitCode: unit.unitCode,
          courseCode: unit.courseCode,
          year: unit.year,
          teachingPeriodCode: unit.teachingPeriodCode,
          enrolmentDate: unit.enrolmentDate,
          fullResp: unit,
        }));

        const unitCodes = unitEnrolments.map(
          unitEnrolment => unitEnrolment.unitCode,
        );

        // if no enrolment data sent back, don't waste time
        // on dud request
        if (unitCodes.length === 0) {
          return Promise.resolve([]);
        }
        return HandbookService.getUnitsInfo(unitCodes);
      })
      .then(unitInfoResponses => {
        const teachingPeriodOrder = getState().TeachingPeriods.data.map(
          tp => tp.code,
        );

        advancedStandingRequest
          .then(advancedStandingResponses => {
            parseEnrolmentAndCreatePlan(
              unitInfoResponses,
              advancedStandingResponses,
              teachingPeriodOrder,
            );
          })
          .catch(err => {
            // Regardless of which error we get, we will still try to
            // create a course plan, since it is better than to
            // show an error message back to the client
            console.warn('Advanced standing response failed!');
            console.warn('Creating plan anyway');

            if (err.message === '404') {
              // student does not have any advanced standing items, so
              // don't log it as an error
              console.warn(
                'Student does not have any advanced standing items. Received: ',
                err,
              );
            } else {
              console.error(
                'Unable to obtain advanced standing items. Received: ',
                err,
              );
            }

            parseEnrolmentAndCreatePlan(
              unitInfoResponses,
              [],
              teachingPeriodOrder,
            );
          });
      })
      .catch(err => {
        console.error(err);
        browserHistory.push(
          `${ROUTES.ERROR}/${ERROR_CODES.COURSE_PLAN_CREATE}`,
        );
      });
  };
};

export const submitCourseFormAndUseTemplate = (
  coursePlanName,
  courseCode,
  startYear,
  timelinePaths,
  studentId = null,
) => {
  return function(dispatch, getState) {
    CourseService.getCourseTimeline(timelinePaths, startYear)
      .then(resp => {
        // we now have the unit info for each unit and the unit data
        const result = EnrolmentUtils.parseTimelineData(resp);

        createPlan(
          dispatch,
          getState,
          coursePlanName,
          courseCode,
          [],
          result,
          studentId,
        );
      })
      .catch(err => {
        console.error(err);
        browserHistory.push(
          `${ROUTES.ERROR}/${ERROR_CODES.COURSE_PLAN_CREATE}`,
        );
      });
  };
};

export const submitEmptyCoursePlan = (coursePlanName, studentId = null) => {
  return function(dispatch, getState) {
    const result = {
      newTeachingPeriods: [],
    };

    createPlan(dispatch, getState, coursePlanName, null, [], result, studentId);
  };
};

/**
 * Gets the event log for the given course plan, this shows things
 * like status changes and feedback items
 */
export const fetchCoursePlanEvents = coursePlanId => dispatch => {
  dispatch({
    type: actions.FETCH_COURSE_PLAN_EVENTS_PENDING,
  });

  CoursePlanService.getCoursePlanEvents(coursePlanId)
    .then(resp => {
      dispatch({
        type: actions.FETCH_COURSE_PLAN_EVENTS_FULFILLED,
        payload: resp,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: actions.FETCH_COURSE_PLAN_EVENTS_REJECTED,
        payload: err,
      });
    });
};

/**
 * A core function used when loading a course plan - this is what
 * actually puts a course plan on the page to the user when they first
 * load a course plan URL. It fetches the course plan and then
 * builds the course and loads it into state
 */
export const loadCoursePlan = planId => (dispatch, getState) => {
  dispatch(clearCourse());

  dispatch({
    type: actions.FETCH_COURSE_PLAN_PENDING,
  });

  CoursePlanService.getCoursePlan(planId)
    .then(resp => {
      const courseCode = resp.coursePlan.courseCode;
      const AOSes = resp.coursePlan.nominatedAreaOfStudys || [];

      const courseSnapshot = JSON.parse(resp.snapshot.blob);

      const {
        teachingPeriods,
        advancedStanding,
        weightedAverageMark,
      } = courseSnapshot;

      dispatch(fetchCoursePlanEvents(resp.coursePlan.coursePlanId));

      dispatch(fetchCourseStructure(courseCode, AOSes));

      dispatch({
        type: actions.SET_WAM,
        weightedAverageMark,
      });
      const processedTeachingPeriods = teachingPeriods.map(tp => ({
        ...tp,
        units: tp.units.map(unit => {
          if (unit) {
            return UnitFactory.createUnit(unit);
          }

          return null;
        }),
      }));

      dispatch({
        type: actions.LOAD_NEW_TEACHING_PERIODS,
        value: processedTeachingPeriods,
      });

      dispatch({
        type: actions.LOAD_NEW_ADVANCED_STANDING,
        value: advancedStanding || [],
      });

      dispatch(fetchCourseInfo(resp.coursePlan.courseCode));

      dispatch({
        type: actions.UPDATE_COURSE_PLAN,
        courseCode: resp.coursePlan.courseCode,
        coursePlanName: resp.coursePlan.coursePlanName,
        coursePlanId: resp.coursePlan.coursePlanId,
        snapshotId: resp.snapshot.snapshotId,
        snapshot: resp.snapshot,
        approval: resp.approval,
        enquiries: resp.enquiries,
        studentName: resp.studentName,
        approvalAdviserName: resp.approvalAdviserName,
      });

      const { user } = getState().User;
      const status = resp.snapshot.status;
      dispatch(updateWriteAccess(user, status));
      dispatch({
        type: actions.FETCH_COURSE_PLAN_FULFILLED,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_COURSE_PLAN_REJECTED,
      });
    });
};
