import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// MUI Icons
import { default as ArrowIcon } from '@material-ui/icons/ArrowForward';
import { default as LockIcon } from '@material-ui/icons/Lock';

// Services
import CourseService from '../../services/CourseService';

// Components
import SmallLoader from '../../components/Base/SmallLoader';
import SearchSelect from '../../components/Base/SearchSelect';
import TextFieldValidator, {
  getErrorMessage,
} from '../../components/Text/TextFieldValidator';
import LIMITS from '../../constants/limits';

const getAllowedYears = () => {
  const CURRENT_YEAR = new Date().getFullYear();
  const START_YEAR = CURRENT_YEAR - 4;
  const END_YEAR = CURRENT_YEAR + 2;
  let ALLOWED_YEARS = [];
  for (let year = START_YEAR; year < END_YEAR + 1; year++) {
    ALLOWED_YEARS.push(year);
  }

  return ALLOWED_YEARS;
};

const ALLOWED_YEARS = getAllowedYears();

class TemplateCourseForm extends React.Component {
  state = {
    selectedYear: '',

    selectedInitialChoice: '',
    initialChoiceObj: {},
    initialChoiceLoading: true,
    initialChoiceError: false,

    courseCode: null,

    choices: {},
    newChoicesLoading: false,
    newChoicesError: false,

    timelinePaths: ['$'],
  };

  handleSubmit = () => {
    const { onSubmit, coursePlanName } = this.props;
    const { timelinePaths, selectedYear, courseCode } = this.state;
    onSubmit(coursePlanName, selectedYear, courseCode, timelinePaths);
  };

  componentDidMount() {
    CourseService.getCourseTimelineChoices()
      .then(resp => {
        if (resp.length === 0) {
          throw new Error(
            'Received empty array of choices for initial timelines select',
          );
        }

        this.setState({
          initialChoiceObj: resp[0],
          initialChoiceLoading: false,
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          initialChoiceLoading: false,
          initialChoiceError: true,
        });
      });
  }

  resetChoices = () => {
    this.setState({
      selectedInitialChoice: '',
      timelinePaths: ['$'],
      choices: {},
      courseCode: null,
      newChoicesLoading: false,
      newChoicesError: false,
    });
  };

  choicesTransform = choices => {
    return choices.map(choice => ({
      value: choice.title,
      label: choice.description,
    }));
  };

  inputValid = () => {
    const {
      choices,
      selectedInitialChoice,
      selectedYear,
      initialChoiceLoading,
      newChoicesLoading,
    } = this.state;

    const { coursePlanName } = this.props;

    if (initialChoiceLoading || newChoicesLoading) {
      return false;
    }

    if (coursePlanName === '') {
      return false;
    }

    if (
      getErrorMessage(
        coursePlanName,
        TextFieldValidator.CharacterCount(
          LIMITS.CHARACTER_LIMITS.COURSE_PLAN_NAME,
        ).validators,
      )
    ) {
      return false;
    }

    if (selectedInitialChoice === '') {
      return false;
    }

    if (selectedYear === '') {
      return false;
    }

    const choicesObjects = Object.values(choices);
    for (let i = 0; i < choicesObjects.length; i++) {
      if (choicesObjects[i].selectedChoice === '') {
        return false;
      }
    }

    return true;
  };

  handleYearSelect = e => {
    const selectedYear = e.target.value;
    this.setState({ selectedYear });
  };

  /**
   *  choices: {
        '$': {
          isLoading: true,
          isError: false,
          selectedChoice: '',
          choices: [],
          dependents: [],
          description: '',
          id: '',
          path: ''
        }
      },
   */
  handleChoiceSelect = (path, choice) => {
    /**
     * Hacky way to handle search select behaviour when pressing delete with an empty input
     */
    if (!choice || (Array.isArray(choice) && choice.length === 0)) {
      return;
    }

    const { initialChoiceObj } = this.state;
    const newPath = `${path}>>${choice.value}`;

    this.setState(prevState => {
      const stateChanges = {
        newChoicesLoading: true,
        newChoicesError: false,
        choices: {
          ...prevState.choices,
        },
        timelinePaths: [
          ...prevState.timelinePaths.filter(val => val !== path),
          newPath,
        ],
      };

      if (path === initialChoiceObj.path) {
        stateChanges.selectedInitialChoice = choice;
        stateChanges.courseCode = choice.value;
      } else {
        stateChanges.choices[path] = {
          ...prevState.choices[path],
          selectedChoice: choice,
        };
      }

      return stateChanges;
    });

    CourseService.getCourseTimelineChoices(newPath)
      .then(resp => {
        let newChoices = {};
        resp.forEach(choiceObj => {
          newChoices[choiceObj.path] = {
            ...choiceObj,
            selectedChoice: '',
          };
        });

        this.setState(prevState => ({
          choices: {
            ...prevState.choices,
            ...newChoices,
          },
          newChoicesLoading: false,
        }));
      })
      .catch(resp => {
        this.setState({
          newChoicesLoading: false,
          newChoicesError: true,
        });
      });
  };

  renderChoiceSelect = (choiceObj, index) => {
    const { classes } = this.props;
    const { newChoicesLoading } = this.state;
    const showLock = choiceObj.selectedChoice !== '';

    return (
      <div
        key={`choice-select-${index + 1}`}
        style={{
          marginBottom: '30px',
          position: 'relative',
        }}>
        {showLock && (
          <LockIcon
            style={{
              position: 'absolute',
              top: '20px',
              right: '40px',
              color: 'hsl(0,0%,80%)',
            }}
          />
        )}
        <SearchSelect
          classes={classes}
          textFieldProps={{
            label: choiceObj.description,
            InputLabelProps: {
              shrink: true,
            },
          }}
          isDisabled={showLock || newChoicesLoading}
          options={this.choicesTransform(choiceObj.choices)}
          value={choiceObj.selectedChoice}
          onChange={this.handleChoiceSelect.bind(this, choiceObj.path)}
          placeholder={'Search options'}
        />
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      initialChoiceLoading,
      initialChoiceError,
      initialChoiceObj,
      selectedInitialChoice,
      newChoicesError,
      newChoicesLoading,
      choices,
      selectedYear,
    } = this.state;

    if (this.state.initialChoiceLoading) {
      return <SmallLoader message="Loading course template form" />;
    }

    if (initialChoiceError || newChoicesError) {
      return (
        <p>
          Failed to load course templates. Please select another course plan
          type, or try again later.
        </p>
      );
    }

    const resetDisabled = newChoicesLoading;
    const showLock = selectedInitialChoice !== '';
    const isDisabled = initialChoiceLoading || initialChoiceError || showLock;

    const inputValid = this.inputValid();

    return (
      <div id="template-course-form" style={{ marginBottom: '30px' }}>
        <p className={classes.errorText} style={{ marginBottom: '1em' }}>
          This is an experimental feature only. Some options may not be
          descriptive enough, the suggested course plan may not reflect your
          course, or it may not work for some courses and/or areas of study
          combinations. Consult with your course adviser if you need any
          assistance.
        </p>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="course-template-starting-year">
            Select starting year
          </InputLabel>
          <Select
            value={selectedYear}
            onChange={this.handleYearSelect}
            input={
              <Input
                name="Select your starting year"
                id="course-template-starting-year"
              />
            }>
            {ALLOWED_YEARS.map((year, i) => {
              return (
                <MenuItem key={`menu-item-${i + 1}`} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div style={{ marginBottom: '30px', position: 'relative' }}>
          {showLock && (
            <LockIcon
              style={{
                color: 'hsl(0,0%,80%)',
                position: 'absolute',
                top: '20px',
                right: '40px',
              }}
            />
          )}
          <SearchSelect
            classes={classes}
            textFieldProps={{
              label: initialChoiceObj.title,
              InputLabelProps: { shrink: true },
            }}
            options={this.choicesTransform(initialChoiceObj.choices)}
            value={selectedInitialChoice}
            onChange={this.handleChoiceSelect.bind(this, initialChoiceObj.path)}
            placeholder={'Search options'}
            isDisabled={isDisabled}
          />
        </div>
        {Object.values(choices)
          .sort((choiceA, choiceB) => {
            if (choiceA.path < choiceB.path) {
              return -1;
            }
            if (choiceA.path === choiceB.path) {
              return 0;
            }
            return 1;
          })
          .map((choiceObj, i) => this.renderChoiceSelect(choiceObj, i))}
        {newChoicesLoading && <SmallLoader message="Loading next choice" />}
        <Button disabled={resetDisabled} onClick={this.resetChoices}>
          Reset Choices
        </Button>
        <div className={classes.submitArrowContainer}>
          <IconButton
            tabIndex={0}
            disabled={!inputValid}
            className={
              inputValid
                ? classes.submitArrowButton
                : `${classes.submitArrowButton} ${
                    classes.submitArrowButtonDisabled
                  }`
            }
            aria-label="Next"
            onClick={this.handleSubmit}>
            <ArrowIcon
              className={
                inputValid
                  ? classes.submitArrow
                  : `${classes.submitArrow} ${classes.submitArrowDisabled}`
              }
            />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default TemplateCourseForm;

TemplateCourseForm.propTypes = {
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
  coursePlanName: PropTypes.string,
};
