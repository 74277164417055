export const gaCategory = {
  PLAN_ACTIONS: 'PLAN',
  PERFORMANCE: 'PERFORMANCE',
  FLOW: 'FLOW',
  EXTERNAL: 'EXTERNAL',
};

export const gaActions = {
  PLAN_ACTIONS: {
    newPlan: {
      STUDENT: 'PLAN_CREATE_STUDENT',
      ADVISOR: 'PLAN_CREATE_ADVISOR',
    },
    openPlan: 'PLAN_OPEN',
    savePlan: 'PLAN_SAVE',
    validatePlan: 'PLAN_VALIDATE',
  },
  PERFORMANCE: {
    provideFeedback: 'PERFORMANCE_/PROVIDE_FEEDBACK',
  },
  FLOW: {
    approvePlan: 'PLAN_APPROVE',
    rejectPlan: 'PLAN_REJECT',
    requestReview: {
      ADVISOR: 'PLAN_REVIEW_REQUESTED_ADVISOR',
      STUDENT: 'PLAN_REVIEW_REQUESTED_STUDENT',
    },
    sendFeedbackToPlan: 'PLAN_PROVIDE_FEEDBACK',
  },
  EXTERNAL: {
    redirects: {
      WES: 'EXTERNAL_REDIRECT_TO_WES',
    },
  },
};
