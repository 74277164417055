import React, { Component } from 'react';
import {
  array,
  element,
  string,
  number,
  bool,
  object,
  func,
  oneOfType,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Actions
import { hideUnitModal } from '../../../actions/UIActions';

// Utils
import { parseOfferings } from '../../../utils/ValidateCoursePlan';
import FacultyUtils from '../../../utils/FacultyUtils';

// Containers
import UnitDescriptionContainer from '../../../containers/UnitDescriptionContainer';
import OfferingContainer from '../../../containers/OfferingContainer';

// Components
import UnitInfoPlaceholder from '../../../components/Unit/UnitInfoPlaceholder';
import HandbookUtils from '../../../utils/HandbookUtils';

/**
 * Unit detail modal is a dialog that shows the unit information.
 * @author JXNS, Saurabh Joshi
 *
 * @param {element} trigger - The element that you want to trigger the modal (only for mobile view)
 * @param {string} unitCode - The unitcode that serves as the key for the unit detail view request you want to pull up
 * @param {string} custom - If custom, hide some features of unit details, and do not perform a query.
 * @returns {ReactElement|Dialog}
 */
class UnitDetailModal extends Component {
  /**
   * Control modal setup
   */
  constructor(props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  /**
   * Open the modal
   */
  handleOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  /**
   * Hides the modal
   */
  handleClose() {
    this.props.hideUnitModal();
  }

  renderModalTitle() {
    const { faculty, classes } = this.props;
    const facultyColor = FacultyUtils.getFacultyColour(faculty);
    const accessibleUnitCode = this.props.unitCode.split('').join(' ');
    const aria = `Information for unit ${accessibleUnitCode}, ${
      this.props.unitName
    }. Managed by faculty ${faculty}, worth ${
      this.props.creditPoints
    } Credit Points`;
    return (
      <div
        tabIndex="0"
        aria-label={aria}
        className={classes.unitDetailModalTitle}
        style={{ background: facultyColor }}>
        <h3 className={classes.unitName}>
          {this.props.unitCode} - {this.props.unitName}
        </h3>
        <p className={classes.subtitle}>
          Managing Faculty: {faculty} - {this.props.creditPoints} Credit Points
        </p>
      </div>
    );
  }

  renderModalBody() {
    const { faculty, classes, isLoading, error } = this.props;
    const facultyColor = FacultyUtils.getFacultyColour(faculty);

    if (error) {
      let errorString;

      if (
        this.props.error.response &&
        400 <= this.props.error.response.status &&
        this.props.error.response.status < 500
      ) {
        errorString =
          'We do not have this unit in our servers. Perhaps this is a custom unit?';
      } else if (
        this.props.error.response &&
        500 <= this.props.error.response.status &&
        this.props.error.response.status < 600
      ) {
        errorString = 'Our server is having problems. Please try again later.';
      } else {
        errorString = 'Please check your connection and try again.';
      }

      return <UnitInfoPlaceholder errorString={errorString} />;
    }

    if (isLoading) {
      return <UnitInfoPlaceholder />;
    }

    return (
      <div className={classes.innerContent} tabIndex="0">
        <div className={classes.leftContent}>
          <OfferingContainer offeringArray={this.props.offeringArray} />
        </div>
        <div className={classes.rightContent}>
          <UnitDescriptionContainer
            textLength={500}
            fullText={this.props.synopsis}
            facultyColor={facultyColor}
          />
          <br />
          <b>Prerequisites</b>
          <div>
            {typeof this.props.prereqs === 'string' &&
            this.props.prereqs.trim() !== '' ? (
              <UnitDescriptionContainer
                textLength={500}
                fullText={this.props.prereqs}
                facultyColor={facultyColor}
              />
            ) : (
              'None'
            )}
          </div>
          <br />
          <b>Corequisites</b>
          <p>
            {typeof this.props.coreqs === 'string' &&
            this.props.coreqs.trim() !== ''
              ? this.props.coreqs
              : 'None'}
          </p>
          <br />
          <b>Prohibitions</b>
          <p>
            {typeof this.props.proh === 'string' &&
            this.props.proh.trim() !== ''
              ? this.props.proh
              : 'None'}
          </p>
        </div>
      </div>
    );
  }

  renderModalContent() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.renderModalTitle()}
        <div className={classes.unitDetailModalContent}>
          {this.renderModalBody()}
          {this.renderModalActions()}
        </div>
      </React.Fragment>
    );
  }

  renderModalActions() {
    const { faculty, classes, unitCode, isAuth, year } = this.props;
    const facultyColor = FacultyUtils.getFacultyColour(faculty);

    return (
      <div className={classes.actions}>
        <span className={classes.unitLinkContainer}>
          <Button
            target="_blank"
            rel="noopener noreferrer"
            aria-label="external website to the units handbook"
            href={HandbookUtils.getUnitLink(unitCode, year)}
            style={{ color: facultyColor }}>
            Handbook
          </Button>
          {isAuth && (
            <Button
              target="_blank"
              rel="noopener noreferrer"
              aria-label="external website to the unit guide"
              href={`https://unitguidemanager.monash.edu/refine?searchQuery=${unitCode}`}
              style={{ color: facultyColor }}>
              Unit Guide
            </Button>
          )}
        </span>
        <Button
          style={{ color: facultyColor, float: 'right' }}
          onClick={this.handleClose}>
          Close
        </Button>
      </div>
    );
  }

  /**
   * displays the modal with the actions defined below linking to other resources
   */
  render() {
    const { trigger, shouldBeOpen, classes, fullScreen } = this.props;
    return (
      <div>
        {trigger}
        <Dialog
          fullScreen={fullScreen}
          open={!!shouldBeOpen}
          onClose={() => this.handleClose()}
          classes={{
            paper: classes.MuiDialog,
            paperWidthXs: classes.MuiDialog,
            paperWidthSm: classes.MuiDialog,
          }}>
          {this.renderModalContent()}
        </Dialog>
      </div>
    );
  }
}

/**
 * Provide hide unit detail modal dispatcher function as a prop.
 */
const bindDispatchToProps = dispatch => {
  const actionBundle = { hideUnitModal };
  return bindActionCreators(actionBundle, dispatch);
};

/**
 * Provide unit details as props.
 */
const bindStateToProps = state => {
  const { unitInfo, unitLoadError, unitLoading } = state.Handbook;
  return {
    isAuth: state.User.isAuth,
    shouldBeOpen: state.UI.showingUnitInfoModal,
    creditPoints: unitInfo.creditPoints,
    error: unitLoadError,
    faculty: unitInfo.faculty,
    isLoading: unitLoading,
    synopsis: unitInfo.synopsis,
    unitCode: unitInfo.unitCode,
    unitName: unitInfo.unitName,
    prereqs: unitInfo.preqs,
    coreqs: unitInfo.coreqs,
    prohibs: unitInfo.proh,
    avgSetuScore: unitInfo.avgSetuScore,
    year: state.UI.selectedYear,
    offeringArray: parseOfferings(unitInfo.offerings),
  };
};

export default connect(
  bindStateToProps,
  bindDispatchToProps,
)(withStyles(styles)(withMobileDialog()(UnitDetailModal)));

UnitDetailModal.propTypes = {
  isAuth: bool,
  trigger: element,
  custom: bool,
  onClick: func,
  onClose: func,
  hideUnitModal: func,
  shouldBeOpen: bool,
  /** A value indicating whether an error has occured or not. If there is an error getting unit data, the component will display error message. */
  error: oneOfType([bool, object]),

  /** Whether or not to display loading UI. */
  isLoading: bool.isRequired,
  /** Number of credit points for this unit */
  creditPoints: number.isRequired,
  /** The faculty a unit belongs to. */
  faculty: string.isRequired,
  /** A short description of the unit. */
  synopsis: string,
  /** The unit's associated unit code. */
  unitCode: string.isRequired,
  /** The unit's associated unit name. */
  unitName: string.isRequired,
  /** Prerequisites in natural language form */
  prereqs: string,
  /** Corequisites in natural language form */
  coreqs: string,
  /** Prohibitions in natural language form */
  prohibs: string,
  /** Teaching period offerings for this unit */
  offeringArray: oneOfType([string, array]),
  /** Average SETU rating */
  avgSetuScore: number,
};
