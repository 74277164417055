import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Components
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// MUI Icons
import { default as DeleteIcon } from '@material-ui/icons/Delete';

// Actions
import { attemptToDeleteTeachingPeriod } from '../../../actions/CourseActions';

/**
 * @author JXNS
 */
class ConfirmDeleteTeachingPeriodButton extends Component {
  /**
   * Sets up initial state and binds the functions
   */
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }

  /**
   * handles the attempt to delete a teaching period, this is handled by the attemptToDeleteTeachingPeriod action creator
   */
  handlePress() {
    const { attemptToDeleteTeachingPeriod, index, units } = this.props;
    attemptToDeleteTeachingPeriod(index, units);
  }

  /**
   * on render we process the message that will be shown in the confirmation popup, if the state is set to open then we show the
   * modal, otherwise we just show the plain remove button
   */
  render() {
    return (
      <MenuItem
        aria-label={`Remove ${this.props.name}, ${
          this.props.year
        } teaching period`}
        onClick={this.handlePress}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Delete Teaching Period</ListItemText>
      </MenuItem>
    );
  }
}

/**
 * We need the removeTeachingPeriod and attemptToDeleteTeachingPeriod functions from courseActions, and need
 * the show and hide confirmDeleteTeachingPeriodModal functions from uiActions
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = { attemptToDeleteTeachingPeriod };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  null,
  mapDispatchToProps,
)(ConfirmDeleteTeachingPeriodButton);

ConfirmDeleteTeachingPeriodButton.propTypes = {
  index: PropTypes.number,
  attemptToDeleteTeachingPeriod: PropTypes.func,
  units: PropTypes.array,
  name: PropTypes.string,
  year: PropTypes.number,
};
