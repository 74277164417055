import * as actions from '../../actions';

import UserFactory from '../../models/User/UserFactory';

const defaultState = {
  // User Reducer
  isAuth: false,
  user: null,
  isLoading: true,
  error: null,
};

/**
 * Stores the user info
 */
const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_PENDING:
      return {
        ...state,
        isAuth: false,
        user: null,
        isLoading: true,
        error: null,
      };
    case actions.FETCH_USER_FULFILLED:
      return {
        ...state,
        isAuth: true,
        user: UserFactory.createUser(action.user),
        isLoading: false,
      };
    case actions.FETCH_USER_REJECTED:
      return {
        ...state,
        isAuth: false,
        user: null,
        isLoading: false,
        error: action.err,
      };
    case actions.LOGOUT:
      return {
        ...state,
        isAuth: false,
        user: null,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default UserReducer;
