import React from 'react';
import { connect } from 'react-redux';

import CourseDetailModal from '../../components/Modals/CourseDetailModal';
import { getStartYear } from '../../selectors/selectors';

const CourseInfoContainer = ({ ...props }) => <CourseDetailModal {...props} />;

const mapStateToProps = state => ({
  courseInfo: state.Handbook.courseInfo,
  startYear: getStartYear(state),
});

export default connect(mapStateToProps)(CourseInfoContainer);
