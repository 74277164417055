import React from 'react';
import { Link } from 'react-router';

// MUI Components
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Icons
import { default as WarningIcon } from '@material-ui/icons/Warning';
import { default as StructureIcon } from '@material-ui/icons/ViewQuilt';

// Components
import FileDrop from '../../../components/Admin/FileDrop/FileDrop';
import FileTypes from '../../../components/Admin/FileDrop/FileTypes';
import BasicActionModal from '../../../components/Modals/BasicActionModal';

// Services
import AdminService from '../../../services/AdminService';
import AdminUploadService from '../../../services/AdminUploadService';

// Styles
import { withStyles } from '@material-ui/core/styles';

const style = {
  uploadButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningBanner: {
    backgroundColor: '#ffa726',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    marginBottom: 15,
    color: 'rgba(0, 0, 0, 0.6)',
    '&:visited': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
};
class CourseStructuresUploadContainer extends React.Component {
  state = {
    maintenanceEnabled: false,
    showUploadStructureModal: false,
    isUploading: false,
    hasErrored: false,
    uploadedValue: null,
    uploadingMessage: null,
    approved: false,
  };

  componentDidMount() {
    AdminService.getCurrentMaintenanceStatus().then(data => {
      this.setState({
        maintenanceEnabled: data.status === 'ENABLED',
      });
    });
  }

  onUploadProgress = progressEvent => {
    const { uploadedValue } = this.state;
    let uploadProgress = Math.floor(progressEvent.loaded / progressEvent.total);
    let newUploadValue = Math.floor(uploadedValue + uploadProgress * 33);
    this.setState({ uploadedValue: newUploadValue });
  };

  uploadFileAndGetAdminLink = files => {
    const file = files[0];
    this.setState({
      uploadedValue: 1,
      isUploading: true,
      uploadingMessage: 'Getting upload link...',
    });
    AdminUploadService.getUploadURL(file.name, file.type)
      .then(uploadURLResp => {
        this.setState({
          uploadedValue: 33,
          isUploading: true,
          uploadingMessage: 'Uploading file...',
        });
        return AdminUploadService.uploadFileToGCS(
          uploadURLResp.uploadUrl, // signedURL
          file, //actual file
          file.type,
          this.onUploadProgress,
        )
          .then(resp => {
            this.setState({
              uploadedValue: 66,
              isUploading: true,
              uploadingMessage: 'Queuing process task for new upload...',
            });
            return AdminUploadService.processCSVFromGCS(
              uploadURLResp.name,
              'COURSE_STRUCTURE',
            ).then(resp => {
              this.setState({
                uploadedValue: 100,
                isUploading: false,
              });
            });
          })
          .catch(err => {
            this.setState({
              isUploading: false,
              hasErrored: true,
              errorMessage: 'Error: Cannot upload file. Please try again later',
            });
          });
      })
      .then(resp => {
        this.setState({
          uploadedValue: 100,
          isUploading: false,
          hasErrored: false,
        });
      })
      .catch(err => {
        this.setState({
          isUploading: false,
          hasErrored: true,
          errorMessage: 'Error: Cannot get upload url. Please try again later',
        });
      });
  };

  render() {
    const {
      showUploadStructureModal,
      maintenanceEnabled,
      isUploading,
      uploadedValue,
      hasErrored,
      uploadingMessage,
      errorMessage,
      approved,
    } = this.state;
    const { classes } = this.props;

    const hasSuccessfullyUpload =
      uploadedValue === 100 && !isUploading && !hasErrored;
    return (
      <div>
        <h2>Course Structure Uploader</h2>
        <div>
          <Button
            onClick={() => this.setState({ showUploadStructureModal: true })}
            variant="outlined">
            <div className={classes.uploadButton}>
              <StructureIcon />
              <span>Upload Course Structures</span>
            </div>
          </Button>
        </div>
        <BasicActionModal
          open={showUploadStructureModal}
          header={
            !maintenanceEnabled && (
              <div className={classes.warningBanner}>
                <WarningIcon />
                <p style={{ marginLeft: 5 }}>
                  Warning! Maintenance Mode is currently disabled. It is
                  recommended to turn on maintenance mode while uploading course
                  structures
                </p>
              </div>
            )
          }
          title={'Upload Course Structures'}
          hasAction={false}
          disableCloseButton={isUploading}
          progress={
            isUploading && (
              <LinearProgress
                variant="determinate"
                value={uploadedValue}
                color="primary"
              />
            )
          }
          body={
            <div>
              <p>
                This task will update information on Course Structures, as part
                of the processing task will clear out the database and create
                new structure entries - users will face access issues. Note that
                for uploading, the following must be done:
              </p>
              <ul>
                <li>Testing must be complete in UAT and QAT environments.</li>
                <li>Change Request (CRQ) has been created.</li>
                <li>Maintenance Mode has been enabled.</li>
              </ul>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={approved}
                    onChange={event =>
                      this.setState({ approved: event.target.checked })
                    }
                    value="approved"
                  />
                }
                label="I understand the risks, continue."
              />
              <p>
                <b>Estimated Processing Time</b>: up to 3 minutes
              </p>
              <FileDrop
                allowedFileTypes={FileTypes.CSV}
                maxNumberOfFiles={1}
                submitButtonLabel="Upload & Process"
                successMessage="Successfully uploaded course structures and queued for processing."
                disableUploadButton={!approved}
                maxFileSize={100}
                onSubmit={this.uploadFileAndGetAdminLink}
                isUploading={isUploading}
                uploadingMessage={uploadingMessage}
                hasSuccessfullyUpload={hasSuccessfullyUpload}
                hasErrored={hasErrored}
                errorMessage={errorMessage}
                hideActionButtonGroup={hasSuccessfullyUpload || hasErrored}
              />
            </div>
          }
          onClose={() => {
            if (!isUploading) {
              this.setState({
                maintenanceEnabled: false,
                showUploadStructureModal: false,
                isUploading: false,
                hasErrored: false,
                uploadedValue: null,
                uploadingMessage: null,
                approved: false,
              });
            }
          }}
        />
        <Link
          to="/admin/history?type=updating-structures"
          className={classes.textContainer}>
          View log history for Course Structure Updates
        </Link>
      </div>
    );
  }
}

export default withStyles(style)(CourseStructuresUploadContainer);
