import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { addUnit } from '../../actions/CourseActions';

import UnitSearchResult from '../../components/Unit/UnitSearchResult';
import { CircularProgress } from '@material-ui/core';

/**
 * Displays a list of units if there are search results.
 *
 * @author Saurabh Joshi
 */
class UnitSearchResultsContainer extends Component {
  /**
   * Using the new API, shows loading message. Otherwise if the search bar is
   * empty, then it returns the default view where it
   * indicates to the user what they should do next if they want to add new
   * units. If there are no search results, then no results component is
   * rendered. Otherwise a list of UnitSearchResults are rendered to the
   * screen.
   */

  render() {
    if (this.props.unitSearchIsLoading) {
      return (
        <div
          style={{
            lineHeight: 1.5,
            marginTop: '1em',
            padding: '0.5em 1.5em',
          }}>
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
          <p style={{ textAlign: 'center' }} aria-live="assertive">
            Fetching units...
          </p>
        </div>
      );
    }

    if (this.props.empty && !this.props.unitSearchIsLoading) {
      return (
        <div>
          <div
            style={{
              lineHeight: 1.5,
              marginTop: '1em',
              padding: '0.5em 1.5em',
              fontSize: 15,
              fontWeight: 400,
            }}>
            Use our search engine above to find units by name, code, or keyword.
            <br />
            You can also filter your search by campus, location, and faculty.
          </div>
        </div>
      );
    }
    if (!this.props.results || this.props.results.length === 0) {
      return (
        <div style={{ marginTop: '1em', padding: '0.5em 1.5em' }}>
          <div style={{ fontSize: 20 }} aria-live="assertive">
            No units found
          </div>
          <p>Please change your keywords and try again.</p>
        </div>
      );
    }

    const eles = this.props.results.map((unitToAdd, index) => {
      const { unitCode, unitName, faculty, creditPoints } = unitToAdd;
      const active = this.props.searchResultIndex === index;

      const ariaResultMessage = `Result ${index + 1} of ${
        this.props.results.length
      }`;
      return (
        <React.Fragment key={index}>
          {' '}
          <div aria-label="results">
            <div
              style={{
                display: 'block',
                background: active ? '#eee' : undefined,
              }}
              tabIndex={-1}>
              <UnitSearchResult
                ariaResultMessage={ariaResultMessage}
                key={`${unitCode}-${index}`}
                creditPoints={creditPoints}
                unitCode={unitCode}
                unitName={unitName}
                faculty={faculty}
                addUnit={this.props.addUnit}
                positionOfUnitToAdd={this.props.positionOfUnitToAdd}
                active={active}
                unitToAdd={unitToAdd}
                id={index}
                setSearchResultIndex={this.props.setSearchResultIndex}
              />
            </div>
          </div>
        </React.Fragment>
      );
    });

    return <div>{eles}</div>;
  }
}

UnitSearchResultsContainer.propTypes = {
  addUnit: PropTypes.func,
  positionOfUnitToAdd: PropTypes.array,
  addToCourse: PropTypes.func,
  setSearchResultIndex: PropTypes.func,
  searchResultIndex: PropTypes.number,
  results: PropTypes.array,
  empty: PropTypes.bool,
  unitSearchIsLoading: PropTypes.bool,
};

/**
 * Injects show custom unit modal action into the props
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = { addUnit };
  return bindActionCreators(actionBundle, dispatch);
};

/**
 * Injecting Props
 */
const mapStateToProps = state => {
  return {
    unitSearchIsLoading: state.UnitSearch.isLoading,
    positionOfUnitToAdd: state.UI.positionOfUnitToAdd,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnitSearchResultsContainer);
