import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NotificationBar from '../../components/Base/NotificationBar';
import { hideNotification } from '../../actions/NotificationActions';

const NotificationBarContainer = ({ ...props }) => (
  <NotificationBar {...props} />
);

const mapStateToProps = ({ Notifications: { open, message, duration } }) => ({
  open,
  message,
  duration,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ hideNotification }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationBarContainer);
