const styles = theme => ({
  MuiDialog: {
    maxWidth: '1000px !important', // this pains me, need to figure out why the base classes override solution doesnt work
    width: '100%',
    minHeight: '550px',
  },

  ExpansionDetails: {
    flexDirection: 'column',
  },
  expansionPanelTitle: {
    backgroundColor: 'rgba(228, 233, 237, 0.6)',
  },
  errorCardRoot: {
    flex: 1,
    margin: 5,
    minWidth: '300px',
    maxWidth: '30%',
    borderStyle: 'dashed',
    borderWidth: '1px',
    borderColor: '#ccc',
  },
  errorDetailsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  errorSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
    padding: '0 24px 24px',
  },
  errorSummaryItemContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
  },
});

export default styles;
