import React, { Fragment } from 'react';
import moment from 'moment';

// MUI Components
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// MUI Icons
import { default as MaintenanceModeIcon } from '@material-ui/icons/Build';
import { default as EditIcon } from '@material-ui/icons/Edit';

// Components
import SmallLoader from '../../Base/SmallLoader';
import BasicActionModal from '../../Modals/BasicActionModal';

// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class MaintenanceConfig extends React.Component {
  displayBody = isEnabled => {
    return `By doing this action, you are modifying the content and also ${
      isEnabled ? 'enabling' : 'disabling'
    } the maintenance mode of the application. Please be aware that users will ${
      isEnabled ? 'not' : ''
    } have access to MonPlan${
      isEnabled ? ' until maintenance mode is disabled.' : '.'
    }`;
  };

  renderReadMode = () => {
    const { classes, pageTitle, pageDescription } = this.props;

    return (
      <div>
        <div className={classes.textContainer}>
          <dl>
            <dt>Page Title: </dt>
            <dd className={classes.descriptionText}>{pageTitle}</dd>
          </dl>
        </div>
        <div className={classes.textContainer}>
          <dl>
            <dt>Message: </dt>
            <dd className={classes.descriptionText}>{pageDescription}</dd>
          </dl>
        </div>
      </div>
    );
  };

  renderWriteMode = () => {
    const {
      classes,
      pageTitle,
      pageDescription,
      onTitleChange,
      onDescriptionChange,
    } = this.props;
    return (
      <div>
        <div className={classes.textContainer}>
          <TextField
            label="Page Title"
            value={pageTitle}
            onChange={onTitleChange}
            style={{ width: '100%' }}
          />
        </div>
        <div className={classes.textContainer}>
          <TextField
            label="Message"
            value={pageDescription}
            onChange={onDescriptionChange}
            style={{ width: '100%' }}
            multiline={true}
            rows={3}
            rowsMax={3}
          />
        </div>
      </div>
    );
  };

  renderLastModifiedText = () => {
    const { classes, lastModified, lastModifiedBy } = this.props;
    return (
      <div className={classes.textContainer}>
        <span className={classes.greyText}>
          Last Modified by {lastModifiedBy} at{' '}
          {moment(lastModified).format('D/MM/YYYY hh:mm a')}
        </span>
      </div>
    );
  };

  // Render Edit Button on Read Mode
  // Render Submit and Cancel Buttons on Write Mode
  renderActionButtons = () => {
    const {
      classes,
      readOnlyMode,
      openWarningModal,
      cancelWriteMode,
      enableWriteMode,
    } = this.props;
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {readOnlyMode && (
              <Button
                color="primary"
                variant="raised"
                style={{ marginBottom: 10 }}
                onClick={enableWriteMode}>
                <EditIcon />
                Edit
              </Button>
            )}
          </div>
        </div>
        {!readOnlyMode && (
          <div
            style={{
              flex: 2,
              justifyContent: 'flex-end',
              display: 'flex',
            }}>
            <Button
              variant="raised"
              className={classes.button}
              onClick={cancelWriteMode}>
              Cancel
            </Button>
            <Button
              variant="raised"
              color="primary"
              className={classes.button}
              onClick={openWarningModal}>
              Submit
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderConfigurationArea = () => {
    const { classes, readOnlyMode, status, onToggleChange } = this.props;

    return (
      <div>
        <div className={classes.iconAndTitle}>
          <b>Enabled?:</b>
          <div className={classes.iconAndTitle} style={{ marginLeft: 10 }}>
            <span>Off</span>
            <Switch
              checked={status}
              value="status"
              disabled={readOnlyMode}
              onChange={onToggleChange}
              color="primary"
            />
            <span>On</span>
          </div>
        </div>
        {readOnlyMode ? this.renderReadMode() : this.renderWriteMode()}
        {this.renderActionButtons()}
        {this.renderLastModifiedText()}
      </div>
    );
  };

  render = () => {
    const {
      classes,
      modalLoading,
      modalError,
      hasSuccesfullyUpdated,
      status,
      showModal,
      isLoading,
      isError,
      onSubmit,
      onClose,
    } = this.props;
    return (
      <Fragment>
        <BasicActionModal
          body={
            modalLoading ? (
              <SmallLoader message="Updating configuration..." />
            ) : modalError ? (
              <div className={classes.errorText}>
                Something went wrong! Cannot update, please try again later.
              </div>
            ) : hasSuccesfullyUpdated ? (
              'Succesfully Updated Maintenance Mode!'
            ) : (
              this.displayBody(status)
            )
          }
          action={'I understand, continue.'}
          title="Warning! You are modifying the maintenance mode of MonPlan"
          open={showModal}
          onClose={onClose}
          onAction={onSubmit}
          hasAction={!modalLoading && !hasSuccesfullyUpdated}
          disableCloseButton={modalLoading}
        />
        <div className={classes.iconAndTitle}>
          <MaintenanceModeIcon className={classes.icon} />
          <h3 className={classes.headings}>Maintenance Mode</h3>
        </div>
        {!isLoading ? (
          isError ? (
            <span className={classes.errorText}>
              Cannot fetch maintenance status. Please try again later.
            </span>
          ) : (
            this.renderConfigurationArea()
          )
        ) : (
          <SmallLoader
            message={'Fetching latest maintenance configuration...'}
          />
        )}
      </Fragment>
    );
  };
}

export default withStyles(styles)(MaintenanceConfig);
