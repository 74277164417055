import * as ReactGA from 'react-ga';

// Constants
import { gaCategory, gaActions } from '../constants/reactGA';

// Actions
import * as actions from './index';
import {
  hideSidebar,
  hideUnitSelectSidebar,
  showValidationModal,
} from './UIActions';
import { displayNotification } from './NotificationActions';

// Services
import CourseService from '../services/CourseService';
import HandbookService from '../services/HandbookService';

// Utils
import DataTransformUtils from '../utils/DataTransformUtils';
import TeachingPeriodUtils from '../utils/TeachingPeriodUtils';

/**
 * Used to set the name of the course plan
 */
export const setCoursePlanName = name => (dispatch, getState) => {
  // Set a new course plan name if it has changed
  if (name !== getState().CoursePlan.coursePlanName) {
    dispatch({
      type: actions.SET_COURSE_PLAN_NAME,
      coursePlanName: name,
    });
  }
};

/**
 * Used to add a teaching period into the course plan
 */
export const insertTeachingPeriod = (year, code) => {
  return function(dispatch, getState) {
    dispatch({
      type: actions.INSERT_TEACHING_PERIOD,
      year,
      code,
      teachingPeriodData: getState().TeachingPeriods.data,
    });

    dispatch(displayNotification('Inserted teaching period'));
  };
};

/**
 * Used when you need to remove a teaching period from the course plan
 */
export const removeTeachingPeriod = index => {
  return function(dispatch) {
    dispatch({
      type: actions.REMOVE_TEACHING_PERIOD,
      index,
    });

    dispatch(displayNotification('Removed teaching period'));
  };
};

/**
 * Increases the number of unit slots for a given teaching period at
 * teachingPeriodIndex
 */
export const increaseStudyLoad = teachingPeriodIndex => {
  return function(dispatch) {
    dispatch({
      type: actions.INCREASE_STUDY_LOAD,
      teachingPeriodIndex,
    });
  };
};

/**
 * Used to lower the number of unit slots in a given teaching period at
 * teachingPeriodIndex
 */
export const decreaseStudyLoad = teachingPeriodIndex => {
  return function(dispatch) {
    dispatch({
      type: actions.DECREASE_STUDY_LOAD,
      teachingPeriodIndex,
    });

    dispatch(displayNotification('Decreased study load'));
  };
};

/**
 * Sets a teaching period as an intermission teaching period,
 * this clears all of the units in the teaching period so should
 * only be used after user confirmation
 */
export const setIntermission = teachingPeriodIndex => {
  return function(dispatch) {
    dispatch({
      type: actions.SET_INTERMISSION,
      teachingPeriodIndex,
    });
  };
};

/**
 * Unsets a teaching period from intermission mode, this
 * makes the teaching period a 4 unit slot teaching period
 * with no units in it
 */
export const unsetIntermission = teachingPeriodIndex => {
  return function(dispatch) {
    dispatch({
      type: actions.UNSET_INTERMISSION,
      teachingPeriodIndex,
    });
  };
};

/**
 * Sets a teaching period as an study abroad period,
 * this clears all of the units in the teaching period so should
 * only be used after user confirmation
 */
export const setStudyAbroad = teachingPeriodIndex => {
  return function(dispatch) {
    dispatch({
      type: actions.SET_STUDY_ABROAD,
      teachingPeriodIndex,
    });
  };
};

/**
 * Unsets a teaching period from study abroad mode, this
 * makes the teaching period a 4 unit slot teaching period
 * with no units in it
 */
export const unsetStudyAbroad = teachingPeriodIndex => {
  return function(dispatch) {
    dispatch({
      type: actions.UNSET_STUDY_ABROAD,
      teachingPeriodIndex,
    });
  };
};

/**
 * Used to add a unit given by 'unit' in the teaching period
 * found at 'tpIndex' and puts it in the teaching period's units
 * array at position 'unitIndex'
 */
export const addUnit = (tpIndex, unitIndex, unit) => {
  return function(dispatch) {
    dispatch({
      type: actions.ADD_UNIT,
      tpIndex,
      unitIndex,
      unit,
    });

    dispatch(displayNotification('Unit Added'));

    /**
     * We need to hide the sidebar because the user may bypass beginAddingUnit
     * altogether if they specify position of unit slot before they
     * add a unit.
     */
    dispatch(hideSidebar());
    dispatch(hideUnitSelectSidebar());
  };
};

/**
 * Removes the unit within teachingPeriods[tpIndex].units[unitIndex]
 */
export const removeUnit = (tpIndex, unitIndex) => {
  return function(dispatch) {
    dispatch({
      type: actions.REMOVE_UNIT,
      tpIndex,
      unitIndex,
    });

    dispatch(displayNotification('Unit Removed'));
  };
};

/**
 * Called to populate the unit to add value, it indicates that a unit is being prepped
 * to be added to the course
 */
export const beginAddingUnit = unit => {
  return function(dispatch) {
    dispatch({
      type: actions.ADDING_UNIT,
      unit,
    });
  };
};

/**
 * Resets a course plan to be an empty course plan with no
 * teaching periods or units
 */
export const clearCourse = () => {
  return function(dispatch) {
    dispatch({
      type: actions.CLEAR_COURSE,
    });
  };
};

/**
 *   attempt to delete a teaching period with the given index. It calculates which, if any units would be affected by the deletion,
 * and if there is - it updates the affected units array so the modal can display and prompt the user to confirm.
 * If there are no units that would be affected by the move (i.e. an empty teaching period), then the teaching period is removed
 * without prompting for confirmation
 */
export const attemptToDeleteTeachingPeriod = (index, units) => {
  return function(dispatch) {
    let affectedUnits = units.reduce((result, unit) => {
      if (unit && !unit.placeholder) {
        return result.concat(unit.unitCode + ' - ' + unit.unitName);
      }

      return result;
    }, []);

    if (affectedUnits.length > 0) {
      dispatch({
        type: actions.SHOW_CONFIRM_DELETE_TEACHING_PERIOD_MODAL,
      });

      dispatch({
        type: actions.UPDATE_AFFECTED_UNITS,
        affectedUnits,
      });

      dispatch({
        type: actions.UPDATE_INDEX_OF_TP_TO_REMOVE,
        index,
      });
    } else {
      dispatch(removeTeachingPeriod(index));
    }
  };
};

/**
 * Attempts to decrease the study load, it calculate the units that would be affected by this deletion. If no units would be affected,
 * automatically decreases the study load, otherwise shows the confirmation modal with the affected units.
 */
export const attemptToDecreaseStudyLoad = (
  teachingPeriod,
  teachingPeriodIndex,
) => {
  return function(dispatch) {
    let units = [];
    let unitCoords = [];
    let affectedUnitStrings = [];

    let currentUnits = teachingPeriod.units;
    for (let j = 0; j < currentUnits.length; j++) {
      let unit = currentUnits[j];
      if (unit !== null && unit !== undefined) {
        if (!unit.placeholder) {
          const unitLength = Math.min(6, unit.creditPoints / 6);
          if (unitLength + j - 1 >= teachingPeriod.numberOfUnits - 1) {
            unitCoords.push([teachingPeriodIndex, j]);
            units.push(unit);
            affectedUnitStrings.push(unit.unitCode + ' - ' + unit.unitName);
          }
        }
      }
    }

    if (affectedUnitStrings.length > 0) {
      dispatch({
        type: actions.SHOW_CONFIRM_DECREASE_STUDY_LOAD_MODAL,
        teachingPeriodIndex,
      });

      dispatch({
        type: actions.UPDATE_AFFECTED_UNITS,
        affectedUnits: affectedUnitStrings,
      });
    } else {
      dispatch({
        type: actions.DECREASE_STUDY_LOAD,
        teachingPeriodIndex,
      });
    }
  };
};

/**
 * When a unit starts being moved, we need to save it's original index position so when it is dropped or swapped we are aware
 */
export const movingUnit = (unit, unitIndex, tpIndex) => {
  return dispatch => {
    dispatch({
      type: actions.MOVING_UNIT,
      unit,
      unitIndex,
      tpIndex,
    });
  };
};

/**
 * Cancels moving unit, which is used when user drags the unit outside of the
 * table then letting go of the mouse button.
 */
export const cancelMovingUnit = () => {
  return dispatch => {
    dispatch({
      type: actions.CANCEL_MOVING_UNIT,
    });
  };
};

/**
 * Simply dispatches the cancel adding unit action
 */
export const cancelAddingUnit = () => {
  return dispatch => {
    dispatch({
      type: actions.CANCEL_ADDING_UNIT,
    });
  };
};

/**
 * Moves the unit that was moving into the given teaching period and units array index
 */
export const moveUnit = (newUnitIndex, newTPIndex) => {
  return dispatch => {
    dispatch({
      type: actions.MOVE_UNIT, //No handlers for this but useful for debugging purposes
      newUnitIndex,
      newTPIndex,
    });
  };
};

/**
 * Swaps a unit with another unit
 */
export const swapUnit = (newUnitIndex, newTPIndex, unitToSwap) => {
  return dispatch => {
    dispatch({
      type: actions.SWAP_UNIT, //No handlers for this but useful for debugging purposes
      newUnitIndex,
      newTPIndex,
      unitToSwap,
    });
  };
};

/**
 * Fetches the handbook info for the course with given coursecode
 */
export const fetchCourseInfo = courseCode => {
  return function(dispatch) {
    dispatch({
      type: actions.FETCH_COURSE_INFO_PENDING,
    });
    HandbookService.getCourseInfo(courseCode)
      .then(resp => {
        dispatch({
          type: actions.FETCH_COURSE_INFO_FULFILLED,
          payload: resp,
          courseCode,
        });
      })
      .catch(err => {
        dispatch({
          type: actions.FETCH_COURSE_INFO_REJECTED,
          payload: err,
        });
      });
  };
};

/**
 * uses MSCV to validate the course structure
 */
export const validateCourse = teachingPeriods => {
  return function(dispatch, getState) {
    dispatch({
      type: actions.FETCH_COURSE_ERRORS_PENDING,
    });
    dispatch(showValidationModal());

    CourseService.validateCourse(
      TeachingPeriodUtils.getStartYear(teachingPeriods),
      getState().AdvancedStanding.advancedStanding,
      teachingPeriods,
      getState().CoursePlan.courseCode,
    )
      .then(resp => {
        dispatch({
          type: actions.FETCH_COURSE_ERRORS_FULFILLED,
          payload: resp.courseErrors,
          teachingPeriods: teachingPeriods,
        });
        ReactGA.ga(tracker => {
          ReactGA.event({
            category: gaCategory.PLAN_ACTIONS,
            action: gaActions.PLAN_ACTIONS.validatePlan,
          });
        });
      })
      .catch(err => {
        console.error(err);
        dispatch({
          type: actions.FETCH_COURSE_ERRORS_REJECTED,
          payload: err,
        });
      });
  };
};

/**
 * used to get all the available area of study options for a
 * given course code as defined by the CourseStructures data
 */
export const fetchAreasOfStudy = courseCode => dispatch => {
  dispatch({
    type: actions.FETCH_COURSE_AOS_PENDING,
  });

  CourseService.getAvailableAOS(courseCode)
    .then(resp => {
      dispatch({
        type: actions.FETCH_COURSE_AOS_FULFILLED,
        payload: resp,
      });
    })
    .catch(err => {
      dispatch({
        type: actions.FETCH_COURSE_AOS_REJECTED,
        payload: err,
      });
    });
};

/**
 * Fetches a CourseStructure
 */
export const fetchCourseStructure = (courseCode, AOSes) => dispatch => {
  dispatch({
    type: actions.FETCH_COURSE_STRUCTURE_PENDING,
  });

  CourseService.getCourseStructure(courseCode, AOSes)
    .then(resp => {
      dispatch({
        type: actions.FETCH_COURSE_STRUCTURE_FULFILLED,
        payload: resp,
      });
    })
    .catch(err => {
      dispatch({
        type: actions.FETCH_COURSE_STRUCTURE_REJECTED,
        payload: err,
      });
    });
};

/**
 * Fetches teaching period string list from API, note that there is no guarantee the teaching periods
 * will be ordered correctly so we sort them before using them in the state.
 */
export const fetchTeachingPeriods = () => {
  return function(dispatch) {
    dispatch({
      type: actions.FETCH_TEACHING_PERIODS_PENDING,
    });

    CourseService.getAllTeachingPeriods()
      .then(DataTransformUtils.sortTeachingPeriods)
      .then(response => {
        dispatch({
          type: actions.FETCH_TEACHING_PERIODS_FULFILLED,
          payload: response,
        });
      })
      .catch(err => {
        dispatch({
          type: actions.FETCH_TEACHING_PERIODS_REJECTED,
          payload: err,
        });
      });
  };
};

/**
 * Changes the start year when the student has started their course studies.
 */
export const changeStartYear = year => dispatch =>
  dispatch({
    type: actions.CHANGE_START_YEAR,
    year,
  });
