const BASE = `https://www.monash.edu.au/pubs`;

export default class HandbookUtils {
  static getCourseLink(courseCode, year) {
    return this._getLink('courses', courseCode, year);
  }

  static getUnitLink(unitCode, year) {
    return this._getLink('units', unitCode, year);
  }

  static _getLink(type, code, year) {
    // No code given
    if (!code) return `${BASE}/handbooks/${type}`;
    // Invalid year given, ignore the year
    else if (typeof year !== 'number') {
      return `${BASE}/handbooks/${type}/${code}.html`;
    }
    // Year greater than this year, return default
    else if (year >= new Date().getFullYear()) {
      return `${BASE}/handbooks/${type}/${code}.html`;
    }
    // Return link with specified year
    return `${BASE}/${year}handbooks/${type}/${code}.html`;
  }
}
