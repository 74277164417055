import NetworkService from './NetworkService';
import { MONPLAN_API_URL } from '../constants';
import {
  MOCK_UNIT_INFO,
  MOCK_UNITS_INFO,
  MOCK_BROKEN_COURSE_INFO,
} from './data/handbookMockData';

import CoreUtils from '../utils/CoreUtils';

import { MOCK_RESPONSE_TIME } from './constants';

export default class HandbookService {
  static getUnitInfo(unitCode) {
    const requestURI = `${MONPLAN_API_URL}/api/handbooks/units/${unitCode}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('HandbookService.getUnitInfo()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_UNIT_INFO,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI);
  }

  static getUnitsInfo(unitCodes) {
    const baseURI = `${window.location.origin}/api/handbooks/units`;

    let query = '';
    if (unitCodes.length > 0) {
      query = '?';
      const queryArgs = unitCodes.map((unitCode, i) =>
        i === 0 ? `unitCode=${unitCode}` : `&unitCode=${unitCode}`,
      );

      query = query + queryArgs.join('');
    }

    const requestURI = `${baseURI}${query}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('HandbookService.getUnitsInfo()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_UNITS_INFO,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getCourseInfo(courseCode) {
    const requestURI = `${MONPLAN_API_URL}/api/handbooks/courses/${courseCode}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('HandbookService.getCourseInfo()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_BROKEN_COURSE_INFO,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI);
  }
}
