import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// Actions
import { removeTeachingPeriod } from '../../../actions/CourseActions';

import { hideConfirmDeleteTeachingPeriodUI } from '../../../actions/UIActions';

/**
 * This modal sits and waits to be activated by the global show confirm delete teaching period modal boolean. This
 * listens for changes to units that would be affected by a teaching period deletion, as well as the index of the teaching
 * period to remove and uses that for the modal actions
 */
class ConfirmDeleteTeachingPeriodModal extends Component {
  render() {
    const {
      index,
      affectedUnits,
      showingConfirmDeleteTeachingPeriodModal,
      removeTeachingPeriod,
      hideConfirmDeleteTeachingPeriodUI,
    } = this.props;

    return (
      <Dialog open={showingConfirmDeleteTeachingPeriodModal}>
        <DialogTitle>
          Are you sure you want to delete this teaching period?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Removing this teaching period will delete the following units from
            your course plan:
          </DialogContentText>
          <div style={{ lineHeight: '1.4rem', color: 'rgba(0, 0, 0, 0.54)' }}>
            <ul>
              {affectedUnits.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={hideConfirmDeleteTeachingPeriodUI}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              removeTeachingPeriod(index);
              hideConfirmDeleteTeachingPeriodUI();
            }}>
            Remove Teaching Period
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

/**
 * we need the following variables for the modal to be populated and controlled
 */
const mapStateToProps = state => {
  return {
    affectedUnits: state.PlanInstance.affectedUnits,
    index: state.PlanInstance.indexOfTPtoRemove,
    showingConfirmDeleteTeachingPeriodModal:
      state.UI.showingConfirmDeleteTeachingPeriodModal,
  };
};

/**
 * We need the hidemodal function from uiActions and the removeTeachingPeriod action from course Actions
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    removeTeachingPeriod,
    hideConfirmDeleteTeachingPeriodUI,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDeleteTeachingPeriodModal);

ConfirmDeleteTeachingPeriodModal.propTypes = {
  index: PropTypes.number,
  affectedUnits: PropTypes.array,
  showingConfirmDeleteTeachingPeriodModal: PropTypes.bool,
  removeTeachingPeriod: PropTypes.func,
  hideConfirmDeleteTeachingPeriodUI: PropTypes.func,
  units: PropTypes.array,
};
