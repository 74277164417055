import NetworkService from './NetworkService';
import {
  MOCK_ARCHIVE_COURSE_PLAN_RESP,
  MOCK_CREATE_COURSE_PLAN_RESP,
  MOCK_FEEDBACK_RESPONSE,
  MOCK_FEEDBACK_SUBMISSION_RESPONSE,
  MOCK_GET_ALL_COURSE_PLANS_AS_ADVISOR_RESP,
  MOCK_GET_ALL_COURSE_PLANS_RESP,
  MOCK_GET_ARCHIVED_COURSE_PLANS_RESP,
  //MOCK_GET_AVAILABLE_COURSE_PLANS_RESP,
  MOCK_GET_COURSE_PLAN_RESP,
  MOCK_GET_SNAPSHOTS_RESP,
  MOCK_REJECT_RESP,
  MOCK_REVIEW_RESP,
  MOCK_SAVE_COURSE_PLAN_RESP,
  MOCK_ENQUIRY_SUBMITTED_RESP,
  MOCK_GET_COURSE_PLAN_EVENTS,
} from './data/mockData';

import { STATUSES } from '../constants';
import { MOCK_RESPONSE_TIME } from './constants';

import CoreUtils from '../utils/CoreUtils';
import DataTransformUtils from '../utils/DataTransformUtils';

/**
 * Responsible for retrieving and modifying the course plans and snapshots associated with a user
 */
export default class CoursePlanService {
  static getCoursePlan(coursePlanId) {
    const requestURI = `${window.location.origin}/api/plans/${coursePlanId}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('CoursePlanService.getCoursePlan()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_GET_COURSE_PLAN_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  // returns unarchived course plans
  static getAllCoursePlans() {
    const requestURI = `${window.location.origin}/api/plans`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.getAllCoursePlans()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_GET_ALL_COURSE_PLANS_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getAllSnapshots(coursePlanId) {
    const requestURI = `${
      window.location.origin
    }/api/snapshots?coursePlanId=${coursePlanId}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.getAllSnapshots()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_GET_SNAPSHOTS_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  // returns only the archived course plans
  static getArchivedCoursePlans() {
    const requestURI = `${window.location.origin}/api/plans?archived=true`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.getArchivedCoursePlans()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_GET_ARCHIVED_COURSE_PLANS_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  // saves a new snapshot and attaches to the provided course plan
  static saveCoursePlan(
    teachingPeriods,
    advancedStanding,
    coursePlanId,
    coursePlanName,
  ) {
    const body = {
      blob: DataTransformUtils.getMonPlanBlob(
        teachingPeriods,
        advancedStanding,
      ),
      coursePlanName,
      archived: false,
    };

    const requestURI = `${window.location.origin}/api/plans/${coursePlanId}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.saveCoursePlan()',
        requestURI,
        'PUT',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_SAVE_COURSE_PLAN_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.put(requestURI, body);
  }

  // archives a course plan so that the user will no longer see it in their list views
  static archiveCoursePlan(
    teachingPeriods,
    advancedStanding,
    coursePlanId,
    coursePlanName,
  ) {
    const body = {
      blob: DataTransformUtils.getMonPlanBlob(
        teachingPeriods,
        advancedStanding,
      ),
      coursePlanName,
      archived: true,
    };

    const requestURI = `${window.location.origin}/api/plans/${coursePlanId}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.archiveCoursePlan()',
        requestURI,
        'PUT',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_ARCHIVE_COURSE_PLAN_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.put(requestURI, body);
  }

  // creates a new course plan
  static createCoursePlan(
    teachingPeriods,
    advancedStanding,
    coursePlanName,
    courseCode,
    selectedAOSes,
    weightedAverageMark,
  ) {
    const body = {
      blob: DataTransformUtils.getMonPlanBlob(
        teachingPeriods,
        advancedStanding,
        weightedAverageMark,
      ),
      coursePlanName,
      courseCode,
      nominatedAreaOfStudys: selectedAOSes,
    };
    const requestURI = `${window.location.origin}/api/plans`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.createCoursePlan()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_CREATE_COURSE_PLAN_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static cloneCoursePlan(previousBlob, coursePlanName, courseCode) {
    const requestURI = `${window.location.origin}/api/plans`;
    const body = {
      blob: previousBlob,
      coursePlanName,
      courseCode,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.cloneCoursePlan()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_CREATE_COURSE_PLAN_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static cloneCoursePlanForStudent(
    previousBlob,
    coursePlanName,
    courseCode,
    studentId,
  ) {
    const requestURI = `${
      window.location.origin
    }/api/students/${studentId}/plans`;

    const body = {
      blob: previousBlob,
      coursePlanName,
      courseCode,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.cloneCoursePlanForStudent()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_CREATE_COURSE_PLAN_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static getCoursePlanEvents(coursePlanId) {
    const requestURI = `${
      window.location.origin
    }/api/plans/${coursePlanId}/events`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.getCoursePlanEvents()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_GET_COURSE_PLAN_EVENTS,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  // currently not in use
  static getSnapshotFeedback(snapshotId) {
    const requestURI = `${
      window.location.origin
    }/api/snapshots/${snapshotId}/feedback`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.getSnapshotFeedback()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_FEEDBACK_RESPONSE,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static submitSnapshotFeedback(snapshotId, feedback) {
    const requestURI = `${window.location.origin}/api/feedback`;

    const body = {
      feedback: feedback,
      snapshotId: snapshotId,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.submitSnapshotFeedback()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_FEEDBACK_SUBMISSION_RESPONSE,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static approveSnapshot(snapshotId) {
    const requestURI = `${window.location.origin}/api/approvals`;
    const body = {
      snapshotId: snapshotId,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.approveSnapshot()',
        requestURI,
        'POST',
        body,
      );
      const MOCK_DATA = {
        approvalId: 5742450765201408,
        snapshotId: 5196672627900416,
        approvalUser: 'ejia0002',
        approvalDate: '2018-09-14T00:33:06.824+0000',
        studentId: '27849821',
      };
      return CoreUtils.createMockRequest('resolve', MOCK_DATA, 1000);
    }

    return NetworkService.post(requestURI, body);
  }

  static getCoursePlansByStudentId(studentId) {
    const requestURI = `${
      window.location.origin
    }/api/students/${studentId}/plans`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.getCoursePlansByStudentId()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_GET_ALL_COURSE_PLANS_AS_ADVISOR_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static createCoursePlanForStudent(
    teachingPeriods,
    advancedStanding,
    coursePlanName,
    courseCode,
    selectedAOSes,
    studentId,
    weightedAverageMark,
  ) {
    const requestURI = `${
      window.location.origin
    }/api/students/${studentId}/plans`;

    const body = {
      blob: DataTransformUtils.getMonPlanBlob(
        teachingPeriods,
        advancedStanding,
        weightedAverageMark,
      ),
      coursePlanName,
      courseCode,
      nominatedAreaOfStudys: selectedAOSes,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.createCoursePlanForStudent()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_CREATE_COURSE_PLAN_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static submitForReview(snapshotId) {
    const requestURI = `${
      window.location.origin
    }/api/snapshots/${snapshotId}/status`;

    const body = {
      newStatus: STATUSES.LOCKED,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.submitForReview()',
        requestURI,
        'PUT',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_REVIEW_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.put(requestURI, body);
  }

  static submitRequestForReviewAustralia(
    teachingPeriods,
    advancedStanding,
    snapshotId,
    coursePlanName,
    faculty,
    school,
    message,
    enquiryType,
  ) {
    const requestURI = `${
      window.location.origin
    }/api/snapshots/${snapshotId}/saveLockAndSubmitEnquiry`;

    const body = {
      enquiryDetails: {
        faculty,
        school,
        message,
        enquiryType,
      },
      saveDetails: {
        blob: DataTransformUtils.getMonPlanBlob(
          teachingPeriods,
          advancedStanding,
        ),
        coursePlanName,
        archived: false,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.submitRequestForReviewAustralia()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_ENQUIRY_SUBMITTED_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static submitRequestForReviewMalaysia(
      teachingPeriods,
      advancedStanding,
      snapshotId,
      coursePlanName,
      malaysiaSchool,
      message,
      enquiryType,
  ) {
    const requestURI = `${
        window.location.origin
        }/api/snapshots/${snapshotId}/saveLockAndSubmitMalaysiaEmail`;

    const body = {
      enquiryDetails: {
        malaysiaSchool,
        message,
        enquiryType,
      },
      saveDetails: {
        blob: DataTransformUtils.getMonPlanBlob(
            teachingPeriods,
            advancedStanding,
        ),
        coursePlanName,
        archived: false,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
          'CoursePlanService.submitRequestForReviewMalaysia()',
          requestURI,
          'POST',
          body,
      );
      return CoreUtils.createMockRequest(
          'resolve',
          MOCK_ENQUIRY_SUBMITTED_RESP,
          MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }

  static rejectSnapshot(snapshotId) {
    const requestURI = `${
      window.location.origin
    }/api/snapshots/${snapshotId}/status`;

    const body = {
      newStatus: STATUSES.UNLOCKED,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.rejectSnapshot()',
        requestURI,
        'PUT',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_REJECT_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.put(requestURI, body);
  }
}
