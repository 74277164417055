import React from 'react';
import { withRouter } from 'react-router';

/**
 * This component scrolls the window to the top when the route changes.
 */
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    // When the path given by the router changes, scroll to the top
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children || null;
  }
}

export default withRouter(ScrollToTop);
