import React from 'react';
import PropTypes from 'prop-types';

import { LinearProgress, Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

/**
 * A simple component for letting the user know that they are adding a unit
 */
const AddingUnitIndicator = ({
  unitCode,
  onCancelAddingUnit,
  requiredCreditPoints,
  creditPoints,
}) => {
  // Place spaces between characters to force screen reader to read one character at a time
  const accessibleUnitCode = unitCode.split('').join(' ');

  return (
    <div style={{ margin: 0 }} className="flexcol">
      <div style={{ padding: '0.5em 1em' }} className="flexrow">
        <div className="flex-item-lg flexcol">
          <div className="flex-item-lg">
            <h3 className="heavy-text" style={{ margin: 0 }}>
              <div
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <AddIcon style={{ width: 16, height: 16 }} />
                <span style={{ marginLeft: '0.5em' }}>
                  Adding {unitCode || 'unit'}
                </span>
              </div>
            </h3>
          </div>
          <div className="flex-item-lg">
            <h5
              className="light-text"
              aria-live="polite"
              style={{ margin: 0 }}
              aria-label={
                'Add ' +
                (accessibleUnitCode || 'the unit') +
                ' by placing it somewhere in your course plan below.'
              }>
              Place the unit somewhere in your course plan below.
            </h5>
          </div>
        </div>
        <div className="flex-item-sm flexcol">
          <div className="flex-item-sm">
            <p className="light-text" style={{ margin: 0 }}>
              {requiredCreditPoints
                ? `${creditPoints}/${requiredCreditPoints}`
                : `${creditPoints}`}
            </p>
          </div>
          <div className="flex-item-sm">
            <p
              className="light-text"
              style={{ margin: 0 }}
              aria-label="Enter Credit Points">
              Credit points
            </p>
          </div>
        </div>
        <div className="flex-item-sm">
          <Button
            color="primary"
            onClick={onCancelAddingUnit}
            aria-label="Cancel">
            Cancel{' '}
          </Button>
        </div>
      </div>
      <LinearProgress mode="indeterminate" />
    </div>
  );
};

export default AddingUnitIndicator;

AddingUnitIndicator.propTypes = {
  unitCode: PropTypes.string.isRequired,
  onCancelAddingUnit: PropTypes.func,
  requiredCreditPoints: PropTypes.number,
  creditPoints: PropTypes.number,
};
