import NetworkService from './NetworkService';
import CoreUtils from '../utils/CoreUtils';

import { MOCK_RESPONSE_TIME } from './constants';
import MOCK_STATS from './data/stats';

export default class StatsService {
  static getStats() {
    const requestURI = `${window.location.origin}/api/stats`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('StatsService.getStats()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_STATS,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getStatsByDate(date) {
    const requestURI = `${window.location.origin}/api/stats?date=${date}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('StatsService.getStatsByDate()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_STATS,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
}
