import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * UnitDescriptionContainer is a container component that controls the hide/show more display of a
 * large text pargraph. It is designed to be reusabled, so once you feed it a limit character count and
 * a text it will manage its own state completely
 *
 * @author JXNS, Saurabh Joshi
 *
 * @param {number} textLength - the max length you want before it is cut off
 * @param {string} fullText - the full text that you want to have display limited
 */
export default class UnitDescriptionContainer extends Component {
  /**
   * Constructor calculates whether the text needs to be shortened, if it does the it saves the
   * shortened version of the text.
   */
  constructor(props) {
    super(props);

    let maxLength = this.props.textLength;
    let fullText = this.props.fullText;
    let shortText = '';

    if (!fullText || !fullText.trim()) {
      fullText = undefined;
    } else if (fullText.length > maxLength) {
      let textArr = fullText.split('');
      shortText = textArr.slice(0, maxLength);
      shortText = shortText.join('');
    }

    this.state = {
      fullText: fullText,
      shortText: shortText,
      remainingText: '',
      hide: true,
    };

    this.toggle = this.toggle.bind(this);
  }

  /**
   * This handles the toggling of the show more/less link
   */
  toggle() {
    let newValue = !this.state.hide;
    this.setState({ hide: newValue });
  }

  /**
   * Render is pretty straight forward. if the shortText string is not null (i.e. theres a reason to shorten it)
   * then it cycles through the hiding and showing of the full text based on the hide parameters
   */
  render() {
    return (
      <p style={{ marginBottom: '16px' }}>
        {(!this.state.fullText && 'No description provided.') ||
          ((this.state.shortText !== '' && (
            <span>
              {this.state.hide ? this.state.shortText : this.state.fullText}
              {this.state.hide && '...'}
              <span
                tabIndex={0}
                style={{
                  cursor: 'pointer',
                  color: this.props.facultyColor,
                }}
                onClick={this.toggle}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.toggle();
                  }
                }}>
                {(this.state.hide && ' Show more') || ' Show less'}
              </span>
            </span>
          )) ||
            this.state.fullText)}
      </p>
    );
  }
}

UnitDescriptionContainer.propTypes = {
  textLength: PropTypes.number.isRequired,
  fullText: PropTypes.string,
  facultyColor: PropTypes.string,
};
