import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  miniProfile: {
    color: 'rgba(0,0,0,0.6)',
    backgroundColor: 'white',
    padding: '16px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  },
  profileInfo: {
    marginTop: '8px',
    fontWeight: 'bold',
  },
});

const MiniProfile = props => {
  const {
    authcate,
    studentId,
    emailAddress,
    firstName,
    surname,
    faculty,
    currentCourse,
    studentStatus,
  } = props.studentInfo;
  const { classes } = props;
  return (
    <div id="miniProfile">
      <div className={classes.miniProfile}>
        <div tabIndex={0} id="miniProfile-content">
          <Typography variant="title">Student Profile</Typography>
          <dl>
            <dt className={classes.profileInfo}>Authcate</dt>
            <dd style={{ margin: 0 }}>{authcate || 'N/A'}</dd>

            <dt className={classes.profileInfo}>Student ID</dt>
            <dd style={{ margin: 0 }}>{studentId || 'N/A'}</dd>

            <dt className={classes.profileInfo}>Student Name</dt>
            <dd style={{ margin: 0 }}>
              {firstName} {surname}
            </dd>

            <dt className={classes.profileInfo}>Email</dt>
            <dd style={{ margin: 0 }}>{emailAddress || 'N/A'}</dd>

            <dt className={classes.profileInfo}>Faculty</dt>
            <dd style={{ margin: 0 }}>{faculty || 'N/A'}</dd>

            <dt className={classes.profileInfo}>Current Course</dt>
            <dd style={{ margin: 0 }}>{currentCourse || 'N/A'}</dd>

            <dt className={classes.profileInfo}>Student Status</dt>
            <dd style={{ margin: 0 }}>{studentStatus || 'N/A'}</dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(MiniProfile);
