const styles = theme => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 10,
  },
  loaderMessage: {
    fontSize: '14px',
  },
});

export default styles;
