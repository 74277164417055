import NetworkService from './NetworkService';
import { IP_TRACKING_URL } from '../constants';
import CoreUtils from '../utils/CoreUtils';

export default class AnalyticsService {
  static getUserIP() {
    const requestURI = `${IP_TRACKING_URL}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('AnalyticsService.getUserIP()', requestURI, 'GET');
    }

    return NetworkService.get(requestURI, {}, true);
  }
}
