import moment from 'moment';

export default class YearCalc {
  /**
   * Returns a list of start years ranging a decade on each side from the
   * initial value.
   *
   * @param {string} initialVal - The initial year.
   */
  static getStartYearVals(initialVal) {
    const val = parseInt(initialVal, 10);

    const min = val - 10;
    const max = val + 10;
    const validStartYears = [];

    for (let i = val + 1; i >= min; i--) {
      validStartYears.push({ label: i.toString(), value: i });
    }

    for (let i = max - 8; i <= max; i++) {
      validStartYears.push({ label: i.toString(), value: i });
    }

    return validStartYears;
  }

  // ASSUMPTION; teachingPeriodData only contains teaching periods from the same year, and not multiple years
  static calculateMinMaxYear = (teachingPeriods, teachingPeriodData) => {
    // Find out the last (contiguous) preloaded teaching period
    let lastPreloadedYear = null;
    let lastPreloadedCode = null;

    for (let i = 0; i < teachingPeriods.length; i++) {
      const tp = teachingPeriods[i];
      if (!tp.isPreloaded) {
        break;
      }

      lastPreloadedYear = tp.year;
      lastPreloadedCode = tp.code;
    }

    // set initial min and max year, based on last preloaded year (null if it does not exist)
    let min = lastPreloadedYear;
    let max = lastPreloadedYear;

    // Determine the value of min and max year from non-preloaded teaching periods
    const yearObj = teachingPeriods.reduce(
        ({ minYear, maxYear }, teachingPeriod) => {
          if (
              !teachingPeriod.isPreloaded &&
              (!minYear || teachingPeriod.year < minYear)
          ) {
            minYear = teachingPeriod.year;
          }
          if (
              !teachingPeriod.isPreloaded &&
              (!maxYear || teachingPeriod.year > maxYear)
          ) {
            maxYear = teachingPeriod.year;
          }

          return { minYear, maxYear };
        },
        { minYear: min, maxYear: max },
    );

    // Give an additional year from both sides, to allow users to add future or past years
    let minYear = yearObj.minYear - 1;
    let maxYear = yearObj.maxYear + 1;

    // If we have preloaded teaching periods, increase the value of minYear
    if (lastPreloadedYear) {
      const lastPreloadedTp = teachingPeriodData.find(
          tp => tp.code === lastPreloadedCode,
      );

      // If we are able to find information about the last preloaded teaching period
      if (lastPreloadedTp && lastPreloadedTp.endDate) {
        const lastPreloadedTpEndDate = moment(
            lastPreloadedTp.endDate,
            'YYYY-MM-DD',
        );

        // Find all teaching periods that starts after the end of the last preloaded teaching period
        const remainingTPs = teachingPeriodData.filter(tp => {
          const tpStartDate = moment(tp.startDate, 'YYYY-MM-DD');
          return (
              tp.code !== lastPreloadedCode &&
              tpStartDate.isAfter(lastPreloadedTpEndDate) // moment is annoying, but this is startDate > endDate
          );
        });

        if (remainingTPs.length > 0) {
          minYear += 1;
        } else {
          minYear += 2;
        }
      } else {
        minYear += 2;
      }
    }

    return {
      minYear,
      maxYear,
    };
  };
}
