import NetworkService from './NetworkService';
import {
  MOCK_COURSE_ENROLMENT_RESP,
  MOCK_UNIT_ENROLMENT_RESP,
  MOCK_ADVANCED_STANDING_RESP,
} from './data/mockData';

import CoreUtils from '../utils/CoreUtils';
import { MOCK_RESPONSE_TIME } from './constants';

/**
 * Responsible for retrieving and modifying the course plans and snapshots associated with a user
 */
export default class EnrolmentService {
  static getCourseEnrolments() {
    const requestURI = `${window.location.origin}/api/enrolments/courses`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnrolmentService.getCourseEnrolments()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_COURSE_ENROLMENT_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getAdvancedStanding() {
    const requestURI = `${
      window.location.origin
    }/api/enrolments/advanced-standing`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnrolmentService.getAdvancedStanding()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_ADVANCED_STANDING_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getUnitEnrolments() {
    const requestURI = `${window.location.origin}/api/enrolments/units`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnrolmentService.getUnitEnrolments()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_UNIT_ENROLMENT_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getStudentCourseEnrolments(studentId) {
    const requestURI = `${
      window.location.origin
    }/api/students/${studentId}/enrolments/courses`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnrolmentService.getStudentCourseEnrolments()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_COURSE_ENROLMENT_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getStudentAdvancedStanding(studentId) {
    const requestURI = `${
      window.location.origin
    }/api/students/${studentId}/enrolments/advanced-standing`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnrolmentService.getStudentAdvancedStanding()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_ADVANCED_STANDING_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getStudentUnitEnrolments(studentId) {
    const requestURI = `${
      window.location.origin
    }/api/students/${studentId}/enrolments/units`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnrolmentService.getStudentUnitEnrolments()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_UNIT_ENROLMENT_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
}
