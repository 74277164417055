/**
 * Defines the default error types for
 */
const ERRORS = {
  DUPLICATE: 'DUPLICATE',
  STUDY_LOAD: 'STUDY_LOAD',
  INTERMISSION: 'INTERMISSION',
  TIME_LIMIT: 'TIME_LIMIT',
  UNIT_OFFERINGS: 'UNIT_OFFERINGS',
  UNIT_RULES: 'UNIT_VERSION_RULES',
  COURSE_RULES: 'COURSE_VERSION_RULES',
  NON_STANDARD_TEACHING_PERIODS: 'NON_STANDARD_TEACHING_PERIODS',
};

export default ERRORS;
