import CoreUtils from '../../utils/CoreUtils';

import { ROLES } from '../../constants';

export default class User {
  constructor({ username, firstName, lastName, roles, emailAddress }) {
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.roles = roles;
    this.emailAddress = emailAddress;

    // change to true for debug
    const DEBUG_FLAG = false;
    CoreUtils.classLogger(DEBUG_FLAG, this);
  }

  get username() {
    return this._username;
  }

  get firstName() {
    if (
      !this._firstName ||
      (typeof this._firstName === 'string' && this._firstName.trim() === '')
    ) {
      return null;
    }

    return this._firstName;
  }

  get lastName() {
    if (
      !this._lastName ||
      (typeof this._lastName === 'string' && this._lastName.trim() === '')
    ) {
      return null;
    }

    return this._lastName;
  }

  get emailAddress() {
    return this._emailAddress;
  }

  get isAusStudent() {
    return this._roles.includes(ROLES.STUDENT);
  }

  get isAusAdvisor() {
    return this._roles.includes(ROLES.ADVISOR);
  }

  get isAdmin() {
    return this._roles.includes(ROLES.ADMIN);
  }

  get isMonashConnect() {
    return this._roles.includes(ROLES.MONASH_CONNECT);
  }

  get isMalaysiaStudent() {
    return this._roles.includes(ROLES.MALAYSIA_STUDENT);
  }

  get isMalaysiaStaff() {
    return this._roles.includes(ROLES.MALAYSIA_STAFF);
  }

  get isMsaStudent() {
    return this._roles.includes(ROLES.MSA_STUDENT);
  }

  get isMsaStaff() {
    return this._roles.includes(ROLES.MSA_STAFF);
  }

  get isMsa() {
    return this.isMsaStaff || this.isMsaStudent;
  }

  get isStudent() {
    return this.isAusStudent || this.isMalaysiaStudent || this.isMsaStudent;
  }

  get isStaff() {
    return this.isAusAdvisor || this.isMonashConnect || this.isMalaysiaStaff || this.isMsaStaff;
  }

  set username(username) {
    this._validateUsername(username);
    this._username = username;
  }

  set firstName(firstName) {
    this._validateFirstName(firstName);
    this._firstName = firstName;
  }

  set lastName(lastName) {
    this._validateLastName(lastName);
    this._lastName = lastName;
  }

  set roles(roles) {
    this._validateRoles(roles);
    this._roles = roles;
  }

  set emailAddress(emailAddress) {
    this._validateEmailAddress(emailAddress);
    this._emailAddress = emailAddress;
  }

  _validateUsername(username) {
    if (!username) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a username value, received: ${username}`,
      );
    }
  }

  _validateFirstName(firstName) {
    return;
  }

  _validateLastName(lastName) {
    return;
  }

  _validateRoles(roles) {
    if (!roles) {
      throw new Error(
        `${
          this.constructor.name
        } was not given roles value, received: ${roles}`,
      );
    }

    if (!roles.length) {
      throw new Error(
         `${
           this.constructor.name
         } was either given roles value which is not an array, or the array is empty`
      )
    }

    if (!Object.keys(ROLES).some(role => roles.includes(role))) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a valid role value, expected one of ${Object.keys(ROLES).join(
          ', ',
        )} but received the following roles: ${roles.join(', ')}`,
      );
    }
  }

  _validateEmailAddress(emailAddress) {
    if (!emailAddress) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a emailAddress value, received: ${emailAddress}`,
      );
    }
  }
}
