import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Today as CalendarIcon } from '@material-ui/icons';

import { teachingPeriodCodeToString } from '../../utils/ValidateCoursePlan';
/**
 * Offering container returns the formatted unit offering/location data
 *
 * @author JXNS
 *
 * @param {array} offeringArray - a multidimensional array of values of the form [[[Location1], [offering1, offering2, ...], [Location2], [offering1, offering2, ...]]] etc
 *
 */
class OfferingContainer extends Component {
  /**
   * If the data is not available it renders a message indicating as much, otherwise it maps through the data and renders it.
   */
  render() {
    const { offeringArray } = this.props;

    return (
      <div style={{ paddingTop: '16px' }}>
        <CalendarIcon
          style={{
            float: 'left',
            margin: '0 16px 0 0',
            width: 24,
            height: 24,
          }}
        />
        <div style={{ marginLeft: '40px' }}>
          {offeringArray.length ? (
            offeringArray.map((item, index) => {
              return (
                <div key={index} style={{ paddingBottom: '8px' }}>
                  <div style={{ fontWeight: '500' }}>
                    {item.location} {item.mode && `(${item.mode})`}
                  </div>
                  {teachingPeriodCodeToString(item.code)}, {item.year}
                </div>
              );
            })
          ) : (
            <span>
              <div style={{ fontWeight: '500' }}>
                No unit offering available
              </div>
              This could be because either this unit has been discontinued or
              the unit is not offered in 2018.
            </span>
          )}
        </div>
      </div>
    );
  }
}

OfferingContainer.propTypes = {
  offeringArray: PropTypes.array,
};

export default OfferingContainer;
