import React, { Component } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// MUI Icons
import { default as ShowMoreIcon } from '@material-ui/icons/ExpandMore';
import { default as ShowLessIcon } from '@material-ui/icons/ExpandLess';
import { default as FeedbackIcon } from '@material-ui/icons/Chat';
import { default as ApproveIcon } from '@material-ui/icons/Done';
import { default as NextIcon } from '@material-ui/icons/ChevronRight';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Constants
import { COLORS, STATUSES } from '../../../constants';

// Utils
import FacultyUtils from '../../../utils/FacultyUtils';
import HandbookUtils from '../../../utils/HandbookUtils';

const BlurredLine = props => {
  return (
    <div>
      <img
        style={{ filter: 'blur(5px)', ...props.style }}
        alt="Loading placeholder"
        src="../img/loaders/smallText.png"
      />
    </div>
  );
};

class CourseMapSummary extends Component {
  state = {
    versionHistoryOpen: false,
    archiveModalOpen: false,
  };

  onVersionHistoryClick = () => {
    this.props.onSeeVersionsClick();
    this.setState({ versionHistoryOpen: true });
  };

  closeVersionHistory = () => {
    this.setState({ versionHistoryOpen: false });
  };

  openArchiveConfirmation = () => {
    this.setState({ archiveModalOpen: true });
  };

  closeArchiveConfirmation = () => {
    this.setState({ archiveModalOpen: false });
  };

  handleArchiveClick = () => {
    this.closeArchiveConfirmation();
    this.props.onArchiveClick();
  };

  renderArchiveModal = () => {
    const { archiveModalOpen } = this.state;
    return (
      <Dialog open={archiveModalOpen} onClose={this.closeArchiveConfirmation}>
        <DialogTitle>Delete Course Plan?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will delete the course plan and you will no longer be able to
            access it from the plans page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button aria-label="Cancel" onClick={this.closeArchiveConfirmation}>
            Cancel
          </Button>
          <Button onClick={this.handleArchiveClick}>Delete</Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderActionsBar = () => {
    const {
      courseCode,
      startYear,
      onLoadClick,
      classes,
      versionsLoading,
      versions,
      onCloneClick,
      readOnly,
      user,
      isAuth,
    } = this.props;
    const { versionHistoryOpen } = this.state;

    let canClone = false;
    if (isAuth) {
      if ((user.isStudent && !user.isMsaStudent) || (user.isStaff && !user.isMsaStaff)) {
        canClone = true;
      }
    }

    const primaryActionText = readOnly
      ? 'View Plan'
      : (user.isStaff && !user.isMsaStaff && 'Review Plan') ||
        (user.isStudent && !user.isMsaStudent && 'Edit Plan');

    const actions = (
      <div className={classes.courseMapActionsContainer}>
        <Button
          aria-label="external link to the Monash University Handbook"
          component="a"
          style={{ color: 'black' }}
          target="_blank"
          rel="noopener noreferrer"
          href={HandbookUtils.getCourseLink(courseCode, startYear)}>
          Handbook
        </Button>
        <div className={classes.courseMapEditLoadContainer}>
          {canClone && (
            <Button aria-label="Clone This Plan" onClick={onCloneClick}>
              Clone This Plan
            </Button>
          )}
          {!readOnly && (
            <Button
              aria-label="Delete Plan"
              onClick={this.openArchiveConfirmation}>
              Delete Plan
            </Button>
          )}
          <Button
            variant="raised"
            color="primary"
            aria-label={primaryActionText}
            onClick={onLoadClick}>
            {primaryActionText}
            <NextIcon color="inherit" />
          </Button>
        </div>
      </div>
    );
    if (versionHistoryOpen && !versionsLoading) {
      return (
        <React.Fragment>
          <Button
            aria-label="Close Version History"
            onClick={this.closeVersionHistory}>
            <ShowLessIcon /> Close Version History
          </Button>
          <div className={classes.versionHistoryContainer}>
            {versions.map((version, i) => (
              <div key={i} className={classes.versionHistoryRow}>
                <div className={classes.versionHistoryData}>
                  <p
                    className={`${classes.version} ${
                      classes.marginReset
                    }`}>{`Version ${version.versionNumber}`}</p>
                  <span
                    className={`${classes.createdContainer} ${
                      classes.marginReset
                    }`}>
                    <dd className={classes.createdLabel}>Created:</dd>
                    <dt className={classes.createdValue}>
                      {version.createdDate}
                    </dt>
                  </span>
                  <span
                    className={`${classes.createdContainer} ${
                      classes.marginReset
                    }`}>
                    <dd className={classes.createdLabel}>Created by:</dd>
                    <dt className={classes.createdValue}>
                      {version.createdBy}
                    </dt>
                  </span>
                </div>
              </div>
            ))}
          </div>
          {actions}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button
          aria-label="Open Version History"
          onClick={this.onVersionHistoryClick}>
          <ShowMoreIcon /> Open Version History
        </Button>
        {actions}
      </React.Fragment>
    );
  };

  renderUnitRows(isLoading) {
    const { classes, unitRows } = this.props;

    const cardStyles = isLoading ? { filter: 'blur(3px)' } : {};
    return (
      <Card className={classes.courseMapUnits} style={cardStyles}>
        {unitRows.map((row, index) => (
          <div
            key={`unit-row-${index}`}
            className={
              index === 0
                ? `${classes.courseMapUnitsFirstRow} ${
                    classes.courseMapUnitsRow
                  }`
                : classes.courseMapUnitsRow
            }>
            {row.map((unit, otherindex) => (
              <div
                key={`unit-cell-${index}-${otherindex}`}
                className={classes.courseMapUnitContainer}>
                {unit && (
                  <div className={classes.courseMapUnit}>
                    <div
                      style={{
                        background: isLoading
                          ? 'rgba(0, 0, 0, 0.4)'
                          : FacultyUtils.getFacultyColour(unit.faculty),
                      }}
                      className={classes.unitStripe}
                    />
                    <div className={classes.courseMapUnitCode}>
                      {unit.unitCode}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </Card>
    );
  }

  render() {
    const {
      title,
      classes,
      faculty,
      courseName,
      lastUpdated,
      totalCreditPoints,
      unsupportedCourse,
      user,
      creditPointsCompleted,
      isLoading,
      status,
    } = this.props;

    if (isLoading) {
      return (
        <Card
          className={classes.courseMapContainer}
          style={{ position: 'relative' }}>
          <CircularProgress
            size={100}
            style={{
              position: 'absolute',
              zIndex: 1,
              top: 'calc(50% - 100px)',
              left: 'calc(50% - 50px)',
              color: 'rgba(0, 0, 0, 0.4)',
            }}
          />
          <div className={classes.courseMapContentContainer}>
            <div className={classes.courseMapContent}>
              <p
                className={classes.courseMapTitle}
                style={{ filter: 'blur(5px)' }}>
                {title}
              </p>
              <div className={classes.courseMapDetail}>
                <dt
                  className={classes.dataLabel}
                  style={{ filter: 'blur(3px)' }}>
                  Course Title:
                </dt>
                <BlurredLine />
              </div>
              <div className={classes.courseMapDetail}>
                <dt
                  className={classes.dataLabel}
                  style={{ filter: 'blur(3px)' }}>
                  Managing Faculty:
                </dt>
                <BlurredLine />
              </div>
              <LinearProgress
                style={{
                  filter: 'blur(5px)',
                  marginTop: '15px',
                  height: '8px',
                  marginBottom: '5px',
                  background: 'rgba(0, 0, 0, 0.4)',
                }}
                variant="determinate"
                value={0}
              />
              <div className={classes.courseMapStats}>
                <dl className={classes.courseMapStat}>
                  <dt
                    className={classes.dataLabel}
                    style={{ filter: 'blur(3px)' }}>
                    Credit Points
                  </dt>
                  <BlurredLine />
                </dl>
                <dl className={classes.courseMapStat}>
                  <dt
                    className={classes.dataLabel}
                    style={{ filter: 'blur(3px)' }}>
                    Starting Year
                  </dt>
                  <BlurredLine />
                </dl>
                <dl className={classes.courseMapStat}>
                  <dt
                    className={classes.dataLabel}
                    style={{ filter: 'blur(3px)' }}>
                    Last Updated
                  </dt>
                  <BlurredLine />
                </dl>
              </div>
            </div>
            <div className={classes.courseMapUnitsContainer}>
              {this.renderUnitRows(true)}
            </div>
          </div>
          <Button disabled>Open Version History</Button>
          <div className={classes.courseMapActionsContainer}>
            <Button disabled>Handbook</Button>
            <div className={classes.courseMapEditLoadContainer}>
              <Button disabled>Clone This Plan</Button>
              <Button disabled>Delete Plan</Button>
              <Button variant="raised" color="primary" disabled>
                Load Plan <NextIcon color="inherit" />
              </Button>
            </div>
          </div>
        </Card>
      );
    }

    let courseMapStatusColor;
    let statusBanner;
    let statusMessage;

    if (user.isMsaStudent) {
      statusBanner = null;
      statusMessage = null;
    } else if (status === STATUSES.LOCKED) {
      courseMapStatusColor = '#757575';
      statusBanner = (
        <div
          className={classes.courseMapStatus}
          style={{ backgroundColor: courseMapStatusColor }}>
          <FeedbackIcon
            className={classes.courseMapStatusIcon}
            style={{
              height: '35px',
            }}
          />
        </div>
      );
      statusMessage = 'Status: Awaiting Adviser Review';
    } else if (status === STATUSES.APPROVED) {
      courseMapStatusColor = COLORS.MISC.success;
      statusBanner = (
        <div
          className={classes.courseMapStatus}
          style={{ backgroundColor: courseMapStatusColor }}>
          <ApproveIcon className={classes.courseMapStatusIcon} />
        </div>
      );
      statusMessage = 'Status: Approved';
    } else if (status === STATUSES.UNLOCKED) {
      // no need to do anything with status banner
      statusBanner = null;
      statusMessage = 'Status: Under development';
    } else {
      console.error('encountered status that we did not handle!', status);
    }

    const progressAmount = unsupportedCourse
      ? 0
      : Math.min((creditPointsCompleted / totalCreditPoints) * 100, 100);

    return (
      <Card className={classes.courseMapContainer} tabIndex={0}>
        {statusBanner}
        <div className={classes.courseMapContentContainer}>
          <div className={classes.courseMapContent}>
            <div className={classes.courseMapTitleContainer}>
              <p className={classes.courseMapTitle}>{title}</p>
              <span className={classes.courseStatus}>{statusMessage}</span>
            </div>
            <div className={classes.courseMapDetail}>
              <dt className={classes.dataLabel}>Course Title:</dt>
              <dd className={classes.marginReset}>{courseName}</dd>
            </div>
            {unsupportedCourse ? (
              <div className={classes.courseMapDetail}>
                <dd className={classes.marginReset}>
                  Unfortunately we do not have data for this course, you will
                  still be able to plan, but some features will not be
                  available.
                </dd>
              </div>
            ) : (
              <div className={classes.courseMapDetail}>
                <dt className={classes.dataLabel}>Managing Faculty:</dt>
                <dd className={classes.marginReset}>{faculty}</dd>
              </div>
            )}
            <LinearProgress
              style={{ marginTop: '15px', height: '8px', marginBottom: '5px' }}
              variant="determinate"
              value={progressAmount}
            />
            <div className={classes.courseMapStats}>
              <dl className={classes.courseMapStat}>
                <dt className={classes.dataLabel}>Credit Points</dt>
                <dd className={classes.marginReset}>
                  {unsupportedCourse
                    ? `${creditPointsCompleted}`
                    : `${creditPointsCompleted} / ${totalCreditPoints}`}
                </dd>
              </dl>
              <dl className={classes.courseMapStat}>
                <dt className={classes.dataLabel}>Last Updated</dt>
                <dd className={classes.marginReset}>{lastUpdated}</dd>
              </dl>
            </div>
          </div>
          <div className={classes.courseMapUnitsContainer}>
            {this.renderUnitRows(false)}
          </div>
        </div>
        {this.renderActionsBar()}
        {this.renderArchiveModal()}
      </Card>
    );
  }
}

export default withStyles(styles)(CourseMapSummary);

CourseMapSummary.defaultProps = {
  status: 'default',
};

CourseMapSummary.propTypes = {
  title: PropTypes.string.isRequired,
  startYear: PropTypes.number,
  faculty: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  unitRows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        unitCode: PropTypes.string,
        faculty: PropTypes.string,
      }),
    ),
  ).isRequired,
  nominatedAreasOfStudy: PropTypes.arrayOf(PropTypes.string),
  courseName: PropTypes.string.isRequired,
  courseCode: PropTypes.string.isRequired,
  onLoadClick: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  onArchiveClick: PropTypes.func.isRequired,
  totalCreditPoints: PropTypes.number.isRequired,
  creditPointsCompleted: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(STATUSES)),
  onCloneClick: PropTypes.func.isRequired,
  user: PropTypes.object,
};
