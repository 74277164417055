import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

// MUI Components
import Paper from '@material-ui/core/Paper';

// Components
import Header from '../../components/Base/Header';
import AdminHistoryContainer from '../../containers/AdminHistoryContainer';

// Styles
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {},
  body: {
    background: '#eee',
    minHeight: '100vh',
    padding: `${4 * theme.spacing.unit}px 0`,
  },
  innerContent: {
    maxWidth: 1000,
    width: '100%',
    margin: '0 auto',
    padding: '16px 0',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
  content: {
    padding: '20px 40px',
    minHeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  headings: {
    color: 'rgba(0, 0, 0, 0.75)',
  },
  icon: {
    marginRight: 10,
  },
  iconAndTitle: {
    display: 'flex',
    alignItems: 'center',
  },
});
class AdminHistory extends React.Component {
  render = () => {
    const { classes, user, isAuth } = this.props;

    if (!(isAuth && user.isAdmin)) {
      browserHistory.push('/');
    }

    return (
      <Fragment>
        <Header />

        <div className={classes.body}>
          <div className={classes.innerContent}>
            <Paper className={classes.content} tabIndex="0">
              <h1 className={classes.headings}>Admin History</h1>
              <AdminHistoryContainer
                query={new URLSearchParams(window.location.search).get('type')}
              />
            </Paper>
          </div>
        </div>
      </Fragment>
    );
  };
}

const AdminDashboardWithStyles = withStyles(styles)(AdminHistory);

const mapStateToProps = state => ({
  user: state.User.user,
  isAuth: state.User.isAuth,
});

export default connect(
  mapStateToProps,
  null,
)(AdminDashboardWithStyles);
