import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

// MUI Icons
import { default as RemoveIcon } from '@material-ui/icons/RemoveCircleOutline';

// Actions
import {
  attemptToDecreaseStudyLoad,
  decreaseStudyLoad,
} from '../../../actions/CourseActions';
import { hideConfirmDecreaseStudyLoadUI } from '../../../actions/UIActions';

/**
 * @author JXNS
 * @param {boolean} isDisabled - parent component controls when the button should be disabled
 * @param {function} getAffectedUnits - parent component calculates all units that would be affected by the remove and returns them in an array
 * @param {function} handleRemove - when user confirms deletion, this function is called to handle the removal of overload column
 */
class ConfirmDeleteOverloadModal extends Component {
  /**
   * sets up start values, modal should not be opened at start and unitArray is empty
   */
  constructor(props) {
    super(props);

    this.handlePress = this.handlePress.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  /**
   * When the user presses the remove button, the parent calculates what (if any) units will be affected and returns them in an array.
   * If the array is empty, the parent can safely remove, if the array has values, then the confirm modal is displayed
   */
  handlePress() {
    if (this.props.numberOfUnits <= 4) {
      this.props.closeMenu();
      return;
    }

    const {
      teachingPeriods,
      teachingPeriodIndex,
      attemptToDecreaseStudyLoad,
    } = this.props;
    attemptToDecreaseStudyLoad(
      teachingPeriods[teachingPeriodIndex],
      teachingPeriodIndex,
    );
  }

  /**
   * The modal closes and the overload column is not affected if user presses cancel
   */
  handleCancel() {
    const { hideConfirmDecreaseStudyLoadUI, closeMenu } = this.props;
    hideConfirmDecreaseStudyLoadUI();
    closeMenu();
  }

  /**
   * user confirms, the modal is closed and parents handles removal.
   */
  handleConfirm() {
    const {
      decreaseStudyLoad,
      teachingPeriodIndex,
      hideConfirmDecreaseStudyLoadUI,
      closeMenu,
    } = this.props;

    hideConfirmDecreaseStudyLoadUI();
    decreaseStudyLoad(teachingPeriodIndex);
    closeMenu();
  }

  /**
   * Construct list of affected items in message for modal, if the modal is not open then show a button.
   *
   * To prevent multiple modals from showing up, the teaching period index is used to check
   * if it matches with what we want it to be.
   */
  render() {
    let IDcount = 0;

    const {
      numberOfUnits,
      showingConfirmDecreaseStudyLoadModal,
      teachingPeriodIndex,
      selectedTeachingPeriodIndex,
    } = this.props;
    const message = (
      <div>
        <p>
          Removing this overload column will delete the following units from
          your course plan:
        </p>
        <ul>
          {this.props.affectedUnits.map(item => {
            return <li key={item + IDcount++}>{item}</li>;
          })}
        </ul>
      </div>
    );

    if (
      showingConfirmDecreaseStudyLoadModal &&
      teachingPeriodIndex === selectedTeachingPeriodIndex
    ) {
      // show the right modal only
      return (
        <Dialog open={true}>
          <DialogTitle>
            Are you sure you want to remove the following units?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              aria-label="Cancel"
              color="primary"
              onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              arial-label="Remove Units"
              color="primary"
              onClick={this.handleConfirm}>
              Remove Units
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return (
      <MenuItem
        disabled={numberOfUnits <= 4}
        onClick={this.handlePress}
        aria-label="Remove overload column">
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText>Remove Overloading Unit</ListItemText>
      </MenuItem>
    );
  }
}

/**
 *
 */
const mapStateToProps = state => {
  return {
    teachingPeriods: state.PlanInstance.teachingPeriods,
    showingConfirmDecreaseStudyLoadModal:
      state.UI.showingConfirmDecreaseStudyLoadModal,
    selectedTeachingPeriodIndex: state.UI.selectedTeachingPeriodIndex,
    affectedUnits: state.PlanInstance.affectedUnits,
  };
};

/**
 *
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    attemptToDecreaseStudyLoad,
    decreaseStudyLoad,
    hideConfirmDecreaseStudyLoadUI,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDeleteOverloadModal);

ConfirmDeleteOverloadModal.propTypes = {
  mobile: PropTypes.bool,
  decreaseStudyLoad: PropTypes.func,
  numberOfUnits: PropTypes.number,
  showingConfirmDecreaseStudyLoadModal: PropTypes.bool,
  hideConfirmDecreaseStudyLoadUI: PropTypes.func,
  teachingPeriods: PropTypes.array,
  attemptToDecreaseStudyLoad: PropTypes.func,
  affectedUnits: PropTypes.array,
  teachingPeriodIndex: PropTypes.number,

  /* Used for confirming that the user wants to delete a column */
  selectedTeachingPeriodIndex: PropTypes.number,
};
