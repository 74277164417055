import React from 'react';
import PropTypes from 'prop-types';

export const getErrorMessage = (value, validators) => {
  for (let validator of validators) {
    const errorMessage = validator(value);
    if (errorMessage) {
      return errorMessage;
    }
  }
  return null;
};

/**
 * This is a wrapper for the Material UI `TextField` component that
 * provides validation on the field input.
 *
 * Provide the `value` prop which is the value that needs to be validated.
 *
 * Use the `validator()` prop to provide a function that will return `true` if
 * the given value is valid, and false otherwise.
 *
 * Provide a `generateHelperText()` and `generateErrorText()` function to create
 * appropriate helper text if the field is valid or invalid.
 *
 * Provide an `errorProps` object to add custom props to the TextField component
 * when there is an error.
 */

class TextFieldValidator extends React.PureComponent {
  static propTypes = {
    value: PropTypes.any,
    generateHelperText: PropTypes.func,
    generateErrorText: PropTypes.func,
    errorProps: PropTypes.object,
    validators: PropTypes.arrayOf(PropTypes.func),
    hideHelperText: PropTypes.bool,
  };

  static defaultProps = {
    generateHelperText: _ => `This input is valid.`,
    generateErrorText: _ => `This input is invalid.`,
    errorProps: { error: true },
    validators: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      childProps: {},
    };
  }

  componentDidMount() {
    this.calculateProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.calculateProps(nextProps);
  }

  // Calculate the props to add to the TextField
  calculateProps = props => {
    const {
      value,
      generateHelperText,
      errorProps,
      validators,
      hideHelperText,
    } = props;

    const errorMessage = getErrorMessage(value, validators);
    let childProps = hideHelperText
      ? {}
      : {
          helperText: errorMessage || generateHelperText(value),
        };

    if (errorMessage) {
      childProps = { ...childProps, ...errorProps };
    }

    this.setState({ childProps });
  };

  render() {
    const { children } = this.props;
    if (!children) return null;

    const { childProps } = this.state;
    return (
      <React.Fragment>
        {React.Children.map(children, child =>
          child ? React.cloneElement(child, childProps) : null,
        )}
      </React.Fragment>
    );
  }
}

export default TextFieldValidator;

// ========================
// Commonly used validators
// ========================

/**
 * A helper function that merges a list of validator objects into a single validator object.
 * @param {array} validatorsArray a list of validator objects
 * @param {function} generateHelperText a function which accepts the current value
 * and returns a helper message. This will override the generateHelperText functions
 * provided by the individual validators. Default returns an empty string.
 */
TextFieldValidator.mergeValidators = (
  validatorsArray,
  generateHelperText = () => ``,
) => ({
  validators: validatorsArray.reduce(
    (list, validator) => [...list, ...validator.validators],
    [],
  ),
  generateHelperText,
});

/**
 * Generate the props to create a character count validator.
 * @param {number} limit character limit
 * @param {function} generateHelperText a function which accepts the current value
 * and returns a helper message. Default returns the a string showing the number
 * of chacacters remaining.
 */
TextFieldValidator.CharacterCount = (
  limit,
  generateHelperText = value =>
    `${limit - value.length} character${
      limit - value.length === 1 ? '' : 's'
    } remaining`,
) => ({
  validators: [
    value => {
      if (value.length > limit) {
        return `Character count exceeds limit (${value.length}/${limit})`;
      }
      return false;
    },
  ],
  generateHelperText,
});

/**
 * Generate the props to create a non-empty validator.
 * @param {string} errorMessage the message to display if the validator fails.
 * @param {function} generateHelperText a function which accepts the current value
 * and returns a helper message. Default returns an empty string.
 */
TextFieldValidator.NotEmpty = (
  errorMessage = 'Cannot be empty',
  generateHelperText = () => ``,
) => ({
  validators: [
    value => {
      if (value.length === 0) {
        return errorMessage;
      }
      return false;
    },
  ],
  generateHelperText,
});

/**
 * Generate props to create a regex validator.
 * @param {RegExp} regex the regex object to use to test the value with.
 * @param {string} errorMessage the message to display if the validator fails.
 * @param {function} generateHelperText a function which accepts the current value
 * and returns a helper message. Default returns an empty string.
 */
TextFieldValidator.Regex = (
  regex,
  errorMessage = 'Invalid value',
  generateHelperText = () => ``,
) => ({
  validators: [
    value => {
      if (!regex.test(value)) {
        return errorMessage;
      }
      return false;
    },
  ],
  generateHelperText,
});
