const styles = theme => ({
  courseActionContainer: {
    boxSixing: 'border-box',
    width: '130px',
    textAlign: 'center',
    padding: '16px',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: '130px',
      /* eslint-disable */
      position: '-webkit-sticky',
      position: 'sticky',
      /* eslint-enable */
      top: 64,
      alignSelf: 'flex-start',
    },
  },
  courseActionCard: {
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    height: '100%',
    width: '80px',
    overflow: 'hidden',
  },
  courseActionList: {
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 0,
  },
  smallTxt: {
    fontSize: '12px',
  },
  actionIconSmall: {
    margin: 0,
    height: '20px',
    width: '20px',
  },
  stackedListItem: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

export default styles;
