import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ReactGA from 'react-ga';

// Services
import CoursePlanService from '../../services/CoursePlanService';

// Actions
import { showAddFeedback, hideAddFeedback } from '../../actions/UIActions';
import { fetchCoursePlanEvents } from '../../actions/CoursePlanActions';
import { displayNotification } from '../../actions/NotificationActions';

// Constants
import { STATUSES } from '../../constants';
import { gaActions, gaCategory } from '../../constants/reactGA';

// Components
import CoursePlanEventLog from '../../components/Course/CoursePlanEventLog';

class CoursePlanFeedbackContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      submittingFeedback: false,
      isExpanded: false, // we dont want the user to miss any feedback so defaults to true
    };
  }

  /**
   * When the user enters feedback text we update the state
   * to track the string
   */
  handleInputChange = e => {
    const value = e.target.value;
    this.setState({ value });
  };

  // expands the feedback section
  handleExpand = () => {
    this.setState({ isExpanded: true });
  };

  // collapses the feedback section
  handleCollapse = () => {
    this.setState({ isExpanded: false });
  };

  handleCancelAddingFeedback = () => {
    this.setState({ value: '' });
    this.props.hideAddFeedback();
  };

  /**
   * When the user submits the feedback we hide the form UI,
   * and submit the feedback - when the snapshot finishes we display
   * a notification showing as much
   */
  handleSubmit = () => {
    const {
      hideAddFeedback,
      snapshotId,
      displayNotification,
      fetchCoursePlanEvents,
      coursePlanId,
    } = this.props;
    const { value } = this.state;

    this.setState({ submittingFeedback: true });
    CoursePlanService.submitSnapshotFeedback(snapshotId, value)
      .then(resp => {
        this.setState({ value: '', submittingFeedback: false });
        hideAddFeedback();
        displayNotification('Feedback submission successful!');
        ReactGA.ga(tracker => {
          ReactGA.event({
            category: gaCategory.FLOW,
            action: gaActions.FLOW.sendFeedbackToPlan,
            label: `${snapshotId}`,
          });
        });
        // inefficient for now, but saves complexity from having to
        // merge the received snapshot in with the others
        fetchCoursePlanEvents(coursePlanId);
      })
      .catch(err => {
        console.error(err);
        this.setState({
          submittingFeedback: false,
          value: '',
        });
        hideAddFeedback();
        displayNotification('Error submitting feedback');
      });
  };

  render() {
    const { value, isExpanded, submittingFeedback } = this.state;

    const {
      user,
      isAuth,
      error,
      status,
      approval,
      coursePlanEvents,
      editMode,
      isLoading,
      showAddFeedback,
    } = this.props;

    const allowedToAddFeedback =
      isAuth && (user.isAusAdvisor || user.isMalaysiaStaff) && status === STATUSES.LOCKED;

    const isStudent = isAuth && user.isStudent;

    return (
      <CoursePlanEventLog
        error={error}
        value={value}
        editMode={editMode}
        isLoading={isLoading || submittingFeedback}
        isExpanded={isExpanded}
        eventItems={coursePlanEvents}
        approval={approval}
        onSubmit={this.handleSubmit}
        onExpand={this.handleExpand}
        onCollapse={this.handleCollapse}
        onOpenFeedback={showAddFeedback}
        onCloseFeedback={this.handleCancelAddingFeedback}
        onChange={this.handleInputChange}
        canAddFeedback={allowedToAddFeedback}
        isStudent={isStudent}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    coursePlanEvents: state.CoursePlan.coursePlanEvents,
    isLoading: state.CoursePlan.coursePlanEventsLoading,
    error: state.CoursePlan.coursePlanEventsError,
    snapshotId: state.CoursePlan.snapshotId,
    coursePlanId: state.CoursePlan.coursePlanId,
    approval: state.CoursePlan.approval,
    editMode: state.UI.showingAddFeedback,
    user: state.User.user,
    isAuth: state.User.isAuth,
    status: state.CoursePlan.status,
  };
};

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    showAddFeedback,
    hideAddFeedback,
    fetchCoursePlanEvents,
    displayNotification,
  };

  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoursePlanFeedbackContainer);

CoursePlanFeedbackContainer.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  snapshotId: PropTypes.number,
  coursePlanId: PropTypes.number,
  editMode: PropTypes.bool,
  showAddFeedback: PropTypes.func,
  hideAddFeedback: PropTypes.func,
  fetchCoursePlanEvents: PropTypes.func,
  displayNotification: PropTypes.func,
  enquiries: PropTypes.arrayOf(
    PropTypes.shape({
      publicCaseId: PropTypes.string,
      submissionState: PropTypes.string,
      snapshotId: PropTypes.number,
      timestamp: PropTypes.number,
    }),
  ),
  approval: PropTypes.shape({
    approvalId: PropTypes.number,
    snapshotId: PropTypes.number,
    approvalUser: PropTypes.string,
    approvalDate: PropTypes.number,
  }),
  coursePlanEvents: PropTypes.array,
};
