const styles = theme => ({
  root: {},
  body: {
    background: '#eee',
    minHeight: '100vh',
    padding: `${4 * theme.spacing.unit}px 0`,
  },
  innerContent: {
    maxWidth: 1000,
    width: '100%',
    margin: '0 auto',
    padding: '16px 0',
  },
  button: {
    marginLeft: 5,
    marginRight: 5,
  },
  content: {
    padding: '20px 40px',
    minHeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  headings: {
    color: 'rgba(0, 0, 0, 0.75)',
  },
  icon: {
    marginRight: 10,
  },
  iconAndTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    color: 'rgba(155, 54, 54, 1)',
  },
  greyText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  textContainer: {
    marginBottom: 15,
    color: 'rgba(0, 0, 0, 0.6)',
    '&:visited': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  link: {
    color: 'rgba(0, 0, 0, 0.6)',
    '&:visited': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  descriptionText: {
    margin: 0,
  },
});

export default styles;
