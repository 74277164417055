const styles = theme => ({
  courseMapContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
    transition: 'height .5s',
    overflow: 'hidden',
    position: 'relative',
  },
  courseMapContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  courseMapActionsContainer: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid rgb(224, 224, 224)',
    transition: 'height .5s',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  heightExpander: {
    transition: 'height .5s',
  },
  versionHistoryContainer: {
    width: '100%',
    borderTop: '1px solid rgb(224, 224, 224)',
    transition: 'height 1s',
    maxHeight: '400px',
    overflow: 'scroll',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  versionHistoryRow: {
    display: 'flex',
    borderTop: '1px solid rgb(224, 224, 224)',
    padding: '10px 20px',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  versionHistoryData: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  createdContainer: {
    display: 'flex',
    paddingBottom: '10px',
    [theme.breakpoints.up('md')]: {
      paddingRight: '20px',
      paddingBottom: '0',
    },
  },
  createdLabel: {
    margin: 0,
    paddingRight: '5px',
    fontWeight: 'bold',
  },
  version: {
    fontWeight: 'bold',
    paddingBottom: '10px',
    [theme.breakpoints.up('md')]: {
      paddingRight: '20px',
      paddingBottom: '0',
    },
  },
  courseMapContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  courseMapTitle: {
    color: 'rgba(0, 0, 0, 0.74)',
    fontWeight: 'bold',
    margin: '20px 0 5px 0',
    fontSize: '24px',
  },
  courseMapDetail: {
    margin: 0,
    marginTop: '10px',
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  courseMapStats: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  courseMapStat: {
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 0px',
  },
  marginReset: {
    margin: 0,
  },
  courseMapUnitsContainer: {
    width: '100%',
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      padding: '20px',
    },
  },
  courseMapUnits: {
    width: '100%',
    padding: '10px',
    fontSize: '12px',
    border: '1px solid rgb(224, 224, 224)',
  },
  courseMapUnitsRow: {
    display: 'flex',
    minHeight: '40px',
    borderBottom: '1px solid rgb(224, 224, 224)',
    borderRight: '1px solid rgb(224, 224, 224)',
  },
  courseMapUnitsFirstRow: {
    borderTop: '1px solid rgb(224, 224, 224)',
  },
  courseMapUnitContainer: {
    padding: '3px',
    width: '100%',
    borderLeft: '1px solid rgb(224, 224, 224)',
  },
  courseMapUnit: {
    display: 'flex',
    height: '100%',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.21)',
    alignItems: 'center',
    borderRadius: '5px',
  },
  unitStripe: {
    minWidth: '5px',
    width: '15%',
    height: '100%',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  courseMapUnitCode: {
    padding: '3px',
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.74)',
    fontWeight: 'bold',
  },
  dataLabel: {
    fontWeight: 'bold',
  },
  courseMapEditLoadContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  courseMapStatus: {
    transform: 'translate(-48px, -48px) rotate(-0.125turn)',
    color: theme.palette.common.white,
    height: 96,
    width: 96,
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  courseMapStatusIcon: {
    transform: 'rotate(0.125turn)',
    height: '40%',
    width: '40%',
    color: 'inherit',
  },
  courseStatus: {
    fontStyle: 'italic',
  },
  courseMapTitleContainer: {
    marginBottom: '15px',
  },
});

export default styles;
