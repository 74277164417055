import User from './User';

export default class UserFactory {
  /**
   *
   * @param {*} data
   */
  static createUser(data) {
    return new User({
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      roles: data.roles,
      emailAddress: data.emailAddress,
    });
  }
}
