const styles = theme => ({
  root: {
    color: 'rgba(0, 0, 0, 0.87)',
    width: '600px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    margin: '8px 0',
  },
  inputContainer: {
    margin: '20px 0 10px 0',
    width: '100%',
  },
  actionButton: {
    color: 'white',
    background: '#006cab',
    '&:hover': {
      background: '#00599a',
    },
  },
  title: {
    '& h2': {
      color: 'white',
    },
    background: '#006cab',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '40px',
  },
  actions: {},
});

export default styles;
