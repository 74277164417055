import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// MUI Components
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Utils
import YearCalc from '../../../utils/YearCalc';

// Actions
import { insertTeachingPeriod } from '../../../actions/CourseActions';

// Components
import SearchSelect from '../../Base/SearchSelect';
import EnrolmentYear from '../../../utils/EnrolmentYear';

class InsertTeachingPeriodForm extends React.Component {
  state = {
    yearSelected: null,
    teachingPeriodTypeSelected: null,
  };

  handleAddTeachingPeriod = () => {
    const { insertTeachingPeriod, onSubmitCb } = this.props;
    const { yearSelected, teachingPeriodTypeSelected } = this.state;

    insertTeachingPeriod(yearSelected.value, teachingPeriodTypeSelected.value);

    this.setState({
      yearSelected: undefined,
      teachingPeriodTypeSelected: undefined,
    });

    if (onSubmitCb) {
      onSubmitCb();
    }
  };

  handleYearSelect = choice => {
    this.setState(
      {
        yearSelected: choice,
        teachingPeriodTypeSelected: null,
      },
      () => choice.length !== 0 && this.teachingPeriodTypeRef.focus(),
    );
  };

  handleTpSelect = choice => {
    this.setState(
      {
        teachingPeriodTypeSelected: choice,
      },
      () => choice.length !== 0 && this.addTeachingButtonRef.focus(),
    );
  };

  calculateYearOptions = () => {
    const { teachingPeriods, teachingPeriodData } = this.props;

    // if no teaching periods then just generate a list of years
    if (teachingPeriods.length === 0) {
      const currentYear = EnrolmentYear();
      return YearCalc.getStartYearVals(currentYear.toString(10));
    }

    const { minYear, maxYear } = YearCalc.calculateMinMaxYear(teachingPeriods, teachingPeriodData);

    return new Array(maxYear - minYear + 1)
      .fill(undefined)
      .map((_, yearOffset) => ({
        value: minYear + yearOffset,
        label: minYear + yearOffset,
      }));
  };

  calculateTpOptions = () => {
    const { yearSelected } = this.state;
    const { teachingPeriods, teachingPeriodData } = this.props;

    let lastPreloadedCode = null;
    let lastPreloadedTpEndDate = null;
    let lastPreloadedYear = null;

    for (let i = 0; i < teachingPeriods.length; i++) {
      const tp = teachingPeriods[i];
      if (!tp.isPreloaded) {
        break;
      }

      lastPreloadedCode = tp.code;
      lastPreloadedYear = tp.year;
    }

    const lastPreloadedTp = teachingPeriodData.find(
      tp => tp.code === lastPreloadedCode,
    );
    if (lastPreloadedTp && lastPreloadedTp.endDate) {
      lastPreloadedTpEndDate = moment(lastPreloadedTp.endDate, 'YYYY-MM-DD');
    }

    let tpOptions = teachingPeriodData.filter(
      type =>
        yearSelected &&
        !teachingPeriods.find(
          tp => tp.code === type.code && tp.year === yearSelected.value,
        ),
    );

    if (
      lastPreloadedTpEndDate &&
      lastPreloadedYear &&
      yearSelected &&
      lastPreloadedYear === yearSelected.value
    ) {
      tpOptions = tpOptions.filter(tp => {
        const tpStartDate = moment(tp.startDate, 'YYYY-MM-DD');
        return tpStartDate.isAfter(lastPreloadedTpEndDate);
      });
    }

    return tpOptions.map(type => ({
      value: type.code,
      label: `${type.name} (${type.code})`,
    }));
  };

  shouldBeDisabled = () => {
    const { yearSelected, teachingPeriodTypeSelected } = this.state;
    return (
      !yearSelected ||
      yearSelected.length === 0 ||
      !teachingPeriodTypeSelected ||
      teachingPeriodTypeSelected.length === 0
    );
  };

  render() {
    const {
      classes,
      screenWidth,
      teachingPeriodsDataError,
      teachingPeriodsDataLoading,
    } = this.props;

    const { yearSelected, teachingPeriodTypeSelected } = this.state;

    if (teachingPeriodsDataLoading) {
      return <p>Loading teaching periods...</p>;
    }
    if (teachingPeriodsDataError) {
      return (
        <p style={{ color: 'rgba(155, 54, 54, 1)' }}>
          Error loading teaching periods, please try again later.
        </p>
      );
    }

    return (
      <React.Fragment>
        <FormControl
          className={classes.formControl}
          style={{
            width: screenWidth < 900 ? '100%' : undefined,
            margin: screenWidth < 900 ? '1em 0' : undefined,
            minWidth: 150,
          }}>
          <SearchSelect
            autoFocus
            onChange={this.handleYearSelect}
            classes={classes}
            options={this.calculateYearOptions()}
            placeholder="Year"
            value={yearSelected}
          />
        </FormControl>
        <FormControl
          className={classes.formControl}
          style={{
            width: screenWidth < 900 ? '100%' : null,
            margin: screenWidth < 900 ? '1em 0' : null,
            minWidth: 300,
          }}>
          <SearchSelect
            getSearchSelectRef={teachingPeriodTypeRef =>
              (this.teachingPeriodTypeRef = teachingPeriodTypeRef)
            }
            classes={classes}
            onChange={this.handleTpSelect}
            className={classes.select}
            placeholder="Teaching period type"
            value={teachingPeriodTypeSelected}
            isDisabled={!yearSelected}
            options={this.calculateTpOptions()}
          />
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          classes={{
            focusVisible: classes.focusVisible,
          }}
          style={{
            margin: screenWidth < 900 ? '0.5em 0' : undefined,
          }}
          buttonRef={addTeachingButtonRef =>
            (this.addTeachingButtonRef = addTeachingButtonRef)
          }
          onClick={this.handleAddTeachingPeriod}
          disabled={this.shouldBeDisabled()}>
          Add Teaching Period
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    teachingPeriodData: state.TeachingPeriods.data,
    teachingPeriodsDataLoading: state.TeachingPeriods.isLoading,
    teachingPeriodsDataError: state.TeachingPeriods.error,
    teachingPeriods: state.PlanInstance.teachingPeriods,
  };
};

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    insertTeachingPeriod,
  };

  return bindActionCreators(actionBundle, dispatch);
};

const InsertTpWithStyles = withStyles(styles)(InsertTeachingPeriodForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsertTpWithStyles);

InsertTeachingPeriodForm.propTypes = {
  onSubmitCb: PropTypes.func,
  insertTeachingPeriod: PropTypes.func,
  screenWidth: PropTypes.number,
  teachingPeriods: PropTypes.array,
  teachingPeriodData: PropTypes.array,
  teachingPeriodsDataLoading: PropTypes.bool,
  teachingPeriodsDataError: PropTypes.bool,
};
