const styles = theme => ({
  root: {},
  body: {
    background: '#eee',
    minHeight: '100vh',
    padding: `${2 * theme.spacing.unit}px 0`,
  },
  innerContent: {
    maxWidth: '80vw',
    width: '100%',
    margin: '0 auto',
    padding: '8px 0',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
  content: {
    padding: '20px 40px',
    minHeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  headings: {
    color: 'rgba(0, 0, 0, 0.75)',
  },
  icon: {
    marginRight: 10,
  },
  iconAndTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 4,
    marginBottom: 4,
  },
  rowReverse: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row-reverse',
    marginTop: 4,
    marginBottom: 4,
  },
  rowItem: {
    flex: 1,
    margin: 5,
  },
  link: {
    color: 'rgba(0, 0, 0, 0.6)',
    '&:visited': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
});

export default styles;
