import { MOCK_GET_NOTIFICATIONS } from './data/notifications';

import NetworkService from './NetworkService';
import CoreUtils from '../utils/CoreUtils';

import { MOCK_RESPONSE_TIME } from './constants';

const CLASSNAME = 'NotificationService';
/**
 * Notification Services
 */

export default class AnnouncementsService {
  static getAllNonExpiredNotifications = () => {
    const requestURI = `${window.location.origin}/api/notifications`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        `${CLASSNAME}.getAllNonExpiredNotifications()`,
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_GET_NOTIFICATIONS,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  };
}
