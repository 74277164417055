// Actions
import * as actions from './index';

// Services
import HandbookService from '../services/HandbookService';

export const fetchUnitGroup = unitCodes => dispatch => {
  dispatch({
    type: actions.FETCH_UNIT_GROUP_PENDING,
  });

  HandbookService.getUnitsInfo(unitCodes)
    .then(unitInfoResponses => {
      dispatch({
        type: actions.FETCH_UNIT_GROUP_FULFILLED,
        payload: unitInfoResponses,
      });
    })
    .catch(err => {
      dispatch({
        type: actions.FETCH_UNIT_GROUP_REJECTED,
        payload: err,
      });
    });
};

/**
 * FETCH_UNIT_INFO
 */
export const fetchUnitInfo = unitCode => {
  return function(dispatch) {
    dispatch({
      type: actions.FETCH_UNIT_INFO_PENDING,
    });
    HandbookService.getUnitInfo(unitCode)
      .then(resp => {
        dispatch({
          type: actions.FETCH_UNIT_INFO_FULFILLED,
          payload: resp,
          unitCode,
        });
      })
      .catch(err => {
        dispatch({
          type: actions.FETCH_UNIT_INFO_REJECTED,
          payload: err,
        });
      });
  };
};
