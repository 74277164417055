const faculties = [
  {
    text: 'Art, Design and Architecture',
    value: 'Faculty of Art, Design and Architecture',
  },
  { text: 'Arts', value: 'Faculty of Arts' },
  {
    text: 'Business and Economics',
    value: 'Faculty of Business and Economics',
  },
  { text: 'Education', value: 'Faculty of Education' },
  { text: 'Engineering', value: 'Faculty of Engineering' },
  {
    text: 'Information Technology',
    value: 'Faculty of Information Technology',
  },
  { text: 'Law', value: 'Faculty of Law' },
  { text: 'Science', value: 'Faculty of Science' },
  {
    text: 'Medicine, Nursing and Health Sciences',
    value: 'Faculty of Medicine, Nursing and Health Sciences',
  },
  {
    text: 'Pharmacy and Pharmaceutical Sciences',
    value: 'Faculty of Pharmacy and Pharmaceutical Sciences',
  },
];

export default faculties;
