import CoreUtils from '../../utils/CoreUtils';

export default class Unit {
  constructor({ unitCode, unitName, creditPoints, faculty, grade }) {
    this.unitCode = unitCode;
    this.unitName = unitName;
    this.creditPoints = creditPoints;
    this.faculty = faculty;
    this.grade = grade;

    // change to true for debug
    const DEBUG_FLAG = false;
    CoreUtils.classLogger(DEBUG_FLAG, this);
  }

  /**
   * The unit unitCode for the unit,
   * usually in the form of 3 letters and 4 numbers
   * such as FIT1010
   */
  get unitCode() {
    return this._unitCode;
  }

  /**
   * The unit unitName
   */
  get unitName() {
    return this._unitName;
  }

  /**
   * The number of credit points associated with
   * taking the unit, usually between 0 and 48
   */
  get creditPoints() {
    return this._creditPoints;
  }

  /**
   * The faculty the unit belongs to, can be null or a string
   * We also include a safety check to return null if there is an
   * empty string
   */
  get faculty() {
    if (
      !this._faculty ||
      (typeof this._faculty === 'string' && this._faculty.trim() === '')
    ) {
      return null;
    }
    return this._faculty;
  }

  /**
   * If the unit is preloaded from enrolment data,
   * there may be a grade string associated with it
   */
  get grade() {
    return this._grade || null;
  }

  set unitCode(unitCode) {
    this._validateUnitCode(unitCode);
    this._unitCode = unitCode;
  }

  set unitName(unitName) {
    this._validateUnitName(unitName);
    this._unitName = unitName;
  }

  set creditPoints(creditPoints) {
    this._validateCreditPoints(creditPoints);
    this._creditPoints = creditPoints;
  }

  set faculty(faculty) {
    this._validateFaculty(faculty);
    this._faculty = faculty;
  }

  set grade(grade) {
    this._grade = grade;
  }

  _validateUnitCode(unitCode) {
    if (!unitCode) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a unitCode value, received: ${unitCode}`,
      );
    }
  }

  _validateUnitName(unitName) {
    if (!unitName) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a unitName value, received: ${unitName}`,
      );
    }
  }

  _validateCreditPoints(creditPoints) {
    if (isNaN(creditPoints) || creditPoints < 0) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a correct creditPoints value, expected number greater or equal to 0, received: ${creditPoints}`,
      );
    }
  }

  _validateFaculty(faculty) {
    if (faculty != null && typeof faculty !== 'string') {
      throw new Error(
        `${
          this.constructor.unitName
        } was not given a correct faculty value, expected null or string, received: ${faculty}`,
      );
    }
  }
}
