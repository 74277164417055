import { COLORS } from '../../../constants';

const styles = theme => ({
  titleContainer: {
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: COLORS.PRIMARY.main,
    color: 'white',
  },
  expanded: {},
  sectionText: {
    fontSize: '14px',

    margin: 0,
  },
  sectionSubtitle: {
    fontSize: '14px',

    margin: '12px 0px 12px 0px',
  },
  sectionSubtitleFirst: {
    margin: '0px 0px 12px 0px',
  },
  section: {
    fontSize: '14px',
    margin: '0px !important',

    minHeight: '40px !important',
    display: 'flex',
    alignItems: 'center',
  },
  detail: {
    padding: '10px 24px',
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid rgb(209, 211, 212)',
  },
  text: {
    margin: 0,
  },
  sectionHeader: {
    margin: 0,
    fontSize: '12px',
  },
  expansionContainer: {
    // margin: 0
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '14px',
    marginBottom: '10px',
  },
  rowUnitContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  rowUnitTitle: {
    margin: 0,
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.81)',
  },
  completeIcon: {
    color: COLORS.MISC.success,
    marginRight: '5px',
  },
  incompleteIcon: {
    color: COLORS.MISC.orange,
    marginRight: '5px',
  },

  errorMsg: {
    padding: '0px 24px 10px 24px',
  },
  actionButton: {
    marginTop: '12px',
    background: COLORS.PRIMARY.main,

    color: 'white',
    '&:hover': {
      background: '#003c5f',
    },
  },
  buttonLink: {
    background: 'none!important',
    color: 'black',
    border: 'none',
    padding: '0 !important',
    font: 'inherit',
    borderBottom: '1px solid #444',
    transitionDuration: '0.2s',
    cursor: 'pointer',
    fontSize: '14px',
    '&:focus': {
      color: '#006cab',
      borderBottom: '1px solid #006cab',
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      color: '#006cab',
      borderBottom: '1px solid #006cab',
      padding: '0 !important',
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      color: '#006cab',
      borderBottom: '1px solid #006cab',
      padding: '0 !important',
      border: 'none',
      outline: 'none',
    },
  },
  buttonLinkContainer: {
    marginTop: '10px',
  },
  courseProgressHeader: {
    fontSize: '16px',
    fontWeight: '400',
  },
});

export default styles;
