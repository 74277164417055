import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Constants
import { STATUSES } from '../../constants';

// Actions
import { showActionDrawer } from '../../actions/UIActions';

// Components
import CourseStatusBanner from '../../components/Course/CourseStatusBanner';

class CourseStatusBannerContainer extends React.Component {
  render() {
    return <CourseStatusBanner {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    status: state.CoursePlan.status,
    user: state.User.user,
  };
};

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    showActionDrawer,
  };

  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseStatusBannerContainer);

CourseStatusBannerContainer.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUSES)),
};
