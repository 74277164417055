import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

// Material-UI Components
import Typography from '@material-ui/core/Typography';

// Styles
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

class MonPlanLink extends React.Component {
  render() {
    const { classes, targetURL } = this.props;
    return (
      <div>
        <Link to={targetURL} className={classes.linkStyles}>
          <Typography variant="title" className={classes.monplanLogo}>
            MonPlan
          </Typography>
        </Link>
      </div>
    );
  }
}

MonPlanLink.defaultProps = {
  targetURL: '/',
};

MonPlanLink.propTypes = {
  targetURL: PropTypes.string,
};

export default withStyles(styles)(MonPlanLink);
