import constants from './constants';

export default class FileDropUtils {
  static convertMbtoBytes(mb) {
    const inputType = typeof mb;
    if (inputType !== 'number') {
      throw constants.DEFAULTS.errors.INTEGER_TYPE;
    }
    return mb * 1024 * 1024;
  }

  static getStyles(
    styles,
    isDragActive,
    isDragReject,
    hasErrored,
    hasSuccessfullyUpload,
  ) {
    let dragZoneStyles = { ...styles.base };
    dragZoneStyles = isDragActive
      ? { ...dragZoneStyles, ...styles.active }
      : dragZoneStyles;
    dragZoneStyles =
      isDragReject || hasErrored
        ? { ...dragZoneStyles, ...styles.reject }
        : dragZoneStyles;
    dragZoneStyles = hasSuccessfullyUpload
      ? { ...dragZoneStyles, ...styles.ok }
      : dragZoneStyles;
    return dragZoneStyles;
  }
}
