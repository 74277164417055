import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

// Constants
import { CORE } from '../../../constants';

const DisclaimerModal = ({ open, onClose, onDecline, onAccept }) => (
  <Dialog open={open} onClose={onDecline}>
    <DialogTitle>Disclaimer</DialogTitle>
    <DialogContent>
      <DialogContentText>
        MonPlan is a planning tool only. It has not enrolled you in the units
        you selected. To enrol in units, go to the{' '}
        <a
          href={CORE.EXTERNAL_LINKS.MONASH.WES}
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          style={{ color: 'rgba(0,0,0,0.87)' }}>
          Web Enrolment System (WES)
        </a>
        .
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button aria-label="Cancel" onClick={onDecline}>
        Cancel
      </Button>
      <Button aria-label="I understand, continue" onClick={onAccept}>
        I understand, continue
      </Button>
    </DialogActions>
  </Dialog>
);

DisclaimerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onDecline: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default DisclaimerModal;
