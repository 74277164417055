import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

// MUI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

// Styling
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
  closeButton: {
    backgroundColor: theme.custom.palette.misc.warning,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.custom.palette.misc.warningLighter,
    },
  },
});

class ClearCourseModal extends Component {
  handleClearCourse = () => {
    this.props.clearCourse();
    this.props.onClose();
  };

  render = () => {
    const { open, onClose, classes } = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Clear Course Plan?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will erase your current course plan, are you sure you want to
            clear it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button aria-label="Cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            component={Link}
            to="/"
            classes={{ root: classes.closeButton }}
            aria-label="Clear my course plan"
            onClick={this.handleClearCourse}>
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

ClearCourseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clearCourse: PropTypes.func.isRequired,
};

export default withStyles(styles)(ClearCourseModal);
