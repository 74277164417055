const locations = [
  { text: 'Clayton', value: 'Clayton' },
  { text: 'Caulfield', value: 'Caulfield' },
  { text: 'Peninsula', value: 'Peninsula' },
  { text: 'City', value: 'City' },
  { text: 'South Africa', value: 'South Africa' },
  { text: 'Malaysia', value: 'Malaysia' },
  { text: 'Prato', value: 'Prato' },
  { text: 'Other', value: 'Other' },
];

export default locations;
