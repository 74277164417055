import React from 'react';
import PropTypes from 'prop-types';

import BasicActionModal from '../BasicActionModal';

class ReviewCourseModal extends React.Component {
  render() {
    const { open, onClose, onReview } = this.props;
    return (
      <BasicActionModal
        open={open}
        onClose={onClose}
        onAction={onReview}
        title={'Review Course Plan'}
        body={
          'This plan has not been marked for review by the student. If you choose to review the plan, the student will no longer be able to edit their plan until you give access back to them. If you wish to only view this plan and not review it, then click cancel.'
        }
        action={'Review course plan'}
      />
    );
  }
}

export default ReviewCourseModal;

ReviewCourseModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onReview: PropTypes.func,
};
