import * as actions from '../../actions';

const defaultState = {
  faculty: [], // an array of faculty names that we want to limit the results to
  location: [], // an array of location names that we want to limit the results to
  creditPointRange: { min: 0, max: 48 }, // the credit point range we want to limit the returned units to
  teachingPeriod: [], // an array of teaching period names that we want to limit the results to
  unitType: ['Undergraduate'] // an array of unit types that we want to limit the results to
};
/**
 * The Filters reducer is used for filtering the units returned by a unit search,
 * based on a variety of query limits the user may have. This includes things like
 * limiting by faculty, credit points and locations. This reducer simply updates the filters
 * as the user enters them so that other components may read from this structure and return
 * results accordingly.
 */
const FiltersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_FACULTY_FILTER:
      return {
        ...state,
        faculty: action.facultyArray,
      };
    case actions.UPDATE_LOCATION_FILTER:
      return {
        ...state,
        location: action.locationArray,
      };
    case actions.UPDATE_CREDITPOINTRANGE_FILTER:
      return {
        ...state,
        creditPointRange: {
          min: action.min,
          max: action.max,
        },
      };
    case actions.UPDATE_TEACHINGPERIOD_FILTER:
      return {
        ...state,
        teachingPeriod: action.teachingPeriodArray,
      };
    case actions.UPDATE_UNITTYPE_FILTER:
      return {
        ...state,
        unitType: action.unitTypeArray,
      };
    default:
      return state;
  }
};

export default FiltersReducer;
