import * as actions from '../../actions';
import DataTransformUtils from '../../utils/DataTransformUtils';

const defaultState = {
  currentStudentId: null,

  courseCode: null,
  coursePlanName: '',
  coursePlanId: null,
  coursePlanLoading: false,
  coursePlanError: false,

  snapshotId: null,
  snapshot: null,
  status: null,

  approval: null,

  enquiries: [],

  coursePlanEvents: [],
  coursePlanEventsError: null,
  coursePlanEventsLoading: false,

  studentName: null,
  approvalAdviserName: null,
};

/**
 * Contains the state to do with a students course plan - this is the more
 * 'meta' data around a course plan like the course plan event log,
 * the course plan details, the latest snapshot details and if there have been
 * any enquiries or approvals
 */
const CoursePlanReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSE_PLAN_PENDING:
      return { ...state, coursePlanLoading: true, coursePlanError: false };
    case actions.FETCH_COURSE_PLAN_FULFILLED:
      return { ...state, coursePlanLoading: false };
    case actions.FETCH_COURSE_PLAN_REJECTED:
      return { ...state, coursePlanLoading: false, coursePlanError: true };
    case actions.UPDATE_COURSE_PLAN:
      return {
        ...state,
        coursePlanName: action.coursePlanName,
        coursePlanId: action.coursePlanId,
        snapshotId: action.snapshotId,
        approval: action.approval,
        snapshot: action.snapshot,
        status: action.snapshot.status,
        enquiries: action.enquiries,
        courseCode: action.courseCode,
        studentName: action.studentName,
        approvalAdviserName: action.approvalAdviserName,
      };

    case actions.FETCH_COURSE_PLAN_EVENTS_PENDING:
      return {
        ...state,
        coursePlanEvents: [],
        coursePlanEventsError: null,
        coursePlanEventsLoading: true,
      };

    case actions.FETCH_COURSE_PLAN_EVENTS_FULFILLED:
      return {
        ...state,
        coursePlanEvents: DataTransformUtils.coursePlanFeedbackRespTransform(
          action.payload,
        ),
        coursePlanEventsLoading: false,
      };

    case actions.FETCH_COURSE_PLAN_EVENTS_REJECTED:
      return {
        ...state,
        coursePlanEventsError: action.payload,
        coursePlanEventsLoading: false,
      };

    case actions.UPDATE_STUDENT_ID:
      return {
        ...state,
        currentStudentId: action.studentId,
      };
    case actions.UPDATE_STUDENT_NAME:
      return {
        ...state,
        currentStudentName: action.studentName,
      };
    case actions.UPDATE_SNAPSHOT:
      return {
        ...state,
        snapshotId: action.snapshot.snapshotId,
        snapshot: action.snapshot,
        status: action.snapshot.status,
      };
    case actions.SET_COURSE_PLAN_NAME:
      return {
        ...state,
        coursePlanName: action.coursePlanName,
      };
    default:
      return state;
  }
};

export default CoursePlanReducer;
