import * as actions from '../../actions';

export const defaultState = {
  areasOfStudy: [],
  areasOfStudyError: null,
  areasOfStudyLoading: false,
};

/**
 * Stores the available areas of study for the current selected
 * course
 */
const AreaOfStudyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSE_AOS_PENDING:
      return {
        ...state,
        areasOfStudyError: null,
        areasOfStudyLoading: true,
      };
    case actions.FETCH_COURSE_AOS_FULFILLED:
      return {
        ...state,
        areasOfStudy: action.payload,
        areasOfStudyLoading: false,
      };
    case actions.FETCH_COURSE_AOS_REJECTED:
      return {
        ...state,
        areasOfStudyError: action.payload,
        areasOfStudyLoading: false,
      };
    default:
      return state;
  }
};

export default AreaOfStudyReducer;
