import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class BasicActionModal extends React.Component {
  render() {
    const {
      open,
      onClose,
      onAction,
      classes,
      title,
      body,
      action,
      disableActionButton,
      disableCloseButton,
      hasAction,
      header,
      footer,
      progress,
    } = this.props;
    const bodyIsString = typeof body === 'string';
    return (
      <Dialog open={open} onClose={onClose}>
        {header}
        {progress}
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {bodyIsString ? <Typography>{body}</Typography> : body}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={disableCloseButton}>
            {hasAction ? 'Cancel' : 'Close'}
          </Button>
          {hasAction && (
            <Button
              className={classes.actionButton}
              onClick={onAction}
              disabled={disableActionButton}>
              {action}
            </Button>
          )}
        </DialogActions>
        <div>{footer}</div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(BasicActionModal);

BasicActionModal.defaultProps = {
  hasAction: true,
};

BasicActionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  title: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  progress: PropTypes.node,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action: PropTypes.string,
  hasAction: PropTypes.bool,
  disableCloseButton: PropTypes.bool,
  disableActionButton: PropTypes.bool,
};
