// Actions
import * as actions from './index';

// Services
import UserService from '../services/UserService';

// hacky way to check for an empty object
const isEmptyObj = obj => Object.keys(obj).length === 0;

/**
 * Queries API for user profile info and updates the user state if successful
 * if the user is not logged in or the api is unavailable this will fail
 */
export const getUser = () => {
  return function(dispatch) {
    dispatch({
      type: actions.FETCH_USER_PENDING,
    });

    UserService.getUser()
      .then(user => {
        // if API request is successful but the user is not logged in
        // it returns a 200 status but just gives back an empty object
        if (!user || isEmptyObj(user)) {
          throw new Error('User is not logged in');
        } else {
          dispatch({
            type: actions.FETCH_USER_FULFILLED,
            user,
          });
        }
      })
      .catch(err => {
        dispatch({
          type: actions.FETCH_USER_REJECTED,
          err: err,
        });
      });
  };
};

/**
 * Resets the user state in redux
 */
export const logout = () => {
  return {
    type: actions.LOGOUT,
  };
};
