import { connect } from 'react-redux';

import ClearCourseModal from './ClearCourseModal';
import { clearCourse } from '../../../actions/CourseActions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  clearCourse: () => {
    dispatch(clearCourse());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClearCourseModal);
