const styles = theme => ({
  drawer: { minWidth: 300 },
  account: {
    backgroundColor: theme.palette.primary.light,
    minHeight: 100,
    padding: theme.spacing.unit,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});

export default styles;
