import CoursePlanEvent from '../models/CoursePlanEvent';
import CoursePlanEventFactory from '../models/CoursePlanEvent/CoursePlanEventFactory';
import UnitFactory from '../models/Unit/UnitFactory';

/**
 * Utilities for transforming data into forms as needed by other services
 */
export default class DataTransformUtils {
  /**
   * Sorts teaching period by start date, then sorts teaching periods with the same start date by end date in the
   * reverse order.
   *
   * @param teachingPeriodData
   * @returns {array} sorted teachingPeriodData
   */
  static sortTeachingPeriods(teachingPeriodData) {
    /**
     * Compares two teaching periods by their start dates.
     *
     * @param {object} a - The first teaching period.
     * @param {object} b - The second teaching period.
     */
    function compareDate(a, b) {
      return Math.sign(new Date(a) - new Date(b));
    }

    return teachingPeriodData
      .slice() // duplicate input as the sort operation is mutable
      .sort(
        (a, b) =>
          /* Teaching periods with earlier start dates appear earlier on the list.
           * Teaching periods with the same start dates are sorted by their end date in the reverse order, so
           * that longer teaching periods appear earlier on the list. Remember that 0 is the only falsy value out
           * of all numbers, and the JavaScript or operator will use the second operand if the first operand is falsy. */
          compareDate(a.startDate, b.startDate) ||
          -compareDate(a.endDate, b.endDate),
      );
  }

  /**
   * Takes the data from the application and converts it into the form that the
   * MSCV validation service expects
   */
  static mscvCourseFormat = (
    startYear,
    advancedStanding,
    teachingPeriods,
    courseCode,
  ) => {
    const processedCourse = {
      startYear: startYear,
      advancedStanding: advancedStanding.map(
        DataTransformUtils.mscvAdvancedStanding,
      ),
      internationalStudent: false,
      courseInfo: {},
    };

    // add course code
    if (courseCode) {
      processedCourse.courseInfo.courseCode = courseCode;
    }

    // process teaching periods to match schema
    const processedTeachingPeriods = teachingPeriods.map(
      DataTransformUtils.mscvTeachingPeriod,
    );

    processedCourse.teachingPeriods = processedTeachingPeriods;

    return processedCourse;
  };

  static mscvAdvancedStanding = advStd => {
    let mscvAdvStd = {
      grantingStatus: advStd.grantingStatus,
      creditType: advStd.creditType,
      courseCode: advStd.courseCode,
      courseVersionNumber: advStd.courseVersionNumber,
      unitLevel: advStd.unitLevel,
      category: advStd.category,
      recognitionType: advStd.recognitionType,
    };

    if (advStd.unitCode) {
      mscvAdvStd.unitCode = advStd.unitCode;
    }
    if (advStd.unitVersionNumber) {
      mscvAdvStd.unitVersionNumber = advStd.unitVersionNumber;
    }
    if (advStd.creditPoints) {
      mscvAdvStd.creditPoints = advStd.creditPoints;
    }

    return mscvAdvStd;
  };

  /**
   * Takes a teaching period object from the application and converts it into the form that the
   * MSCV validation service expects
   */
  static mscvTeachingPeriod = tp => ({
    year: tp.year,
    code: tp.code,
    units: tp.units
      .map(DataTransformUtils.mscvUnit)
      .filter(unit => !(unit === null)),
    intermission: !!tp.isIntermission,
    studyAbroad: !!tp.isStudyAbroad,
  });

  /**
   * Takes a unit object from the application and converts it into the form that the
   * MSCV validation service expects
   */
  static mscvUnit = unit => {
    if (unit === null) {
      return unit;
    }

    let mscvUnit = {
      unitCode: unit.unitCode,
      placeholder: !!unit.placeholder,
    };

    if (unit.grade) {
      mscvUnit.grade = unit.grade;
      mscvUnit.status = 'COMPLETED';
    }

    return mscvUnit;
  };

  static coursePlanFeedbackRespTransform = resp => {
    let feedback = [];

    const versionNum = resp.length;

    resp.forEach((respObj, i) => {
      const snapshotTimestamp = respObj.snapshot.lastModifiedDate;

      const versionParams = {
        version: versionNum - i,
        versionDelta: i,
        isCurrentVersion: i === 0,
        snapshotTimestamp,
      };

      const snapshotEnquiryItems = respObj.enquiries.map(enquiryItem =>
        CoursePlanEventFactory.createEvent({
          eventType: CoursePlanEvent.TYPE().ENQUIRY,
          ...versionParams,
          ...enquiryItem,
        }),
      );

      const snapshotFeedbackItems = respObj.feedbackList.map(feedbackItem =>
        CoursePlanEventFactory.createEvent({
          eventType: CoursePlanEvent.TYPE().FEEDBACK,
          ...versionParams,
          ...feedbackItem,
        }),
      );

      const snapshotEventItems = respObj.events.map(eventItem =>
        CoursePlanEventFactory.createEvent({
          eventType: CoursePlanEvent.TYPE().STATUS_UPDATE,
          ...versionParams,
          ...eventItem,
        }),
      );

      const newfeedbackItems = [
        ...snapshotEnquiryItems,
        ...snapshotFeedbackItems,
        ...snapshotEventItems,
      ].sort((prev, next) => next.timestamp - prev.timestamp);

      feedback = [...feedback, ...newfeedbackItems];
    });

    return feedback;
  };

  static getMonPlanBlob(
    teachingPeriods,
    advancedStanding,
    weightedAverageMark,
  ) {
    return JSON.stringify({
      teachingPeriods: teachingPeriods.map(tp => ({
        ...tp,
        units: tp.units.map(unit => {
          if (unit) {
            return UnitFactory.serialiseUnitObject(unit);
          }

          return null;
        }),
      })),
      advancedStanding: advancedStanding,
      weightedAverageMark,
      version: process.env.REACT_APP_VERSION,
    });
  }
}
