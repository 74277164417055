import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { insertTeachingPeriod } from '../../actions/CourseActions';
import { displayNotification } from '../../actions/NotificationActions';

// Components
import InsertTeachingPeriodButton from '../../components/Buttons/InsertTeachingPeriodButton';
import TeachingPeriodUtils from '../../utils/TeachingPeriodUtils';
import { getNextTeachingPeriod } from '../../selectors/selectors';

/**
 *
 */
const InsertTeachingPeriodButtonContainer = props => {
  const { nextTeachingPeriod, teachingPeriodData } = props;

  return (
    <InsertTeachingPeriodButton
      insertTeachingPeriodRef={props.insertTeachingPeriodRef}
      semesterString={TeachingPeriodUtils.getTeachingPeriodString({
        ...nextTeachingPeriod,
        teachingPeriodData,
      })}
      addSemester={() =>
        props.insertTeachingPeriod(
          nextTeachingPeriod.year,
          nextTeachingPeriod.code,
        )
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    nextTeachingPeriod: getNextTeachingPeriod(state),
    showingInsertTeachingPeriodUI: state.UI.showingInsertTeachingPeriodUI,
    teachingPeriods: state.PlanInstance.teachingPeriods,
    teachingPeriodData: state.TeachingPeriods.data,
  };
};

/**
 *
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = { insertTeachingPeriod, displayNotification };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsertTeachingPeriodButtonContainer);

InsertTeachingPeriodButtonContainer.propTypes = {
  addTeachingPeriod: PropTypes.func,
  nextSemesterString: PropTypes.string,
  showingInsertTeachingPeriodUI: PropTypes.bool,
  teachingPeriods: PropTypes.array,
  teachingPeriodData: PropTypes.array,
};
