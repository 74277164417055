// Actions
import * as actions from './index';

/**
 * Updates which faculties we want to see
 * unit search results from.
 */
export const updateFacultyFilter = facultyArray => ({
  type: actions.UPDATE_FACULTY_FILTER,
  facultyArray,
});

/**
 * Updates which locations we want to see
 * unit search results from.
 */
export const updateLocationFilter = locationArray => ({
  type: actions.UPDATE_LOCATION_FILTER,
  locationArray,
});

/**
 * Updates what range of credit points we want to filter unit
 * results for
 */
export const updateCreditPointRangeFilter = (min, max) => ({
  type: actions.UPDATE_CREDITPOINTRANGE_FILTER,
  min,
  max,
});

/**
 * Updates which teaching periods we want to see
 * unit search results from.
 */
export const updateTeachingPeriodFilter = teachingPeriodArray => ({
  type: actions.UPDATE_TEACHINGPERIOD_FILTER,
  teachingPeriodArray,
});

/**
 * Updates which type of units we want to see
 * units from.
 */
export const updateUnitTypeFilter = unitTypeArray => ({
  type: actions.UPDATE_UNITTYPE_FILTER,
  unitTypeArray,
});
