const styles = theme => {
  return {
    MuiDialog: {
      maxWidth: '850px !important', // this pains me, need to figure out why the base classes override solution doesnt work
      width: '100%',
    },
    rootTest: {
      fontSize: '14px',
      border: '1px solid red',
      padding: '0',
    },
    unitDetailModalTitle: {
      padding: '14px 16px 12px 16px',
      color: 'white',
    },
    unitDetailModalContent: {
      height: '100%',
      display: 'flex',
      color: 'rgba(0, 0, 0, 0.6)',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    innerContent: {
      padding: '14px 16px 12px 16px',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    rightContent: {
      flexBasis: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        flexBasis: '66.6666666%',
        maxWidth: '66.66666666%',
      },
    },
    leftContent: {
      flexBasis: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        flexBasis: '33.33333333%',
        maxWidth: '33.33333333%',
      },
    },
    unitName: {
      fontSize: '16px',
      fontWeight: '500',
      margin: '0',
    },
    subtitle: {
      fontWeight: '300',
      opacity: '0.8',
      fontSize: '14px',
      marginBottom: '0',
    },
    actions: {
      minHeight: '40px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: '1px solid rgb(224, 224, 224)',
    },
    unitLinkContainer: {
      display: 'flex',
      alignContent: 'center',
    },
  };
};

export default styles;
