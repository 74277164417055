const styles = theme => ({
  courseProgressContainer: {
    boxSixing: 'border-box',
    width: '100%',
    padding: '16px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
      minWidth: '300px',
      flex: '1',
      /* eslint-disable */
      position: '-webkit-sticky',
      position: 'sticky',
      /* eslint-enable */
      top: 64,
      alignSelf: 'flex-start',
    },
  },
  mobileFlex: {
    background: '#eee',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  coursePlanContainer: {
    height: '100%',
    minWidth: '1000px',
    maxWidth: '1000px',
    '@media (max-width: 1000px)': {
      minWidth: '0',
      width: '100%',
    },
  },
});

export default styles;
