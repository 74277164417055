const styles = theme => ({
  readOnly: {
    cursor: 'auto',
  },
  placeholderContainer: {
    fontSize: '13px',
    minHeight: '100px',
    color: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    padding: '10px',
  },
  marginReset: {
    margin: 0,
  },
  unitCardContainer: {
    fontSize: '13px',
    minHeight: '100px',
    color: 'rgba(0, 0, 0, 0.74)',
    background: 'white',
    display: 'flex',
    width: '100%',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    borderRadius: '5px',
  },
  unitCardPreloaded: {
    background: '#e3e8ef',
  },
  unitCardContainerError: {
    color: 'white',
    background: 'rgba(155, 54, 54, 1)',
  },
  unitCardContainerPlaceholder: {
    color: 'rgba(0,0,0,0.7)',
    backgroundColor: 'rgb(243, 243, 243)',
    border: '2px dotted rgba(0, 0, 0, 0.11)',
  },

  facultyStripe: {
    width: '24px',
    borderTopLeftRadius: '5px',
    perspective: '1px',
    borderBottomLeftRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  facultyStripeText: {
    transform: 'rotate(-90deg)',
    color: 'white',
    perspective: '1px',
  },

  unitCardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 6px',
    flexGrow: 1,
    justifyContent: 'space-between',
  },

  searchResultActionContainer: {
    width: '26px',
    padding: '6px 18px',
    display: 'flex',
    justifyContent: 'center',
  },

  unitCardActionContainer: {
    width: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },

  unitErrorButton: {
    zIndex: 1000,
    width: 20,
    height: 20,
    color: 'white',
    position: 'absolute',
    bottom: '5px',
    right: '3px',
  },

  creditPointsContainer: {
    width: 22,
    height: 22,
    position: 'absolute',
    top: '5px',
    right: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unitCardGrade: {
    position: 'absolute',
    top: '12px',
  },
});

export default styles;
