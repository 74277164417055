import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FocusTrap from 'focus-trap-react';

import { Toolbar, IconButton, TextField, Typography } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

import { COLORS } from '../../constants';

import { hideSidebar } from '../../actions/UIActions';

import { searchUnit } from '../../actions/SearchActions';

import { addUnit, beginAddingUnit } from '../../actions/CourseActions';

import UnitSearchResultsContainer from '../UnitSearchResultsContainer';
import UnitSearchFilterContainer from '../UnitSearchFilterContainer';

/**
 * This component searches through the available units for selection
 * @author JXNS
 */
class UnitSearchContainer extends Component {
  /**
   * The constructor initialises the state and binds the methods used
   * @author JXNS
   */
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      searchResultIndex: 0,
      timeoutValue: null,
      empty: true,
      searchFilter: [],
      currentValue: '',
      activeTrap: false,
    };

    this.searchVisible = false;

    this.resetComponent = this.resetComponent.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);

    this.savedFocusedElement = undefined;
  }

  /**
   * If sidebar becomes visible, then focus and select the search bar.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.searchVisible && !this.props.searchVisible) {
      if (this.savedFocusedElement !== undefined) {
        if (document.body.contains(this.savedFocusedElement)) {
          // restore original focus
          this.savedFocusedElement.focus();
        }
      }
    }

    if (
      prevProps.unitSearchIsLoading &&
      !this.props.unitSearchIsLoading &&
      !this.props.unitSearchError &&
      this.searchInput
    ) {
      this.searchInput.focus();
    }
  }

  /**
   * If sidebar is being shown, save original focus before showing the
   * sidebar.
   */
  componentWillReceiveProps(nextProps) {
    if (!this.props.searchVisible && nextProps.searchVisible) {
      this.savedFocusedElement = document.activeElement;
    }
  }

  /**
   * Mount trap on focus entering component
   */
  mountTrap() {
    this.setState({ activeTrap: true });
  }

  /**
   * Mount trap on component close
   */
  unmountTrap() {
    this.setState({ activeTrap: false });
  }

  /**
   * Reset needs to be called after a unit is selected, when it is selected we change the entered value back to empty string (clears the searchbox).
   * @author JXNS
   */
  resetComponent() {
    if (this.state.timeoutValue) {
      clearTimeout(this.state.timeoutValue);
    }

    this.searchInput.value = '';

    this.setState({
      results: [],
      timeoutValue: null,
      searchResultIndex: 0,
    });
  }

  /**
   * Moves search selection up by one. If the first result was selected,
   * then the last result will be selected.
   */
  moveUpSearchResult() {
    this.setState({
      searchResultIndex:
        (this.state.searchResultIndex - 1 + this.props.units.length) %
        this.props.units.length,
    });
  }

  /**
   * Moves search selection down by one. If the last result was selected,
   * then the first result will be selected.
   */
  moveDownSearchResult() {
    this.setState({
      searchResultIndex:
        (this.state.searchResultIndex + 1) % this.props.units.length,
    });
  }

  /**
   * Set index to focus on a valid value.
   */
  setSearchResultIndex(searchResultIndex) {
    this.setState({
      searchResultIndex,
    });
  }

  /**
   * Handles adding the unit.
   */
  addingUnit(unitToAdd) {
    if (typeof this.props.positionOfUnitToAdd !== 'undefined') {
      // we know where to add the unit, so do so
      this.props.addUnit(
        this.props.positionOfUnitToAdd[0],
        this.props.positionOfUnitToAdd[1],
        unitToAdd,
      );
    } else {
      // we don't know which position to add the unit, so prompt user
      this.props.beginAddingUnit(unitToAdd);
    }
  }

  /**
   * Selects the currently selected search result
   */
  selectSearchResult() {
    // Ignore if there are no search results
    if (this.props.units.length === 0) {
      return;
    }

    const searchResult = this.props.units[this.state.searchResultIndex];
    this.addingUnit(searchResult);
  }

  /**
   * If one of the following keys are pressed, then the following actions are
   * performed: Enter selects the search result, up moves search selection up
   * by one, and down moves search selection down by one.
   */
  onKeyDown(e) {
    switch (e.keyCode) {
      case 13: // Enter
        this.selectSearchResult();
        e.preventDefault();
        break;
      case 27: // Escape
        this.props.hideSidebar();
        e.preventDefault();
        break;
      case 38: // Up
        this.moveUpSearchResult();
        e.preventDefault();
        break;
      case 40: // Down
        this.moveDownSearchResult();
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  /**
   * Handle search change updates the currently entered text in the prompt and searches through the results based on the currently entered text.
   * @author JXNS
   */
  handleSearchChange(e) {
    const value = e.target.value;
    if (value === '') {
      return;
    }

    this.setState({ currentValue: value });

    if (this.state.timeoutValue) {
      clearTimeout(this.state.timeoutValue);
    }

    const timeoutValue = setTimeout(() => {
      this.performUnitSearch(value);
    }, 500);

    this.setState({
      searchResultIndex: 0,
      timeoutValue,
    });
  }

  /**
   * Performs unit search
   * @param value - The unit search query that the user requested
   */
  performUnitSearch = value => {
    const { filters } = this.props;
    const { faculty, location, teachingPeriod, unitType, creditPointRange } = filters;

    let points = [];
    points.push(creditPointRange['min']);
    points.push(creditPointRange['max']);

    this.props.searchUnit(value, 1, faculty, location, teachingPeriod, unitType, points);

    this.setState({
      value,
      empty: !value,
    });
  };

  /**
   * Updates search results when a filter is changed.
   */
  handleFilterChange() {
    let e = {
      target: {
        value: this.state.currentValue,
      },
    };

    this.handleSearchChange(e);
  }

  /**
   * The renderer simply returns a search component populated with the data necessary
   * FocusTrap 'traps' focus within the component
   * @author JXNS, David Copley
   */
  render() {
    const { unitSearchIsLoading, focusActive } = this.props;

    let trapClass = 'trap';
    if (this.state.activeTrap) {
      trapClass += ' is-active';
    }

    return (
      <FocusTrap
        id="focus-trap-three"
        tag="section"
        data-whatever="nothing"
        aria-hidden="false"
        active={focusActive}
        className={trapClass}
        onKeyDown={this.onKeyDown}
        focusTrapOptions={{
          onDeactivate: this.unmountTrap,
          clickOutsideDeactivates: true,
        }}
        style={{ width: 300 }}>
        <Toolbar style={{ background: COLORS.MISC.monashBlue }}>
          <Typography style={{ color: 'white', flex: 1 }}>Add Unit</Typography>
          <IconButton
            aria-label="Close add unit sidebar"
            onClick={this.props.hideSidebar}>
            <ClearIcon style={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
        <div>
          <div style={{ padding: '0 1.5em', paddingBottom: '1em' }}>
            <TextField
              style={{
                marginBottom: '1.2em',
                marginTop: '0.8em',
                width: '100%',
              }}
              inputRef={input => {
                this.searchInput = input;
              }}
              autoFocus
              label={'Search for units'}
              variant="filled"
              aria-label={
                'Enter search text to find units, unit results will appear below'
              }
              aria-live="polite"
              onChange={this.handleSearchChange}
              helperText={unitSearchIsLoading ? 'Loading units...' : ''}
            />
            <UnitSearchFilterContainer onFilterChange={this.handleFilterChange} />
          </div>
          <hr className="divider-margin-delete" />
          {this.props.units.length > 0 && (
            <Typography
              tabIndex="0"
              align="center"
              aria-label="Choose which unit to add from the unit results below"
              style={{
                padding: '0 1em',
              }}>
              Drag and drop units to add to your course plan!
            </Typography>
          )}

          <UnitSearchResultsContainer
            tabIndex="0"
            searchResultIndex={this.state.searchResultIndex}
            empty={this.state.empty}
            results={this.props.units}
            setSearchResultIndex={this.setSearchResultIndex.bind(this)}
          />
        </div>
      </FocusTrap>
    );
  }
}

UnitSearchContainer.propTypes = {
  showCustomUnitUI: PropTypes.func,
  searchVisible: PropTypes.bool,
  hideSidebar: PropTypes.func,
  beginAddingUnit: PropTypes.func,
  addUnit: PropTypes.func,
  positionOfUnitToAdd: PropTypes.array,
  unitSearchIsLoading: PropTypes.bool,
  filters: PropTypes.object,
  focusActive: PropTypes.bool,
};

/**
 * Used for focusing input when sidebar is shown.
 */
const mapStateToProps = state => {
  return {
    searchVisible: state.UI.showingSidebar,
    unitSearchIsLoading: state.UnitSearch.isLoading,
    unitSearchError: state.UnitSearch.error,
    units: state.UnitSearch.data,
    filters: state.Filters,
    positionOfUnitToAdd: state.PlanInstance.positionOfUnitToAdd,
  };
};

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    hideSidebar,
    searchUnit,
    addUnit,
    beginAddingUnit,
  };

  return bindActionCreators(actionBundle, dispatch);
};

/**
 * Injects the required actions from redux action creators
 */

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnitSearchContainer);
