import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FocusTrap from 'focus-trap-react';

// MUI Components
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// MUI Icons
import { Clear as ClearIcon } from '@material-ui/icons';

import { COLORS } from '../../constants';

import { hideUnitSelectSidebar } from '../../actions/UIActions';

import { addUnit, beginAddingUnit } from '../../actions/CourseActions';

import UnitSearchResultsContainer from '../UnitSearchResultsContainer';

/**
 * This component searches through the available units for selection
 * @author JXNS
 */
class UnitSelectContainer extends Component {
  /**
   * The constructor initialises the state and binds the methods used
   * @author JXNS
   */
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      searchResults: [],
      searchResultIndex: 0,
      timeoutValue: null,
      empty: true,
      searchFilter: [],
      currentValue: '',
      activeTrap: false,
    };

    this.searchVisible = false;

    this.onKeyDown = this.onKeyDown.bind(this);
    this.mountTrap = this.mountTrap.bind(this);
    this.unmountTrap = this.unmountTrap.bind(this);

    this.savedFocusedElement = undefined;
    this.savedFocusedParentElement = undefined;
  }

  /**
   * If sidebar becomes visible, then focus and select the search bar.
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.searchVisible && this.props.searchVisible) {
      this.searchInput.select();
      this.searchInput.focus();
    }

    if (prevProps.searchVisible && !this.props.searchVisible) {
      if (this.savedFocusedElement !== undefined) {
        if (document.body.contains(this.savedFocusedElement)) {
          // restore original focus
          this.savedFocusedElement.focus();
        }
      }
    }
  }

  /**
   * If sidebar is being shown, save original focus before showing the
   * sidebar.
   */
  componentWillReceiveProps(nextProps) {
    if (!this.props.searchVisible && nextProps.searchVisible) {
      this.savedFocusedElement = document.activeElement;
      this.savedFocusedParentElement = this.savedFocusedElement.parentElement;
      window.ele = this.savedFocusedElement;
    }
  }

  /**
   * Mount trap on focus entering component
   */
  mountTrap() {
    this.setState({ activeTrap: true });
  }

  /**
   * Mount trap on component close
   */
  unmountTrap() {
    this.setState({ activeTrap: false });
  }

  /**
   * Moves search selection up by one. If the first result was selected,
   * then the last result will be selected.
   */
  moveUpSearchResult() {
    this.setState({
      searchResultIndex:
        (this.state.searchResultIndex - 1 + this.props.unitGroup.length) %
        this.props.unitGroup.length,
    });
  }

  /**
   * Moves search selection down by one. If the last result was selected,
   * then the first result will be selected.
   */
  moveDownSearchResult() {
    this.setState({
      searchResultIndex:
        (this.state.searchResultIndex + 1) % this.props.unitGroup.length,
    });
  }

  /**
   * Set index to focus on a valid value.
   */
  setSearchResultIndex(searchResultIndex) {
    this.setState({
      searchResultIndex,
    });
  }

  /**
   * Handles adding the unit.
   */
  addingUnit(unitToAdd) {
    if (typeof this.props.positionOfUnitToAdd !== 'undefined') {
      // we know where to add the unit, so do so
      this.props.addUnit(
        this.props.positionOfUnitToAdd[0],
        this.props.positionOfUnitToAdd[1],
        unitToAdd,
      );
    } else {
      // we don't know which position to add the unit, so prompt user
      this.props.beginAddingUnit(unitToAdd);
    }
  }

  /**
   * Selects the currently selected search result
   */
  selectSearchResult() {
    // Ignore if there are no search results
    if (this.props.unitGroup.length === 0) {
      return;
    }

    const searchResult = this.props.unitGroup[this.state.searchResultIndex];
    this.addingUnit(searchResult);
  }

  /**
   * If one of the following keys are pressed, then the following actions are
   * performed: Enter selects the search result, up moves search selection up
   * by one, and down moves search selection down by one.
   */
  onKeyDown(e) {
    switch (e.keyCode) {
      case 13: // Enter
        this.selectSearchResult();
        e.preventDefault();
        break;
      case 27: // Escape
        this.props.hideUnitSelectSidebar();
        e.preventDefault();
        break;
      case 38: // Up
        this.moveUpSearchResult();
        e.preventDefault();
        break;
      case 40: // Down
        this.moveDownSearchResult();
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  /**
   * The renderer simply returns a search component populated with the data necessary
   * FocusTrap 'traps' focus within the component
   * @author JXNS, David Copley
   */
  render() {
    const {
      focusActive,
      unitGroup,
      unitGroupLoading,
      unitGroupError,
      aosName,
      showingRequiredUnits,
    } = this.props;

    const units = unitGroup && unitGroup.length > 0 ? unitGroup : [];

    let trapClass = 'trap';
    if (this.state.activeTrap) {
      trapClass += ' is-active';
    }

    let content = null;
    let contentStr = '';
    if (unitGroupLoading) {
      contentStr = showingRequiredUnits
        ? `Loading required units for ${aosName}...`
        : `Loading elective units for ${aosName}...`;

      content = (
        <p tabIndex="0" style={{ textAlign: 'center', padding: '5px' }}>
          {contentStr}
        </p>
      );
    } else if (unitGroupError || (unitGroup && unitGroup.length === 0)) {
      content = (
        <p tabIndex="0" style={{ textAlign: 'center', padding: '5px' }}>
          {`Could not find units for ${aosName}. The information may not exist, or the server is encountering difficulties. Please try again later.`}
        </p>
      );
    } else {
      content = (
        <React.Fragment>
          {units.length > 0 && (
            <Typography
              tabIndex="0"
              align="center"
              style={{
                padding: '0 1em',
              }}>
              Drag and drop units to add to your course plan!
            </Typography>
          )}
          <UnitSearchResultsContainer
            tabIndex="0"
            searchResultIndex={this.state.searchResultIndex}
            empty={units.length === 0}
            results={units}
            setSearchResultIndex={this.setSearchResultIndex.bind(this)}
          />
        </React.Fragment>
      );
    }

    return (
      <FocusTrap
        id="focus-trap-three"
        tag="section"
        active={focusActive}
        className={trapClass}
        focusTrapOptions={{
          onDeactivate: this.unmountTrap,
          clickOutsideDeactivates: true,
        }}
        onKeyDown={this.onKeyDown.bind(this)}
        style={{ width: 300 }}>
        <Toolbar style={{ background: COLORS.MISC.monashBlue }}>
          <Typography style={{ color: 'white', flex: 1 }}>Add Units</Typography>
          <IconButton
            aria-label="Close add unit sidebar"
            onClick={this.props.hideUnitSelectSidebar}>
            <ClearIcon style={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
        <div>
          <div
            tabIndex="0"
            style={{ padding: '0 1.5em', paddingBottom: '1em' }}>
            <p
              ref={input => {
                this.searchInput = input;
              }}>{`${
              showingRequiredUnits ? 'Required' : 'Elective'
            } units for ${aosName}`}</p>
          </div>
          <hr className="divider-margin-delete" />
          {content}
        </div>
      </FocusTrap>
    );
  }
}

UnitSelectContainer.propTypes = {
  searchVisible: PropTypes.bool,
  hideSidebar: PropTypes.func,
  beginAddingUnit: PropTypes.func,
  addUnit: PropTypes.func,
  positionOfUnitToAdd: PropTypes.array,
  unitSearchIsLoading: PropTypes.bool,
  filters: PropTypes.object,
  focusActive: PropTypes.bool,
};

/**
 * Used for focusing input when sidebar is shown.
 */
const mapStateToProps = state => {
  return {
    searchVisible: state.UI.showingSidebar,
    filters: state.Filters,
    positionOfUnitToAdd: state.UI.positionOfUnitToAdd,
    unitGroup: state.Handbook.unitGroup,
    unitGroupLoading: state.Handbook.unitGroupLoading,
    unitGroupError: state.Handbook.unitGroupError,
    aosName: state.UI.currentAOSName,
    showingRequiredUnits: state.UI.showingRequiredUnits,
  };
};

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    addUnit,
    beginAddingUnit,
    hideUnitSelectSidebar,
  };

  return bindActionCreators(actionBundle, dispatch);
};

/**
 * Injects the required actions from redux action creators
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnitSelectContainer);
