import { Faculty } from './faculty';

const COLORS = {
  PRIMARY: {
    light: '#006dae',
    main: '#006cab',
    dark: '#00599a',
    contrastText: '#fff',
  },
  SECONDARY: {
    light: '#898989',
    main: '#565656',
    dark: '#454545',
    contrastText: '#fff',
  },
  ERROR: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  FACULTY: {
    [Faculty.ADA]: '#455a64',
    [Faculty.ART]: '#b71c1c',
    [Faculty.BUS]: '#00bcd4',
    [Faculty.EDU]: '#b81b60',
    [Faculty.ENG]: '#ff9800',
    [Faculty.FIT]: '#7b1fa2',
    [Faculty.LAW]: '#776c55',
    [Faculty.MHS]: '#2196f3',
    [Faculty.PHA]: '#7c8500',
    [Faculty.SCI]: '#009688',
    default: '#006dae',
  },
  MISC: {
    footer: '#4d4d4d',
    warning: '#C3185A',
    warningLighter: '#DD3274',
    beta: '#ffb300',
    success: '#3A8340',
    softBackground: '#dddddd',
    monashBlue: '#006cab',
    reviewStatus: '#757575',
    orange: '#FF9800',
  },
};

export default COLORS;
