import React, { Component } from 'react';
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';

// Components
import UnitDisplay from '../UnitDisplay';

// Styling
import { facultyFontColorMap } from '../../../styles';

/**
 * The unit component.
 */
class Unit extends Component {
  /**
   * Handles adding the unit.
   */
  addingUnit = () => {
    if (typeof this.props.positionOfUnitToAdd !== 'undefined') {
      // we know where to add the unit, so add it
      this.props.addUnit(
        this.props.positionOfUnitToAdd[0],
        this.props.positionOfUnitToAdd[1],
        this.props.unitToAdd,
      );
    } else {
      // we don't know which position to add the unit, so prompt user
      this.props.beginAddingUnit(this.props.unitToAdd);
    }
  };

  handleUnitClick = e => {
    e.stopPropagation();

    if (this.props.isSearchResult) {
      this.addingUnit();
    } else if (this.props.unitIsMoving) {
      this.props.onUnitMoveRequest();
    }
  };

  handleKeyPress = e => {
    const { isSearchResult, unitIsMoving, onUnitMoveRequest } = this.props;

    e.stopPropagation();
    isSearchResult ? this.addingUnit() : unitIsMoving && onUnitMoveRequest();
  };

  render() {
    const {
      code,
      creditPoints,
      errors,
      faculty,
      handleDelete,
      handleUnitFocus,
      handleUnitMouseEnter,
      handleUnitMouseLeave,
      handleUnitMouseMove,
      index,
      grade,
      isSearchResult,
      isPreloaded,
      name,
      numberOfUnits,
      onInfoButtonClick,
      onUnitInfoRequest,
      width,
      viewOnly,
      showingGrades,
      unitIsMoving,
      onUnitMoveRequest,
      ariaResultMessage,
      year
    } = this.props;

    const unitHasErrors = errors && errors.length > 0;

    // Place spaces between characters to force screen reader to read one character at a time
    const accessibleUnitCode = code ? code.split('').join(' ') : 'unit';

    const unitStyle = {
      cursor: 'pointer',
      width: width ? width : undefined,
      height: '100%',
      color: unitHasErrors
        ? 'rgba(213, 0, 0, 0.54)'
        : facultyFontColorMap[faculty] || facultyFontColorMap['all'],
    };

    // previously we would use unitIsMoving && onUnitMoveRequest but this
    // is not valid for keypress handlers anymore
    const unitMovingKeypressFallback = unitIsMoving
      ? onUnitMoveRequest
      : undefined;

    return (
      <div
        aria-label={accessibleUnitCode + ' ' + name}
        onKeyPress={
          isSearchResult ? this.addingUnit : unitMovingKeypressFallback
        }
        onFocus={() => handleUnitFocus && handleUnitFocus(index)}
        style={unitStyle}
        onKeyDown={e => e.key === 'Delete' && handleDelete()}
        onClick={this.handleUnitClick}
        onMouseEnter={e => handleUnitMouseEnter && handleUnitMouseEnter(e)}
        onMouseMove={e => handleUnitMouseMove && handleUnitMouseMove(e)}
        onMouseLeave={e => handleUnitMouseLeave && handleUnitMouseLeave(e)}
        className={'draggableUnit'}>
        <div
          style={{
            height: '100%',
            width: '100%',
          }}>
          <UnitDisplay
            ariaResultMessage={ariaResultMessage}
            isPreloaded={isPreloaded}
            title={code}
            subtitle={name}
            viewOnly={viewOnly}
            grade={showingGrades ? grade : null}
            points={creditPoints}
            faculty={faculty}
            numberOfUnits={numberOfUnits}
            isSearchResult={isSearchResult}
            onUnitInformationRequest={
              isSearchResult
                  ? onInfoButtonClick.bind(null, code, year)
                  : onUnitInfoRequest.bind(null, code, year)
            }
            onMoveUnitRequest={
              (!isSearchResult &&
                this.props.movingUnit.bind(
                  null,
                  this.props.unit,
                  this.props.index,
                  this.props.teachingPeriodIndex,
                )) ||
              null
            }
            onRemoveUnitRequest={handleDelete}
            isError={unitHasErrors}
            errors={errors}
            accessibleUnitCode={accessibleUnitCode}
          />
        </div>
      </div>
    );
  }
}

Unit.propTypes = {
  /** Unit code */
  code: string,
  /** Name for unit */
  name: string,
  /** Which faculty is responsible for the unit */
  faculty: string,

  creditPoints: number,
  teachingPeriodIndex: number,
  numberOfUnits: number,
  index: number,

  hovering: bool,
  handleDelete: func,
  handleUnitFocus: func,
  handleUnitMouseEnter: func,
  handleUnitMouseLeave: func,
  handleUnitMouseMove: func,
  width: oneOfType([string, number]),

  unitToAdd: object,
  errors: array,

  beginAddingUnit: func,
  addUnit: func,
  positionOfUnitToAdd: array,
  unitIsMoving: bool,
  isSearchResult: bool,
  onUnitMoveRequest: func,
};

export default Unit;
