import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const SmallLoader = props => {
  const { classes, message, sizeOverride } = props;
  return (
    <div className={classes.loaderContainer}>
      <CircularProgress
        size={sizeOverride || 50}
        style={{ color: '#006cab' }}
      />
      <p className={classes.loaderMessage}>{message}</p>
    </div>
  );
};

export default withStyles(styles)(SmallLoader);

SmallLoader.defaultProps = {
  message: 'Loading...',
};

SmallLoader.propTypes = {
  message: PropTypes.string,
};
