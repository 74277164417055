const styles = {
  base: { borderStyle: 'dashed', borderWidth: '2px', marginBottom: 10 },
  active: {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee',
  },
  reject: {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee',
  },
  ok: {
    borderStyle: 'solid',
    borderColor: '#6c6',
  },
  core: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  message: {
    padding: 10,
  },
  IconStyle: color => {
    return { fontSize: '3em', color: color };
  },
};

export default styles;
