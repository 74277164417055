import * as actions from '../../actions';

const defaultState = {
  advancedStanding: [], // The array of advanced standing results from Callista
};

const AdvancedStandingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.LOAD_NEW_ADVANCED_STANDING:
      return {
        ...state,
        advancedStanding: action.value,
      };

    default:
      return state;
  }
};

export default AdvancedStandingReducer;
