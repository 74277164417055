import React, { Component } from 'react';
import { string, func } from 'prop-types';

// MUI Components
import Button from '@material-ui/core/Button';

// MUI Icons
import { default as AddIcon } from '@material-ui/icons/Add';
// Constants
import { COLORS } from '../../../constants';

/**
 * Allows students to insert new teaching periods, by both quickly adding new
 * semesters, as well as giving the option to add other teaching periods such as
 * summer semesters.
 */

class InsertTeachingPeriodButton extends Component {
  render() {
    let { addSemester, semesterString } = this.props;

    if (semesterString === null) {
      semesterString = 'Semester 1';
    }

    return (
      <div
        ref={this.props.insertTeachingPeriodRef}
        style={{
          display: 'flex',
          backgroundColor: COLORS.MISC.monashBlue,
          textAlign: 'center',
          borderTop: 'solid 1px #d1d3d4',
        }}>
        <Button
          style={{ flex: 1, color: 'white', padding: '28px 0' }}
          aria-label={`Add ${semesterString}`}
          onClick={addSemester}>
          <AddIcon style={{ color: 'white' }} />
          {` Add ${semesterString}`}
        </Button>
      </div>
    );
  }
}

export default InsertTeachingPeriodButton;

InsertTeachingPeriodButton.propTypes = {
  semesterString: string,
  addSemester: func.isRequired,
};
