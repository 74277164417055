import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';

// MUI Components
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// Constants
import { ERROR_CODES, ROUTES } from '../constants';
// Components
import Header from '../components/Base/Header';

// Styling
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
  root: {},
  body: {
    background: '#eee',
    minHeight: '100vh',
    padding: `${4 * theme.spacing.unit}px 0`,
  },
  innerContent: {
    maxWidth: 1000,
    width: '100%',
    margin: '0 auto',
    padding: '16px 0',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
  content: {
    padding: '20px 40px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

class ErrorPage extends Component {
  renderCourseFormError = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <h1>Error creating course plan</h1>
        <p>
          We encountered an error while creating your course plan, please try
          again later.
        </p>
        <Button
          fullWidth
          variant="raised"
          color="primary"
          component={Link}
          to={ROUTES.CREATE_NEW_PLAN}
          style={{ color: 'white' }}
          classes={{ root: classes.button }}>
          Return to New Course Form
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { classes, params } = this.props;
    if (!params.errorCode) {
      browserHistory.push(`/`);
    }

    let errorContent = null;
    switch (params.errorCode) {
      case ERROR_CODES.COURSE_PLAN_CREATE:
        errorContent = this.renderCourseFormError();
        break;
      default:
        errorContent = null;
        break;
    }

    return (
      <React.Fragment>
        <Header />

        <div className={classes.body}>
          <div className={classes.innerContent}>
            <Paper className={classes.content}>{errorContent}</Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  const actionBundle = {};

  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(ErrorPage));

ErrorPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

ErrorPage.propTypes = {};
