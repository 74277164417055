import React from 'react';
import PropTypes from 'prop-types';

import TextFieldValidator from './TextFieldValidator';
import EditingTextFieldButtons from './EditingTextFieldButtons';

// MUI components
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// Utils
import { COLORS } from '../../constants';

const styles = {
  root: {
    color: COLORS.SECONDARY.main,
    '&$disabled': {
      color: COLORS.SECONDARY.dark,
    },
  },
  disabled: {},
};

/**
 * This component renders a text field that looks like a standard text node,
 * but has buttons that can turn it into an editable text input.
 *
 * The look of the text node can be customised by passing a style property
 * when using the `createEditableTextField` helper function, which creates
 * an instance of this component with customised styles.
 */
class EditableTextField extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  static defaultProps = {
    validators: [],
  };

  onStartEditHandler = () => {
    const { onStartEdit, value } = this.props;
    onStartEdit();
    // Allow 1 tick of event loop to pass so that React applies setState of
    // parent component first before we try to focus on the element
    setTimeout(() => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
        this.inputRef.current.setSelectionRange(value.length, value.length);
      }
    }, 1);
  };

  onSubmitHandler = () => {
    const { onSubmitEdit } = this.props;
    onSubmitEdit();
  };

  onCancelHandler = () => {
    const { onCancelEdit } = this.props;
    onCancelEdit();
  };

  render() {
    const {
      readOnly,
      classes,
      validators,
      generateHelperText,
      value,
      editing,
      onChange,
      loading,
    } = this.props;

    return (
      <React.Fragment>
        <TextFieldValidator
          validators={validators}
          generateHelperText={generateHelperText}
          value={value}
          hideHelperText={!editing}>
          <TextField
            style={{ margin: 0, width: '100%' }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.root,
                disabled: classes.disabled,
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!editing}
            onChange={onChange}
            value={value}
            inputRef={this.inputRef}
          />
          {readOnly ? null : (
            <EditingTextFieldButtons
              editing={editing}
              loading={loading}
              ariaName={this.props.ariaName}
              onStartEditHandler={this.onStartEditHandler}
              onSubmitHandler={this.onSubmitHandler}
              onCancelHandler={this.onCancelHandler}
            />
          )}
        </TextFieldValidator>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EditableTextField);

/**
 * Generate an EditableTextField component with custom styling
 * @param styles the styles to apply to the root
 */
export const createEditableTextField = customStyles => {
  return withStyles({
    ...styles,
    root: {
      ...styles.root,
      ...customStyles,
    },
  })(EditableTextField);
};

EditableTextField.propTypes = {
  readOnly: PropTypes.bool,
  ariaName: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.func),
  generateHelperText: PropTypes.func,
  value: PropTypes.string,
  editing: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onStartEdit: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSubmitEdit: PropTypes.func.isRequired,
};
