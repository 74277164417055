import { createSelector } from 'reselect';
import TeachingPeriodUtils from '../utils/TeachingPeriodUtils';

export const getCreditPoints = createSelector(
  [
    state => state.PlanInstance.teachingPeriods,
    state => state.AdvancedStanding.advancedStanding,
  ],
  (teachingPeriods, advancedStanding) =>
    TeachingPeriodUtils.getCreditPoints(teachingPeriods, advancedStanding),
);

export const getNextTeachingPeriod = createSelector(
  [state => state.PlanInstance.teachingPeriods, state => state.TeachingPeriods.data],
  (teachingPeriods, teachingPeriodData) =>
    TeachingPeriodUtils.nextTeachingPeriod(teachingPeriods, teachingPeriodData),
);

export const getStartYear = createSelector(
  state => state.PlanInstance.teachingPeriods,
  TeachingPeriodUtils.getStartYear,
);
