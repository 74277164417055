import * as actions from '../../actions';

import TeachingPeriodUtils from '../../utils/TeachingPeriodUtils';
import EnrolmentYear from '../../utils/EnrolmentYear';

const defaultState = {
  startYear: EnrolmentYear(), // Which year the student has begun their course

  weightedAverageMark: undefined,

  teachingPeriods: [], // The array of sequential teaching periods for a course structure

  affectedUnits: [], // Holds names and titles of units that would be affected by deletion of a teaching period or

  unitToAdd: undefined, // Holds a unit object when we are adding it to the course structure

  hidingPlaceholders: [], // Holds a list of placeholders where a unit is on top of it.

  indexOfTPtoRemove: 0,

  unitToBeMoved: undefined,
  tpIndexOfUnitToBeMoved: 0,
  unitsIndexOfUnitToBeMoved: 0,
  unitIsMoving: false,

  positionOfUnitToAdd: undefined, // Holds the saved position to add the unit (if available)
};

/**
 * Contains the core state that drives the course planning UI for the app.
 * Handles things like the moving, adding and swapping of units. Manipulation
 * of teaching periods, storage of course and unit info results etc.
 *
 * To aide readability some of the areas of state have been split into sub-reducers
 */
const PlanInstanceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.CLEAR_COURSE:
      return {
        ...state,
        teachingPeriods: [],
      };

    /*
      Inserts a teaching period into the course structure.
      Depends on teaching period data.
    */
    case actions.INSERT_TEACHING_PERIOD:
      let index;

      for (index = 0; index < state.teachingPeriods.length; index++) {
        const teachingPeriod = state.teachingPeriods[index];
        if (teachingPeriod.year === action.year) {
          if (teachingPeriod.code === action.code) {
            // Teaching period already exists, no need to insert it
            return state;
          }

          if (action.teachingPeriodData == null) {
            /**
             * We do not have teaching period data, so just
             * insert the teaching period here, even if the
             * order of the teaching periods is incorrect.
             * This should not happen, since the user would
             * not have a choice to select which teaching
             * period in the first place.
             */
            break;
          }

          const teachingPeriodIndex = action.teachingPeriodData.findIndex(
            tp => tp.code === teachingPeriod.code,
          );

          if (teachingPeriodIndex === -1) {
            continue;
          }

          const actionTeachingPeriodIndex = action.teachingPeriodData.findIndex(
            tp =>
              tp.code === action.code &&
              tp.year === action.teachingPeriodData[teachingPeriodIndex].year,
          );

          if (actionTeachingPeriodIndex < teachingPeriodIndex) {
            /**
             * We found the right place to insert this teaching period
             */
            break;
          }
        } else if (teachingPeriod.year > action.year) {
          /**
           * We found the right place to insert this teaching period
           */
          break;
        }
      }
      return {
        ...state,
        teachingPeriods: [
          ...state.teachingPeriods.slice(0, index),
          {
            numberOfUnits: 4,
            year: action.year,
            code: action.code,
            units: new Array(4).fill(null),
          },
          ...state.teachingPeriods.slice(index),
        ],
      };

    /*
            Removes a teaching period located at the given index
        */
    case actions.REMOVE_TEACHING_PERIOD:
      return {
        ...state,
        teachingPeriods: [
          ...state.teachingPeriods.slice(0, action.index),
          ...state.teachingPeriods.slice(action.index + 1),
        ],
      };

    case actions.LOAD_NEW_TEACHING_PERIODS:
      return {
        ...state,
        teachingPeriods: action.value,
      };

    case actions.SET_INTERMISSION:
      return {
        ...state,
        teachingPeriods: [
          ...state.teachingPeriods.slice(0, action.teachingPeriodIndex),
          {
            ...state.teachingPeriods[action.teachingPeriodIndex],
            numberOfUnits: 1,
            isIntermission: true,
            isStudyAbroad: false,
            units: new Array(1).fill(null),
          },
          ...state.teachingPeriods.slice(
            action.teachingPeriodIndex + 1,
            action.index,
          ),
        ],
      };

    case actions.UNSET_INTERMISSION:
      return {
        ...state,
        teachingPeriods: [
          ...state.teachingPeriods.slice(0, action.teachingPeriodIndex),
          {
            ...state.teachingPeriods[action.teachingPeriodIndex],
            numberOfUnits: 4,
            isIntermission: false,
            isStudyAbroad: false,
            units: new Array(4).fill(null),
          },
          ...state.teachingPeriods.slice(
            action.teachingPeriodIndex + 1,
            action.index,
          ),
        ],
      };

    case actions.SET_STUDY_ABROAD:
      return {
        ...state,
        teachingPeriods: [
          ...state.teachingPeriods.slice(0, action.teachingPeriodIndex),
          {
            ...state.teachingPeriods[action.teachingPeriodIndex],
            numberOfUnits: 1,
            isStudyAbroad: true,
            isIntermission: false,
            units: new Array(1).fill(null),
          },
          ...state.teachingPeriods.slice(
            action.teachingPeriodIndex + 1,
            action.index,
          ),
        ],
      };

    case actions.UNSET_STUDY_ABROAD:
      return {
        ...state,
        teachingPeriods: [
          ...state.teachingPeriods.slice(0, action.teachingPeriodIndex),
          {
            ...state.teachingPeriods[action.teachingPeriodIndex],
            numberOfUnits: 4,
            isStudyAbroad: false,
            isIntermission: false,
            units: new Array(4).fill(null),
          },
          ...state.teachingPeriods.slice(
            action.teachingPeriodIndex + 1,
            action.index,
          ),
        ],
      };

    case actions.UPDATE_INDEX_OF_TP_TO_REMOVE:
      return {
        ...state,
        indexOfTPtoRemove: action.index,
      };

    case actions.INCREASE_STUDY_LOAD:
      return {
        ...state,
        teachingPeriods: state.teachingPeriods.map((tp, index) => {
          if (index === action.teachingPeriodIndex) {
            return {
              ...tp,
              numberOfUnits: tp.numberOfUnits + 1,
              units: [...tp.units, null],
            };
          }

          return tp;
        }),
      };

    case actions.DECREASE_STUDY_LOAD:
      return {
        ...state,
        teachingPeriods: state.teachingPeriods.map((tp, index) => {
          if (index === action.teachingPeriodIndex) {
            return {
              ...tp,
              numberOfUnits: tp.numberOfUnits - 1,
              units: tp.units.slice(0, tp.numberOfUnits - 1),
            };
          }

          return tp;
        }),
      };

    case actions.ADD_UNIT:
      return {
        ...state,
        ...TeachingPeriodUtils.addUnit(
          state.hidingPlaceholders,
          state.teachingPeriods,
          action.tpIndex,
          action.unitIndex,
          action.unit,
        ),
      };

    case actions.MOVE_UNIT:
      return {
        ...state,
        unitIsMoving: false,
        ...TeachingPeriodUtils.moveUnit(
          state.hidingPlaceholders,
          state.teachingPeriods,
          action.newTPIndex,
          state.tpIndexOfUnitToBeMoved,
          action.newUnitIndex,
          state.unitsIndexOfUnitToBeMoved,
          state.unitToBeMoved,
        ),
      };

    case actions.SWAP_UNIT:
      return {
        ...state,
        unitIsMoving: false,
        ...TeachingPeriodUtils.swapUnit(
          state.teachingPeriods,
          action.newTPIndex,
          state.tpIndexOfUnitToBeMoved,
          action.newUnitIndex,
          state.unitsIndexOfUnitToBeMoved,
          state.unitToBeMoved,
          action.unitToSwap,
        ),
      };

    case actions.REMOVE_UNIT:
      return {
        ...state,
        ...TeachingPeriodUtils.removeUnit(
          state.hidingPlaceholders,
          state.teachingPeriods,
          action.tpIndex,
          action.unitIndex,
        ),
      };

    case actions.ADDING_UNIT:
      return {
        ...state,
        unitToAdd: action.unit,
      };

    case actions.MOVING_UNIT:
      return {
        ...state,
        unitIsMoving: true,
        unitToBeMoved: action.unit,
        tpIndexOfUnitToBeMoved: action.tpIndex,
        unitsIndexOfUnitToBeMoved: action.unitIndex,
      };

    case actions.CANCEL_MOVING_UNIT:
      return {
        ...state,
        unitIsMoving: false,
        unitToBeMoved: undefined,
        tpIndexOfUnitToBeMoved: undefined,
        unitsIndexOfUnitToBeMoved: undefined,
      };

    case actions.CANCEL_ADDING_UNIT:
      return {
        ...state,
        unitToAdd: undefined,
      };

    case actions.UPDATE_AFFECTED_UNITS:
      return {
        ...state,
        affectedUnits: action.affectedUnits,
      };

    case actions.SHOW_SIDEBAR:
      if (
        typeof action.teachingPeriodIndex === 'number' &&
        typeof action.unitIndex === 'number'
      ) {
        return {
          ...state,
          positionOfUnitToAdd: [action.teachingPeriodIndex, action.unitIndex],
        };
      }

      return {
        ...state,
        positionOfUnitToAdd: undefined,
      };

    case actions.HIDE_SIDEBAR:
      return {
        ...state,
        positionOfUnitToAdd: undefined,
      };

    case actions.CHANGE_START_YEAR:
      return {
        ...state,
        startYear: action.year,
      };

    case actions.SET_WAM:
      return {
        ...state,
        weightedAverageMark: action.weightedAverageMark,
      };

    default:
      return state;
  }
};

export default PlanInstanceReducer;
