import * as actions from '../../actions';

const defaultState = {
  courseEnrolments: [],
  courseEnrolmentsError: null,
  courseEnrolmentsLoading: false,
};

/**
 * Handles the storing of student enrolment information
 */
const EnrolmentsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSE_ENROLMENTS_PENDING:
      return {
        ...state,
        courseEnrolments: [],
        courseEnrolmentsError: null,
        courseEnrolmentsLoading: true,
      };
    case actions.FETCH_COURSE_ENROLMENTS_FULFILLED:
      return {
        ...state,
        courseEnrolments: action.payload,
        courseEnrolmentsLoading: false,
      };
    case actions.FETCH_COURSE_ENROLMENTS_REJECTED:
      return {
        ...state,
        courseEnrolmentsError: action.payload,
        courseEnrolmentsLoading: false,
      };
    default:
      return state;
  }
};

export default EnrolmentsReducer;
