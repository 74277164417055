export default {
  // GENERAL
  LANDING: '/ ',
  ABOUT: '/about',
  DISCLAIMER: '/disclaimer',

  // STUDENTS
  LOGIN: '/saml/login',
  SAML_LOGOUT: '/account/logout',
  LOGOUT: '/logout',

  LIST_PLANS: '/my-plans',
  CREATE_NEW_PLAN: '/new',
  EDIT_PLAN: '/plan',
  ERROR: '/error',

  // COURSE ADVISORS
  STUDENT_SEARCH: '/students',
  //REVIEW_PLAN: '/review', // NOT IN USE: ADVISOR AND STUDENT SHARE SAME PLAN PAGE

  // VIEWING COURSE PLAN
  VIEW_PLAN: '/view',
  PRINT_PLAN: '/print',

  // ACCOUNT
  ACCOUNT: '/account', // NOT IN USE

  // ADMIN
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_HISTORY: '/admin/history',
  MOCK_LOGIN: '/debug/fakelogin',
};
