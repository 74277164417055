import React, { Component } from 'react';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Components
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// MUI Icons
import { default as NewPlanIcon } from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ViewList from '@material-ui/icons/ViewList';

// Constants
import { ROUTES } from '../constants';

// Actions
import { updateStudentId } from '../actions/CoursePlanActions';

// Components
import Header from '../components/Base/Header';
import TextFieldValidator from '../components/Text/TextFieldValidator';

// Styling
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
  root: {
    background: '#eee',
    minHeight: '100vh',
    padding: `${4 * theme.spacing.unit}px 0`,
  },
  innerContent: {
    maxWidth: 1000,
    width: '100%',
    margin: '0 auto',
    padding: '16px 0',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
  textField: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
  },
  content: {
    padding: '20px 40px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

class StudentSearch extends Component {
  state = {
    studentId: '',
    isValid: false,
  };

  handleSubmit = () => {
    if (this.state.isValid) {
      const { updateStudentId } = this.props;
      updateStudentId(this.state.studentId);
      browserHistory.push(`${ROUTES.STUDENT_SEARCH}/${this.state.studentId}`);
    }
  };

  // shortcut to automatically submit if ENTER is pressed
  handleKeyDown = event => {
    if (event.key === 'Enter') this.handleSubmit();
  };

  handleBack = () => {
    browserHistory.push(
      `${ROUTES.STUDENT_SEARCH}/${this.props.currentStudentId}`,
    );
  };

  handleInputEvent = event =>
    this.setState({
      studentId: event.target.value,
      isValid: this.isInputValid(event.target.value),
    });

  isInputValid = input => /^[1-9][0-9]{7}$/.test(input);

  render() {
    const { classes, isAuth, user } = this.props;
    const { studentId, isValid } = this.state;

    if (!isAuth || !user.isStaff) {
      // window.location = `${window.location.origin}`;
    }

    return (
      <React.Fragment>
        <Header>
          {this.props.currentStudentId && (
            <ListItem
              aria-label="Create New Course Plan"
              button
              component={Link}
              to={ROUTES.CREATE_NEW_PLAN}>
              <ListItemIcon>
                <NewPlanIcon />
              </ListItemIcon>
              <ListItemText>Create New Course Plan</ListItemText>
            </ListItem>
          )}
          {this.props.currentStudentId && (
            <ListItem
              aria-label="Student Plans"
              button
              component={Link}
              to={`${ROUTES.STUDENT_SEARCH}/${this.props.currentStudentId}`}>
              <ListItemIcon>
                <ViewList />
              </ListItemIcon>
              <ListItemText>Student Plans</ListItemText>
            </ListItem>
          )}
        </Header>

        <div className={classes.root}>
          <div className={classes.innerContent}>
            <Paper className={classes.content}>
              <Typography
                style={{ color: '#6D6D6D' }}
                variant="display3"
                align="center"
                gutterBottom>
                Student Search
              </Typography>

              <Typography variant="subheading" align="center" gutterBottom>
                {this.props.currentStudentId && (
                  <div
                    style={{
                      fontSize: '18px',
                      color: 'black',
                      marginRight: '1%',
                    }}>
                    <IconButton onClick={this.handleBack}>
                      <ArrowBack
                        style={{ color: 'black' }}
                        aria-label={`Return to plans for student #${
                          this.props.currentStudentId
                        }`}
                      />
                    </IconButton>
                    {`Return to plans for student #${
                      this.props.currentStudentId
                    }`}
                  </div>
                )}
                Enter a student ID below to display their course plans.
                <br /> If the student has not used MonPlan before, there may not
                be any course plans.
              </Typography>
              <TextFieldValidator
                value={studentId}
                {...TextFieldValidator.Regex(
                  // This allows incomplete student IDs so that advisors are not
                  // bombarded with a red field while they are typing
                  /^(|[1-9][0-9]{0,7})$/,
                  'Invalid student ID',
                  () => 'Please enter a valid student ID',
                )}>
                <TextField
                  aria-label={'Enter Student ID'}
                  required
                  autoFocus
                  label="Enter a student ID"
                  onChange={this.handleInputEvent}
                  onKeyDown={this.handleKeyDown}
                  onSubmit={this.handleSubmit}
                  error={!isValid && studentId.length >= 8}
                  value={studentId}
                  fullWidth
                  classes={{ root: classes.textField }}
                  FormHelperTextProps={{
                    className: classes.validationHelperText,
                  }}
                />
              </TextFieldValidator>
              <Button
                fullWidth
                variant="raised"
                color="primary"
                onClick={this.handleSubmit}
                disabled={!isValid}
                classes={{ root: classes.button }}>
                Search for student
              </Button>
            </Paper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentStudentId: state.CoursePlan.currentStudentId,
  user: state.User.user,
  isAuth: state.User.isAuth,
});

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    updateStudentId,
  };

  return bindActionCreators(actionBundle, dispatch);
};

const styledStudentSearch = withStyles(styles)(StudentSearch);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledStudentSearch);
