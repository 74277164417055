import React from 'react';
import { func, number, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setStudyAbroad, unsetStudyAbroad } from '../../actions/CourseActions';

// Components
import StudyAbroadButton from '../../components/Buttons/StudyAbroadButton';

const StudyAbroadButtonContainer = ({
  teachingPeriodIndex,
  isStudyAbroad,
  isMalaysiaMember,
  setStudyAbroad,
  unsetStudyAbroad,
  units,
  removeUnit,
  closeMenu,
}) => {
  return (
    <StudyAbroadButton
      teachingPeriodIndex={teachingPeriodIndex}
      isStudyAbroad={isStudyAbroad}
      isMalaysiaMember={isMalaysiaMember}
      setStudyAbroad={setStudyAbroad}
      units={units}
      unsetStudyAbroad={unsetStudyAbroad}
      removeUnit={removeUnit}
      closeMenu={closeMenu}
    />
  );
};

const mapStateToProps = state => {
  return {
    isMalaysiaMember: state.User.user && (state.User.user.isMalaysiaStudent || state.User.user.isMalaysiaStaff)
  }
};

/**
 * Bind course action dispatches to OverloadButton
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    setStudyAbroad,
    unsetStudyAbroad,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudyAbroadButtonContainer);

StudyAbroadButtonContainer.propTypes = {
  teachingPeriodIndex: number,
  isStudyAbroad: bool,
  isMalaysiaMember: bool,
  setStudyAbroad: func,
  unsetStudyAbroad: func,
  units: array,
};
