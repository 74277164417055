const constants = {
  DEFAULTS: {
    messages: {
      success: 'Succesfully Uploaded Files for processing!',
      uploadError: 'Something went wrong! Please try again later!',
      activeDrag: 'Drop files here...',
      emptyState: description => `Try dropping some files here, or click here to select files to
      upload. ${description} are allowed to be
      uploaded.`,
      submitButtonLabel: 'Submit',
    },
    component: {
      // default max file size is set to 20 megabytes
      MAX_FILE_SIZE: 20,
      // default max number of files is set to 1
      MAX_NO_FILES: 1,
    },
    errors: {
      INTEGER_TYPE: new Error('InputType must be a number'),
    },
    colors: {
      OK_COLOR: '#6c6',
      ERROR_COLOR: '#c66',
    },
  },
};

export default constants;
