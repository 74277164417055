import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import moment from 'moment';

// Components
import Header from '../../components/Base/Header';
import MaintenanceConfigContainer from '../../containers/AdminDashboard/MaintenanceConfigContainer';
import EnquiryTypesConfigContainer from '../../containers/AdminDashboard/EnquiryTypesConfigContainer';
import CourseStructuresUploadContainer from '../../containers/AdminDashboard/CourseStructuresUploadContainer';

// Styles
import { withStyles } from '@material-ui/core/styles';
import NotificationCreationContainer from '../../containers/AdminDashboard/NotificationCreationContainer/NotificationCreationContainer';

import styles from './styles';
import {
  Card,
  CardContent,
  Button,
  CardActions,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Input,
} from '@material-ui/core';

import PreviewIcon from '@material-ui/icons/RemoveRedEye';
import HistoryIcon from '@material-ui/icons/History';
import UserIcon from '@material-ui/icons/Person';
import PlanIcon from '@material-ui/icons/GridOn';
import ApprovedIcon from '@material-ui/icons/CheckCircle';
import FeedbackIcon from '@material-ui/icons/Feedback';

import StatsService from '../../services/StatsService';
import StatsCard from './StatsCard';
import { COLORS } from '../../constants';
import STATS from '../../constants/stats';

class AdminDashboard extends React.Component {
  state = {
    stats: null,
    isLoadingStats: false,
    isErrorStats: false,
    selectedRange: STATS.WEEK,
  };

  componentDidMount() {
    this.setState({
      stats: null,
      isLoadingStats: true,
      isErrorStats: false,
    });
    StatsService.getStats()
      .then(data =>
        this.setState({
          stats: data,
          isLoadingStats: false,
          isErrorStats: false,
        }),
      )
      .catch(() => {
        this.setState({
          stats: null,
          isLoadingStats: false,
          isErrorStats: true,
        });
      });
  }

  fetchStatsByDate = selectedRange => {
    this.setState({
      stats: null,
      isLoadingStats: true,
      isErrorStats: false,
    });
    let date;
    if (selectedRange === STATS.WEEK) {
      date = new moment().subtract(1, 'week');
    } else if (selectedRange === STATS.ONE_MONTH) {
      date = new moment().subtract(1, 'month');
    } else if (selectedRange === STATS.THREE_MONTHS) {
      date = new moment().subtract(3, 'month');
    }
    StatsService.getStatsByDate(date.toISOString())
      .then(data =>
        this.setState({
          stats: data,
          isLoadingStats: false,
          isErrorStats: false,
        }),
      )
      .catch(() => {
        this.setState({
          stats: null,
          isLoadingStats: false,
          isErrorStats: true,
        });
      });
  };

  handleRangeSelect = e => {
    e.stopPropagation();
    this.setState({ selectedRange: e.target.value });
    this.fetchStatsByDate(e.target.value);
  };

  renderQuickStatsRow = () => {
    const { classes } = this.props;
    const { stats, isLoadingStats, isErrorStats, selectedRange } = this.state;

    return (
      <div>
        <h2>Stats</h2>
        <div className={classes.rowReverse}>
          <FormControl>
            <InputLabel shrink htmlFor="select-date-range">
              Since
            </InputLabel>
            <Select
              value={selectedRange}
              onChange={this.handleRangeSelect}
              input={<Input name="age" id="select-date-range" />}>
              <MenuItem value={STATS.WEEK}>1 week</MenuItem>
              <MenuItem value={STATS.ONE_MONTH}>1 month</MenuItem>
              <MenuItem value={STATS.THREE_MONTHS}>3 months</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={classes.row}>
          <StatsCard
            isLoadingStats={isLoadingStats}
            isErrorStats={isErrorStats}
            stat={stats && stats.numOfUsers}
            title="Users logged in"
            icon={<UserIcon style={{ fontSize: '2em' }} />}
          />
          <StatsCard
            isLoadingStats={isLoadingStats}
            isErrorStats={isErrorStats}
            stat={stats && stats.numOfPlansModified}
            title="Plans modified"
            color={COLORS.MISC.warning}
            icon={<PlanIcon style={{ fontSize: '2em' }} />}
          />
          <StatsCard
            isLoadingStats={isLoadingStats}
            isErrorStats={isErrorStats}
            stat={stats && stats.numOfApprovals}
            title="Plans approved"
            color={COLORS.MISC.success}
            icon={<ApprovedIcon style={{ fontSize: '2em' }} />}
          />
          <StatsCard
            isLoadingStats={isLoadingStats}
            isErrorStats={isErrorStats}
            stat={stats && stats.numOfFeedback}
            title="Feedback provided"
            color={COLORS.MISC.beta}
            icon={<FeedbackIcon style={{ fontSize: '2em' }} />}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {stats && !isLoadingStats ? (
            <em style={{ fontSize: '1em', color: '#888' }}>{`Since ${moment(
              stats.date,
            ).format('DD/MM/YYYY hh:mm a')}
          `}</em>
          ) : (
            <div>
              {isLoadingStats && <em>Loading Stats...</em>}
              {isErrorStats && (
                <em>Something went wrong. Please try again later</em>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  render = () => {
    const { classes, user, isAuth } = this.props;

    if (!(isAuth && user.isAdmin)) {
      browserHistory.push('/');
    }

    return (
      <Fragment>
        <Header />

        <div className={classes.body}>
          <div className={classes.innerContent}>
            <div className={classes.content} tabIndex="0">
              <h1 className={classes.headings}>MonPlan App Dashboard</h1>
              {this.renderQuickStatsRow()}
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <h2>Application Configuration</h2>
              <div className={classes.row}>
                <div className={classes.rowItem}>
                  <Card>
                    <CardContent>
                      <MaintenanceConfigContainer />
                    </CardContent>
                    <CardActions>
                      <Button
                        component={Link}
                        to="/maintenance"
                        className={classes.link}>
                        <PreviewIcon style={{ marginRight: 10 }} />
                        Preview
                      </Button>

                      <Button
                        component={Link}
                        to="/maintenance"
                        className={classes.link}>
                        <HistoryIcon style={{ marginRight: 10 }} />
                        View History
                      </Button>
                    </CardActions>
                  </Card>
                </div>
                <div className={classes.rowItem}>
                  <Card>
                    <CardContent>
                      <NotificationCreationContainer />
                    </CardContent>
                  </Card>
                </div>
              </div>

              <div className={classes.row}>
                <Card className={classes.rowItem}>
                  <CardContent>
                    <CourseStructuresUploadContainer />
                  </CardContent>
                </Card>
              </div>

              <div className={classes.row}>
                <Card className={classes.rowItem}>
                  <CardContent>
                    <EnquiryTypesConfigContainer />
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}

const AdminDashboardWithStyles = withStyles(styles)(AdminDashboard);

const mapStateToProps = state => ({
  user: state.User.user,
  isAuth: state.User.isAuth,
});

export default connect(
  mapStateToProps,
  null,
)(AdminDashboardWithStyles);
