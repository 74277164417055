import { COLORS } from '../../../constants/';

const styles = theme => ({
  formControl: { margin: '0 1em' },
  select: {
    minWidth: 120,
    display: 'block',
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: theme.palette.grey[300],
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  text: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  errorText: {
    color: 'rgba(155, 54, 54, 1)',
  },
  helperText: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    marginTop: '30px',
    '&:visited': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  paperContainer: {
    background: 'white',
  },
  checkboxLabel: {
    color: 'white',
  },
  checkbox: {
    color: 'white',
  },
  formContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    width: '100%',
    margin: '0 20px 0 20px',
  },
  coursePlanNameContainer: {
    margin: '20px 0 20px 0',
    minHeight: '60px',
    borderRadius: 2,
  },
  coursePlanNameField: {
    position: 'relative',
    top: 12,
    height: 40,
  },
  submitArrowContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '20px 0',
  },
  submitArrowButton: {
    borderRadius: '100%',
    width: '60px',
    height: '60px',
    background: COLORS.PRIMARY.main,

    '&:hover': {
      background: '#003c5f',
    },
  },
  submitArrowButtonDisabled: {
    background: '#bdbdbd',
    '&:hover': {
      background: '#bdbdbd',
    },
  },
  submitArrow: {
    width: '40px',
    height: '40px',
    color: 'white',
  },
  unconfirmedStatusText: {
    marginTop: '0px',
    marginBottom: '40px',
  },
});

export default styles;
