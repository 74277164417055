import React from 'react';
import { withStyles, Card, CardContent } from '@material-ui/core';
import SmallLoader from '../../components/Base/SmallLoader';

import styles from './styles';
import { COLORS } from '../../constants';

class StatsCard extends React.Component {
  render() {
    const {
      classes,
      isLoadingStats,
      isErrorStats,
      color,
      stat,
      title,
      icon,
    } = this.props;
    return (
      <div className={classes.rowItem}>
        <Card>
          <div
            style={{
              backgroundColor: color || COLORS.PRIMARY.main,
              height: 10,
            }}
          />
          <CardContent style={{ padding: 10 }}>
            {isLoadingStats && <SmallLoader message="" />}
            {isErrorStats && <div>An Errored has Occured</div>}
            {!isLoadingStats && !isErrorStats && (
              <div style={{ textAlign: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <h2>{stat}</h2>
                  {icon && <div style={{ marginLeft: 5 }}>{icon}</div>}
                </div>

                <p style={{ fontWeight: 300 }}>{title}</p>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(StatsCard);
