export const MONPLAN_API_URL = '';
export const IP_TRACKING_URL = 'https://api.ipify.org';

const getMscvUrl = () => {
  switch (window.location.hostname) {
    case 'monplan.apps.monash.edu':
      return 'https://mscv.apps.monash.edu';
    case 'monplan-au-uat.appspot.com':
      return 'https://monash-mscv-uat.appspot.com';
    default:
      return 'https://monash-mscv-qa.appspot.com';
  }
};
export const MSCV_API_URL = getMscvUrl();
