import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// MUI Components
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

// MUI Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { default as CompleteIcon } from '@material-ui/icons/CheckCircle';
import { default as IncompleteIcon } from '@material-ui/icons/RemoveCircle';

// Actions
import {
  showUnitSelectSidebar,
  setCurrentAOSName,
  showUnitModal,
} from '../../../actions/UIActions';

import { fetchUnitGroup, fetchUnitInfo } from '../../../actions/UnitActions';

// Utils/Services
import PermissionUtils from '../../../utils/PermissionUtils';
import EnrolmentUtils from '../../../utils/EnrolmentUtils';
import HandbookService from '../../../services/HandbookService';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

/**
 * Extracts the unique list of units that you have completed in your course
 * @param {Array<TeachingPeriod>} teachingPeriods
 * @param {Array<AdvancedStandingItem>} advancedStanding
 */
const extractUnits = (teachingPeriods, advancedStanding) => {
  let units = [];
  teachingPeriods.forEach(tp => {
    const tpUnits = tp.units.filter(
      unit => unit && !unit.placeholder && unit.unitCode,
    );

    units = units.concat(tpUnits);
  });

  advancedStanding.forEach(unit => {
    if (unit.creditType === 'specifiedCredit') {
      units.push(unit);
    }
  });

  return units;
};

class CourseProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      core: false,
      unitInfoLoading: false,
      unitNameDict: {},
    };
  }

  componentDidMount() {
    const { isLoading, isError, courseStructure } = this.props;
    if (!isLoading && !isError && courseStructure) {
      this.getUnitsInfo(courseStructure);
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoading, courseStructure, isError } = this.props;

    if (prevProps.isLoading && !isLoading) {
      if (!isError && courseStructure) {
        this.getUnitsInfo(courseStructure);
        const aosKeys = Object.keys(courseStructure.aosUnits);
        let aosExpansionObj = {};
        aosKeys.forEach(aosCode => {
          aosExpansionObj[aosCode] = false;
        });
        this.setState({
          ...aosExpansionObj,
        });
      }
    }
  }

  getUnitsInfo = courseStructure => {
    let unitCodes = [
      ...courseStructure.courseCoreUnits,
      ...this.getAdvancedStandingUnits(),
    ];

    unitCodes = Object.values(courseStructure.aosUnits).reduce(
      (currentArr, aosObj) => {
        return [...currentArr, ...aosObj.coreUnits];
      },
      unitCodes,
    );

    const uniqueUnitCodes = [...new Set(unitCodes)];

    this.setState({
      unitInfoLoading: true,
    });

    if (uniqueUnitCodes.length > 0) {
      HandbookService.getUnitsInfo(uniqueUnitCodes)
        .then(unitsInfo => {
          const unitNameDict = unitsInfo.reduce(
            (unitDict, unit) => ({
              [unit.unitCode]: unit.unitName,
              ...unitDict,
            }),
            {},
          );

          this.setState({
            unitInfoLoading: false,
            unitNameDict,
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({
            unitInfoLoading: false,
            unitNameDict: {},
          });
        });
    }
  };

  handleUnitCodeClick = unitCode => {
    this.props.fetchUnitInfo(unitCode);
    this.props.showUnitModal();
  };

  handleChange = expansionKey => {
    this.setState(prevState => ({
      [expansionKey]: !prevState[expansionKey],
    }));
  };

  handleUnitGroupSelect = (unitCodes, aosName, isRequired) => {
    const {
      showUnitSelectSidebar,
      fetchUnitGroup,
      setCurrentAOSName,
    } = this.props;

    fetchUnitGroup(unitCodes);
    setCurrentAOSName(aosName);
    showUnitSelectSidebar(isRequired);
  };

  renderUnitProgressRow = (unitProgress, number, total, aosTitle, expanded) => {
    const { classes } = this.props;
    const { unitNameDict } = this.state;

    const accessibleUnitCode = unitProgress.unitCode.split('').join(' ');

    const present = unitProgress.complete ? 'is' : 'is not';
    const aria = `${aosTitle} core unit ${number} of ${total}, ${accessibleUnitCode} ${present} complete`;

    const unitName = unitNameDict[unitProgress.unitCode] || null;
    return (
      <div
        key={`progress-row-${number}`}
        aria-label={aria}
        className={classes.row}>
        <div>
          {unitProgress.complete ? (
            <CompleteIcon className={classes.completeIcon} />
          ) : (
            <IncompleteIcon className={classes.incompleteIcon} />
          )}
        </div>
        <div className={classes.rowUnitContainer}>
          <button
            tabIndex={expanded ? 0 : -1}
            title={`View unit information for ${unitProgress.unitCode}`}
            onClick={this.handleUnitCodeClick.bind(this, unitProgress.unitCode)}
            className={classes.buttonLink}>
            {unitProgress.unitCode}
          </button>
          {unitName && <p className={classes.rowUnitTitle}>{unitName}</p>}
        </div>
      </div>
    );
  };

  renderProgressSection = (
    index,
    title,
    requiredUnits,
    actualUnits,
    expansionKey,
    extraUnits = [],
  ) => {
    const { classes, user, status, isAuth } = this.props;

    let hasWritePermission = false;
    if (isAuth) {
      hasWritePermission = PermissionUtils.hasWriteAccess(user, status);
    }

    const requiredCount = requiredUnits.length;

    let completedCount = 0;

    const expanded = this.state[expansionKey];

    // Collect units into a dictionary
    const actualUnitsDict = actualUnits.reduce(
      (dict, unit) => ({ ...dict, [unit.unitCode]: unit }),
      {},
    );

    let unitProgressArr = requiredUnits.map(unitCode => {
      const isCompleted =
        // Check that unit has been completed
        !!actualUnitsDict[unitCode] &&
        // Complete if: there is no grade OR the grade is a pass grade
        (!actualUnitsDict[unitCode].grade ||
          EnrolmentUtils.isNonFailGrade(actualUnitsDict[unitCode].grade));

      if (isCompleted) {
        completedCount++;
      }

      return {
        unitCode: unitCode,
        complete: isCompleted,
      };
    });

    // sort unitProgressArr by unit code, ascending
    unitProgressArr = unitProgressArr.sort((prevUnit, nextUnit) => {
      if (prevUnit.unitCode < nextUnit.unitCode) {
        return -1;
      }
      if (prevUnit.unitCode > nextUnit.unitCode) {
        return 1;
      }
      return 0;
    });

    let electiveCount = 0;

    const electiveProgressArr = extraUnits.map(unitCode => {
      const isCompleted =
        // Check that unit has been completed
        !!actualUnitsDict[unitCode] &&
        // Complete if: there is no grade OR the grade is a pass grade
        (!actualUnitsDict[unitCode].grade ||
          EnrolmentUtils.isNonFailGrade(actualUnitsDict[unitCode].grade));

      if (isCompleted) {
        electiveCount++;
      }
      return {
        unitCode: unitCode,
        complete: isCompleted,
      };
    });

    const titleWithProgress = `${title}`;
    const ariaTitle = `Progress summary towards completing ${title}`;

    let coreTitleWithProgress = `Required Units (${completedCount}/${requiredCount}):`;
    if (requiredCount === 0) {
      coreTitleWithProgress = `Required Units:`;
    }

    let coreAriaTitle = `${title} required units. ${completedCount} units completed of ${requiredCount} required units`;
    if (requiredCount === 0) {
      coreAriaTitle = `${title} required units.`;
    }

    const electiveTitle = `Elective Units:`;
    const electiveAriaTitle = `${title} elective units. ${electiveCount} elective units currently in plan.`;

    return (
      <ExpansionPanel
        key={`progress-section-${index}`}
        onChange={this.handleChange.bind(this, expansionKey)}
        expanded={expanded}
        classes={{ expanded: classes.expansionContainer }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            content: classes.section,
            expanded: classes.section,
          }}>
          <h5 aria-label={ariaTitle} className={classes.sectionHeader}>
            {titleWithProgress}
          </h5>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          <p
            aria-label={coreAriaTitle}
            className={`${classes.sectionSubtitle} ${
              classes.sectionSubtitleFirst
            }`}>
            {coreTitleWithProgress}
          </p>
          {unitProgressArr.length === 0 && (
            <p
              aria-label={`No required units listed, please consult the handbook for more details`}
              className={classes.sectionText}>
              {`No required units listed, please consult the handbook for more details`}
            </p>
          )}
          {unitProgressArr.map((unitProgress, i) =>
            this.renderUnitProgressRow(
              unitProgress,
              i + 1,
              requiredCount,
              title,
              expanded,
            ),
          )}
          {unitProgressArr.length > 0 && hasWritePermission && (
            <div className={classes.buttonLinkContainer}>
              <button
                tabIndex={expanded ? 0 : -1}
                onClick={this.handleUnitGroupSelect.bind(
                  this,
                  requiredUnits,
                  title,
                  true,
                )}
                className={classes.buttonLink}>
                {`Choose From Required Units`}
              </button>
            </div>
          )}
          {extraUnits.length > 0 && hasWritePermission && (
            <React.Fragment>
              <Divider style={{ marginTop: '10px' }} />
              <p
                aria-label={electiveAriaTitle}
                className={classes.sectionSubtitle}>
                {electiveTitle}
              </p>
              <p
                aria-label={`${
                  electiveProgressArr.length
                } elective units found, use the button below to see them all.`}
                className={classes.sectionText}>
                {`${
                  electiveProgressArr.length
                } elective units found, use the button below to see them all.`}
              </p>
              <div className={classes.buttonLinkContainer}>
                <button
                  tabIndex={expanded ? 0 : -1}
                  onClick={this.handleUnitGroupSelect.bind(
                    this,
                    extraUnits,
                    title,
                    false,
                  )}
                  className={classes.buttonLink}>
                  {`Choose From Elective Units`}
                </button>
              </div>
            </React.Fragment>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  getAdvancedStandingUnits = () => {
    const { advancedStanding } = this.props;
    return advancedStanding
      .filter(
        item =>
          item.creditType === 'specifiedCredit' &&
          (item.category === 'CREDIT' || item.category === 'PRECLUSION'),
      )
      .map(item => item.unitCode);
  };

  renderAdvancedStanding = () => {
    const { advancedStanding, classes } = this.props;
    const { unitNameDict } = this.state;

    if (advancedStanding.length === 0) {
      return null;
    }

    const expansionKey = 'advStanding';
    const expanded = this.state[expansionKey];

    const ariaTitle = 'Received credits';
    const specifiedCreditAriaTitle = 'Specified credits';
    const unspecifiedCreditAriaTitle = 'Unspecified credits';
    const exemptionAriaTitle = 'Exemptions';

    const specifiedCreditItems = advancedStanding.filter(
      item =>
        item.creditType === 'specifiedCredit' && item.category === 'CREDIT',
    );

    const unspecifiedCreditItems = advancedStanding.filter(
      item => item.creditType === 'unspecifiedCredit',
    );

    const exemptionItems = advancedStanding.filter(
      item =>
        item.creditType === 'specifiedCredit' && item.category === 'PRECLUSION',
    );

    const renderUnitCreditRow = (item, i, keyPrefix) => {
      const unitName = unitNameDict[item.unitCode] || null;
      return (
        <div key={`${keyPrefix}${i}`} className={classes.row}>
          <div>
            <CompleteIcon className={classes.completeIcon} />
          </div>
          <div className={classes.rowUnitContainer}>
            <button
              tabIndex={expanded ? 0 : -1}
              title={`View unit information for ${item.unitCode}`}
              onClick={this.handleUnitCodeClick.bind(this, item.unitCode)}
              className={classes.buttonLink}>
              {item.unitCode}
            </button>
            {unitName && <p className={classes.rowUnitTitle}>{unitName}</p>}
          </div>
        </div>
      );
    };

    return (
      <ExpansionPanel
        key={`advanced-standing`}
        onChange={this.handleChange.bind(this, expansionKey)}
        expanded={expanded}
        classes={{ expanded: classes.expansionContainer }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            content: classes.section,
            expanded: classes.section,
          }}>
          <h5 aria-label={ariaTitle} className={classes.sectionHeader}>
            Credit
          </h5>
        </ExpansionPanelSummary>
        {specifiedCreditItems.length > 0 && (
          <ExpansionPanelDetails classes={{ root: classes.detail }}>
            <p
              aria-label={specifiedCreditAriaTitle}
              className={`${classes.sectionSubtitle} ${
                classes.sectionSubtitleFirst
              }`}>
              Specified credits:
            </p>
            {specifiedCreditItems.map((item, i) =>
              renderUnitCreditRow(item, i, 'specified-credit-'),
            )}
          </ExpansionPanelDetails>
        )}
        {unspecifiedCreditItems.length > 0 && (
          <ExpansionPanelDetails classes={{ root: classes.detail }}>
            <p
              aria-label={unspecifiedCreditAriaTitle}
              className={`${classes.sectionSubtitle} ${
                classes.sectionSubtitleFirst
              }`}>
              Unspecified credits:
            </p>
            {unspecifiedCreditItems.map((item, i) => (
              <div key={`unspecified-credit-${i}`} className={classes.row}>
                <div>
                  <CompleteIcon className={classes.completeIcon} />
                </div>
                <div className={classes.rowUnitContainer}>
                  <p className={classes.text}>{`Level ${
                    item.unitLevel
                  } block credit`}</p>
                  <p className={classes.rowUnitTitle}>{`${
                    item.creditPoints
                  } Credit Points`}</p>
                </div>
              </div>
            ))}
          </ExpansionPanelDetails>
        )}
        {exemptionItems.length > 0 && (
          <ExpansionPanelDetails classes={{ root: classes.detail }}>
            <p
              aria-label={exemptionAriaTitle}
              className={`${classes.sectionSubtitle} ${
                classes.sectionSubtitleFirst
              }`}>
              Exemptions:
            </p>
            {exemptionItems.map((item, i) =>
              renderUnitCreditRow(item, i, 'exemption-'),
            )}
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    );
  };

  renderProgress = () => {
    const { courseStructure, courseUnits, courseInfo } = this.props;

    if (!courseStructure) {
      return null;
    }

    let courseTitle = 'Course';
    if (courseInfo && courseInfo.courseName && courseInfo.courseName !== '') {
      courseTitle = courseInfo.courseName;
    }

    const aosKeys = Object.keys(courseStructure.aosUnits);

    return (
      <React.Fragment>
        {this.renderAdvancedStanding()}
        {this.renderProgressSection(
          0,
          courseTitle,
          courseStructure.courseCoreUnits,
          courseUnits,
          'core',
        )}
        {aosKeys.map((aosCode, index) => {
          const aosObj = courseStructure.aosUnits[aosCode];
          return this.renderProgressSection(
            index + 1,
            aosObj.aosName,
            aosObj.coreUnits,
            courseUnits,
            aosObj.aosCode,
            aosObj.otherUnits,
          );
        })}
      </React.Fragment>
    );
  };

  renderError = () => {
    const { classes } = this.props;
    return (
      <p className={classes.errorMsg} tabIndex="0">
        An error occurred while loading your course progress. Please try again
        later. If you are undertaking an older course, there may be no course
        progress info available.
      </p>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <div>
        <CircularProgress
          size={60}
          style={{
            position: 'absolute',
            zIndex: 1,
            top: 'calc(50% - 16px)',
            left: 'calc(50% - 34px)',
            color: 'rgba(0, 0, 0, 0.4)',
          }}
        />
        <div style={{ filter: 'blur(4px)' }}>
          <ExpansionPanel classes={{ expanded: classes.expansionContainer }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                content: classes.section,
                expanded: classes.section,
              }}>
              <p className={classes.text}>Course Required Units (0/8)</p>
            </ExpansionPanelSummary>
          </ExpansionPanel>
          <ExpansionPanel classes={{ expanded: classes.expansionContainer }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                content: classes.section,
                expanded: classes.section,
              }}>
              <p className={classes.text}>Major Units (0/8)</p>
            </ExpansionPanelSummary>
          </ExpansionPanel>
          <ExpansionPanel classes={{ expanded: classes.expansionContainer }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                content: classes.section,
                expanded: classes.section,
              }}>
              <p className={classes.text}>Minor Units (0/8)</p>
            </ExpansionPanelSummary>
          </ExpansionPanel>
        </div>
      </div>
    );
  };

  render() {
    const { classes, isLoading, isError } = this.props;

    let content = null;
    if (isLoading) {
      content = this.renderLoading();
    } else if (isError) {
      content = this.renderError();
    } else {
      content = this.renderProgress();
    }

    return (
      <Paper tabIndex="0">
        <div className={classes.titleContainer}>
          <h4 className={classes.courseProgressHeader}>
            Course Progression Guide
          </h4>
        </div>
        {content}
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.CourseStructure.isLoading,
  isError: state.CourseStructure.isError,
  courseStructure: state.CourseStructure.data,
  courseUnits: extractUnits(
    state.PlanInstance.teachingPeriods,
    state.AdvancedStanding.advancedStanding,
  ),
  advancedStanding: state.AdvancedStanding.advancedStanding,
  courseInfo: state.Handbook.courseInfo,
  user: state.User.user,
  isAuth: state.User.isAuth,
  status: state.CoursePlan.status,
});

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    showUnitSelectSidebar,
    fetchUnitGroup,
    setCurrentAOSName,
    fetchUnitInfo,
    showUnitModal,
  };

  return bindActionCreators(actionBundle, dispatch);
};

const CourseProgressWithStyles = withStyles(styles)(CourseProgress);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseProgressWithStyles);
