import React from 'react';
import PropTypes from 'prop-types';
// MUI imports
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// MUI Icons
import {default as SaveIcon} from '@material-ui/icons/Save';
import {default as PrintIcon} from '@material-ui/icons/Print';
import {default as ApproveIcon} from '@material-ui/icons/Done';
import {default as RejectIcon} from '@material-ui/icons/Close';
import {default as CloneIcon} from '@material-ui/icons/FileCopy';
import {default as ReviewIcon} from '@material-ui/icons/SupervisorAccount';
import {default as ValidateIcon} from '@material-ui/icons/PlaylistAddCheck';
// Styling
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
// Utils
import PermissionUtils from '../../../utils/PermissionUtils';
// Constants
import {STATUSES} from '../../../constants';

class CourseActionButtons extends React.Component {
  approvalButton = () => {
    const { classes } = this.props;
    const key = 'approvalButton';
    return (
      <Tooltip key={key} title="Approve this course plan" placement="right">
        <ListItem
          button
          onClick={this.props.onApprovalClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <ApproveIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Approve
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  saveButton = () => {
    const { classes, hasUnsavedChanges } = this.props;
    const key = 'saveButton';
    return (
      <Tooltip key={key} title="Save changes to course plan" placement="right">
        <ListItem
          button
          disabled={!hasUnsavedChanges}
          onClick={this.props.onSaveClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <SaveIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Save
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  cloneButton = () => {
    const { classes } = this.props;
    const key = 'cloneButton';
    return (
      <Tooltip key={key} title="Clone this course plan" placement="right">
        <ListItem
          button
          onClick={this.props.onCloneClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <CloneIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Clone
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  requestReviewButton = () => {
    const { classes } = this.props;
    const key = 'requestReviewButton';
    return (
      <Tooltip
        key={key}
        title="Request that a course adviser review this course plan"
        placement="right">
        <ListItem
          button
          onClick={this.props.onRequestReviewClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <ReviewIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Request
            <br />
            Review
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  rejectButton = () => {
    const { classes } = this.props;
    const key = 'rejectButton';
    return (
      <Tooltip
        key={key}
        title="Send course plan back to the student"
        placement="right">
        <ListItem
          button
          onClick={this.props.onRejectClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <RejectIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Reject
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  reviewButton = () => {
    const { classes } = this.props;
    const key = 'reviewButton';
    return (
      <Tooltip
        key={key}
        title="Put course plan into review mode"
        placement="right">
        <ListItem
          button
          onClick={this.props.onReviewClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <ReviewIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Review
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  printButton = () => {
    const { classes } = this.props;
    const key = 'printButton';
    return (
      <Tooltip key={key} title="Print course plan" placement="right">
        <ListItem
          button
          onClick={this.props.onPrintClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <PrintIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Print
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  validateButton = () => {
    const { classes } = this.props;
    const key = 'validateButton';
    return (
      <Tooltip key={key} title="Validate Course Plan" placement="right">
        <ListItem
          button
          onClick={this.props.onValidateClick}
          className={classes.stackedListItem}>
          <ListItemIcon>
            <ValidateIcon className={classes.actionIconSmall} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.smallTxt }}>
            Validate
          </ListItemText>
        </ListItem>
      </Tooltip>
    );
  };

  renderCourseActionButtons = () => {
    const { isAuth, status, user } = this.props;
    const approvalButton = this.approvalButton();
    const saveButton = this.saveButton();
    const cloneButton = this.cloneButton();
    const requestReviewButton = this.requestReviewButton();
    const rejectButton = this.rejectButton();
    const reviewButton = this.reviewButton();
    const printButton = this.printButton();
    const validateButton = this.validateButton();

    if (isAuth)
      return PermissionUtils.renderAllowedUserActionButtons({
        user,
        status,
        approvalButton,
        saveButton,
        cloneButton,
        requestReviewButton,
        rejectButton,
        reviewButton,
        printButton,
        validateButton,
      });

    return [];
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.courseActionContainer}>
        <Paper className={classes.courseActionCard}>
          <List className={classes.courseActionList}>
            {this.renderCourseActionButtons()}
          </List>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(CourseActionButtons);

CourseActionButtons.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  status: PropTypes.oneOf(Object.values(STATUSES)),
  user: PropTypes.object.isRequired,
  onApprovalClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCloneClick: PropTypes.func.isRequired,
  onRequestReviewClick: PropTypes.func.isRequired,
  onRejectClick: PropTypes.func.isRequired,
  onReviewClick: PropTypes.func.isRequired,
  onPrintClick: PropTypes.func.isRequired,
  onValidateClick: PropTypes.func.isRequired,
};
