import React from 'react';
import moment from 'moment';

// Material UI Components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker } from 'material-ui-pickers';

// Icons
import AnnouncementIcon from '@material-ui/icons/Announcement';

// Components
import BasicActionModal from '../../../components/Modals/BasicActionModal';
import SmallLoader from '../../../components/Base/SmallLoader';
import TextFieldValidator from '../../../components/Text/TextFieldValidator';

// Services
import AdminService from '../../../services/AdminService';

import { LIMITS } from '../../../constants';
import Annnouncement from '../../../components/Base/Announcement/Annnouncement';
import strings from './strings';

const announcementMessageValidators = TextFieldValidator.mergeValidators([
  TextFieldValidator.NotEmpty('Message cannot by empty'),
  TextFieldValidator.CharacterCount(
    LIMITS.CHARACTER_LIMITS.ANNOUNCEMENT_MESSAGE,
  ),
]);

class NotificationCreationContainer extends React.Component {
  state = {
    modalOpen: false,
    expiryDate: new moment(),
    message: '',
    submissionOk: false,
    isSubmitting: false,
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleSubmit = () => {
    const { expiryDate, message } = this.state;
    const date = expiryDate.toISOString();
    this.setState({ isSubmitting: true });
    AdminService.createNewNotification(date, message)
      .then(() =>
        this.setState({
          submissionOk: true,
          isSubmitting: false,
        }),
      )
      .catch(() =>
        this.setState({
          submissionOk: false,
          isSubmitting: false,
        }),
      );
  };

  renderBody = () => {
    const { expiryDate, message } = this.state;
    return (
      <>
        <TextFieldValidator value={message} {...announcementMessageValidators}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter your message"
            value={message}
            onChange={e => this.setState({ message: e.target.value })}
            fullWidth
            style={{ marginBottom: 10 }}
          />
        </TextFieldValidator>
        <DateTimePicker
          value={expiryDate}
          disablePast
          onChange={val => this.setState({ expiryDate: val })}
          label="Expiry date"
          minDate={new moment()}
          showTodayButton
        />
        <div style={{ marginTop: 10 }}>
          <b>Preview</b>
          <Annnouncement variant="info" message={message} />
        </div>
      </>
    );
  };
  render() {
    const { modalOpen, isSubmitting, submissionOk } = this.state;
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <AnnouncementIcon
            style={{
              marginRight: 10,
            }}
          />
          <h3>Submit an Announcement to display</h3>
        </div>
        <div style={{ marginBottom: 10 }}>
          <span>{strings.WARNING_MESSAGE}</span>
        </div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            this.setState({
              modalOpen: true,
            })
          }
          style={{ marginBottom: 10 }}>
          Submit new Announcement
        </Button>
        <BasicActionModal
          open={modalOpen}
          action={'I understand, continue.'}
          title="Create an Announcement for Users"
          style={{ maxWidth: '80%', width: '80%', minWidth: '80%' }}
          body={
            <>
              {isSubmitting ? (
                <SmallLoader />
              ) : submissionOk ? (
                <>
                  <b>Successfully Submitted!</b>
                </>
              ) : (
                this.renderBody()
              )}
            </>
          }
          onClose={this.handleModalClose}
          onAction={this.handleSubmit}
          hasAction={!isSubmitting && !submissionOk}
          disableCloseButton={isSubmitting}
        />
      </>
    );
  }
}

export default NotificationCreationContainer;
