// USER ACTIONS
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';
export const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
export const FETCH_USER_REJECTED = 'FETCH_USER_REJECTED';
export const LOGOUT = 'LOGOUT';

// TEACHING PERIOD ACTIONS
export const ADD_UNIT = 'ADD_UNIT';
export const CANCEL_MOVING_UNIT = 'CANCEL_MOVING_UNIT';
export const CLEAR_COURSE = 'CLEAR_COURSE';
export const DECREASE_STUDY_LOAD = 'DECREASE_STUDY_LOAD';
export const INCREASE_STUDY_LOAD = 'INCREASE_STUDY_LOAD';
export const INSERT_TEACHING_PERIOD = 'INSERT_TEACHING_PERIOD';
export const LOAD_NEW_TEACHING_PERIODS = 'LOAD_NEW_TEACHING_PERIODS';
export const LOAD_NEW_ADVANCED_STANDING = 'LOAD_NEW_ADVANCED_STANDING';
export const MOVE_UNIT = 'MOVE_UNIT';
export const MOVING_UNIT = 'MOVING_UNIT';
export const REMOVE_TEACHING_PERIOD = 'REMOVE_TEACHING_PERIOD';
export const REMOVE_UNIT = 'REMOVE_UNIT';
export const SWAP_UNIT = 'SWAP_UNIT';
export const UPDATE_AFFECTED_UNITS = 'UPDATE_AFFECTED_UNITS';
export const UPDATE_COURSE_INFO = 'UPDATE_COURSE_INFO';
export const UPDATE_INDEX_OF_TP_TO_REMOVE = 'UPDATE_INDEX_OF_TP_TO_REMOVE';
export const CANCEL_ADDING_UNIT = 'CANCEL_ADDING_UNIT';
export const SET_INTERMISSION = 'SET_INTERMISSION';
export const UNSET_INTERMISSION = 'UNSET_INTERMISSION';
export const SET_STUDY_ABROAD = 'SET_STUDY_ABROAD';
export const UNSET_STUDY_ABROAD = 'UNSET_STUDY_ABROAD';
export const ADDING_UNIT = 'ADDING_UNIT';

// PERIOD ACTIONS
export const FETCH_TEACHING_PERIODS_PENDING = 'FETCH_TEACHING_PERIODS_PENDING';
export const FETCH_TEACHING_PERIODS_FULFILLED =
  'FETCH_TEACHING_PERIODS_FULFILLED';
export const FETCH_TEACHING_PERIODS_REJECTED =
  'FETCH_TEACHING_PERIODS_REJECTED';

// ENROLMENT ACTIONS
export const FETCH_COURSE_ENROLMENTS_PENDING =
  'FETCH_COURSE_ENROLMENTS_PENDING';
export const FETCH_COURSE_ENROLMENTS_FULFILLED =
  'FETCH_COURSE_ENROLMENTS_FULFILLED';
export const FETCH_COURSE_ENROLMENTS_REJECTED =
  'FETCH_COURSE_ENROLMENTS_REJECTED';

// AREA OF STUDY ACTIONS
export const FETCH_COURSE_AOS_PENDING = 'FETCH_COURSE_AOS_PENDING';
export const FETCH_COURSE_AOS_FULFILLED = 'FETCH_COURSE_AOS_FULFILLED';
export const FETCH_COURSE_AOS_REJECTED = 'FETCH_COURSE_AOS_REJECTED';

// VALIDATION ACTIONS
export const FETCH_COURSE_ERRORS_PENDING = 'FETCH_COURSE_ERRORS_PENDING';
export const FETCH_COURSE_ERRORS_FULFILLED = 'FETCH_COURSE_ERRORS_FULFILLED';
export const FETCH_COURSE_ERRORS_REJECTED = 'FETCH_COURSE_ERRORS_REJECTED';

// COURSE STRUCTURE ACTIONS
export const FETCH_COURSE_STRUCTURE_PENDING = 'FETCH_COURSE_STRUCTURE_PENDING';
export const FETCH_COURSE_STRUCTURE_FULFILLED =
  'FETCH_COURSE_STRUCTURE_FULFILLED';
export const FETCH_COURSE_STRUCTURE_REJECTED =
  'FETCH_COURSE_STRUCTURE_REJECTED';

// FILTER ACTIONS
export const UPDATE_FACULTY_FILTER = 'UPDATE_FACULTY_FILTER';
export const UPDATE_LOCATION_FILTER = 'UPDATE_LOCATION_FILTER';
export const UPDATE_CREDITPOINTRANGE_FILTER = 'UPDATE_CREDITPOINTRANGE_FILTER';
export const UPDATE_TEACHINGPERIOD_FILTER = 'UPDATE_TEACHINGPERIOD_FILTER';
export const UPDATE_UNITTYPE_FILTER = 'UPDATE_UNITTYPE_FILTER';

// NOTIFICATION ACTIONS
export const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

// UI ACTIONS
export const SHOW_ADD_UNIT_UI = 'SHOW_ADD_UNIT_UI';
export const HIDE_ADD_UNIT_UI = 'HIDE_ADD_UNIT_UI';
export const SHOW_MOVE_UNIT_UI = 'SHOW_MOVE_UNIT_UI';
export const HIDE_MOVE_UNIT_UI = 'HIDE_MOVE_UNIT_UI';
export const SHOW_CONFIRM_DELETE_TEACHING_PERIOD_MODAL =
  'SHOW_CONFIRM_DELETE_TEACHING_PERIOD_MODAL';
export const HIDE_CONFIRM_DELETE_TEACHING_PERIOD_MODAL =
  'HIDE_CONFIRM_DELETE_TEACHING_PERIOD_MODAL';
export const SHOW_CONFIRM_DECREASE_STUDY_LOAD_MODAL =
  'SHOW_CONFIRM_DECREASE_STUDY_LOAD_MODAL';
export const HIDE_CONFIRM_DECREASE_STUDY_LOAD_MODAL =
  'HIDE_CONFIRM_DECREASE_STUDY_LOAD_MODAL';
export const SHOW_CUSTOM_UNIT_MODAL = 'SHOW_CUSTOM_UNIT_MODAL';
export const HIDE_CUSTOM_UNIT_MODAL = 'HIDE_CUSTOM_UNIT_MODAL';
export const SHOW_ABOUT_MODAL = 'SHOW_ABOUT_MODAL';
export const HIDE_ABOUT_MODAL = 'HIDE_ABOUT_MODAL';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const TOGGLE_PALETTE = 'TOGGLE_PALETTE';
export const SET_COURSE_READ_ONLY = 'SET_COURSE_READ_ONLY';
export const SET_COURSE_READ_AND_WRITE = 'SET_COURSE_READ_AND_WRITE';
export const SHOW_UNIT_MODAL = 'SHOW_UNIT_MODAL';
export const HIDE_UNIT_MODAL = 'HIDE_UNIT_MODAL';
export const SHOW_ADD_FEEDBACK_UI = 'SHOW_ADD_FEEDBACK_UI';
export const HIDE_ADD_FEEDBACK_UI = 'HIDE_ADD_FEEDBACK_UI';
export const SHOW_ACTION_DRAWER = 'SHOW_ACTION_DRAWER';
export const HIDE_ACTION_DRAWER = 'HIDE_ACTION_DRAWER';
export const HIDE_GRADES = 'HIDE_GRADES';
export const SHOW_GRADES = 'SHOW_GRADES';
export const HIDE_VALIDATION_MODAL = 'HIDE_VALIDATION_MODAL';
export const SHOW_VALIDATION_MODAL = 'SHOW_VALIDATION_MODAL';
export const HIDE_UNIT_SELECT_SIDEBAR = 'HIDE_UNIT_SELECT_SIDEBAR';
export const SHOW_UNIT_SELECT_SIDEBAR = 'SHOW_UNIT_SELECT_SIDEBAR';
export const SET_CURRENT_AOS_NAME = 'SET_CURRENT_AOS_NAME';
export const HIDE_PREFER_DESKTOP_NOTIFICATION =
  'HIDE_PREFER_DESKTOP_NOTIFICATION';

// COURSE PLAN ACTIONS
export const FETCH_COURSE_PLAN_PENDING = 'FETCH_COURSE_PLAN_PENDING';
export const FETCH_COURSE_PLAN_FULFILLED = 'FETCH_COURSE_PLAN_FULFILLED';
export const FETCH_COURSE_PLAN_REJECTED = 'FETCH_COURSE_PLAN_REJECTED';

export const FETCH_COURSE_PLAN_EVENTS_PENDING =
  'FETCH_COURSE_PLAN_EVENTS_PENDING';
export const FETCH_COURSE_PLAN_EVENTS_FULFILLED =
  'FETCH_COURSE_PLAN_EVENTS_FULFILLED';
export const FETCH_COURSE_PLAN_EVENTS_REJECTED =
  'FETCH_COURSE_PLAN_EVENTS_REJECTED';

export const UPDATE_COURSE_PLAN = 'UPDATE_COURSE_PLAN';
export const UPDATE_STUDENT_ID = 'UPDATE_STUDENT_ID';
export const UPDATE_STUDENT_NAME = 'UPDATE_STUDENT_NAME';
export const UPDATE_SNAPSHOT = 'UPDATE_SNAPSHOT';

export const SET_COURSE_PLAN_NAME = 'SET_COURSE_PLAN_NAME';
export const CHANGE_START_YEAR = 'CHANGE_START_YEAR';

// HANDBOOK ACTIONS
export const FETCH_UNIT_GROUP_PENDING = 'FETCH_UNIT_GROUP_PENDING';
export const FETCH_UNIT_GROUP_FULFILLED = 'FETCH_UNIT_GROUP_FULFILLED';
export const FETCH_UNIT_GROUP_REJECTED = 'FETCH_UNIT_GROUP_REJECTED';

// UNIT SEARCH ACTIONS
export const FETCH_UNITS_PENDING = 'FETCH_UNITS_PENDING';
export const FETCH_UNITS_FULFILLED = 'FETCH_UNITS_FULFILLED';
export const FETCH_UNITS_REJECTED = 'FETCH_UNITS_REJECTED';

export const FETCH_COURSE_INFO_PENDING = 'FETCH_COURSE_INFO_PENDING';
export const FETCH_COURSE_INFO_FULFILLED = 'FETCH_COURSE_INFO_FULFILLED';
export const FETCH_COURSE_INFO_REJECTED = 'FETCH_COURSE_INFO_REJECTED';

export const FETCH_UNIT_INFO_PENDING = 'FETCH_UNIT_INFO_PENDING';
export const FETCH_UNIT_INFO_FULFILLED = 'FETCH_UNIT_INFO_FULFILLED';
export const FETCH_UNIT_INFO_REJECTED = 'FETCH_UNIT_INFO_REJECTED';

export const SET_WAM = 'SET_WAM';
