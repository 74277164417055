import React from 'react';
import { func, number, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import {
  setIntermission,
  unsetIntermission,
} from '../../actions/CourseActions';

// Components
import IntermissionButton from '../../components/Buttons/IntermissionButton/IntermissionButton';

/**
 * Need as simple wrapper for this because we pass in mobile, numberOfUnits
 * and teachingPeriodIndex externally, and increaseStudyLoad from redux
 * action creators.
 */
const IntermissionButtonContainer = ({
  teachingPeriodIndex,
  isIntermission,
  setIntermission,
  unsetIntermission,
  units,
  removeIntermissionUnit,
  closeMenu,
}) => {
  return (
    <IntermissionButton
      teachingPeriodIndex={teachingPeriodIndex}
      isIntermission={isIntermission}
      setIntermission={setIntermission}
      units={units}
      unsetIntermission={unsetIntermission}
      removeIntermissionUnit={removeIntermissionUnit}
      closeMenu={closeMenu}
    />
  );
};

/**
 * Bind course action dispatches to OverloadButton
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    setIntermission,
    unsetIntermission,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  null,
  mapDispatchToProps,
)(IntermissionButtonContainer);

IntermissionButtonContainer.propTypes = {
  teachingPeriodIndex: number,
  isIntermission: bool,
  setIntermission: func,
  unsetIntermission: func,
  year: number,
  units: array,
};
