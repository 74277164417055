import React from 'react';
import { number, func, bool, array, object, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';

// Styling
import './unitHover.css';

// Actions
import { fetchUnitInfo } from '../../../actions/UnitActions';

import {
  showUnitModal,
  showSidebar,
  hideSidebar,
} from '../../../actions/UIActions';

import {
  moveUnit,
  removeUnit,
  swapUnit,
  movingUnit,
  addUnit,
} from '../../../actions/CourseActions';

// Components
import UnitDragPreview from '../UnitDragPreview';
import Unit from '../Unit';
import UnitDisplay from '../UnitDisplay';
import {CORE} from "../../../constants";

/**
 * Used for drop in react-dnd
 */
const unitTarget = {
  drop(props) {
    if (props.free || props.placeholder) {
      if (props.showingAddingUnitUI) {
        props.addUnit(props.teachingPeriodIndex, props.index, props.unitToAdd);
      } else if (props.showingMovingUnitUI) {
        props.moveUnit(props.index, props.teachingPeriodIndex);
      }
    } else if (props.showingMovingUnitUI) {
      props.swapUnit(props.index, props.teachingPeriodIndex, props.unit);
    }

    return {};
  },
};

/**
 * Mapping functions to props (for react-dnd)
 */
function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

/**
 * Unit component. Class is exported for unit testing.
 *
 * @class
 * @extends React.Component
 */
export class UnitSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      tableCellHover: false,
      overInput: false,
    };
  }

  /**
   * Used when the user hovers on a table cell whilst adding a unit.
   */
  handleTableCellMouseOver() {
    this.setState({
      tableCellHover: true,
    });
  }

  /**
   * Used when the user is no longer hovering on a table cell.
   */
  handleTableCellMouseOut() {
    this.setState({
      tableCellHover: false,
    });
  }

  /**
   * Set hover to false if we are no longer adding a unit. This is a
   * workaround in case the browser does not detect a mouseOut.
   */
  componentWillReceiveProps(nextProps) {
    if (
      (this.props.unitToAdd && !nextProps.unitToAdd) ||
      (this.props.showingMovingUnitUI && !nextProps.showingMovingUnitUI)
    ) {
      this.setState({
        tableCellHover: false,
      });
    }
  }

  /**
   * Updates state to indicate that the user is hovering on the unit.
   */
  handleUnitMouseEnter() {
    if (!this.state.hovering) {
      this.setState({
        hovering: true,
      });
    }
  }

  /**
   * Updates state to indicate that the user is hovering on the unit.
   */
  handleUnitMouseMove() {
    if (!this.state.hovering) {
      this.setState({
        hovering: true,
      });
    }
  }

  /**
   * Updates state to indicate that the user is no longer hovering on the
   * unit.
   */
  handleUnitMouseLeave() {
    if (this.state.hovering) {
      this.setState({
        hovering: false,
      });
    }
  }

  /**
   * The handle click function is called whenever use clicks a table cell, if the cell is empty (not free),
   * then the unit detail button will be updated via the onUnitClick function
   */
  handleClick(e) {
    if (this.props.viewOnly || this.props.isPreloaded) {
      return;
    }

    if (this.props.free || this.props.placeholder) {
      if (this.props.unitToAdd) {
        this.props.addUnit(
          this.props.teachingPeriodIndex,
          this.props.index,
          this.props.unitToAdd,
        );

        return;
      }

      if (this.props.showingMovingUnitUI) {
        this.props.moveUnit(this.props.index, this.props.teachingPeriodIndex);
        return;
      }
    }

    if (this.props.free || this.props.placeholder) {
      if (!this.props.unitToAdd) {
        e.stopPropagation(); /* otherwise sidebar will never show */
        this.props.showSidebar(
          this.props.teachingPeriodIndex,
          this.props.index,
        );
      }
    } else {
      if (this.props.onUnitClick) {
        this.props.onUnitClick(this.props.code, this.props.custom);
      }
    }
  }

  /**
   * Removes unit from the course structure.
   */
  handleDelete() {
    if (this.props.free) {
      return;
    }

    this.props.removeUnit(
      this.props.teachingPeriodIndex,
      this.props.index,
      this.props.creditPoints,
    );
  }

  /**
   * When a unit is clicked the unit info modal should open, and we need to fetch data for it, however,
   * we have a caching mechanism in place, so before wasitng resources on an API call, we check if the
   * unit is in the cache already.
   */
  handleUnitInfoRequest(unitCode, year) {
    this.props.hideSidebar();
    this.props.fetchUnitInfo(unitCode);

    const handBookUrl = CORE.EXTERNAL_LINKS.MONASH.HANDBOOK + "units/" + unitCode;
    window.open(handBookUrl, '_blank');
    // make sure details are updated before showing the modal
    //this.props.showUnitModal(year);
  }

  handleUnitMoveRequest() {
    if (this.props.free || this.props.placeholder) {
      this.props.moveUnit(this.props.index, this.props.teachingPeriodIndex);
    } else {
      this.props.swapUnit(
        this.props.index,
        this.props.teachingPeriodIndex,
        this.props.unit,
      );
    }
  }

  /**
   * Opens add unit sidebar
   */
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleClick(e);
    }
  }

  calculateEmptyUnitAriaLabel = () => {
    const {
      unitToMove,
      unitToAdd,
      showingAddingUnitUI,
      showingMovingUnitUI,
      teachingPeriods,
      teachingPeriodIndex,
      tpData,
    } = this.props;

    const teachingPeriod = teachingPeriods[teachingPeriodIndex];

    let teachingPeriodName = teachingPeriod.code;
    if (tpData) {
      const tp = tpData.find(element => element.code === teachingPeriod.code);
      if (tp !== undefined) {
        teachingPeriodName = tp.name;
      }
    }

    let msg = 'Empty unit. Press enter to add unit.';

    if (showingAddingUnitUI) {
      let accessibleUnitCode = 'unit';
      if (unitToAdd && unitToAdd.unitCode) {
        accessibleUnitCode = unitToAdd.unitCode.split('').join(' ');
      }
      msg = `Press enter to add unit ${accessibleUnitCode} in teaching period ${teachingPeriodName}, ${
        teachingPeriod.year
      }`;
    }

    if (showingMovingUnitUI) {
      let accessibleUnitCode = 'unit';
      if (unitToMove && unitToMove.unitCode) {
        accessibleUnitCode = unitToMove.unitCode.split('').join(' ');
      }
      msg = `Press enter to move unit ${accessibleUnitCode} to teaching period ${teachingPeriodName}, ${
        teachingPeriod.year
      }`;
    }

    return msg;
  };

  /**
   * Renders a table cell with a Message inside of it, which displays the
   * unit code and name, as well as showing the color to represent the
   * faculty of the unit.
   */
  render() {
    const {
      connectDropTarget,
      isOver,
      isPreloaded,
      numberOfUnits,
      viewOnly,
    } = this.props;

    const flexSpan = this.props.creditPoints > 0 ? this.props.creditPoints : 6;

    const UnitComponent = viewOnly || isPreloaded ? Unit : UnitDragPreview;

    const emptyUnitAriaLabel = this.calculateEmptyUnitAriaLabel();
    return connectDropTarget(
      <div
        style={{
          flex: flexSpan,
          background: 'rgb(243, 243, 243)',
          borderRight: '1px solid rgb(209, 211, 212)',
          padding: '0.2em 0.125em',
        }}
        onMouseOver={this.handleTableCellMouseOver.bind(this)}
        onMouseOut={this.handleTableCellMouseOut.bind(this)}
        onClick={this.handleClick.bind(this)}>
        {this.props.free && (
          <div
            className={'unitHover'}
            onKeyPress={this.handleKeyPress.bind(this)}
            style={{
              boxSizing: 'border-box',
              height: '100%',
              backgroundColor: '#fffffff',
              color: 'rgba(0,0,0,0.7)',
              cursor: 'pointer',
              outline: isOver ? '2px solid red' : undefined,
              fontSize: 14,
              padding: 4,
            }}>
            {isPreloaded || viewOnly ? (
              <div />
            ) : (
              <UnitDisplay
                ariaOverride={emptyUnitAriaLabel}
                isPlaceholder
                numberOfUnits={numberOfUnits}
                title="Empty unit"
                subtitle={this.props.viewOnly ? '' : 'Click here to add unit'}
              />
            )}
          </div>
        )}
        {!this.props.free && (
          <div
            onKeyPress={this.handleKeyPress.bind(this)}
            style={{
              height: '100%',
              fontWeight: 400,
              color: 'rgba(0,0,0,0.7)',
              outline: isOver ? '2px solid red' : undefined,
              padding: this.props.placeholder ? 8 : 0,
              cursor: this.props.placeholder ? 'pointer' : undefined,
              boxSizing: 'border-box',
            }}>
            {this.props.placeholder && (
              <UnitDisplay
                ariaOverride={emptyUnitAriaLabel}
                isPlaceholder
                numberOfUnits={numberOfUnits}
                title={this.props.code}
                subtitle={this.props.name}
                //isPreloaded={this.props.isPreloaded}
              />
            )}
            {!this.props.placeholder && (
              <UnitComponent
                tabIndex={0}
                hovering={this.state.hovering}
                handleUnitMouseEnter={this.handleUnitMouseEnter.bind(this)}
                handleUnitMouseMove={this.handleUnitMouseMove.bind(this)}
                handleUnitMouseLeave={this.handleUnitMouseLeave.bind(this)}
                handleButtonMouseEnter={() =>
                  this.setState({
                    overInput: true,
                  })
                }
                handleButtonMouseLeave={() =>
                  this.setState({
                    overInput: false,
                  })
                }
                handleDelete={this.handleDelete.bind(this)}
                onUnitInfoRequest={this.handleUnitInfoRequest.bind(this)}
                onUnitMoveRequest={this.handleUnitMoveRequest.bind(this)}
                unitIsMoving={this.props.showingMovingUnitUI}
                movingUnit={this.props.movingUnit}
                {...this.props}
              />
            )}
          </div>
        )}
      </div>,
    );
  }
}

/**
 * Set up any functions from the action creators you want to pass in
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    fetchUnitInfo,
    hideSidebar,
    moveUnit,
    removeUnit,
    showSidebar,
    showUnitModal,
    swapUnit,
    movingUnit,
    addUnit,
  };
  return bindActionCreators(actionBundle, dispatch);
};

/**
 * The unit cells need to be aware of the current unit to add
 */
const mapStateToProps = state => {
  return {
    unitToAdd: state.PlanInstance.unitToAdd,
    unitToMove: state.PlanInstance.unitToBeMoved,
    viewOnly: state.UI.readOnly,
    showingAddingUnitUI: state.UI.showingAddingUnitUI,
    showingMovingUnitUI: state.UI.showingMovingUnitUI,
    teachingPeriods: state.PlanInstance.teachingPeriods,
    tpData: state.TeachingPeriods.data,
  };
};

// https://github.com/gaearon/react-dnd/issues/157

const drop = DropTarget('unit', unitTarget, collectTarget)(UnitSlot);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(drop);

UnitSlot.propTypes = {
  /* Used for indicating whether unit is free or not */

  free: bool,

  unit: object,

  index: number,
  code: string,
  name: string,
  creditPoints: number,
  faculty: string,
  teachPeriodIndex: number,
  placeholder: bool,
  custom: bool,
  viewOnly: bool,
  unitIsMoving: bool,
  numberOfUnits: number,

  /* Used for placeholder units when users click on it */
  showSidebar: func,

  /* Used for drop functionality */
  connectDropTarget: func,
  isOver: bool,

  /* Validation */
  errors: array,

  showingAddingUnitUI: bool,
  showingMovingUnitUI: bool,

  /* Redux action creators */
  fetchUnitInfo: func,
  removeUnit: func,
  hideSidebar: func,
  showUnitModal: func,

  addUnit: func,
  swapUnit: func,
  moveUnit: func,
  onUnitClick: func,
};
