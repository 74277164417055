import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import CourseStructureReducer from './CourseStructureReducer';
import UIReducer from './UIReducer';
import NotificationsReducer from './NotificationsReducer';
import FiltersReducer from './FiltersReducer';
import UnitSearchReducer from './UnitSearchReducer';
import EnrolmentsReducer from './EnrolmentsReducer';
import UserReducer from './UserReducer';
import CoursePlanReducer from './CoursePlanReducer';
import AreaOfStudyReducer from './AreaOfStudyReducer';
import ValidationReducer from './ValidationReducer';
import HandbookReducer from './HandbookReducer';
import PlanInstanceReducer from './PlanInstanceReducer';
import TeachingPeriodsReducer from './TeachingPeriodsReducer';
import AdvancedStandingReducer from './AdvancedStandingReducer';

const appReducer = combineReducers({
  CoursePlan: CoursePlanReducer,
  CourseStructure: CourseStructureReducer,
  Enrolments: EnrolmentsReducer,
  Filters: FiltersReducer,
  Notifications: NotificationsReducer,
  UI: UIReducer,
  UnitSearch: UnitSearchReducer,
  User: UserReducer,
  AreaOfStudy: AreaOfStudyReducer,
  Validation: ValidationReducer,
  Handbook: HandbookReducer,
  PlanInstance: PlanInstanceReducer,
  TeachingPeriods: TeachingPeriodsReducer,
  AdvancedStanding: AdvancedStandingReducer,
  routing: routerReducer,
});

export default appReducer;
