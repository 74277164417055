import React from 'react';
import PropTypes from 'prop-types';
import * as ReactGA from 'react-ga';

// MUI Components
import Button from '@material-ui/core/Button';

// MUI Icons
import { default as ApproveIcon } from '@material-ui/icons/Done';
import { default as FeedbackIcon } from '@material-ui/icons/Chat';
import { default as EditIcon } from '@material-ui/icons/Edit';
import { default as LockIcon } from '@material-ui/icons/Lock';

// Constants
import { STATUSES, COLORS } from '../../../constants';
import { gaActions, gaCategory } from '../../../constants/reactGA';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class CourseStatusBanner extends React.Component {
  render() {
    const { status, classes, user, showActionDrawer, snapshotId } = this.props;

    switch (status) {
      case STATUSES.APPROVED:
        if (user.isStudent && !user.isMsaStudent) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ background: COLORS.MISC.success }}>
              <ApproveIcon className={classes.statusIcon} />
              <p className={classes.text}>
                Your course plan has been approved. You should now enrol in WES.
              </p>
              <Button
                className={`${classes.button} ${classes.successHover}`}
                component="a"
                aria-label="Enrol in the Monash University Web Enrolment System"
                target="_blank"
                rel="noopener noreferrer"
                href={
                  snapshotId
                    ? `https://monplan.apps.monash.edu/redirect/wes?snapshotId=${snapshotId}&source=app`
                    : 'https://my.monash.edu.au/wes/'
                }
                onClick={() => {
                  ReactGA.ga(trackerId => {
                    ReactGA.event({
                      category: gaCategory.EXTERNAL,
                      action: gaActions.EXTERNAL.redirects.WES,
                      label: 'WES',
                    });
                  });
                }}>
                Enrol in WES
              </Button>
            </div>
          );
        } else if (user.isAusAdvisor || user.isMalaysiaStaff) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ background: COLORS.MISC.success }}>
              <ApproveIcon className={classes.statusIcon} />
              <p className={classes.text}>
                This course plan has been approved.
              </p>
            </div>
          );
        } else if (user.isMonashConnect) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ background: COLORS.MISC.success }}>
              <ApproveIcon className={classes.statusIcon} />
              <p className={classes.text}>
                This course plan has been approved by a Course Adviser.
                <br />
                This means a Course Adviser assessed the plan and saw it as
                valid for enrolment. If a student wishes to continue working on
                this plan they can clone the plan from the action menu. The
                student must enrol in WES, MonPlan does not enrol the student.
              </p>
            </div>
          );
        }

        return null;
      case STATUSES.LOCKED:
        if (user.isStudent && !user.isMsaStudent) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ background: COLORS.MISC.reviewStatus }}>
              <FeedbackIcon className={classes.statusIcon} />
              <p className={classes.text}>
                This plan is in review mode.
                <br />
                To continue working on this plan, use the action menu to clone
                it and continue work on the copy.
              </p>
              <Button
                aria-label="Clone Course Plan"
                className={`${classes.button} ${classes.reviewHover}`}
                onClick={showActionDrawer}>
                Clone Course Plan
              </Button>
            </div>
          );
        } else if (user.isAusAdvisor || user.isMalaysiaStaff) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ background: COLORS.MISC.reviewStatus }}>
              <FeedbackIcon className={classes.statusIcon} />
              <p className={classes.text}>
                This plan is in review mode. You can move units, approve it for
                enrolment and add feedback via the Add Feedback button below.
                Please remember to approve or release a plan once you are done
                giving advice.
              </p>
            </div>
          );
        } else if (user.isMonashConnect) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ background: COLORS.MISC.reviewStatus }}>
              <FeedbackIcon className={classes.statusIcon} />
              <p className={classes.text}>
                This course plan is under Course Adviser control.
                <br />A Course Adviser is reviewing this plan so the student
                can’t edit the plan. The student can still clone this plan if
                they wish to continue working on it - but otherwise can’t access
                the plan until the Course Adviser has finished the review.
              </p>
            </div>
          );
        }

        return null;
      case STATUSES.UNLOCKED:
        if (user.isStaff && !user.isMsaStaff) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ backgroundColor: COLORS.MISC.warning }}>
              <LockIcon className={classes.statusIcon} />
              <p className={classes.text}>
                This student is currently working on this course plan. As an
                adviser, you should wait for them to approach you.
                <br />
                You can still give advice on this plan, but you must go to the
                Action Menu and select "Review Course Plan"
              </p>
              {/*
              There is intentionally no "Open Menu" button because we are trying
              to discourage Course Advisers from forcing a plan into review mode.
              */}
            </div>
          );
        } else if (user.isMonashConnect) {
          return (
            <div
              tabIndex="0"
              className={classes.statusContainer}
              style={{ backgroundColor: COLORS.MISC.warning }}>
              <EditIcon className={classes.statusIcon} />
              <p className={classes.text}>
                This course plan is under student control. <br />A student can
                edit this plan, but a Course Adviser can’t until it is put in
                'review mode'. Students can submit this plan for review by
                pressing the 'ready for review' button in the action menu.
              </p>
            </div>
          );
        }

        return null;
      default:
        return null;
    }
  }
}

export default withStyles(styles)(CourseStatusBanner);

CourseStatusBanner.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUSES)),
  coursePlanLink: PropTypes.string,
};
