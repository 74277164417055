import * as actions from '../../actions';

const defaultState = {
  open: false,
  message: 'Default Message String',
  duration: 4000,
};

/**
 * Handles the storing of a notification to display
 */
export default function NotificationsReducer(state = defaultState, action) {
  switch (action.type) {
    case actions.DISPLAY_NOTIFICATION:
      if (action.message === state.message) {
        action.message += ' ';
      }
      return {
        ...state,
        open: true,
        message: action.message,
        duration: action.duration,
      };
    case actions.HIDE_NOTIFICATION:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
