import NetworkService from './NetworkService';
import { MONPLAN_API_URL } from '../constants';
import {
  facultiesOrSchools,
  malaysiaSchools,
  ENQUIRY_TYPES,
  ENQUIRY_RESPONSE_CASE_ID_AVAILABLE,
} from './data/enquiryMockData';

import CoreUtils from '../utils/CoreUtils';

import { MOCK_RESPONSE_TIME } from './constants';

export default class EnquiryService {
  static getFaculties() {
    const requestURI = `${MONPLAN_API_URL}/api/enquiry/faculties`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('EnquiryService.getFaculties()', requestURI, 'GET');
      return CoreUtils.createMockRequest('resolve', facultiesOrSchools, 2000);
    }

    return NetworkService.get(requestURI);
  }

  static getMalaysiaSchools() {
    const requestURI = `${MONPLAN_API_URL}/api/enquiry/malaysia-schools`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('EnquiryService.getMalaysiaSchools()', requestURI, 'GET')
      return CoreUtils.createMockRequest('resolve', malaysiaSchools, 2000);
    }

    return NetworkService.get(requestURI);
  }

  static getEnquiryTypes() {
    const requestURI = `${MONPLAN_API_URL}/api/enquiry/enquiry-types`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('EnquiryService.getEnquiryTypes()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        ENQUIRY_TYPES,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.get(requestURI);
  }

  static submitRequest = (
    snapshotId,
    faculty,
    school,
    message,
    enquiryType,
  ) => {
    const requestURI = `${MONPLAN_API_URL}/api/snapshots/${snapshotId}/request-feedback`;
    const body = {
      // Student ID is inferred from request/session
      faculty,
      school,
      message,
      enquiryType,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnquiryService.submitRequest()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        ENQUIRY_RESPONSE_CASE_ID_AVAILABLE,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  };

  // Will return undefined if an enquiry does not exist
  static getEnquiryBySnapshot(snapshotId) {
    const requestURI = `${MONPLAN_API_URL}/api/snapshot/${snapshotId}/enquiry`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'EnquiryService.getEnquiryBySnapshot()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        ENQUIRY_RESPONSE_CASE_ID_AVAILABLE,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI);
  }
}
