import { COLORS } from '../../../constants';

const styles = theme => ({
  statusContainer: {
    width: '100%',
    color: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '10px 30px',
  },
  statusIcon: {
    fontSize: 40,
  },
  text: {
    flex: 2,
    textAlign: 'left',
    margin: 24,
    minWidth: 200,
  },
  button: {
    border: '2px solid white',
    background: 'inherit',
    color: 'white',
    '&:visited': {
      color: 'white',
    },
    minWidth: 100,
    padding: 8,
  },

  successHover: {
    '&:hover': {
      background: 'white',
      color: COLORS.MISC.success,
    },
  },

  reviewHover: {
    '&:hover': {
      background: 'white',
      color: COLORS.MISC.reviewStatus,
    },
  },
});

export default styles;
