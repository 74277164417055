import * as actions from '../../actions';
import UnitFactory from '../../models/Unit/UnitFactory';

export const defaultState = {
  courseInfoLoadError: null,
  courseInfoLoading: false,
  courseInfo: {
    courseCode: '',
    courseName: '',
    faculty: '',
    creditPoints: 0,
    courseDescription: '',
    durationStr: '',
    modeAndLocation: '',
    awards: '',
    abrTitle: '',
  },

  unitLoading: false,
  unitLoadError: null,
  unitInfo: {
    preqs: '',
    creditPoints: 0,
    offerings: '',
    proh: '',
    unitName: '',
    faculty: '',
    unitCode: '',
    descriptions: '',
  },

  unitGroupError: null,
  unitGroupLoading: false,
  unitGroup: [],
};

/**
 * Stores the currently loaded course and unit handbook data
 */
const HandbookReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSE_INFO_PENDING:
      return {
        ...state,
        courseInfoLoading: true,
        courseInfoLoadError: null,
      };

    case actions.FETCH_COURSE_INFO_FULFILLED:
      return {
        ...state,
        courseInfoLoading: false,
        courseInfo: {
          courseCode: action.payload.courseCode,
          courseName: action.payload.courseName,
          faculty: action.payload.mangFac,
          creditPoints: action.payload.creditPoints,
          courseDescription: action.payload.courseDescrip,
          durationStr: action.payload.courseDuration,
          modeAndLocation: action.payload.modeLoc,
          awards: action.payload.courseAward,
          abrTitle: action.payload.abrevTitle,
        },
      };

    case actions.FETCH_COURSE_INFO_REJECTED:
      return {
        ...state,
        courseInfoLoading: false,
        courseInfoLoadError: true,
      };

    case actions.UPDATE_COURSE_INFO:
      return {
        ...state,
        courseInfo: action.courseInfo,
      };

    case actions.CLEAR_COURSE:
      return defaultState;

    case actions.FETCH_UNIT_INFO_PENDING:
      return {
        ...state,
        unitLoading: true,
        unitLoadError: null,
        unitInfo: defaultState.unitInfo,
      };

    case actions.FETCH_UNIT_INFO_FULFILLED:
      return {
        ...state,
        unitLoading: false,
        unitInfo: action.payload,
      };

    case actions.FETCH_UNIT_INFO_REJECTED:
      return {
        ...state,
        unitLoading: false,
        unitLoadError: action.payload,
      };

    case actions.FETCH_UNIT_GROUP_PENDING:
      return {
        ...state,
        unitGroupLoading: true,
        unitGroupError: null,
      };

    case actions.FETCH_UNIT_GROUP_FULFILLED:
      return {
        ...state,
        unitGroupLoading: false,
        unitGroup: action.payload
          .sort((prevUnit, nextUnit) => {
            if (prevUnit.unitCode < nextUnit.unitCode) {
              return -1;
            }
            if (prevUnit.unitCode > nextUnit.unitCode) {
              return 1;
            }
            return 0;
          })
          .map(UnitFactory.createUnit),
      };

    case actions.FETCH_UNIT_GROUP_REJECTED:
      return {
        ...state,
        unitGroupLoading: false,
        unitGroupError: true,
      };

    default:
      return state;
  }
};

export default HandbookReducer;
