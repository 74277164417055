/**
 * Util functions for a plan instance
 */
export default class PlanInstanceUtils {
  /**
   * Takes an array of teaching periods and creates a string from it.
   * Useful for comparing changes across plan instances.
   * @param {Array<TeachingPeriodObj>} teachingPeriods
   */
  static stringifyTeachingPeriods(teachingPeriods) {
    let stringifiedTps = '';
    for (let i = 0; i < teachingPeriods.length; i++) {
      const currentTp = teachingPeriods[i];
      stringifiedTps += currentTp.code;
      if (currentTp.isIntermission) {
        stringifiedTps += 'INTERMISSION';
      }
      if (currentTp.isStudyAbroad) {
        stringifiedTps += 'STUDY_ABROAD';
      }
      for (let j = 0; j < currentTp.units.length; j++) {
        const currentUnit = currentTp.units[j];
        if (currentUnit) {
          stringifiedTps += currentUnit.unitCode;
        } else {
          stringifiedTps += 'NULL';
        }
      }
    }

    return stringifiedTps;
  }

  /**
   * Compare if a given plan instance is the same as the saved snapshot.
   * Returns true if the plan is modified.
   *
   * This is designed to work where a component state stores a snapshot of the last
   * saved state, and the props pass through the latest version of the plan instance.
   * This way, `this.state` and `this.props` can be directly passed into this function
   * without any transformation necessary.
   *
   * @param {Object<{ teachingPeriodsString: object, coursePlanName: string}>} state
   * @param {Object<{ teachingPeriods: object, coursePlanName: string}>} props
   */
  static isPlanModified(
    {
      teachingPeriodsString: teachingPeriodsOld,
      coursePlanName: coursePlanNameOld,
    },
    { teachingPeriods: teachingPeriodsNew, coursePlanName: coursePlanNameNew },
  ) {
    const modified =
      teachingPeriodsOld !==
        PlanInstanceUtils.stringifyTeachingPeriods(teachingPeriodsNew) ||
      coursePlanNameOld !== coursePlanNameNew;
    return modified;
  }
}
