import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';

// MUI Icons
import ViewList from '@material-ui/icons/ViewList';

// MUI Components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Constants
import { ROUTES } from '../constants';

// Components
import Header from '../components/Base/Header';

// Containers
import CourseSelectFormContainer from '../containers/CourseSelectFormContainer';

class NewPlan extends Component {
  render() {
    const { studentId, user, isAuth } = this.props;

    if (!isAuth || user.isMsaStudent) {
      window.location = `${window.location.origin}`;
    }

    if (
      (user.isAusAdvisor && !studentId) ||
      (user.isMalaysiaStaff && !studentId) ||
      user.isMonashConnect ||
      user.isMsaStaff
    ) {
      browserHistory.push(ROUTES.STUDENT_SEARCH);
    }

    return (
      <React.Fragment>
        <Header title="Menu">
          {studentId && (
            <ListItem
              aria-label="Student Plans"
              button
              component={Link}
              to={`${ROUTES.STUDENT_SEARCH}/${this.props.studentId}`}>
              <ListItemIcon>
                <ViewList />
              </ListItemIcon>
              <ListItemText>Student Plans</ListItemText>
            </ListItem>
          )}
        </Header>

        <div style={{ background: '#eee', minHeight: '100vh' }}>
          <div
            style={{
              maxWidth: 1000,
              width: '100%',
              margin: '0 auto',
              padding: '16px 0',
            }}>
            <CourseSelectFormContainer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User.user,
  isAuth: state.User.isAuth,
  studentId: state.CoursePlan.currentStudentId,
  userIsLoading: state.User.isLoading,
});

export default connect(mapStateToProps)(NewPlan);

NewPlan.contextTypes = {
  router: PropTypes.object.isRequired,
};

NewPlan.propTypes = {};
