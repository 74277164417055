import Unit from './Unit';

export default class UnitFactory {
  /**
   *
   * @param {*} data
   */
  static createUnit(data) {
    return new Unit({
      unitCode: data.unitCode,
      unitName: data.unitName,
      creditPoints: data.creditPoints,
      faculty: data.faculty,
      grade: data.grade,
    });
  }

  /**
   * Converts unit class into an object for
   * storing in json format
   */
  static serialiseUnitObject(unitModel) {
    return {
      unitCode: unitModel.unitCode,
      unitName: unitModel.unitName,
      creditPoints: unitModel.creditPoints,
      faculty: unitModel.faculty,
      grade: unitModel.grade,
    };
  }
}
