// Actions
import * as actions from './index';

// Services
import UnitService from '../services/UnitService';

/**
 *
 * Uses the unit search api to fetch a
 * list of units matching the given string
 * and filters
 *
 * TODO: need to clean up the use of a promise here,
 * either make use of redux with the actions
 * or just remove this entirely and just call
 * the service directly in the necessary component
 */
export const searchUnit = (
  text,
  page = 1,
  facultiesFilters = [],
  locationsFilters = [],
  teachingPeriodFilters = [],
  unitTypesFilters = [],
  creditPoints = [0, 48],
) => dispatch => {
  const filter = {};
  if (locationsFilters.length > 0) {
    filter.locations = locationsFilters;
  }
  if (facultiesFilters.length > 0) {
    filter.faculties = facultiesFilters;
  }
  if (teachingPeriodFilters.length > 0) {
    filter.teachingPeriods = teachingPeriodFilters;
  }
  if (unitTypesFilters.length > 0) {
    filter.unitTypes = unitTypesFilters;
  }
  filter.creditPoints = creditPoints;
  dispatch({ type: actions.FETCH_UNITS_PENDING });

  UnitService.searchUnits(text, filter)
    .then(res => {
      dispatch({
        type: actions.FETCH_UNITS_FULFILLED,
        payload: res,
      });
    })
    .catch(err => {
      dispatch({
        type: actions.FETCH_UNITS_REJECTED,
        error: err,
      });
    });
};
