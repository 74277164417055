import CoursePlanEvent from './CoursePlanEvent';
import CoursePlanEnquiryEvent from '../CoursePlanEnquiryEvent';
import CoursePlanFeedbackEvent from '../CoursePlanFeedbackEvent';
import CoursePlanStatusUpdateEvent from '../CoursePlanStatusUpdateEvent';

export default class CoursePlanEventFactory {
  static createEvent(data) {
    switch (data.eventType) {
      case CoursePlanEvent.TYPE().ENQUIRY:
        return CoursePlanEventFactory.createEnquiryEvent(data);
      case CoursePlanEvent.TYPE().FEEDBACK:
        return CoursePlanEventFactory.createFeedbackEvent(data);
      case CoursePlanEvent.TYPE().STATUS_UPDATE:
        return CoursePlanEventFactory.createStatusUpdateEvent(data);
      default:
        throw new Error(
          `CoursePlanEventFactory was given an event type it did not recognise, received ${
            data.eventType
          }`,
        );
    }
  }

  /**
   * {
      id: 9249419419254423,
      publicCaseId: '321',
      submissionState: ENQUIRY_STATUSES.ENQUIRY_PUBLIC_CASE_ID_AVAILABLE,
      snapshotId: 5679790782676992,
      timestamp: 1531353033244,
      message: 'Some message the student left',
      user: 'user name or authcate'
    },
   */
  static createEnquiryEvent(data) {
    return new CoursePlanEnquiryEvent({
      eventType: data.eventType,
      timestamp: data.timestamp,
      snapshotId: data.snapshotId,
      snapshotTimestamp: data.snapshotTimestamp,
      version: data.version,
      versionDelta: data.versionDelta,
      isCurrentVersion: data.isCurrentVersion,
      id: data.id,
      publicCaseId: data.publicCaseId,
      submissionState: data.submissionState,
      enquiryMessage: data.message,
      user: data.user,
    });
  }

  /**
   * {
        feedbackId: 5698390272770048,
        snapshotId: 5653625506365440,
        timestamp: 1531353033244,
        user: 'Darth V',
        snapshotFeedback: "",
      },
   */
  static createFeedbackEvent(data) {
    return new CoursePlanFeedbackEvent({
      eventType: data.eventType,
      timestamp: data.timestamp,
      snapshotId: data.snapshotId,
      snapshotTimestamp: data.snapshotTimestamp,
      version: data.version,
      versionDelta: data.versionDelta,
      isCurrentVersion: data.isCurrentVersion,
      feedbackId: data.feedbackId,
      user: data.user,
      feedbackText: data.snapshotFeedback,
    });
  }

  /**
   * {
        id: 172148079133696,
        coursePlanId: 5711898079133696,
        snapshotId: 5653625506365440,
        statusType: EVENT_STATUSES.FORCED_REVIEW,
        timestamp: 1546820238947,
        user: 'Darth V',
      }
   * 
   */
  static createStatusUpdateEvent(data) {
    return new CoursePlanStatusUpdateEvent({
      eventType: data.eventType,
      timestamp: data.timestamp,
      snapshotId: data.snapshotId,
      snapshotTimestamp: data.snapshotTimestamp,
      version: data.version,
      versionDelta: data.versionDelta,
      isCurrentVersion: data.isCurrentVersion,
      eventId: data.id,
      coursePlanId: data.coursePlanId,
      statusType: data.statusType,
      user: data.user,
    });
  }
}
