import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { fetchUnitInfo } from '../../../actions/UnitActions';

import { addUnit, beginAddingUnit } from '../../../actions/CourseActions';
import { CORE } from '../../../constants';
import {
  hideSidebar,
  hideUnitSelectSidebar,
  showUnitModal,
} from '../../../actions/UIActions';

// Components
import UnitDragPreview from '../UnitDragPreview';

/**
 * Returns the way in which we want a search result to be rendered
 * @author JXNS
 *
 * @param {string} UnitCode - The unit code associated with a result.
 * @param {string} UnitName - The unit name associated with a result.
 * @param {string} Faculty - The faculty associated with a result.
 * @param {bool} custom - Whether or not the unit is a custom unit.
 * @param {number} id - Index of search result.
 * @param {func} addUnit - Function that adds a unit to the course structure.
 * @param {object} unitToAdd - The unit to be added to the course.
 * @param {func} beginAddingUnit - Function that triggers add unit UI upon call.
 */
class UnitSearchResult extends Component {
  /**
   * Holds UI state related to the UnitMessage.
   */
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      /* Should be true iff user is hovering on the buttons */
      overInput: false,
    };
  }

  /**
   * Updates state to indicate that the user is hovering on the unit.
   */
  handleMouseEnter = () => {
    if (!this.state.hovering) {
      this.props.setSearchResultIndex(this.props.id);

      this.setState({
        hovering: true,
      });
    }
  };

  /**
   * Updates state to indicate that the user is hovering on the unit.
   */
  handleMouseMove = () => {
    if (!this.state.hovering) {
      this.setState({
        hovering: !this.state.overInput,
      });
    }
  };

  /**
   * Fetches unit details, then shows unit modal after hiding the sidebar.
   */
  handleInfoButtonClick(code, year) {
    this.props.fetchUnitInfo(code);
    this.props.hideSidebar();
    this.props.hideUnitSelectSidebar();
    const handBookUrl = CORE.EXTERNAL_LINKS.MONASH.HANDBOOK + "units/" + code;
    window.open(handBookUrl, '_blank');
    //this.props.showUnitModal(year);
  }

  /**
   * Updates state to indicate that the user is no longer hovering on the
   * unit.
   */
  handleMouseLeave = () => {
    if (this.state.hovering) {
      this.setState({
        hovering: false,
      });
    }
  };

  /**
   * Renders a UnitMessage component, which is draggable
   */
  render() {
    const {
      unitCode,
      unitName,
      creditPoints,
      faculty,
      id,
      unitToAdd,
      addUnit,
      beginAddingUnit,
      positionOfUnitToAdd,
      setSearchResultIndex,
      ariaResultMessage,
    } = this.props;

    return (
      <div style={{ padding: '0.4em 1.5em' }}>
        <UnitDragPreview
          newUnit
          draggable
          hovering={this.state.hovering}
          isSearchResult
          ariaResultMessage={ariaResultMessage}
          beginAddingUnit={beginAddingUnit}
          addUnit={addUnit}
          positionOfUnitToAdd={positionOfUnitToAdd}
          handleUnitFocus={() => setSearchResultIndex(this.props.id)}
          handleUnitMouseEnter={() => this.handleMouseEnter()}
          handleUnitMouseMove={() => this.handleMouseMove()}
          handleUnitMouseLeave={() => this.handleMouseLeave()}
          handleButtonMouseEnter={() => this.setState({ overInput: true })}
          handleButtonMouseLeave={() => this.setState({ overInput: false })}
          onInfoButtonClick={this.handleInfoButtonClick.bind(this)}
          code={unitCode}
          name={unitName}
          creditPoints={creditPoints}
          faculty={faculty}
          unitToAdd={unitToAdd}
          index={id}
        />
      </div>
    );
  }
}

UnitSearchResult.propTypes = {
  unitCode: PropTypes.string.isRequired,
  unitName: PropTypes.string.isRequired,
  creditPoints: PropTypes.number,
  faculty: PropTypes.string,
  unitToAdd: PropTypes.object,
  beginAddingUnit: PropTypes.func,
  setSearchResultIndex: PropTypes.func,
  addUnit: PropTypes.func,
  positionOfUnitToAdd: PropTypes.array,
  custom: PropTypes.bool,
  id: PropTypes.number.isRequired,

  /* Redux action creators */
  fetchUnitInfo: PropTypes.func,
  hideSidebar: PropTypes.func,
  showUnitModal: PropTypes.func,
};

/**
 * Inject fetchUnitInfo action creator into props.
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    fetchUnitInfo,
    addUnit,
    beginAddingUnit,
    hideSidebar,
    hideUnitSelectSidebar,
    showUnitModal,
  };

  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  null,
  mapDispatchToProps,
)(UnitSearchResult);
