const styles = theme => ({
  root: {
    backgroundColor: theme.custom.palette.misc.footer,
    color: theme.palette.common.white,
    justifyContent: 'center',
    display: 'flex',
    padding: '1em 1.5em',
    [theme.breakpoints.up('md')]: {
      padding: '1em',
    },
  },
  logoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inner: {
    width: 'auto',
    minWidth: 300,
    marginTop: '0.5em',
    [theme.breakpoints.up('md')]: {
      width: 800,
    },
  },
  lightFont: {
    fontWeight: 300,
  },
  monashLogo: {
    height: 48,
    margin: '8px 0',
  },
  monplanLogo: {
    height: 32,
    margin: '16px 0',
  },
});

export default styles;
