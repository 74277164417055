import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import CoursePlanService from '../../../services/CoursePlanService';
import EnquiryService from '../../../services/EnquiryService';
import { COLORS, CORE } from '../../../constants';

import styles from './styles';

const ENQUIRY_TYPE_OTHER = 'Other';
const LOCATIONS = ['Australia', 'Malaysia'];

class RequestEnquiryModal extends React.Component {
  defaultState = {
    // The faculty object, listing faculties and schools
    facultyResponse: {
      payload: undefined,
      isErrored: false,
      isLoading: false,
    },
    malaysiaSchoolsResponse: {
      payload: undefined,
      isErrored: false,
      isLoading: false,
    },
    enquiryTypesResponse: {
      payload: undefined,
      isErrored: false,
      isLoading: false,
    },
    // The enquiry object,
    enquiryResponse: {
      payload: undefined,
      isErrored: false,
      isLoading: false,
    },
    selectedLocationIndex: '', // or number, material-ui doesn't accept undefined
    selectedFacultyIndex: '', // or number, material-ui doesn't accept undefined
    selectedMalaysiaSchoolIndex: '',
    selectedSchoolIndex: '', // or number, material-ui doesn't accept undefined
    selectedEnquiryTypeIndex: '',
    message: '',
  };
  state = this.defaultState;

  // Prefer this over componentDidMount so that it only fires when the modal
  // itself opens
  componentDidUpdate = prevProps => {
    if (prevProps.open === false && this.props.open === true) {
      if (this.props.isAusStudent) {
        if (!this.props.isMalaysiaStudent) {
          // Automatically select location to be Australia
          this.setState({
            selectedLocationIndex: LOCATIONS.indexOf('Australia'),
          });
        }

        this.fetchAustralianFaculties();
      }

      // student could potentially be both Australian and Malaysian, in which case we do not specify location upfront
      if (this.props.isMalaysiaStudent) {
        if (!this.props.isAusStudent) {
          // Automatically select location to be Malaysia
          this.setState({
            selectedLocationIndex: LOCATIONS.indexOf('Malaysia'),
          });
        }

        this.fetchMalaysiaSchools();
      }

      this.fetchEnquiryTypes();
    }
  }

  fetchAustralianFaculties = () => {
    this.setState({
      facultyResponse: {
        payload: undefined,
        isLoading: true,
        isErrored: false,
      },
    });

    EnquiryService.getFaculties()
      .then(payload => {
        this.setState({
          facultyResponse: {
            payload,
            isLoading: false,
            isErrored: false,
          },
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          facultyResponse: {
            payload: undefined,
            isLoading: false,
            isErrored: true,
          },
        });
      });
  };

  fetchMalaysiaSchools = () => {
    this.setState({
      malaysiaSchoolsResponse: {
        payload: undefined,
        isLoading: true,
        isErrored: false,
      },
    });

    EnquiryService.getMalaysiaSchools()
      .then(payload => {
        this.setState({
          malaysiaSchoolsResponse: {
            payload,
            isLoading: false,
            isErrored: false,
          },
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          malaysiaSchoolsResponse: {
            payload: undefined,
            isLoading: false,
            isError: true,
          },
        });
      });
  };

  fetchEnquiryTypes = () => {
    EnquiryService.getEnquiryTypes()
      .then(payload => {
        this.setState({
          enquiryTypesResponse: {
            payload: [...payload, ENQUIRY_TYPE_OTHER],
            isLoading: false,
            isErrored: false,
          },
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          enquiryTypesResponse: {
            payload: undefined,
            isLoading: false,
            isErrored: true,
          },
        });
      });
  };

  handleUserInput = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCreateEnquiry = () => {
    const { selectedLocationIndex } = this.state;

    if (selectedLocationIndex !== '') {
      if (LOCATIONS[selectedLocationIndex] === 'Australia') {
        this.handleCreateEnquiryAustralia();
      } else if (LOCATIONS[selectedLocationIndex] === 'Malaysia') {
        this.handleCreateEnquiryMalaysia();
      } else {
        console.error(
          `Invalid location selected (LOCATIONS=${LOCATIONS}, ` +
            `selectedLocationIndex=${selectedLocationIndex})`,
        );
      }
    }
  };

  handleCreateEnquiryAustralia = () => {
    const {
      selectedFacultyIndex,
      selectedSchoolIndex,
      facultyResponse,
      message,
      selectedEnquiryTypeIndex,
      enquiryTypesResponse,
    } = this.state;

    const {
      snapshotId,
      teachingPeriods,
      advancedStanding,
      coursePlanName,
      onSuccess,
    } = this.props;

    let faculty = '';
    let school = null;

    if (selectedFacultyIndex !== '') {
      const tmp = facultyResponse.payload[selectedFacultyIndex];
      faculty = tmp.value;
      if (tmp.schools.length !== 0 && selectedSchoolIndex !== '') {
        school = tmp.schools[selectedSchoolIndex].name;
      }
    }

    // While it's not possible to request an enquiry without choosing a
    // faculty/school and adding a message, this will prevent any
    // requests going through without the mandatory facultyOrSchool
    if (faculty !== '') {
      this.setState({
        enquiryResponse: {
          payload: undefined,
          isLoading: true,
          isErrored: false,
        },
      });

      CoursePlanService.submitRequestForReviewAustralia(
        teachingPeriods,
        advancedStanding,
        snapshotId,
        coursePlanName,
        faculty,
        school,
        message,
        enquiryTypesResponse.payload[selectedEnquiryTypeIndex],
      )
        .then(payload => {
          this.setState({
            enquiryResponse: {
              payload: payload.enquiries,
              isLoading: false,
              isErrored: false,
            },
          });

          // certain things need to happen in parent component if the
          // enquiry is submitted successfully, such as resetting the save hooks
          // because the enquiry call involves a save
          if (onSuccess) {
            onSuccess(payload);
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({
            enquiryResponse: {
              payload: undefined,
              isLoading: false,
              isErrored: true,
            },
          });
        });
    }
  };

  handleCreateEnquiryMalaysia = () => {
    const {
      selectedMalaysiaSchoolIndex,
      malaysiaSchoolsResponse,
      message,
      selectedEnquiryTypeIndex,
      enquiryTypesResponse,
    } = this.state;

    const {
      snapshotId,
      teachingPeriods,
      advancedStanding,
      coursePlanName,
      onSuccess,
    } = this.props;

    let malaysiaSchool = '';

    if (selectedMalaysiaSchoolIndex !== '') {
      const tmp = malaysiaSchoolsResponse.payload[selectedMalaysiaSchoolIndex];
      malaysiaSchool = tmp.value;
    }

    // While it's not possible to request an enquiry without choosing a
    // school and adding a message, this will prevent any
    // requests going through without the mandatory malaysiaSchool
    if (malaysiaSchool !== '') {
      this.setState({
        enquiryResponse: {
          payload: undefined,
          isLoading: true,
          isErrored: false,
        },
      });

      CoursePlanService.submitRequestForReviewMalaysia(
        teachingPeriods,
        advancedStanding,
        snapshotId,
        coursePlanName,
        malaysiaSchool,
        message,
        enquiryTypesResponse.payload[selectedEnquiryTypeIndex],
      )
        .then(payload => {
          this.setState({
            enquiryResponse: {
              payload: payload.enquiries,
              isLoading: false,
              isErrored: false,
            },
          });

          // certain things need to happen in parent component if the
          // enquiry is submitted successfully, such as resetting the save hooks
          // because the enquiry call involves a save
          if (onSuccess) {
            onSuccess(payload);
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({
            enquiryResponse: {
              payload: undefined,
              isLoading: false,
              isErrored: true,
            },
          });
        });
    }
  };

  isAbleToSubmitEnquiry = () => {
    const {
      facultyResponse,
      malaysiaSchoolsResponse,
      message,
      selectedLocationIndex,
      selectedFacultyIndex,
      selectedMalaysiaSchoolIndex,
      selectedSchoolIndex,
      selectedEnquiryTypeIndex,
    } = this.state;

    // No chosen location
    if (selectedLocationIndex === '') return false;

    // Cannot read Malaysia schools for Malaysia student
    if (
      LOCATIONS[selectedLocationIndex] === "Malaysia" &&
      (malaysiaSchoolsResponse.payload === undefined ||
        malaysiaSchoolsResponse.isLoading ||
        malaysiaSchoolsResponse.isErrored)
    )
      return false;

    // Cannot read faculties and schools for Australia student
    if (
      LOCATIONS[selectedLocationIndex] === "Australia" &&
      (facultyResponse.payload === undefined ||
        facultyResponse.isLoading ||
        facultyResponse.isErrored)
    )
      return false;
    // No chosen faculty
    else if (selectedFacultyIndex === '' && selectedMalaysiaSchoolIndex === '')
      return false;
    // Faculty selected for Australia student, but there are schools and one is not selected
    else if (
      LOCATIONS[selectedLocationIndex] === "Australia" &&
      facultyResponse.payload[selectedFacultyIndex].schools.length > 0 &&
      selectedSchoolIndex === ''
    )
      return false;
    // Adding a custom message to give advisors any needed context
    else if (message.length === 0) return false;
    else if (selectedEnquiryTypeIndex === '') return false;

    return true;
  };

  renderLoadingForm = classes => (
    <div className={classes.loaderContainer}>
      <CircularProgress size={50} style={{ color: '#006cab' }} />
      <p>Loading enquiry form</p>
    </div>
  );

  renderCreatingEnquiry = classes => (
    <div className={classes.loaderContainer}>
      <CircularProgress size={50} style={{ color: '#006cab' }} />
      <p>Creating enquiry</p>
    </div>
  );

  renderSubmittedEnquiry = selectedLocationIndex => (
    <div>
      <h3>Your request has been submitted</h3>
      {LOCATIONS[selectedLocationIndex] === 'Australia' && (
        <p>
          MonPlan will connect you with a course adviser through Monash Connect.
          You'll receive an email confirming your request. A staff member from
          the relevant service area will respond to your enquiry within five
          business days.
        </p>
      )}
      {LOCATIONS[selectedLocationIndex] === 'Malaysia' && (
        <p>
          MonPlan will connect you with a Malaysia course adviser. You'll
          receive an email confirming your request. A staff member from the
          relevant service area will respond to your enquiry within five
          business days.
        </p>
      )}
      <p>
        You can't edit this course plan while waiting for feedback, but you can
        make a copy.
      </p>
    </div>
  );

  renderEnquiryUnavailable = () => (
    <div>
      <h3>Enquiry form unavailable</h3>
      <p style={{ color: COLORS.MISC.warning }}>
        It's not possible to create a course plan enquiry at the moment. Please
        check back later.
      </p>
    </div>
  );

  renderEnquiryError = () => (
    <div>
      <h3>Enquiry not submitted</h3>
      <p style={{ color: COLORS.MISC.warning }}>
        We encountered an error while submitting your enquiry. Please try again
        later.
      </p>
    </div>
  );

  renderEnquiryForm = () => {
    const {
      malaysiaSchoolsResponse,
      facultyResponse,
      enquiryResponse,
      enquiryTypesResponse,
      selectedLocationIndex,
      selectedFacultyIndex,
      selectedMalaysiaSchoolIndex,
      selectedSchoolIndex,
      selectedEnquiryTypeIndex,
    } = this.state;
    const { classes, isAusStudent, isMalaysiaStudent } = this.props;

    if (enquiryResponse.isLoading)
      // If an enquiry-creating request is still pending
      return this.renderCreatingEnquiry(classes);

    if (!enquiryResponse.isErrored && enquiryResponse.payload !== undefined)
      return this.renderSubmittedEnquiry(selectedLocationIndex);

    if (
      facultyResponse.isErrored ||
      malaysiaSchoolsResponse.isErrored ||
      enquiryTypesResponse.isErrored
    )
      // We are unable to get the required list data
      return this.renderEnquiryUnavailable();

    if (enquiryResponse.isErrored) return this.renderEnquiryError();

    if (
      (selectedLocationIndex === '' ||
        (LOCATIONS[selectedLocationIndex] === 'Australia' &&
          facultyResponse.payload !== undefined) ||
        (LOCATIONS[selectedLocationIndex] === 'Malaysia' &&
          malaysiaSchoolsResponse.payload !== undefined)) &&
      enquiryTypesResponse.payload !== undefined
    ) {
      // We got the list we are after
      return (
        <div>
          {isAusStudent && isMalaysiaStudent && (
            <FormControl className={classes.inputContainer}>
              <InputLabel>Select location to send enquiry to</InputLabel>
              <Select
                fullWidth
                value={selectedLocationIndex}
                name="selectedLocationIndex"
                onChange={this.handleUserInput}>
                {LOCATIONS.map((location, i) => (
                  <MenuItem key={i} value={i}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {LOCATIONS[selectedLocationIndex] === 'Australia' && (
            <>
              <FormControl className={classes.inputContainer}>
                <InputLabel>Select faculty to send enquiry to</InputLabel>
                <Select
                  fullWidth
                  value={selectedFacultyIndex}
                  name="selectedFacultyIndex"
                  onChange={this.handleUserInput}>
                  {facultyResponse.payload.map((faculty, i) => {
                    return (
                      <MenuItem key={i} value={i}>
                        {faculty.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {selectedFacultyIndex !== '' &&
                facultyResponse.payload[selectedFacultyIndex] &&
                facultyResponse.payload[selectedFacultyIndex].schools &&
                facultyResponse.payload[selectedFacultyIndex].schools.length >
                  0 && (
                  <FormControl className={classes.inputContainer}>
                    <InputLabel>
                      Select which faculty school to send enquiry to
                    </InputLabel>
                    <Select
                      fullWidth
                      value={selectedSchoolIndex}
                      name="selectedSchoolIndex"
                      onChange={this.handleUserInput}>
                      {facultyResponse.payload[
                        selectedFacultyIndex
                      ].schools.map((school, i) => {
                        return (
                          <MenuItem key={i} value={i}>
                            {school.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
            </>
          )}
          {LOCATIONS[selectedLocationIndex] === 'Malaysia' && (
            <FormControl className={classes.inputContainer}>
              <InputLabel>Select school to send enquiry to</InputLabel>
              <Select
                fullWidth
                value={selectedMalaysiaSchoolIndex}
                name="selectedMalaysiaSchoolIndex"
                onChange={this.handleUserInput}>
                {malaysiaSchoolsResponse.payload.map((school, i) => {
                  return (
                    <MenuItem key={i} value={i}>
                      {school.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          <FormControl className={classes.inputContainer}>
            <InputLabel>Select type of enquiry</InputLabel>
            <Select
              fullWidth
              value={selectedEnquiryTypeIndex}
              name="selectedEnquiryTypeIndex"
              onChange={this.handleUserInput}>
              {enquiryTypesResponse.payload.map((type, i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {enquiryTypesResponse.payload[selectedEnquiryTypeIndex] ===
            ENQUIRY_TYPE_OTHER && (
            <span>Please describe your query type below</span>
          )}
          <FormControl className={classes.inputContainer}>
            <TextField
              fullWidth
              label="Explain what you need help with"
              id="outlined-multiline-flexible"
              multiline
              rowsMax={6}
              rows={6}
              value={this.state.message}
              onChange={this.handleUserInput}
              name="message"
            />
          </FormControl>
        </div>
      );
    }

    return this.renderEnquiryUnavailable();
  };

  render() {
    const {
      open,
      onClose,
      classes,
      fullScreen,
      isAusStudent,
      isMalaysiaStudent,
    } = this.props;

    const {
      facultyResponse,
      malaysiaSchoolsResponse,
      enquiryTypesResponse,
      enquiryResponse,
    } = this.state;

    const submittingEnquiry = enquiryResponse.isLoading;
    const enquirySubmitted =
      !enquiryResponse.isErrored && enquiryResponse.payload !== undefined;
    const errorEncountered =
      facultyResponse.isErrored || enquiryResponse.isErrored;

    // if enquiry not submitted, actions are to cancel modal or submit the enquiry
    // once the enquiry is submitted or if an error is encountered all you can do is close
    const actions =
      enquirySubmitted || errorEncountered
        ? [
            <Button key="close-enquiry-button" onClick={onClose}>
              Close
            </Button>,
          ]
        : [
            <Button
              disabled={submittingEnquiry}
              onClick={onClose}
              key="cancel-enquiry-button">
              Cancel
            </Button>,
            <Button
              key="submit-enquiry-button"
              onClick={this.handleCreateEnquiry}
              disabled={!this.isAbleToSubmitEnquiry() || submittingEnquiry}
              className={
                this.isAbleToSubmitEnquiry() && !submittingEnquiry
                  ? classes.actionButton
                  : ''
              }>
              Submit
            </Button>,
          ];

    return (
      <Dialog
        fullScreen={fullScreen}
        classes={{ paper: classes.root }}
        open={open}
        onClose={onClose}>
        <DialogTitle classes={{ root: classes.title }}>
          Send your plan to a course adviser
        </DialogTitle>
        <DialogContent>
          {!enquirySubmitted && !errorEncountered && (
            <p>
              MonPlan is a planning tool only. It has not enrolled you in the
              units you selected. To enrol in units, go to the{' '}
              <a
                href={CORE.EXTERNAL_LINKS.MONASH.WES}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                style={{ color: 'rgba(0,0,0,0.87)' }}>
                Web Enrolment System (WES)
              </a>
              .
            </p>
          )}
          {(isAusStudent && facultyResponse.isLoading) ||
          (isMalaysiaStudent && malaysiaSchoolsResponse.isLoading) ||
          enquiryTypesResponse.isLoading
            ? this.renderLoadingForm(classes)
            : this.renderEnquiryForm()}
        </DialogContent>
        <DialogActions className={classes.actions}>{actions}</DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(RequestEnquiryModal));

RequestEnquiryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  coursePlanId: PropTypes.string.isRequired,
  isMalaysiaStudent: PropTypes.bool,
  isAusStudent: PropTypes.bool,
};
