import React from 'react';
import PropTypes from 'prop-types';

// Components
import InsertTeachingPeriodForm from '../../components/TeachingPeriod/InsertTeachingPeriodForm';

/**
 * This component is displayed when there are no teaching periods in the course
 * structure.
 */
const NoTeachingPeriodContainer = props => {
  const { viewOnly, screenWidth } = props;

  return (
    <div>
      <div
        style={{
          overflow: 'visible',
          textAlign: 'center',
          padding: '48px 24px',
        }}>
        <span
          style={{
            display: 'block',
            color: 'rgba(0,0,0,0.54)',
            fontFace: 'Roboto',
            fontSize: '16px',
            marginTop: '1.5em',
          }}>
          Your course plan is empty
        </span>
        <span
          style={{
            display: 'block',
            color: 'rgba(0,0,0,0.8)',
            fontFace: 'Roboto',
            fontSize: '24px',
            padding: '0.6em 0',
          }}>
          {(!viewOnly && 'Add your first teaching period below!') ||
            'No teaching periods'}
        </span>
        {!viewOnly && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: screenWidth < 900 ? 'column' : 'row',
              justifyContent: 'center',
              width: screenWidth < 900 ? '100%' : undefined,
            }}>
            <InsertTeachingPeriodForm screenWidth={screenWidth} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NoTeachingPeriodContainer;

NoTeachingPeriodContainer.propTypes = {
  viewOnly: PropTypes.bool,
  screenWidth: PropTypes.number,
};
