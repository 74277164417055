export const Faculty = {
  ART: 'facultyofarts',
  ADA: 'facultyofart,designandarchitecture',
  BUS: 'facultyofbusinessandeconomics',
  FIT: 'facultyofinformationtechnology',
  LAW: 'facultyoflaw',
  EDU: 'facultyofeducation',
  ENG: 'facultyofengineering',
  MHS: 'facultyofmedicine,nursingandhealthsciences',
  PHA: 'facultyofpharmacyandpharmaceuticalsciences',
  SCI: 'facultyofscience',
  ALL: 'facultyofall',
};

let FacultyKeys = {};
Object.keys(Faculty).forEach(facultyCode => {
  const facultyName = Faculty[facultyCode];
  FacultyKeys[facultyName] = facultyCode;
});

export { FacultyKeys };
