import * as actions from '../../actions';

const defaultState = {
  isLoading: false,
  error: false,
  data: null,
};

/**
 * Contains the core state that drives the course planning UI for the app.
 * Handles things like the moving, adding and swapping of units. Manipulation
 * of teaching periods, storage of course and unit info results etc.
 *
 * To aide readability some of the areas of state have been split into sub-reducers
 */
const CourseStructureReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSE_STRUCTURE_PENDING:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case actions.FETCH_COURSE_STRUCTURE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case actions.FETCH_COURSE_STRUCTURE_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default CourseStructureReducer;
