import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

// MUI Icons
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';

// Utils
import { COLORS } from '../../constants';

/**
 * This component generates the edit button, save or cancel buttons, and
 * the loader for an EditableTextField component, depending on the `editing`
 * and `loading` props passed. It can also be used a child in the
 * `TextFieldValidator`, where the save button will be disabled if the
 * `error` prop is passed as `true`.
 */
class EditingButtons extends React.Component {
  static propTypes = {
    ariaName: PropTypes.string,
    editing: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    onSubmitHandler: PropTypes.func.isRequired,
    onCancelHandler: PropTypes.func.isRequired,
  };

  render() {
    const {
      editing,
      loading,
      ariaName,
      error,
      onSubmitHandler,
      onCancelHandler,
      onStartEditHandler,
    } = this.props;

    if (loading) {
      return (
        <CircularProgress
          style={{ color: COLORS.SECONDARY.light, width: 24, height: 24 }}
        />
      );
    }

    return editing ? (
      <React.Fragment>
        <IconButton
          key="submit-edit"
          aria-label={`Submit ${ariaName} change`}
          style={{
            height: '30px',
            width: '30px',
            marginLeft: '0.2em',
            marginBottom: '0.1em',
          }}
          disabled={error}
          onClick={onSubmitHandler}>
          <DoneIcon style={{ fontSize: '0.8em' }} />
        </IconButton>
        <IconButton
          key="cancel-edit"
          aria-label={`Cancel ${ariaName} change`}
          style={{
            height: '30px',
            width: '30px',
            marginLeft: '0.2em',
            marginBottom: '0.1em',
          }}
          onClick={onCancelHandler}>
          <CancelIcon style={{ fontSize: '0.8em' }} />
        </IconButton>
      </React.Fragment>
    ) : (
      <IconButton
        key="edit"
        aria-label={`Edit ${ariaName}`}
        style={{
          height: '30px',
          width: '30px',
          marginLeft: '0.2em',
          marginBottom: '0.1em',
        }}
        onClick={onStartEditHandler}>
        <EditIcon style={{ fontSize: '0.8em' }} />
      </IconButton>
    );
  }
}

export default EditingButtons;
