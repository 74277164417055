import { COLORS } from '../constants';
import { FacultyKeys } from '../constants/faculty';

export default class FacultyUtils {
  static getFacultyColour(facultyStr) {
    if (!facultyStr) {
      return COLORS.FACULTY.default;
    }

    const normalisedStr = facultyStr.replace(/ /g, '').toLowerCase();
    if (COLORS.FACULTY[normalisedStr]) {
      return COLORS.FACULTY[normalisedStr];
    }

    return COLORS.FACULTY.default;
  }

  static getFacultyKey(facultyStr) {
    if (!facultyStr) {
      return '';
    }

    const normalisedStr = facultyStr.replace(/ /g, '').toLowerCase();
    if (FacultyKeys[normalisedStr]) {
      return FacultyKeys[normalisedStr];
    }

    return '';
  }
}
