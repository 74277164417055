import CoursePlanEvent from '../CoursePlanEvent';

export default class CoursePlanFeedbackEvent extends CoursePlanEvent {
  constructor({
    eventType,
    timestamp,
    snapshotId,
    snapshotTimestamp,
    version,
    versionDelta,
    isCurrentVersion,
    user,
    feedbackId,
    feedbackText,
  }) {
    super({
      eventType,
      timestamp,
      snapshotId,
      snapshotTimestamp,
      version,
      versionDelta,
      isCurrentVersion,
      user,
    });

    this.feedbackId = feedbackId;
    this.feedbackText = feedbackText;
  }

  /**
   * The id of the feedback entity that is associated
   * with this event
   */
  get feedbackId() {
    return this._feedbackId;
  }

  /**
   * The text that was provided as feedback
   */
  get feedbackText() {
    return this._feedbackText;
  }

  set feedbackId(feedbackId) {
    this._validateFeedbackId(feedbackId);
    this._feedbackId = feedbackId;
  }

  set feedbackText(feedbackText) {
    this._validateFeedbackText(feedbackText);
    this._feedbackText = feedbackText;
  }

  _validateFeedbackId(feedbackId) {
    if (!feedbackId) {
      throw new Error(
        `CoursePlanFeedbackEvent was not given a feedbackId value, received: ${feedbackId}`,
      );
    }
  }

  _validateFeedbackText(feedbackText) {
    if (!feedbackText) {
      throw new Error(
        `CoursePlanFeedbackEvent was not given a feedbackText value, received: ${feedbackText}`,
      );
    }
  }
}
