const CHARACTER_LIMITS = {
  COURSE_PLAN_NAME: 200,
  FEEDBACK: 1000,
  ANNOUNCEMENT_MESSAGE: 150,
};

const MAX_UNITS = 7;

export default {
  CHARACTER_LIMITS,
  MAX_UNITS,
};
