import UnitFactory from '../models/Unit/UnitFactory';

export default class EnrolmentUtils {
  // grades that are not considered fail grades which would invalidate requisite
  // for more information see: https://www.monash.edu/exams/results/results-legend
  static NON_FAIL_GRADES = [
    // pass grades
    'HD',
    'D',
    'C',
    'P',
    'PGO',
    'E',
    'FP',
    'HI',
    'HIIA',
    'HIIB',
    'HIII',

    // interim grades
    'DEF',
    'NS',
    'WH',
  ];

  static isNonFailGrade(grade) {
    return this.NON_FAIL_GRADES.includes(grade);
  }

  /**
   * This returns all the data needed to create a course structure
   * from enrolment data
   * @param {Array<UnitEnrolmentObj>} unitEnrolments
   * @param {Array<UnitInfoObj || Error>} unitInfoItems
   * @param {Array<AdvStandingObj>} advancedStandingItems
   * @param {String} currentCourseCode
   * @param {Array<Teaching period codes>} teachingPeriodOrder
   */
  static parseEnrolmentData(
    unitEnrolments,
    unitInfoItems,
    advancedStandingItems,
    currentCourseCode,
    teachingPeriodOrder,
  ) {
    // first we zip these units up so that their info is together
    let units = unitEnrolments.map((unitEnrolment, i) => {
      const currentUnitCode = unitEnrolment.unitCode;
      const unitInfoResp = unitInfoItems.find(
        unitInfoObj => unitInfoObj.unitCode === currentUnitCode,
      );

      /**
       * unitInfoItems is an array of the unit info that could be found by the api,
       * if a unit was not found, it will not be present in the array, so
       * we simply check for its presence above, then fallback below if
       * the object is not found
       */
      if (!unitInfoResp) {
        let faculty = '';
        let unitName = '';
        let creditPoints = null;

        if (unitEnrolment.fullResp.studyUnit) {
          if (unitEnrolment.fullResp.studyUnit.faculty) {
            faculty = unitEnrolment.fullResp.studyUnit.faculty.name || '';
          }

          unitName = unitEnrolment.fullResp.studyUnit.title || '';

          creditPoints =
            unitEnrolment.fullResp.studyUnit.maxCreditPoints || null;
        }

        const unitInfo = {
          unitCode: unitEnrolment.unitCode,
          unitName,
          faculty,
          creditPoints,
          unknown: true, // can be used later to identify units we cannot find unit info for
        };

        return {
          enrolment: unitEnrolment,
          info: unitInfo,
        };
      }

      return {
        enrolment: unitEnrolment,
        info: unitInfoResp,
      };
    });

    /**
     * Here we build out a courses object that serves to organise the units,
     * looks something like:
     * {
     *  "C2000": {
     *    years: {
     *      "2017": {
     *        "S2-01": [unit1, unit2, unit3... ]
     *      }
     *    }
     *  }
     * }
     */
    const courses = {};
    units.forEach(unit => {
      const courseCode = unit.enrolment.courseCode;
      const year = unit.enrolment.year;
      const tpCode = unit.enrolment.teachingPeriodCode;

      // a bunch of admin for ensuring the object is built correctly
      if (courses[courseCode] === undefined) {
        courses[courseCode] = {
          years: {},
        };
      }

      if (courses[courseCode]['years'][year] === undefined) {
        courses[courseCode]['years'][year] = {};
      }

      if (courses[courseCode]['years'][year][tpCode] === undefined) {
        courses[courseCode]['years'][year][tpCode] = [];
      }

      // Discontinued units are excluded from teaching periods
      if (unit.enrolment.status !== 'DISCONTIN') {
        courses[courseCode]['years'][year][tpCode].push(unit);
      }
    });

    /**
     * Either there were no unit enrolments on record, or
     * no unit enrolments matched the current course
     * in either case we just start with a blank course
     */
    if (units.length === 0 || courses[currentCourseCode] === undefined) {
      return {
        newTeachingPeriods: [],
        newAdvancedStanding: [],
      };
    }

    // extract out the current course
    const currentCourse = courses[currentCourseCode];
    // remove it from the other courses obj for simplicity later
    delete courses[currentCourseCode];

    let teachingPeriods = [];

    const sortedYearKeys = Object.keys(currentCourse.years).sort();
    sortedYearKeys.forEach(year => {
      const tpObject = currentCourse.years[year];

      /**
       * Teaching periods are just codes so they have no implicit sort,
       * the most robust thing we can do is sort them so that they appear in
       * the same order that they are returned from the teaching period API.
       *
       * NB: If the teaching period API ever decides that it will not return sorted
       * teaching periods then we will have to hard code that somewhere
       */
      const sortedTpKeys = Object.keys(tpObject).sort((a, b) => {
        return teachingPeriodOrder.indexOf(a) > teachingPeriodOrder.indexOf(b)
          ? 1
          : -1;
      });

      sortedTpKeys.forEach(tpCode => {
        const tpUnits = tpObject[tpCode];

        let shouldBeReadOnly = true;

        tpUnits.forEach(unit => {
          /**
           * This handles the case where a unit was brought over from
           * another degree, if so it is marked as DUPLICATE. A duplicate
           * unit does not contain the grade info, so we need to
           * do some processing to find the original record in the previous course.
           *
           * This code relies on the assumption that a unit will always appear in the
           * new course plan in the same teaching period and year as it did in the previous
           * degree.
           */
          if (unit.enrolment.status === 'DUPLICATE') {
            // we do not know which degree it is from so we check them all
            Object.keys(courses).forEach(courseCode => {
              const unitYear = courses[courseCode].years[unit.enrolment.year];
              if (unitYear && unitYear[unit.enrolment.teachingPeriodCode]) {
                const units = unitYear[unit.enrolment.teachingPeriodCode];
                units.forEach(tpUnit => {
                  if (tpUnit.enrolment.unitCode === unit.enrolment.unitCode) {
                    if (tpUnit.enrolment.status === 'COMPLETED') {
                      unit.enrolment.grade = tpUnit.enrolment.grade;
                    }
                  }
                });
              }
            });
          } else if (unit.enrolment.status === 'ENROLLED') {
            /**
             * preloaded units that are only 'enrolled' should not be read only,
             * as the student still has time to change and play around.
             * Technically the best solution would be to detect if the status is enrolled AND
             * we are before the census date, but this simpler solution was decided on
             * because it covers most use cases with minimal effort.
             **/
            shouldBeReadOnly = false;
          }
        });

        let tpProcessedUnits = tpUnits.map(unit =>
          UnitFactory.createUnit({
            ...unit.info,
            grade: unit.enrolment.grade,
          }),
        );

        const tpCreditPoints = tpUnits.reduce((prevCP, unit) => {
          if (unit.info && unit.info.creditPoints) {
            return (prevCP += 6);
          }

          return prevCP;
        }, 0);

        if (tpCreditPoints < 24) {
          const offset = (24 - tpCreditPoints) / 6;
          for (let i = 0; i < offset; i++) {
            tpProcessedUnits.push(null);
          }
        }

        teachingPeriods.push({
          year: parseInt(year, 10),
          code: tpCode,
          numberOfUnits: tpUnits.length,
          units: tpProcessedUnits,
          isPreloaded: shouldBeReadOnly,
        });
      });
    });

    // Only import granted advaneced standing items with the same course code
    const newAdvancedStanding = advancedStandingItems.filter(
      item =>
        item.courseCode === currentCourseCode &&
        item.grantingStatus === 'GRANTED',
    );

    return {
      newTeachingPeriods: teachingPeriods,
      newAdvancedStanding,
    };
  }

  static parseTimelineData(courseTimeline) {
    let teachingPeriods = [];
    let totalCP = 0;

    Object.values(courseTimeline.teachingPeriods).forEach(tpObj => {
      let units = tpObj.units;

      units.forEach(unit => {
        if (unit && unit.creditPoints) {
          totalCP += unit.creditPoints;
        }
      });

      const tpCreditPoints = units.reduce((prevCP, unit) => {
        if (unit && unit.creditPoints) {
          return (prevCP += 6);
        }

        return prevCP;
      }, 0);

      if (tpCreditPoints < 24) {
        const offset = (24 - tpCreditPoints) / 6;
        for (let i = 0; i < offset; i++) {
          units.push(null);
        }
      }

      teachingPeriods.push({
        year: tpObj.year,
        code: tpObj.teachingPeriodCode,
        numberOfUnits: units.length,
        units,
        isPreloaded: false,
      });
    });

    return {
      newTeachingPeriods: teachingPeriods,
      newCP: totalCP,
    };
  }
}
