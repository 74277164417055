import * as actions from '../../actions';

const defaultState = {
  showingMovingUnitUI: false, // Indicates whether we are currently showing the moving unit view
  showingAddingUnitUI: false, // Indicates whether we are currently showing the adding unit view
  readOnly: false, // Indicates whether we are displaying the course structure in a read only manner
  showingConfirmDeleteTeachingPeriodModal: false, // Whether or not the confirm delete teaching period modal is being displayed
  showingCustomUnitModal: false, // Whether or not the create custom unit modal is being displayed
  showingConfirmDecreaseStudyLoadModal: false, // Whether or not the confirm decrease study load modal is being displayed
  showingAboutModal: false, // Whether or not the about modal is being displayed
  showingSidebar: false, // Whether we are showing the sidebar currently
  showingPalette: false, // Whether we are shoing the palette currently,
  showingUnitInfoModal: false,
  showingOverrideExistingPlanModal: false, // Whether or not the override existing plan modal should be displayed
  showingAddFeedback: false,
  showingActionDrawer: false,
  showingGrades: true,
  showingValidationModal: false,
  showingUnitSelectSidebar: false,
  currentAOSName: '',
  showingRequiredUnits: false,
  showPreferDesktopBanner: true, // Shows a notification on mobile devices to suggest using the desktop app
  selectedYear: '', // Get year from selected teaching period when showing the unit modal
};

/**
 * @author JXNS
 * A simple reducer to handle UI state globally.
 */
const UIReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.ADDING_UNIT:
      return {
        ...state,
        showingAddingUnitUI: true,
        showingSidebar: false,
        showingUnitSelectSidebar: false,
      };

    case actions.ADD_UNIT:
    case actions.CANCEL_ADDING_UNIT:
      return {
        ...state,
        showingAddingUnitUI: false,
      };

    case actions.MOVING_UNIT:
      return {
        ...state,
        showingMovingUnitUI: true,
      };

    case actions.MOVE_UNIT:
    case actions.SWAP_UNIT:
    case actions.CANCEL_MOVING_UNIT:
      return {
        ...state,
        showingMovingUnitUI: false,
      };

    case actions.SET_COURSE_READ_ONLY:
      return {
        ...state,
        readOnly: true,
      };

    case actions.SET_COURSE_READ_AND_WRITE:
      return {
        ...state,
        readOnly: false,
      };

    case actions.SHOW_CONFIRM_DELETE_TEACHING_PERIOD_MODAL:
      return {
        ...state,
        showingConfirmDeleteTeachingPeriodModal: true,
      };

    case actions.HIDE_CONFIRM_DELETE_TEACHING_PERIOD_MODAL:
      return {
        ...state,
        showingConfirmDeleteTeachingPeriodModal: false,
      };

    case actions.SHOW_CONFIRM_DECREASE_STUDY_LOAD_MODAL:
      return {
        ...state,
        showingConfirmDecreaseStudyLoadModal: true,
        selectedTeachingPeriodIndex: action.teachingPeriodIndex,
      };

    case actions.HIDE_CONFIRM_DECREASE_STUDY_LOAD_MODAL:
      return {
        ...state,
        showingConfirmDecreaseStudyLoadModal: false,
      };

    case actions.SHOW_CUSTOM_UNIT_MODAL:
      return {
        ...state,
        showingCustomUnitModal: true,
        customUnitCode: action.unitCode,
      };

    case actions.HIDE_CUSTOM_UNIT_MODAL:
      return {
        ...state,
        showingCustomUnitModal: false,
      };

    case actions.SHOW_ABOUT_MODAL:
      return {
        ...state,
        showingAboutModal: true,
      };

    case actions.HIDE_ABOUT_MODAL:
      return {
        ...state,
        showingAboutModal: false,
      };

    case actions.SHOW_SIDEBAR:
      if (
        typeof action.teachingPeriodIndex === 'number' &&
        typeof action.unitIndex === 'number'
      ) {
        return {
          ...state,
          showingSidebar: true,
        };
      }

      return {
        ...state,
        showingSidebar: true,
      };

    case actions.HIDE_SIDEBAR:
      return {
        ...state,
        showingSidebar: false,
      };

    case actions.HIDE_UNIT_SELECT_SIDEBAR:
      return {
        ...state,
        showingUnitSelectSidebar: false,
      };

    case actions.SHOW_UNIT_SELECT_SIDEBAR:
      return {
        ...state,
        showingUnitSelectSidebar: true,
        showingRequiredUnits: action.showingRequiredUnits,
      };

    case actions.TOGGLE_PALETTE:
      return {
        ...state,
        showingPalette: !state.showingPalette,
      };

    case actions.SHOW_UNIT_MODAL:
      return {
        ...state,
        selectedYear: action.year,
        showingUnitInfoModal: true,
      };

    case actions.HIDE_UNIT_MODAL:
      return {
        ...state,
        showingUnitInfoModal: false,
      };

    case actions.SHOW_ADD_FEEDBACK_UI:
      return {
        ...state,
        showingAddFeedback: true,
      };

    case actions.HIDE_ADD_FEEDBACK_UI:
      return {
        ...state,
        showingAddFeedback: false,
      };

    case actions.HIDE_GRADES:
      return {
        ...state,
        showingGrades: false,
      };

    case actions.SHOW_GRADES:
      return {
        ...state,
        showingGrades: true,
      };

    case actions.SHOW_ACTION_DRAWER:
      return {
        ...state,
        showingActionDrawer: true,
      };

    case actions.HIDE_ACTION_DRAWER:
      return {
        ...state,
        showingActionDrawer: false,
      };

    case actions.SHOW_VALIDATION_MODAL:
      return {
        ...state,
        showingValidationModal: true,
      };

    case actions.HIDE_VALIDATION_MODAL:
      return {
        ...state,
        showingValidationModal: false,
      };

    case actions.SET_CURRENT_AOS_NAME:
      return {
        ...state,
        currentAOSName: action.aosName,
      };

    case actions.HIDE_PREFER_DESKTOP_NOTIFICATION:
      return {
        ...state,
        showPreferDesktopBanner: false,
      };

    default:
      return state;
  }
};

export default UIReducer;
