export { store, history } from './store';
export { default as theme } from './theme';
export { default as routes } from './routes';
export { default as releaseNotes } from './release';

const domains = {
  PRODUCTION: 'monplan.apps.monash.edu',
  QA: 'monplan-qa.apps.monash.edu',
  UAT: 'monplan-au-uat.appspot.com',
};

const GANALYTICS_TRACKING = {
  PRODUCTION: 'UA-88744252-2',
  UAT: 'UA-88744252-3',
  QA: 'UA-88744252-4',
  OTHER: 'UA-88744252-1',
};

export const getGoogleAnalyticsTrackingCode = domain => {
  switch (domain) {
    case domains.PRODUCTION:
      return GANALYTICS_TRACKING.PRODUCTION;
    case domains.QA:
      return GANALYTICS_TRACKING.QA;
    case domains.UAT:
      return GANALYTICS_TRACKING.UAT;
    default:
      return GANALYTICS_TRACKING.OTHER;
  }
};
