const styles = theme => ({
  feedbackItemContainer: {
    display: 'flex',
    margin: '20px 0',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  feedbackItemTimestamp: {
    fontWeight: 'bold',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      marginRight: '40px',
    },
  },
  feedbackItemText: {
    margin: 0,
    marginTop: '5px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
  },
  feedbackItemQuotedText: {
    fontStyle: 'italic',
  },
  feedbackTextInput: {
    marginBottom: '10px',
    width: '100%',
    paddingRight: '20px',
  },
  feedbackButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expandCollapseButton: {
    width: '100%',
  },
  feedbackContainer: {
    padding: '10px 20px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.64)',
  },
  errorText: {
    color: '#c62828',
  },
  addFeedbackButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  validationHelperText: {
    textAlign: 'right',
  },
});

export default styles;
