// Actions
import * as actions from './index';

/**
 * Displays the given message as a notification with the given
 * duration in ms, if no duration is given it will default
 * to 3s
 */
export const displayNotification = (message, duration) => {
  return {
    type: actions.DISPLAY_NOTIFICATION,
    message,
    duration: duration ? duration : 3000,
  };
};

/**
 * Used to hide a notification
 */
export const hideNotification = () => {
  return {
    type: actions.HIDE_NOTIFICATION,
  };
};
