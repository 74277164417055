/**
 * Returns a list of units from the course plan given a list of teaching
 * periods.
 *
 * @author Saurabh Joshi
 * @param {object} teachingPeriods - List of teaching periods containing the
 * units.
 * @returns {array}
 */
export function getListOfUnits(teachingPeriods) {
  return teachingPeriods
    .map(
      (ele, teachingPeriodIndex) =>
        ele.units
          .map((unit, unitIndex) => {
            if (unit && !unit.placeholder) {
              // Placeholder units should not be processed
              return {
                ...unit,
                teachingPeriodIndex,
                teachingPeriodCode: ele.code,
                teachingPeriodYear: ele.year,
                unitIndex,
              };
            }

            return null;
          })
          .filter(unit => unit), // Remove free and placeholder units
    )
    .reduce((units, list) => units.concat(list), []); // Flatten matrix into a single dimensional array
}

/**
 * Parses offerings from CUPID data extract
 */
export function parseOfferings(offeringsString) {
  // location and mode mapping
  const locationMapping = {
    CLAYTON: 'Clayton',
    CAULFIELD: 'Caulfield',
    BERWICK: 'Berwick',
    PARKVILLE: 'Parkville',
    PENINSULA: 'Peninsula',
    GIPPSLAND: 'Gippsland',
    CITY: 'City (Melbourne)',
    SAFRICA: 'South Africa',
    MALAYSIA: 'Malaysia',
    PRATO: 'Prato',
  };

  const modeMapping = {
    'ON-CAMPUS': 'On-campus',
    'OTHER-OS DAY-OFF': 'Off-campus Day',
    'EXAMOUA EXAMOUA': 'Examoua',
    ONLINE: 'Online',
  };

  // We first need to split offeringsString into individual offerings, and store that in an array.
  // For some reason, there are unnecessary newlines, which is why we need to use regex.
  const offeringsArray = [];

  if (typeof offeringsString !== 'string') {
    // console.warn('offeringsString is not a string. Value is:', offeringsString)
    // console.warn('Interpreting it as empty offerings array')
    return offeringsArray;
  }

  let index = 0;

  while (index < offeringsString.length) {
    const firstMatch = offeringsString
      .slice(index)
      .match(/[0-9]{4} [A-Za-z0-9\- \n]*:[A-Z\- \n]*/);

    if (firstMatch != null) {
      offeringsArray.push(firstMatch[0].replace('\n', ''));
      index += firstMatch.index + firstMatch[0].length;
    } else {
      offeringsArray[offeringsArray.length - 1] += offeringsString
        .slice(index)
        .replace('\n', '');
      break;
    }
  }

  return offeringsArray.map(offer => {
    const [time, locationAndMode] = offer.split(':');
    const year = parseInt(time.split(' ')[0], 10);
    const teachingPeriodString = time
      .split(' ')
      .slice(1)
      .join(' ');

    const teachingPeriodToCodeMapping = {
      '1': 'S1-01',
      '2': 'S2-01',
      FY: 'FY-01',
      'SA-02': 'SSA-02',
      SB: 'SSB-01',
      W: 'WS-01',
    };

    let location = undefined,
      mode = undefined;

    if (typeof locationAndMode === 'string') {
      for (let key in locationMapping) {
        if (locationAndMode.match(key)) {
          location = locationMapping[key];
          break;
        }
      }

      for (let key in modeMapping) {
        if (locationAndMode.match(key)) {
          mode = modeMapping[key];
          break;
        }
      }
    }

    return {
      year,
      code:
        teachingPeriodToCodeMapping[
          teachingPeriodString.replace('Sem', '').trim()
        ] || teachingPeriodString.replace('Sem', '').trim(),
      location,
      mode,
    };
  });
}

/**
 * Given teaching period code, either returns teaching period string, or
 * "this teaching period" as the default string.
 *
 * @param {*} code
 */
export function teachingPeriodCodeToString(code) {
  const codeMap = {
    'FY-01': 'Full year',
    'S1-01': 'First semester',
    'S2-01': 'Second semester',
    'SSA-02': 'Summer semester A',
    'SSB-01': 'Summer semester B',
    'WS-01': 'Winter semester',
  };

  return codeMap[code] || 'this teaching period';
}
