import * as actions from '../../actions';

export const defaultState = {
  data: null, // Eventually becomes the array of teaching periods that are offered at Monash
  isLoading: false, // Indicates whether the teaching period dat is loading
  error: false, // Indicates whether there was an error loading the teaching periods
};

/**
 * @author JXNS
 * The periods reducer handles the state surrounding the inital load of a list of
 * teaching periods offered at Monash University. It is a fairly simple reducer as it just handles a single fetch cycle of
 * a piece of data, and you see that reflected below in the Pending, fulfilled and rejected cases
 */
const TeachingPeriodsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_TEACHING_PERIODS_PENDING:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case actions.FETCH_TEACHING_PERIODS_FULFILLED:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case actions.FETCH_TEACHING_PERIODS_REJECTED:
      return {
        ...state,
        data: null,
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default TeachingPeriodsReducer;
