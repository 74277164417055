import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

function FileDropInner({ icon, message }) {
  return (
    <div style={styles.core}>
      {icon}
      <h3>{message}</h3>
    </div>
  );
}

FileDropInner.propTypes = {
  icon: PropTypes.node.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default FileDropInner;
