import React, { Component } from 'react';
import { Link } from 'react-router';

// MUI Components
import { Button, Typography, withStyles } from '@material-ui/core';

// Constants
import { CORE } from '../constants';

// Components
import Header from '../components/Base/Header';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: `${4 * theme.spacing.unit}px 0`,
  },
  disclaimer: {
    maxWidth: 800,
    padding: 20,
    margin: 'auto',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
});

class Landing extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Header title="Disclaimer" />
        <div className={classes.root}>
          <Typography variant="display3" align="center" gutterBottom>
            MonPlan Disclaimer
          </Typography>
          <Typography
            variant="subheading"
            align="center"
            classes={{ root: classes.disclaimer }}
            gutterBottom>
            {CORE.GENERAL.DISCLAIMER}
          </Typography>
          <Button
            fullWidth
            variant="raised"
            color="primary"
            component={Link}
            to="/"
            classes={{ root: classes.button }}>
            Return to the Homepage
          </Button>
          <Button
            fullWidth
            variant="raised"
            color="error"
            component="a"
            href={CORE.EXTERNAL_LINKS.MONPLAN.ISSUE}
            classes={{ root: classes.button }}>
            Report an Issue
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Landing);
