import {
  MOCK_MAINTENANCE,
  MOCK_ENQUIRY_TYPE,
  MOCK_HISTORY_MAINTENANCE,
} from './data/mockData';

import NetworkService from './NetworkService';
import CoreUtils from '../utils/CoreUtils';

import { MOCK_RESPONSE_TIME } from './constants';
import { MOCK_CREATE_NOTIFICATIONS } from './data/notifications';

/**
 * Responsible for Managing the Application
 * @author Eric Jiang
 */
export default class AdminService {
  /**
   * MAINTENANCE STATUS
   */
  static getCurrentMaintenanceStatus() {
    const requestURI = `${window.location.origin}/api/admin/maintenance`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminService.getCurrentMaintenanceStatus()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_MAINTENANCE,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static updateMaintenanceInfo(pageTitle, pageDescription, enabled) {
    const requestURI = `${window.location.origin}/api/admin/maintenance`;

    const requestBody = {
      pageTitle,
      pageDescription,
      enabled,
    };
    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminService.updateMaintenanceInfo()',
        requestURI,
        'PUT',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_MAINTENANCE,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.put(requestURI, requestBody);
  }

  /**
   * ENQUIRY TYPES
   * managing the list of enquiry types
   */
  static getEnquiryTypeStatus() {
    const requestURI = `${window.location.origin}/api/admin/enquiries/types`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminService.getEnquiryTypeStatus()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_ENQUIRY_TYPE,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static updateEnquiryTypes(types) {
    const requestURI = `${window.location.origin}/api/admin/enquiries/types`;

    const requestBody = types;
    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminService.updateMaintenanceInfo()',
        requestURI,
        'PUT',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_ENQUIRY_TYPE,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.put(requestURI, requestBody);
  }

  /**
   * CONFIGURATION HISTORY
   * @param type: configuration type
   * @returns {*}
   */

  static getHistoryForMaintenanceType(type) {
    const requestURI = `${window.location.origin}/api/admin/${type}/history`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminService.getHistoryForMaintenanceType()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_HISTORY_MAINTENANCE,
        MOCK_RESPONSE_TIME,
      );
    }
    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  /**
   * Creates a new Notification
   * @param {*} date
   * @param {*} message
   */
  static createNewNotification(date, message) {
    const body = null;
    const requestURI = `${
      window.location.origin
    }/api/notifications?expiryDateTime=${date}&message=${message}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'AdminService.createNewNotification()',
        requestURI,
        'POST',
        body,
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_CREATE_NOTIFICATIONS,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }
}
