import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Containers
import CourseInfoContainer from '../../../containers/CourseInfoContainer';

/**
 * Component that shows a small selection of course info at the top of the page so that users
 * are aware of what course thet are looking at
 */
class CourseOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCourseInfoModalOpen: false,
    };

    this.startingEl = null;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.courseSelected && this.props.courseSelected) {
      if (this.startingEl) {
        this.startingEl.focus();
      }
    }
  }

  setCourseInfoModalClosed = () => {
    this.setState({ isCourseInfoModalOpen: false });
  };

  setCourseInfoModalOpen = () => {
    this.setState({ isCourseInfoModalOpen: true });
  };

  render() {
    const {
      courseName,
      courseCode,
      courseFaculty,
      creditPoints,
      requiredCreditPoints,
      courseSelected,
      isError,
      weightedAverageMark,
      showGrades,
    } = this.props;

    const { isCourseInfoModalOpen } = this.state;

    // Make sure screen reader reads out course code one by one
    const accessibleCourseCode =
      courseSelected && courseCode.split('').join(' ');

    let courseInfo = null;
    if (isError) {
      courseInfo = (
        <div className="flex-item-lg flexcol">
          <div className="flex-item-lg">
            <h3 style={{ margin: 0, width: '100%' }} className="heavy-text">
              Course Information Unavailable
            </h3>
          </div>
          <div className="flex-item-lg">
            <h4 style={{ margin: 0, width: '100%' }} className="light-text">
              The course may be too old or our servers could be experiencing
              difficulties
            </h4>
          </div>
        </div>
      );
    } else if (courseSelected) {
      courseInfo = (
        <div className="flex-item-lg flexcol">
          <div
            className="flex-item-lg"
            ref={el => {
              if (el) {
                this.startingEl = el;
              }
            }}>
            <h3
              style={{ margin: 0, width: '100%' }}
              aria-label={`${accessibleCourseCode} - ${courseName}`}
              className="heavy-text">
              {`${courseCode} - ${courseName}`}
            </h3>
          </div>
          <div className="flex-item-lg">
            <h4 style={{ margin: 0, width: '100%' }} className="light-text">
              {courseSelected && `Managing Faculty: ${courseFaculty}`}
            </h4>
          </div>
        </div>
      );
    } else {
      courseInfo = (
        <div className="flex-item-lg flexcol">
          <div className="flex-item-lg">
            <h3 style={{ margin: 0, width: '100%' }} className="heavy-text">
              No course selected
            </h3>
          </div>
          <div className="flex-item-lg">
            <h4 style={{ margin: 0, width: '100%' }} className="light-text">
              Could not find a course with course code attached to this plan
            </h4>
          </div>
        </div>
      );
    }

    const wamFloat = parseFloat(weightedAverageMark).toFixed(2);

    return (
      <div tabIndex="0" className="flexcol" style={{ margin: 0 }}>
        <CourseInfoContainer
          open={isCourseInfoModalOpen}
          onClose={this.setCourseInfoModalClosed}
        />
        <div style={{ padding: '0.5em 1em' }} className="flexrow">
          {courseInfo}
          <div className={'flex-item-lg'}>
            {// hide the credit points block if there are no units to receive credit for
            // otherwise, small mobile interfaces will be too cluttered
            creditPoints > 0 && (
              <div className="flex-item-sm flexcol">
                <p
                  aria-label={
                    courseSelected && requiredCreditPoints
                      ? `${creditPoints} out of ${requiredCreditPoints}`
                      : `${creditPoints}`
                  }
                  className="light-text left-align-text"
                  style={{ margin: 0 }}>
                  {courseSelected && requiredCreditPoints
                    ? `${creditPoints}/${requiredCreditPoints}`
                    : `${creditPoints}`}
                </p>

                <p
                  className="light-text left-align-text"
                  style={{
                    margin: 0,
                    whiteSpace: 'nowrap',
                  }}>
                  Credit Points
                </p>
              </div>
            )}
            {weightedAverageMark && showGrades && (
              <div className="flex-item-sm flexcol">
                <p
                  className="light-text left-align-text"
                  style={{ margin: 0 }}
                  aria-label={`Your Weighted Average Mark (WAM) is ${wamFloat}`}>
                  {wamFloat}
                </p>
                <p
                  className="light-text left-align-text"
                  style={{
                    margin: 0,
                    whiteSpace: 'nowrap',
                  }}>
                  WAM
                </p>
              </div>
            )}
            {courseSelected && (
              <div className="flex-item-sm flexcol right-align-text">
                <Button onClick={this.setCourseInfoModalOpen}>
                  Course Information
                </Button>
              </div>
            )}
          </div>
        </div>
        <LinearProgress
          variant="determinate"
          value={
            courseSelected
              ? Math.floor((creditPoints / requiredCreditPoints) * 100)
              : 0
          }
        />
      </div>
    );
  }
}

export default CourseOverview;

CourseOverview.propTypes = {
  readOnly: PropTypes.bool,
  courseSelected: PropTypes.bool,
  courseName: PropTypes.string,
  courseCode: PropTypes.string,
  courseFaculty: PropTypes.string,
  creditPoints: PropTypes.number,
  requiredCreditPoints: PropTypes.number,
  weightedAverageMark: PropTypes.string,
  showGrades: PropTypes.bool,
};
