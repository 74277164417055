const constants = {
  requirements: {
    COURSE_COMPLETION: 'Course Completion Requirements',
    TEACHING_PERIOD: 'Teaching Period Requirements',
    UNIT: 'Unit Requirements',
  },
  title: {
    VALIDATION_DETAILS: 'Validation Details',
    VALIDATION_LOADING: 'Validating Course Plan',
  },
  summary: {
    REQUIREMENTS_SATISFIED: 'All requirements satisfied!',
  },
};

export default constants;
