import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory, Link } from 'react-router';

// MUI Components
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// MUI Icons
import { Add as NewPlanIcon } from '@material-ui/icons';

// Actions
import { hideActionDrawer } from '../actions/UIActions';
import {
  updateStudentId,
  updateStudentName,
} from '../actions/CoursePlanActions';
import { displayNotification } from '../actions/NotificationActions';

// Services/Utils
import UserService from '../services/UserService';
import CoursePlanService from '../services/CoursePlanService';
import CoursePlanOrdering from '../utils/CoursePlanOrdering';
import TeachingPeriodUtils from '../utils/TeachingPeriodUtils';
import HandbookService from '../services/HandbookService';

// Constants
import { ROUTES, STATUSES } from '../constants';

// Components
import Header from '../components/Base/Header';
import Loader from '../components/Base/Loader';
import CourseMapSummary from '../components/Course/CourseMapSummary';
import MiniProfile from './MiniProfile';

// Styling
import { withStyles } from '@material-ui/core/styles';

// noinspection JSDuplicatedDeclaration
const styles = theme => ({
  miniProfileView: {
    maxWidth: 1000,
    minWidth: '300px',
    boxSixing: 'border-box',
    width: '100%',

    padding: '16px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      flex: '1',
      /* eslint-disable */
      position: '-webkit-sticky',
      position: 'sticky',
      /* eslint-enable */
      top: 64,
      alignSelf: 'flex-start',
    },
  },
  mobileFlex: {
    display: '',
    background: '#eee',
    minHeight: '100vh',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  plans: {
    width: '100%',
    padding: '16px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '',
      flex: '4',
    },
  },
});
/**
 * The list plan component displays the course plans a student has
 * associated with their account, to achieve this - we need to
 * query the api for the courseplans associated with an account,
 * as well as the course info and version history if a user requests it
 */
class ListPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coursePlansLoading: false,
      isError: false,
      coursePlans: [], // array of course maps we retrieve from the api
      courseInfo: {}, // where we store the course info responses as we receive them
      courseSnapshots: {}, // where we store the course snapshot histories when we receive them
      studentInfo: {}, // where we store the student info
    };
  }

  /**
   * On mount we query for all the course plans associated with the account,
   * displaying generic loading UI until we receive them
   */
  componentDidMount() {
    const { params, user, currentStudentId, updateStudentId } = this.props;
    const { studentId } = params;

    if (studentId && user.isStudent && !user.isMsaStudent) {
      // browserHistory.push('/');
    }

    this.setState({ coursePlansLoading: true, studentProfileLoading: true });
    if (user.isStaff) {
      // if (window.location.pathname === ROUTES.LIST_PLANS) {
      //   browserHistory.push('/');
      // }
      if (studentId !== currentStudentId) {
        updateStudentId(studentId);
      }
      this.updateStudentDetails(studentId);
    }

    if (user.isStudent) {
      CoursePlanService.getAllCoursePlans()
        .then(resp => this.extractCoursePlanListFromResponse(resp))
        .catch(err => {
          this.setState({
            coursePlansLoading: false,
            isError: true,
          });
          console.error(err);
        });
    } else {
      // ADVISOR VIEW
      CoursePlanService.getCoursePlansByStudentId(studentId)
        .then(resp => {
          this.extractCoursePlanListFromResponse(resp);
          return resp;
        })
        .then(data => {
          /**
           * Plans found, but user not created. We update the student profile card
           * updateStudentId if student profile doesn't exist. (code 11: "OK. Created user as no user exists")
           */
          if (data.status && data.status.code === 11) {
            this.updateStudentDetails(studentId);
          }
        })
        .catch(err => {
          this.setState({
            coursePlansLoading: false,
            isError: true,
          });
          console.error(err);
        });
    }
  }

  // Update Student Details to the Profile Card
  updateStudentDetails = studentId => {
    UserService.getStudentDetails(studentId).then(data => {
      this.setState({ studentInfo: data });
      if (data.firstName && data.surname) {
        this.props.updateStudentName(`${data.firstName} ${data.surname}`);
      }
    });
  };

  extractCoursePlanListFromResponse = resp => {
    const { user } = this.props;
    let coursePlans = resp.items;
    if (user.isAusAdvisor || user.isMalaysiaStaff || user.isMonashConnect) {
      coursePlans = CoursePlanOrdering.sortForAdvisorView(coursePlans);
    }

    /**
     * Course plan summary cards have an option to see their version histories,
     * this step initialises the state so that every course plan id exists as a key
     * within the courseSnapshots state object.
     *
     * These objects are initialised to { isLoading: false, data: null }
     * so that we can track their loading state and data if the user clicks the
     * 'show version history' buttons. The reason we don't just load all of these by default
     * is it would introduce another potentially lengthy API call, so instead we add
     * extra logic here so that users only load it if they request it
     */
    const coursePlanIds = coursePlans.map(
      courseMap => courseMap.coursePlan.coursePlanId,
    );
    const courseSnapshotObj = {};
    coursePlanIds.forEach(coursePlanId => {
      courseSnapshotObj[coursePlanId] = { isLoading: false, data: null };
    });

    /**
     * Here we get the set of unique course codes across all the course maps,
     * we then go through and initialise a data object for each, and start
     * fetching the course info.
     *
     * The course info requests are handled independently and not as a Promise.all
     * because there is no need to block the UI
     */
    const courseCodes = coursePlans.map(
      courseMap => courseMap.coursePlan.courseCode,
    );
    const uniqueCourseCodes = [...new Set(courseCodes)];
    const courseInfoObj = {};
    uniqueCourseCodes.forEach(courseCode => {
      courseInfoObj[courseCode] = { isLoading: true, data: null, error: null };
      this.fetchCourseInfo(courseCode);
    });

    this.setState({
      coursePlans: coursePlans,
      coursePlansLoading: false,
      courseInfo: courseInfoObj,
      courseSnapshots: courseSnapshotObj,
    });
  };

  /**
   * Handles the fetching and processing of an individual course info request
   */
  fetchCourseInfo(courseCode) {
    HandbookService.getCourseInfo(courseCode)
      .then(resp => {
        if (!resp) {
          this.setState(prevState => ({
            courseInfo: {
              ...prevState.courseInfo,
              [courseCode]: {
                data: { courseCode: courseCode },
                error: true,
                isLoading: false,
              },
            },
          }));
        } else {
          // extract the course info from the resp
          const courseInfo = {
            courseCode: resp.courseCode,
            courseName: resp.courseName,
            faculty: resp.mangFac,
            creditPoints: resp.creditPoints,
            courseDescription: resp.courseDescrip.value,
            durationStr: resp.courseDuration,
            modeAndLocation: resp.modeLoc,
            awards: resp.courseAward,
            abrTitle: resp.abrevTitle,
          };

          // update the appropriate state
          this.setState(prevState => ({
            courseInfo: {
              ...prevState.courseInfo,
              [courseCode]: {
                data: courseInfo,
                isLoading: false,
              },
            },
          }));
        }
      })
      .catch(err => {
        this.setState(prevState => ({
          courseInfo: {
            ...prevState.courseInfo,
            [courseCode]: {
              data: { courseCode: courseCode },
              error: err,
              isLoading: false,
            },
          },
        }));
      });
  }

  /**
   * Processes a course structure and returns a unit rows data structure
   * that the course map summary cards require
   *
   * essentially if there is a valid unit, we return { unitCode, faculty }
   * otherwise we return null. We also pad out the the rows depending
   * on the max amount of units in any one teaching period. This helps
   * keep the logic within the course map summary component simple, as it
   * doesnt have to deal with variable length rows
   */
  buildUnitRows(teachingPeriods) {
    let numberOfUnitsMax = 4;
    teachingPeriods.forEach(tp => {
      if (tp.numberOfUnits > numberOfUnitsMax) {
        numberOfUnitsMax = tp.numberOfUnits;
      }
    });

    let unitRows = [];
    for (let i = 0; i < teachingPeriods.length; i++) {
      const tp = teachingPeriods[i];
      let currentRow = [];
      for (let k = 0; k < tp.units.length; k++) {
        let unit = tp.units[k];
        if (!unit || (unit && unit.placeholder)) {
          currentRow.push(null);
        } else {
          currentRow.push({ unitCode: unit.unitCode, faculty: unit.faculty });
        }
      }

      if (tp.units.length < numberOfUnitsMax) {
        const lengthDiff = numberOfUnitsMax - tp.units.length;
        let paddingNulls = new Array(lengthDiff).fill(null);
        currentRow = [...currentRow, ...paddingNulls];
      }

      unitRows.push(currentRow);
    }

    return unitRows;
  }

  handleLoadMap(coursePlanId) {
    this.context.router.push({
      pathname: `/plan/${coursePlanId}`,
    });
  }

  /**
   * checks whether we have already loaded the course snapshots for the
   * given course plan, if we havent, makes an API call to get them
   */
  handleSeeVersions(coursePlanId) {
    const { courseSnapshots } = this.state;
    const courseSnapshotObj = courseSnapshots[coursePlanId];
    if (!courseSnapshotObj.isLoading && !courseSnapshotObj.data) {
      this.handleLoadSnapshots(coursePlanId);
    }
  }

  // If the user chooses to archive their course map, then
  // we make the API call and remove it from the UI
  handleArchiveMap(
    teachingPeriods,
    advancedStanding,
    coursePlanId,
    coursePlanName,
  ) {
    CoursePlanService.archiveCoursePlan(
      teachingPeriods,
      advancedStanding,
      coursePlanId,
      coursePlanName,
    )
      .then(resp => {
        this.setState(prevState => ({
          coursePlans: prevState.coursePlans.filter(
            cm => cm.coursePlan.coursePlanId !== coursePlanId,
          ),
        }));
        this.props.displayNotification('Course Plan Deleted');
      })
      .catch(err => {
        console.error(err);
        this.props.displayNotification('Error Deleting Course Plan');
      });
  }

  handleLoadSnapshots(coursePlanId) {
    this.setState(prevState => ({
      courseSnapshots: {
        ...prevState.courseSnapshots,
        [coursePlanId]: {
          ...prevState.courseSnapshots[coursePlanId],
          isLoading: true,
        },
      },
    }));

    CoursePlanService.getAllSnapshots(coursePlanId)
      .then(resp => {
        this.setState(prevState => ({
          courseSnapshots: {
            ...prevState.courseSnapshots,
            [coursePlanId]: {
              isLoading: false,
              data: resp,
            },
          },
        }));
      })
      .catch(resp => {
        console.error(resp);
      });
  }

  handleNewCoursePlan = () => {
    const { hideActionDrawer } = this.props;
    hideActionDrawer();
    browserHistory.push(ROUTES.CREATE_NEW_PLAN);
  };

  handleCloneClick = (coursePlan, courseSnapshot) => {
    const { user } = this.props;
    const { studentId } = this.props.params;
    const coursePlanName = `Copy of ${coursePlan.coursePlanName}`;
    const courseCode = coursePlan.courseCode;

    // TODO create returns the newly created course plan object, so we could
    // insert it into the UI without needing to trigger a full data refresh

    if (user.isStudent && !user.isMsaStudent) {
      CoursePlanService.cloneCoursePlan(
        courseSnapshot.blob,
        coursePlanName,
        courseCode,
      )
        .then(() => {
          this.props.displayNotification('Course Plan Cloned');
          this.componentDidMount();
        })
        .catch(err => {
          console.error(err);
          this.props.displayNotification('Error Cloning Course Plan');
        });
    }

    if (user.isAusAdvisor || user.isMalaysiaStaff) {
      CoursePlanService.cloneCoursePlanForStudent(
        courseSnapshot.blob,
        coursePlanName,
        courseCode,
        studentId,
      )
        .then(() => this.componentDidMount())
        .then(resp => {
          this.props.displayNotification('Course Plan Cloned');
          browserHistory.push(ROUTES.LIST_PLANS);
        })

        .catch(err => {
          console.error(err);
          this.props.displayNotification('Error Cloning Course Plan');
        });
    }
  };

  renderNoPlans = () => {
    const { user } = this.props;
    const { studentId } = this.props.params;

    let noPlansTitle = 'No Plans found';
    let noPlansBody =
      'You have not created any course plans yet! Click the button above to start planning your Monash journey!';
    if (user.isAusAdvisor || user.isMalaysiaStaff || user.isMonashConnect) {
      noPlansTitle = `No Plans for student ${studentId}`;
      noPlansBody =
        'This student either does not exist or does not have any existing course plans.';
    } else if (user.isMsaStaff) {
      noPlansTitle = `No Plans for student ${studentId}`;
      noPlansBody =
        'This student either does not exist or does not have any existing course plans.';
    } else if (user.isMsaStudent) {
      noPlansTitle = 'No Plans found';
      noPlansBody = 'You currently do not have any course plans on record';
    }

    return (
      <div>
        <Typography variant="title">{noPlansTitle}</Typography>
        <Typography style={{ maxWidth: 1000 }} gutterBottom>
          {noPlansBody}
        </Typography>
      </div>
    );
  };

  rendercoursePlanSummaries() {
    const { user } = this.props;

    return this.state.coursePlans.map((courseMap, i) => {
      const { snapshot, coursePlan } = courseMap;
      const { teachingPeriods, advancedStanding } = JSON.parse(snapshot.blob);
      const credits = TeachingPeriodUtils.getCreditPoints(
        teachingPeriods,
        advancedStanding,
      );
      const creditPointsCompleted = credits.total || 0;
      const startYear = TeachingPeriodUtils.getStartYear(teachingPeriods);

      const unitRows = this.buildUnitRows(teachingPeriods);
      const dateTimeStr = moment(snapshot.lastModifiedDate).format(
        'h:mmA DD/MM/YYYY',
      );
      const courseInfo = this.state.courseInfo[coursePlan.courseCode].data;
      const isError = !!this.state.courseInfo[coursePlan.courseCode].error;
      const isLoading = this.state.courseInfo[coursePlan.courseCode].isLoading;

      let courseName = '';
      let totalCreditPoints = 1;
      let faculty = '';

      if (courseInfo) {
        courseName = isError
          ? courseInfo.courseCode
          : `${courseInfo.courseCode} - ${courseInfo.courseName}`;

        totalCreditPoints = isError ? 1 : courseInfo.creditPoints;

        faculty = isError ? '' : courseInfo.faculty;
      }

      const snapshots =
        this.state.courseSnapshots[coursePlan.coursePlanId].data || [];
      const versionsAreLoading = this.state.courseSnapshots[
        coursePlan.coursePlanId
      ].isLoading;

      const status = courseMap.snapshot.status;

      const versions = snapshots.map((snapshotObj, index) => {
        const snapshot = snapshotObj.snapshot;
        return {
          versionNumber: snapshots.length - index,
          createdDate: moment(snapshot.lastModifiedDate).format(
            'h:mmA DD/MM/YYYY',
          ),
          createdBy: snapshot.lastModifiedBy,
        };
      });

      let readOnly = true;

      if (user.isMsaStudent) {
        readOnly = true; // redundant but helps keep the logic explicit
      } else if (user.isMsaStaff) {
        readOnly = true;
      } else if (user.isMonashConnect) {
        readOnly = true;
      } else if (status === STATUSES.UNLOCKED && user.isStudent && !user.isMsaStudent) {
        readOnly = false;
      } else if (status === STATUSES.LOCKED && user.isStaff && !user.isMsaStaff) {
        readOnly = false;
      }

      return (
        <div style={{ maxWidth: 1000 }} key={`course-map-summary-${i}`}>
          <CourseMapSummary
            isLoading={isLoading}
            unsupportedCourse={isError}
            title={coursePlan.coursePlanName}
            startYear={startYear}
            courseName={courseName}
            courseCode={coursePlan.courseCode}
            faculty={faculty}
            creditPointsCompleted={creditPointsCompleted}
            totalCreditPoints={totalCreditPoints}
            lastUpdated={dateTimeStr}
            unitRows={unitRows}
            approval={courseMap.approval}
            versionsLoading={versionsAreLoading}
            onSeeVersionsClick={this.handleSeeVersions.bind(
              this,
              coursePlan.coursePlanId,
            )}
            versions={versions}
            onLoadClick={this.handleLoadMap.bind(this, coursePlan.coursePlanId)}
            onArchiveClick={this.handleArchiveMap.bind(
              this,
              teachingPeriods,
              advancedStanding,
              coursePlan.coursePlanId,
              coursePlan.coursePlanName,
            )}
            status={status}
            readOnly={readOnly}
            onCloneClick={this.handleCloneClick.bind(
              this,
              coursePlan,
              snapshot,
            )}
            user={user}
          />
        </div>
      );
    });
  }

  render() {
    const { classes, user } = this.props;
    const { coursePlansLoading, coursePlans, studentInfo } = this.state;

    // if (!isAuth) {
    //   window.location = `${window.location.origin}`;
    // }

    let content = <Loader message="Loading your course plans" />;
    if (!coursePlansLoading) {
      content =
        coursePlans.length > 0
          ? this.rendercoursePlanSummaries()
          : this.renderNoPlans();
    }

    const newPlanButton = (
      <Button
        variant="raised"
        style={{
          padding: 16,
          boxSizing: 'border-box',
          marginTop: 16,
          marginBottom: 32,
          color: 'white',
        }}
        component={Link}
        to={ROUTES.CREATE_NEW_PLAN}
        fullWidth
        onClick={() => browserHistory.push(ROUTES.CREATE_NEW_PLAN)}
        color="primary">
        Create a new course plan
      </Button>
    );

    const showCreateButton = (user.isStudent && !user.isMsaStudent) || (user.isStaff && !user.isMsaStaff);

    return (
      <React.Fragment>
        <Header>
          {showCreateButton && (
            <ListItem
              aria-label="Create New Course Plan"
              button
              component={Link}
              onClick={this.handleNewCoursePlan}>
              <ListItemIcon>
                <NewPlanIcon />
              </ListItemIcon>
              <ListItemText>Create New Course Plan</ListItemText>
            </ListItem>
          )}
        </Header>
        {user.isStaff ? (
          <div style={{ backgroundColor: '#eee', width: '100%' }}>
            <div style={{ maxWidth: '1600px', margin: '0 auto' }}>
              <div className={classes.mobileFlex}>
                <div className={classes.miniProfileView}>
                  <MiniProfile studentInfo={studentInfo} />
                </div>
                <div className={classes.plans}>
                  {(user.isAusAdvisor || user.isMalaysiaStaff) && newPlanButton}
                  {content}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ background: '#eee', minHeight: '100vh' }}>
            <div
              style={{
                maxWidth: 1000,
                width: '100%',
                margin: '0 auto',
                padding: '16px 0',
              }}>
              {user.isStudent && newPlanButton}
              {content}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User.user,
  isAuth: state.User.isAuth,
  userIsLoading: state.User.isLoading,
  currentStudentId: state.CoursePlan.currentStudentId,
});

const ListPlansWithStyles = withStyles(styles)(ListPlans);
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    hideActionDrawer,
    updateStudentId,
    updateStudentName,
    displayNotification,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListPlansWithStyles);

ListPlans.propTypes = {
  params: PropTypes.shape({ studentId: PropTypes.string }), // specifies whether a student is viewing their plans, or a course advisor is viewing a students plans
};

ListPlans.contextTypes = {
  router: PropTypes.object.isRequired,
};

ListPlans.propTypes = {};
