const ROLES = {
  STUDENT: 'STUDENT',
  ADVISOR: 'ADVISOR',
  ADMIN: 'ADMIN',
  MONASH_CONNECT: 'MONASH_CONNECT',
  MALAYSIA_STUDENT: 'MALAYSIA_STUDENT',
  MALAYSIA_STAFF: 'MALAYSIA_STAFF',
  MSA_STUDENT: 'MSA_STUDENT',
  MSA_STAFF: 'MSA_STAFF',
};

export default ROLES;
