import React, { Component } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// MUI Icons
import { default as AddStudyAbroadIcon } from '@material-ui/icons/FlightTakeoff';
import { default as RemoveStudyAbroadIcon } from '@material-ui/icons/FlightLand';

class StudyAbroadButton extends Component {
  constructor(props) {
    super(props);

    this.handlePress = this.handlePress.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleRemoveStudyAbroad = this.handleRemoveStudyAbroad.bind(this);

    this.state = { showModal: false };
  }
  handlePress() {
    this.setState({ showModal: true });
  }

  handleConfirm() {
    const { units, teachingPeriodIndex, removeUnit } = this.props;

    //Remove all units within teachingPeriod before setting as study abroad
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      if (unit) {
        removeUnit(teachingPeriodIndex, i, unit.creditPoints);
      }
    }

    this.props.setStudyAbroad(this.props.teachingPeriodIndex);
    this.setState({ showModal: false });
  }

  handleCancel() {
    this.setState({ showModal: false });
  }

  handleRemoveStudyAbroad() {
    this.props.unsetStudyAbroad(this.props.teachingPeriodIndex);
  }

  render() {
    /**
     * Goes through all the units and filters out any Free Elective or null unit, so they can be displayed in the modal
     */
    let IDcount = 0;

    const unitsToDelete = this.props.units.map(
      data =>
        data && data.unitCode ? `${data.unitCode} - ${data.unitName}` : null,
    );

    const filteredUnitsToDelete = unitsToDelete.filter(x => x != null);
    /**
     * Message displayed in modal
     */
    const disclaimer = (
      <p>
        This will set this teaching period as a study abroad period, to study
        abroad you will need to consult your faculty. For more information on
        studying abroad please visit the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={this.props.isMalaysiaMember
              ? "https://www.monash.edu.my/study-abroad"
              : "https://www.monash.edu/study-abroad"}>
          Monash study abroad information page
        </a>
      </p>
    );

    const message =
      filteredUnitsToDelete.length === 0 ? (
        <div>{disclaimer}</div>
      ) : (
        <div>
          {disclaimer}
          <p>
            Setting this Teaching Period to Study Abroad will delete the
            following units from your course plan:
          </p>
          <ul>
            {filteredUnitsToDelete.map(item => {
              return <li key={item + IDcount++}>{item}</li>;
            })}
          </ul>
        </div>
      );
    if (!this.props.isStudyAbroad && this.state.showModal) {
      return (
        <Dialog open={true}>
          <DialogTitle>
            Are you sure you want to set this teaching period to study abroad?
          </DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            <Button
              aria-label="Cancel"
              color="primary"
              onClick={() => {
                this.handleCancel();
                this.props.closeMenu();
              }}>
              Cancel
            </Button>
            <Button
              arial-label="Yes"
              color="primary"
              onClick={() => {
                this.handleConfirm();
                this.props.closeMenu();
              }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    /**
     * Study Abroad Button
     */
    if (!this.props.isStudyAbroad) {
      return (
        <MenuItem
          disabled={this.props.isStudyAbroad}
          onClick={() => {
            this.handlePress();
          }}
          aria-label="Mark as Study Abroad">
          <ListItemIcon>
            <AddStudyAbroadIcon />
          </ListItemIcon>
          <ListItemText>Mark as Study Abroad</ListItemText>
        </MenuItem>
      );
    }
    /**
     * Remove Study Abroad
     */
    return (
      <MenuItem
        disabled={!this.props.isStudyAbroad}
        onClick={() => {
          this.handleRemoveStudyAbroad();
          this.props.closeMenu();
        }}
        aria-label="Remove Study Abroad">
        <ListItemIcon>
          <RemoveStudyAbroadIcon />
        </ListItemIcon>
        <ListItemText>Remove Study Abroad</ListItemText>
      </MenuItem>
    );
  }
}

export default StudyAbroadButton;

StudyAbroadButton.propTypes = {
  teachingPeriodIndex: PropTypes.number,
  isStudyAbroad: PropTypes.bool,
  isMalaysiaMember: PropTypes.bool,
  units: PropTypes.array.isRequired,
};
