import React from 'react';

// MUI Components
import CircularProgress from '@material-ui/core/CircularProgress';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class Loader extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes, message } = this.props;
    return (
      <div className={classes.loaderOverlay}>
        <div className={classes.loaderContainer}>
          <CircularProgress size={100} style={{ color: 'white' }} />
          <p className={classes.loaderMessage} tabIndex={1}>
            {message}
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Loader);
