import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

// MUI Icons
import { default as WarningIcon } from '@material-ui/icons/Warning';
import { default as MoreVertIcon } from '@material-ui/icons/MoreVert';
import { default as AddUnitIcon } from '@material-ui/icons/Add';
import { default as LockIcon } from '@material-ui/icons/Lock';

// Actions
import {
  swapUnit,
  removeUnit,
  increaseStudyLoad,
} from '../../../actions/CourseActions';
import { showSidebar } from '../../../actions/UIActions';

// Containers
import OverloadButtonContainer from '../../../containers/OverloadButtonContainer';
import IntermissionButtonContainer from '../../../containers/IntermissionButtonContainer';
import StudyAbroadButtonContainer from '../../../containers/StudyAbroadButtonContainer';

// Components
import ConfirmDeleteOverloadModal from '../../Modals/ConfirmDeleteOverloadModal';
import ConfirmDeleteTeachingPeriodButton from '../../Buttons/ConfirmDeleteTeachingPeriodButton';
import UnitSlot from '../../Unit/UnitSlot';

// Utils
import { LIMITS } from '../../../constants';

/**
 * TeachingPeriod component
 *
 * Common teaching periods: semester one, semester two, summer semester A,
 * summer semester B, winter semester
 *
 * @function
 * @arg props
 */
class TeachingPeriod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuAnchor: null,
      tpErrorOpen: false,
      tpErrorAnchorEl: null,
    };

    this.closeMenu = this.closeMenu.bind(this);
  }

  handleErrorClick = e => {
    e.stopPropagation();

    this.setState({
      tpErrorOpen: true,
      tpErrorAnchorEl: e.currentTarget,
    });
  };

  handleRequestErrorClose = e => {
    e.stopPropagation();
    this.setState({
      tpErrorOpen: false,
    });
  };

  setMenuOpen = event => this.setState({ menuAnchor: event.currentTarget });

  setMenuClosed = event => this.setState({ menuAnchor: null });

  handleAddUnitAndClose = () => {
    this.setMenuClosed();
    this.props.showSidebar();
  };

  closeMenu() {
    this.setMenuClosed();
  }

  renderLockIcon = () => {
    const tpHasErrors = this.tpHasErrors();

    return (
      <LockIcon
        style={{ color: tpHasErrors ? 'white' : 'rgba(0, 0, 0, 0.54)' }}
      />
    );
  };

  renderTpError = () => {
    const { errors } = this.props;
    const { tpErrorOpen, tpErrorAnchorEl, width } = this.state;

    let stylesObj = {
      width: '24px',
      height: '24px',
      position: 'absolute',
    };

    if (width >= 1000) {
      stylesObj = {
        ...stylesObj,
        bottom: '10px',
        right: '28px',
      };
    } else {
      stylesObj = {
        ...stylesObj,
        top: '0',
        left: '0',
      };
    }

    return (
      <div>
        <IconButton
          onClick={this.handleErrorClick}
          aria-label={'View errors detected with this teaching period'}
          style={stylesObj}>
          <WarningIcon style={{ color: 'white', width: 20, height: 20 }} />
        </IconButton>
        <Popover
          open={tpErrorOpen}
          anchorEl={tpErrorAnchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          anchorPosition={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleRequestErrorClose}>
          <div style={{ maxWidth: 350, paddingRight: 15 }}>
            <p
              style={{
                fontWeight: '550',
                paddingLeft: 15,
                fontSize: '13px',
              }}>
              The following issues were identified:{' '}
            </p>
            <ul style={{ fontSize: '12px' }}>
              {errors.map((d, i) => {
                return <li key={i}>{d.description}</li>;
              })}
            </ul>
          </div>
        </Popover>
      </div>
    );
  };

  renderTpMenuTrigger = () => {
    const tpHasErrors = this.tpHasErrors();

    return (
      <IconButton
        onClick={this.setMenuOpen}
        aria-label="See more options for teaching period"
        style={{
          width: 24,
          height: 24,
          marginLeft: 20,
        }}>
        <MoreVertIcon
          style={{ color: tpHasErrors ? 'white' : 'rgba(0, 0, 0, 0.54)' }}
        />
      </IconButton>
    );
  };

  renderTpActions = () => {
    const { menuAnchor } = this.state;
    const { year, units, index, numberOfUnits, code, data } = this.props;

    let teachingPeriodName = code;
    if (data) {
      const teachingPeriod = data.find(element => element.code === code);

      if (teachingPeriod !== undefined) {
        teachingPeriodName = teachingPeriod.name;
      }
    }

    return (
      <div>
        <Menu
          open={Boolean(menuAnchor)}
          anchorEl={menuAnchor}
          onClose={this.setMenuClosed}>
          {/* ADD UNIT */}
          <MenuItem
            button
            onClick={this.handleAddUnitAndClose}
            disabled={this.props.isIntermission || this.props.isStudyAbroad}>
            <ListItemIcon>
              <AddUnitIcon />
            </ListItemIcon>
            <ListItemText primary="Add Unit" />
          </MenuItem>
          {/* DELETE TEACHING PERIOD */}
          <ConfirmDeleteTeachingPeriodButton
            style={{ height: 18 }}
            name={teachingPeriodName}
            year={year}
            index={index}
            units={units}
          />
          {/* OVERLOAD */}
          <OverloadButtonContainer
            numberOfUnits={numberOfUnits}
            teachingPeriodIndex={index}
            isIntermission={this.props.isIntermission}
            isStudyAbroad={this.props.isStudyAbroad}
            closeMenu={this.closeMenu}
          />
          {/* REMOVE OVERLOAD */}
          <ConfirmDeleteOverloadModal
            numberOfUnits={numberOfUnits}
            teachingPeriodIndex={index}
            closeMenu={this.closeMenu}
          />
          {/*Add Intermission and Remove Intermission*/}
          <IntermissionButtonContainer
            teachingPeriodIndex={this.props.index}
            isIntermission={this.props.isIntermission}
            units={this.props.units}
            removeIntermissionUnit={this.props.removeUnit}
            closeMenu={this.closeMenu}
          />
          <StudyAbroadButtonContainer
            teachingPeriodIndex={this.props.index}
            isStudyAbroad={this.props.isStudyAbroad}
            units={this.props.units}
            removeUnit={this.props.removeUnit}
            closeMenu={this.closeMenu}
          />
        </Menu>
      </div>
    );
  };

  tpHasErrors = () => {
    const { errors } = this.props;
    return errors.length > 0;
  };

  render() {
    const {
      year,
      data,
      code,
      units,
      index,
      unitErrors,
      swapUnit,
      viewOnly,
      unitIsMoving,
      showingGrades,
      numberOfUnits,
      increaseStudyLoad,
      isPreloaded,
      screenWidth,
      isIntermission,
      isStudyAbroad,
      user,
    } = this.props;
    let unitRep = [],
      tmp = [...units],
      chain = 0;

    for (let i = 0; i < tmp.length; i++) {
      let currentUnit = tmp[i];
      if (currentUnit !== null && currentUnit !== undefined) {
        if (!currentUnit.placeholder && currentUnit.creditPoints / 6 > 1) {
          chain += Math.min(LIMITS.MAX_UNITS, currentUnit.creditPoints / 6 - 1);
        }
        unitRep.push(currentUnit);
      } else if (currentUnit === null) {
        if (chain > 0) {
          unitRep.push('shouldNotDisplay');
          chain -= 1;
        } else {
          unitRep.push(currentUnit);
        }
      } else {
        unitRep.push(currentUnit);
      }
    }

    for (let j = 0; j < chain; j++) {
      if (numberOfUnits < LIMITS.MAX_UNITS) {
        // increase to a max of MAX_UNITS units
        increaseStudyLoad(index);
      }
    }

    const unitsEle = unitRep.map((unit, i) => {
      if (!unit) {
        return (
          <UnitSlot
            numberOfUnits={numberOfUnits}
            key={i}
            index={i}
            teachingPeriodIndex={index}
            free
            unitIsMoving={unitIsMoving}
            isPreloaded={isPreloaded}
            year={year}
          />
        );
      } else if (unit === 'shouldNotDisplay') {
        return null;
      } else if (unit === 'display') {
        return (
          <UnitSlot
            key={i}
            index={i}
            teachingPeriodIndex={index}
            free
            unitIsMoving={unitIsMoving}
            isPreloaded={isPreloaded}
            year={year}
          />
        );
      }

      /**
       * if showMoveUnitUI || unitToAdd dont render error UI
       */

      return (
        <UnitSlot
          numberOfUnits={numberOfUnits}
          viewOnly={viewOnly}
          isPreloaded={isPreloaded}
          key={i}
          index={i}
          isIntermisson={this.props.isIntermisson}
          teachingPeriodIndex={index}
          swapUnit={swapUnit.bind(null, index)}
          code={unit.unitCode}
          name={unit.unitName}
          grade={unit.grade}
          creditPoints={unit.creditPoints}
          faculty={unit.faculty}
          placeholder={unit.placeholder}
          unit={unit}
          unitIsMoving={unitIsMoving}
          showingGrades={showingGrades}
          errors={unitErrors[unit.unitCode] || []}
          year={year}
        />
      );
    });

    let teachingPeriodName = code;
    if (data) {
      const teachingPeriod = data.find(element => element.code === code);

      if (teachingPeriod !== undefined) {
        teachingPeriodName = teachingPeriod.name;
      }
    }

    const tpHasErrors = this.tpHasErrors();
    let backgroundColor = 'white';
    if (isPreloaded) {
      backgroundColor = '#e3e8ef';
    } else if (tpHasErrors) {
      backgroundColor = 'rgba(155, 54, 54, 1)';
    }

    let body = null;
    if (isIntermission) {
      body = (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            color: 'black',
            background: `repeating-linear-gradient(45deg,
              #ffffff,
              #ffffff 10px,
              #d8d8d8 10px,
              #d8d8d8 11px)`,
          }}>
          <p
            style={{
              fontSize: '32px',
              margin: 0,
            }}>
            Intermission
          </p>
          <a
            style={{ color: 'black' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.monash.edu/enrolments/change/intermission">
            Intermission information page
          </a>
        </div>
      );
    } else if (isStudyAbroad) {
      body = (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            color: 'black',
            background: `repeating-linear-gradient(45deg,
              #ffffff,
              #ffffff 10px,
              #d8d8d8 10px,
              #d8d8d8 11px)`,
          }}>
          <p
            style={{
              fontSize: '32px',
              margin: 0,
            }}>
            Study Abroad
          </p>
          <a
            style={{ color: 'black' }}
            target="_blank"
            rel="noopener noreferrer"
            href={user && (user.isMalaysiaStudent || user.isMalaysiaStaff) ?
                "http://www.monash.edu.my/study-abroad":
                "https://www.monash.edu/study-abroad"
            }>
            Study Abroad information page
          </a>
        </div>
      );
    } else {
      body = (
        <div
          style={{
            display: 'flex',
            flexDirection: screenWidth < 1000 ? 'column' : 'row',
            flex: 1,
          }}>
          {unitsEle}
        </div>
      );
    }

    return (
      <div
        style={{
          minHeight: screenWidth < 1000 ? '400px' : '120px',
          color: 'black',
          display: 'flex',
          borderTop: 'solid 1px #d1d3d4',
          width: '100% ',
          background: backgroundColor,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: screenWidth < 1000 ? 50 : 140,
            flexShrink: 0,
            borderRight:
              screenWidth < 1000 ? undefined : '1px solid rgb(209, 211, 212)',
            fontSize: 13,
            position: 'relative',
            color: tpHasErrors ? 'white' : 'black',
            background: tpHasErrors ? 'rgba(155, 54, 54, 1)' : 'white',
          }}>
          <div
            style={{
              transform: screenWidth < 1000 ? 'rotate(-90deg)' : undefined,
              whiteSpace: screenWidth < 1000 ? 'nowrap' : undefined,
              maxWidth: screenWidth < 1000 ? undefined : '100px',
              display: 'flex',
              alignItems: 'center',
            }}
            tabIndex="0">
            {`${teachingPeriodName}, ${year}`}
            {(viewOnly || isPreloaded) && this.renderLockIcon()}
            {!viewOnly && !isPreloaded && this.renderTpMenuTrigger()}
          </div>
          {tpHasErrors && this.renderTpError()}
          {!viewOnly && !isPreloaded && this.renderTpActions()}
        </div>
        {body}
      </div>
    );
  }
}

/**
 * All teaching period currently needs are course actions such as remove teaching period
 */
const mapDisaptchToProps = dispatch => {
  const actionBundle = {
    swapUnit,
    removeUnit,
    increaseStudyLoad,
    showSidebar,
  };

  return bindActionCreators(actionBundle, dispatch);
};

/**
 * Injects the required state as this.props.from redux
 */
const mapStateToProps = state => {
  return {
    data: state.TeachingPeriods.data,
    showingMovingUnitUI: state.UI.showingMovingUnitUI,
    unitIsMoving: state.PlanInstance.unitIsMoving,
    unitToBeMoved: state.PlanInstance.unitToBeMoved,
    tpIndexOfUnitToBeMoved: state.PlanInstance.tpIndexOfUnitToBeMoved,
    showingAddingUnitUI: state.UI.showingAddingUnitUI,
    unitToAdd: state.PlanInstance.unitToAdd,
    showingGrades: state.UI.showingGrades,
    user: state.User.user,
  };
};

export default connect(
  mapStateToProps,
  mapDisaptchToProps,
)(TeachingPeriod);

TeachingPeriod.propTypes = {
  code: PropTypes.string.isRequired,
  data: PropTypes.array,
  errors: PropTypes.array,
  increaseStudyLoad: PropTypes.func,
  index: PropTypes.number.isRequired,
  isIntermission: PropTypes.bool,
  numberOfUnits: PropTypes.number,
  removeTeachingPeriod: PropTypes.func,
  showingAddingUnitUI: PropTypes.bool,
  showingMovingUnitUI: PropTypes.bool,
  tpIndexOfUnitToBeMoved: PropTypes.number,
  unitIsMoving: PropTypes.bool,
  units: PropTypes.array.isRequired,
  unitToAdd: PropTypes.object,
  unitToBeMoved: PropTypes.object,
  viewOnly: PropTypes.bool,
  year: PropTypes.number.isRequired,
  showingGrades: PropTypes.bool,
  screenWidth: PropTypes.number,
  user: PropTypes.object,
};
