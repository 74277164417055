import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import * as ReactGA from 'react-ga';

// MUI Components
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

// MUI Icons
import ArrowBack from '@material-ui/icons/ArrowBack';

// Constants
import { gaCategory, gaActions } from '../../constants/reactGA';
import { ROUTES, LIMITS } from '../../constants';

// Actions
import {
  submitCourseFormAndPreloadUnits,
  submitCourseFormAndUseTemplate,
  submitEmptyCoursePlan,
} from '../../actions/CoursePlanActions';
import {
  fetchCourseInfo,
  fetchAreasOfStudy,
} from '../../actions/CourseActions';

import { fetchCourseEnrolments } from '../../actions/EnrolmentActions';

// Components
import Loader from '../../components/Base/Loader';
import BlankCourseForm from './BlankCourseForm';
import CourseTemplateForm from './TemplateCourseForm';
import PreloadCourseForm from './PreloadCourseForm';

// Styling
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import TextFieldValidator from '../../components/Text/TextFieldValidator';

const PRELOAD_FORM_ID = 'PRELOAD';
const TEMPLATE_FORM_ID = 'TEMPLATE';
const EMPTY_FORM_ID = 'EMPTY';

class CourseSelectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coursePlanName: '',
      buildingCoursePlan: false,
      selectedForm: '',
    };
  }

  componentDidMount() {
    const { isAuth, user, studentId, fetchCourseEnrolments } = this.props;
    if (isAuth) {
      if (user.isAusAdvisor || user.isMalaysiaStaff) {
        fetchCourseEnrolments(studentId);
      } else {
        fetchCourseEnrolments();
      }
    }
  }

  submitEmptyForm = coursePlanName => {
    const { studentId } = this.props;
    this.props.submitEmptyCoursePlan(coursePlanName, studentId);
  };

  submitTemplateForm = (
    coursePlanName,
    selectedYear,
    courseCode,
    timelinePaths,
  ) => {
    const { user, submitCourseFormAndUseTemplate, studentId } = this.props;

    this.setState({ buildingCoursePlan: true });

    ReactGA.ga(tracker => {
      ReactGA.event({
        category: gaCategory.PLAN_ACTIONS,
        action: user.isAusAdvisor || user.isMalaysiaStaff
          ? gaActions.PLAN_ACTIONS.newPlan.ADVISOR
          : gaActions.PLAN_ACTIONS.newPlan.STUDENT,
        label: `{${courseCode}}`,
      });
    });

    submitCourseFormAndUseTemplate(
      coursePlanName,
      courseCode,
      selectedYear,
      timelinePaths,
      studentId,
    );
  };

  submitPreloadForm = (
    selectedCourseCode,
    selectedAOSes,
    coursePlanName,
    currentCourseEnrolment,
  ) => {
    const { user, submitCourseFormAndPreloadUnits, studentId } = this.props;

    this.setState({ buildingCoursePlan: true });

    const selectedAOSCodes = selectedAOSes.map(aos => aos.value);

    ReactGA.ga(tracker => {
      ReactGA.event({
        category: gaCategory.PLAN_ACTIONS,
        action: user.isAusAdvisor || user.isMalaysiaStaff
          ? gaActions.PLAN_ACTIONS.newPlan.ADVISOR
          : gaActions.PLAN_ACTIONS.newPlan.STUDENT,
        label: `{${selectedCourseCode}}`,
      });
    });
    submitCourseFormAndPreloadUnits(
      coursePlanName,
      selectedCourseCode,
      currentCourseEnrolment,
      selectedAOSCodes,
      studentId,
    );
  };

  handleCoursePlanNameChange = e => {
    this.setState({
      coursePlanName: e.target.value,
    });
  };

  handleFormChange = event => {
    const selectedForm = event.target.value;
    this.setState({ selectedForm });
  };

  isLoading = () => {
    return this.props.courseEnrolmentsLoading || this.state.buildingCoursePlan;
  };

  handleBack = () => {
    browserHistory.push(`${ROUTES.STUDENT_SEARCH}/${this.props.studentId}`);
  };

  renderFormContainer = () => {
    const { classes, courseEnrolmentsError } = this.props;
    const { coursePlanName } = this.state;

    return (
      <React.Fragment>
        <div className={classes.coursePlanNameContainer}>
          <TextFieldValidator
            value={coursePlanName}
            {...TextFieldValidator.CharacterCount(
              LIMITS.CHARACTER_LIMITS.COURSE_PLAN_NAME,
            )}>
            <TextField
              required
              autoFocus
              label="Enter a name for this course plan"
              onChange={this.handleCoursePlanNameChange}
              value={coursePlanName}
              fullWidth
              classes={{ root: classes.textField }}
              aria-label="Enter a name for this course plan"
              FormHelperTextProps={{ className: classes.validationHelperText }}
            />
          </TextFieldValidator>
        </div>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              padding: '0',
              fontSize: '0.75rem',
              fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
              lineHeight: '1',
            }}>
            Course plan type
          </FormLabel>
          <RadioGroup
            aria-label="Course plan type"
            className={classes.group}
            value={this.state.selectedForm}
            onChange={this.handleFormChange}>
            <FormControlLabel
              control={<Radio />}
              value={PRELOAD_FORM_ID}
              disabled={courseEnrolmentsError}
              label="Prefill with my enrolment information"
            />
            <FormControlLabel
              control={<Radio />}
              value={TEMPLATE_FORM_ID}
              label="Use a course plan template"
            />
            <FormControlLabel
              control={<Radio />}
              value={EMPTY_FORM_ID}
              label="Start with a blank course plan"
            />
          </RadioGroup>
          {courseEnrolmentsError && (
            <FormHelperText className={classes.errorText}>
              Unfortunately we could not load your enrolment information so we
              have disabled the prefill with enrolment information option. You
              can still create a plan with a course plan template or you can try
              again later.
            </FormHelperText>
          )}
        </FormControl>
        {this.renderSelectedForm()}
      </React.Fragment>
    );
  };

  renderSelectedForm = () => {
    const {
      classes,
      areasOfStudy,
      fetchAreasOfStudy,
      areasOfStudyError,
      courseEnrolments,
      areasOfStudyLoading,
      courseEnrolmentsLoading,
    } = this.props;

    const { coursePlanName, selectedForm } = this.state;

    switch (selectedForm) {
      case PRELOAD_FORM_ID:
        return (
          <PreloadCourseForm
            classes={classes}
            areasOfStudy={areasOfStudy}
            coursePlanName={coursePlanName}
            fetchAreasOfStudy={fetchAreasOfStudy}
            areasOfStudyError={areasOfStudyError}
            courseEnrolments={courseEnrolments}
            areasOfStudyLoading={areasOfStudyLoading}
            courseEnrolmentsLoading={courseEnrolmentsLoading}
            onSubmit={this.submitPreloadForm}
          />
        );
      case TEMPLATE_FORM_ID:
        return (
          <CourseTemplateForm
            onSubmit={this.submitTemplateForm}
            classes={classes}
            coursePlanName={coursePlanName}
          />
        );
      case EMPTY_FORM_ID:
        return (
          <BlankCourseForm
            onSubmit={this.submitEmptyForm}
            classes={classes}
            coursePlanName={coursePlanName}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { buildingCoursePlan } = this.state;
    const { user, classes, studentId } = this.props;

    if (this.isLoading()) {
      const message = buildingCoursePlan
        ? 'Creating your course plan'
        : 'Loading enrolment data';
      return <Loader message={message} />;
    }

    if (!user) {
      return null;
    }

    return (
      <Paper tabIndex="0" classes={{ root: classes.paperContainer }}>
        <div className={classes.formContainer}>
          <div className={classes.fieldContainer}>
            {studentId && (
              <Button
                style={{
                  marginTop: '20px',
                  fontSize: '18px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                component={Link}
                to={ROUTES.STUDENT_SEARCH}>
                <ArrowBack
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                  aria-label={`Back to Plans listing for Student #${
                    this.props.studentId
                  }`}
                />
                {`Plans for Student #${this.props.studentId}`}
              </Button>
            )}
            <h1 className={classes.text} tabIndex="0">
              Create New Course Plan
            </h1>
            {this.renderFormContainer()}
          </div>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User.user,
  isAuth: state.User.isAuth,
  studentId: state.CoursePlan.currentStudentId,
  userIsLoading: state.User.isLoading,
  courseEnrolments: state.Enrolments.courseEnrolments,
  courseEnrolmentsError: state.Enrolments.courseEnrolmentsError,
  courseEnrolmentsLoading: state.Enrolments.courseEnrolmentsLoading,
  areasOfStudy: state.AreaOfStudy.areasOfStudy,
  areasOfStudyError: state.AreaOfStudy.areasOfStudyError,
  areasOfStudyLoading: state.AreaOfStudy.areasOfStudyLoading,
});

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    fetchAreasOfStudy,
    fetchCourseInfo,
    fetchCourseEnrolments,
    submitCourseFormAndPreloadUnits,
    submitEmptyCoursePlan,
    submitCourseFormAndUseTemplate,
  };

  return bindActionCreators(actionBundle, dispatch);
};

const styledCourseSelectFormContainer = withStyles(styles)(CourseSelectForm);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(styledCourseSelectFormContainer);
