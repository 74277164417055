const MONPLAN_VERSION = 'MONPLAN_VERSION';

export default class LocalStorage {
  static isMonPlanUpdated() {
    const version = localStorage.getItem(MONPLAN_VERSION);
    return version !== process.env.REACT_APP_VERSION;
  }

  static setMonPlanVersion = version => {
    localStorage.setItem(MONPLAN_VERSION, version);
  };
}
