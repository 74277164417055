import { createMuiTheme } from '@material-ui/core';

import { COLORS } from '../constants';

const theme = createMuiTheme({
  palette: {
    primary: COLORS.PRIMARY,
    secondary: COLORS.SECONDARY,
    error: COLORS.ERROR,
  },
  // define custom theme props here
  custom: {
    palette: {
      facultyColors: COLORS.FACULTY,
      misc: COLORS.MISC,
    },
    shadows: {
      // default shadow for Paper
      appBar:
        '0px 3px 3px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  // try to avoid using this unless absolutely necessary!
  // it will apply changes to ALL components of a certain type
  overrides: {},
});

export default theme;
