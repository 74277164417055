import React, { Component } from 'react';
import { string } from 'prop-types';

// MUI Components
import Button from '@material-ui/core/Button';

/**
 * This serves as the interpreter for the unique and frankly wacky way in which Monash stores it's
 * course information strings. Tilde's represent new lines, plus signs represent list items and random
 * numbers of equal signs greater than 2 represent headings.
 */
class CourseDescription extends Component {
  state = {
    isShowingMore: false,
    descriptionSections: [],
  };

  componentDidMount = () => {
    const { description } = this.props;
    this.setState({ descriptionSections: this.processData(description) });
  };

  processData = inputString => {
    let keyID = 0;
    let paragraphArray = inputString.split('. ~');
    let description = [];
    for (let i = 0; i < paragraphArray.length; i++) {
      let para = paragraphArray[i];
      let paraArr = para.split('');
      let equalsCount = 0;
      let currentStr = '';
      let array = false;
      let potentialArrayEnd = false;
      let allEquals = false;
      let arrayVal = [];
      for (let j = 0; j < paraArr.length; j++) {
        let char = paraArr[j];
        if (char === '=') {
          if (!allEquals) {
            equalsCount += 1;
          }
          if (equalsCount === 2) {
            description.push(['p', currentStr.replace('~', ' ')]);
            currentStr = '';
            allEquals = true;
          } else if (equalsCount === 4) {
            description.push(['h', currentStr.replace('~', ' ')]);
            currentStr = '';
            equalsCount = 0;
            allEquals = true;
          }
        } else if (char === '+') {
          potentialArrayEnd = false;
          description.push(['p', currentStr.replace('~', ' ')]);
          currentStr = '';
          array = true;
        } else if (array && char === '~') {
          arrayVal.push(currentStr.replace('~', ' '));
          currentStr = '';
          potentialArrayEnd = true;
        } else if (array && potentialArrayEnd && char !== ' ' && char !== '+') {
          description.push(['a', arrayVal]);
          potentialArrayEnd = false;
          array = false;
          currentStr = '';
          arrayVal = [];
        } else {
          currentStr += char;
        }
        if (char !== '=' && allEquals) {
          allEquals = false;
        }
      }
      if (currentStr !== '') {
        description.push(['p', currentStr.replace('~', ' ')]);
      }
    }

    description = description.map(item => {
      switch (item[0]) {
        case 'p':
          let para = item[1].replace('~', ' ');
          return <p key={keyID++}>{para}</p>;
        case 'h':
          return <h5 key={keyID++}>{item[1].replace('~', ' ')}</h5>;
        case 'a':
          let list = item[1].map(item => {
            return <li key={keyID++}>{item.replace('~', ' ')}</li>;
          });
          return <ul key={keyID++}>{list}</ul>;
        default:
          return <h5 key={keyID++}>Error displaying some data...</h5>;
      }
    });

    return description;
  };

  toggleShowMore = () => {
    const { isShowingMore } = this.state;
    this.setState({ isShowingMore: !isShowingMore });
  };

  render() {
    const { isShowingMore, descriptionSections } = this.state;

    return (
      <div style={{ paddingBottom: '16px' }}>
        {isShowingMore
          ? descriptionSections
          : descriptionSections.slice(
              0,
              Math.min(descriptionSections.length, 3),
            )}
        <Button onClick={this.toggleShowMore}>
          {`Show ${isShowingMore ? 'Less' : 'More'}`}
        </Button>
      </div>
    );
  }
}

CourseDescription.propTypes = {
  description: string,
};

export default CourseDescription;
