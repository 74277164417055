import { ENQUIRY_STATUSES } from '../../constants';

export const DOES_ENQUIRY_INITIALLY_EXIST = false; // boolean

export const facultiesOrSchools = [
  {
    code: 'ADA',
    value: 'Art, Design and Architecture',
    schools: [],
  },
  {
    code: 'ART',
    value: 'Arts',
    schools: [],
  },
  {
    code: 'BUSECO',
    value: 'Business and Economics',
    schools: [],
  },
  {
    code: 'EDU',
    value: 'Education',
    schools: [],
  },
  {
    code: 'ENG',
    value: 'Engineering',
    schools: [],
  },
  {
    code: 'INFOTECH',
    value: 'Information Technology',
    schools: [],
  },
  {
    code: 'LAW',
    value: 'Law',
    schools: [],
  },
  {
    code: 'MNHS',
    value: 'Medicine, Nursing and Health Sciences',
    schools: [
      {
        code: 'MNHS-CCS',
        name: 'MNHS - Central Clinical School',
      },
      {
        code: 'MNHS-EHCS',
        name: 'MNHS - Eastern Health Clinical School',
      },
      {
        code: 'MNHS-CCMH',
        name: 'MNHS - Clinical Sciences at Monash Health',
      },
      {
        code: 'MNHS-MEDC',
        name: 'MNHS - Monash School of Medicine',
      },
      {
        code: 'MNHS-MIDW',
        name: 'MNHS - School of Nursing and Midwifery',
      },
      {
        code: 'MNHS-PAHC',
        name: 'MNHS - School of Primary and Allied Health Care',
      },
      {
        code: 'MNHS-PHPM',
        name: 'MNHS - School of Public Health and Preventive Medicine',
      },
      {
        code: 'MNHS-RURAL',
        name: 'MNHS - School of Rural Health',
      },
      {
        code: 'MNHS-BIOMED',
        name: 'MNHS - School of Biomedical Sciences',
      },
      {
        code: 'MNHS-PSYC',
        name: 'MNHS - School of Psychological Sciences',
      },
    ],
  },
  {
    code: 'PHAR',
    value: 'Pharmacy and Pharmaceutical Sciences',
    schools: [],
  },
  {
    code: 'SCI',
    value: 'Science',
    schools: [],
  },
];

export const malaysiaSchools = [
  {
    code: 'SASS',
    value: 'Arts and Social Sciences'
  },
  {
    code: 'BUSECO',
    value: 'Business'
  },
  {
    code: 'ENG',
    value: 'Engineering'
  },
  {
    code: 'INFOTECH',
    value: 'Information Technology'
  },
  {
    code: 'MED',
    value: 'Jeffrey Cheah School of Medicine and Health Sciences'
  },
  {
    code: 'SCI',
    value: 'Science'
  },
  {
    code: 'PHARM',
    value: 'Pharmacy'
  }
];

export const ENQUIRY_TYPES = [
  'Course Progression Check',
  'Monash Abroad Check',
];

/**
 * submissionState can take
 * ENQUIRY_PUBLIC_CASE_ID_AVAILABLE
 * ENQUIRY_PENDING_SUBMISSION
 * ENQUIRY_SUBMITTED
 */
export const ENQUIRY_RESPONSE_CASE_ID_AVAILABLE = {
  publicCaseId: '321',
  submissionState: ENQUIRY_STATUSES.ENQUIRY_PUBLIC_CASE_ID_AVAILABLE,
  snapshotId: 2,
  timestamp: 1531353033244,
};

export const ENQUIRY_RESPONSE_CASE_ID_UNAVAILABLE = {
  publicCaseId: '321',
  submissionState: ENQUIRY_STATUSES.ENQUIRY_PENDING_SUBMISSION,
  snapshotId: 2,
  timestamp: 1531353033244,
};
