import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// MUI Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

// MUI Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OkIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import LoadingErrorIcon from '@material-ui/icons/SmsFailedOutlined';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Actions
import { hideValidationModal } from '../../../actions/UIActions';

// Constants
import ValidationModalConstants from './constants';

const loadingMessages = [
  'Validating course completion',
  'Validating teaching period selections',
  'Validating unit selections',
  'Validating unit pre-reqs',
  'Validating unit co-reqs',
  'Validating unit prohibitions',
];

class ValidationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLoadingMessage: `${loadingMessages[0]}`,
      loadingMessageIndex: 0,
      courseExpanded: false,
      teachingPeriodExpanded: false,
      unitExpanded: false,
    };

    this.timeout = null;
  }

  handleChange = expansionKey => {
    this.setState(prevState => ({
      [expansionKey]: !prevState[expansionKey],
    }));
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isLoading && this.props.isLoading) {
      this.timeout = setInterval(this.calculateNextLoadingStr, 3000);
    }

    let aosExpansionObj = {};
    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.timeout) {
        clearInterval(this.timeout);
        this.timeout = null;
      }

      if (!this.props.isError && this.props.courseErrors) {
        let hasTeachingPeriodErrors =
          this.props.courseErrors.teachingPeriodErrorCount > 0;
        let hasUnitErrors = this.props.courseErrors.unitErrorCount > 0;

        if (hasTeachingPeriodErrors) {
          this.tpErrorMap(this.props.courseErrors.teachingPeriod).forEach(
            tpObj => {
              const key = `${tpObj.tpCode}${tpObj.year}`;
              aosExpansionObj[key] = false;
            },
          );
        }

        if (hasUnitErrors) {
          this.unitErrorMap(this.props.courseErrors.unit).forEach(unitObj => {
            const key = `${unitObj.unitCode}`;
            aosExpansionObj[key] = false;
          });
        }
      }

      this.setState({
        currentLoadingMessage: `${loadingMessages[0]}`,
        loadingMessageIndex: 0,
        ...aosExpansionObj,
      });
    }
  }

  calculateNextLoadingStr = () => {
    const { loadingMessageIndex } = this.state;

    let newLoadingMessageIndex = Math.min(
      loadingMessageIndex + 1,
      loadingMessages.length - 1,
    );

    const newMessage = `${loadingMessages[newLoadingMessageIndex]}`;

    this.setState({
      currentLoadingMessage: newMessage,
      loadingMessageIndex: newLoadingMessageIndex,
    });
  };

  handleCloseRequest = () => {
    const { hideValidationModal, isLoading } = this.props;
    if (isLoading) {
      return null;
    }

    hideValidationModal();
  };

  tpErrorMap = tpErrorObj => {
    let tpObjs = [];

    Object.keys(tpErrorObj).forEach(year => {
      const yearObj = tpErrorObj[year];
      Object.keys(yearObj).forEach(tpCode => {
        const tpErrors = yearObj[tpCode];
        tpObjs.push({
          year,
          tpCode,
          errors: tpErrors,
        });
      });
    });

    return tpObjs;
  };

  unitErrorMap = unitErrorObj => {
    let unitObjs = [];

    Object.keys(unitErrorObj).forEach(year => {
      const yearObj = unitErrorObj[year];
      Object.keys(yearObj).forEach(tpCode => {
        const tpObj = yearObj[tpCode];
        Object.keys(tpObj).forEach(unitCode => {
          const unitErrors = tpObj[unitCode];
          unitObjs.push({
            year,
            tpCode,
            unitCode,
            errors: unitErrors,
          });
        });
      });
    });

    return unitObjs;
  };

  renderValidationDetails = () => {
    const { courseErrors, classes } = this.props;
    let hasCourseErrors = courseErrors.courseErrorCount > 0;
    let hasTeachingPeriodErrors = courseErrors.teachingPeriodErrorCount > 0;
    let hasUnitErrors = courseErrors.unitErrorCount > 0;
    return (
      <div>
        {hasCourseErrors && (
          <div>
            <h4>{ValidationModalConstants.requirements.COURSE_COMPLETION}</h4>
            <div className={classes.errorDetailsContainer}>
              {courseErrors.course.map((err, i) => (
                <div className={classes.errorCardRoot} key={i}>
                  <CardContent>
                    <h4>{`${err.title}`}</h4>
                    <p className={classes.errorDescription}>
                      {err.description}
                    </p>
                  </CardContent>
                </div>
              ))}
            </div>
          </div>
        )}
        {hasTeachingPeriodErrors && (
          <div>
            <h4>{ValidationModalConstants.requirements.TEACHING_PERIOD}</h4>
            <div className={classes.errorDetailsContainer}>
              {this.tpErrorMap(courseErrors.teachingPeriod).map(tpObj => (
                <div className={classes.errorCardRoot}>
                  <CardContent>
                    <h4>{`${tpObj.tpCode} ${tpObj.year} (${
                      tpObj.errors.length
                    })`}</h4>

                    {tpObj.errors.map(err => (
                      <div>
                        <ExpansionPanel>
                          <ExpansionPanelSummary
                            className={classes.expansionPanelTitle}
                            expandIcon={<ExpandMoreIcon />}>
                            <b>{`${err.title}`}</b>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <p className={classes.errorDescription}>
                              {err.description}
                            </p>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    ))}
                  </CardContent>
                </div>
              ))}
            </div>
          </div>
        )}
        {hasUnitErrors && (
          <div>
            <h4>{ValidationModalConstants.requirements.UNIT}</h4>
            <div className={classes.errorDetailsContainer}>
              {this.unitErrorMap(courseErrors.unit).map(unitObj => (
                <div className={classes.errorCardRoot}>
                  <CardContent>
                    <h4>{`${unitObj.unitCode} (${unitObj.errors.length})`}</h4>
                    {unitObj.errors.map(err => (
                      <div>
                        <ExpansionPanel>
                          <ExpansionPanelSummary
                            className={classes.expansionPanelTitle}
                            expandIcon={<ExpandMoreIcon />}>
                            <b>{`${err.title}`}</b>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <p className={classes.errorDescription}>
                              {err.description}
                            </p>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    ))}
                  </CardContent>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  renderQuickSummary = () => {
    const { classes, courseErrors } = this.props;
    const hasCourseErrors = courseErrors.courseErrorCount > 0;
    const hasTeachingPeriodErrors = courseErrors.teachingPeriodErrorCount > 0;
    const hasUnitErrors = courseErrors.unitErrorCount > 0;

    let summaryText = `This dialog shows the errors that were detected with your course. Currently you have ${
      courseErrors.courseErrorCount
    } errors with you course, ${
      courseErrors.teachingPeriodErrorCount
    } errors with your teaching periods and ${
      courseErrors.unitErrorCount
    } errors with your unit selections. Expand the panels below by pressing space bar to see the errors.`;

    const okIcon = (
      <Tooltip title={`All good! All rules satisfied!`} placement="right-start">
        <OkIcon style={{ color: 'green', marginLeft: 5 }} />
      </Tooltip>
    );
    const warningIcon = numberofErrors => (
      <Tooltip
        title={`${numberofErrors} warnings detected! Please check detailed view below `}
        placement="right-start">
        <WarningIcon style={{ color: 'orange', marginLeft: 5 }} />
      </Tooltip>
    );

    return (
      <div className={classes.errorSummaryContainer} aria-label={summaryText}>
        <div className={classes.errorSummaryItemContainer}>
          <div style={{ flex: 1 }}>
            <p>{ValidationModalConstants.requirements.COURSE_COMPLETION}</p>
          </div>
          {hasCourseErrors
            ? warningIcon(courseErrors.courseErrorCount)
            : okIcon}
        </div>
        <div className={classes.errorSummaryItemContainer}>
          <div style={{ flex: 1 }}>
            <p>{ValidationModalConstants.requirements.TEACHING_PERIOD}</p>
          </div>
          {hasTeachingPeriodErrors
            ? warningIcon(courseErrors.teachingPeriodErrorCount)
            : okIcon}
        </div>
        <div className={classes.errorSummaryItemContainer}>
          <div style={{ flex: 1 }}>
            <p>{ValidationModalConstants.requirements.UNIT}</p>
          </div>
          {hasUnitErrors ? warningIcon(courseErrors.unitErrorCount) : okIcon}
        </div>
      </div>
    );
  };

  renderErrorSummary = () => {
    const { courseErrors } = this.props;
    let hasCourseErrors = courseErrors.courseErrorCount > 0;
    let hasTeachingPeriodErrors = courseErrors.teachingPeriodErrorCount > 0;
    let hasUnitErrors = courseErrors.unitErrorCount > 0;

    let noErrors =
      !hasCourseErrors && !hasTeachingPeriodErrors && !hasUnitErrors;

    return (
      <React.Fragment>
        <DialogTitle>Validation summary</DialogTitle>
        <div>{this.renderQuickSummary()}</div>
        <Divider style={{ marginBottom: 10 }} />
        <DialogContent>
          <h3>{ValidationModalConstants.title.VALIDATION_DETAILS}</h3>
          {noErrors ? (
            <div>
              <p>{ValidationModalConstants.summary.REQUIREMENTS_SATISFIED}</p>
            </div>
          ) : (
            <div>{this.renderValidationDetails()}</div>
          )}
        </DialogContent>
      </React.Fragment>
    );
  };

  renderBody = () => {
    const { isLoading, isError, courseErrors } = this.props;

    const { currentLoadingMessage } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <DialogTitle tabIndex="0">
            {ValidationModalConstants.title.VALIDATION_LOADING}
          </DialogTitle>
          <DialogContent>
            <div
              aria-live="assertive"
              aria-label="Validating your course plan, please wait..."
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <CircularProgress size={100} />
              <p>{currentLoadingMessage}</p>
            </div>
          </DialogContent>
        </React.Fragment>
      );
    }

    // if (this.timeout) {
    //  clearInterval(this.timeout)
    // }

    if (isError) {
      return (
        <React.Fragment>
          <DialogTitle tabIndex="0">Error Validating Course Plan</DialogTitle>
          <DialogContent tabIndex="0">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}>
              <LoadingErrorIcon
                style={{ color: 'red', marginLeft: 5, height: 40, width: 40 }}
              />
              <p>
                Encountered an error while trying to validate your course plan,
                please try again later
              </p>
            </div>
          </DialogContent>
        </React.Fragment>
      );
    }

    if (courseErrors && !courseErrors.hasErrors) {
      return (
        <React.Fragment>
          <DialogTitle tabIndex="0">Course plan validated</DialogTitle>
          <DialogContent tabIndex="0">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}>
              <OkIcon
                style={{ color: 'green', marginLeft: 5, height: 40, width: 40 }}
              />
              <p>
                We have successfully validated your plan and no requirements
                have been breached. We do recommend you to get your plan checked
                by a course advisor, before proceeding to enrol in{' '}
                <a
                  href="http://my.monash.edu.au/wes/"
                  style={{
                    color: '#2e2e2e',
                    '&:visited': { color: '#2e2e2e' },
                  }}>
                  Web Enrolment System (WES)
                </a>
              </p>
            </div>
          </DialogContent>
        </React.Fragment>
      );
    }

    return this.renderErrorSummary();
  };
  render() {
    const { fullScreen, isOpen, classes, isLoading } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={this.handleCloseRequest}
        classes={{
          paper: classes.MuiDialog,
          paperWidthXs: classes.MuiDialog,
          paperWidthSm: classes.MuiDialog,
        }}>
        {this.renderBody()}
        <DialogActions>
          <Button disabled={isLoading} onClick={this.handleCloseRequest}>
            OK!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const bindDispatchToProps = dispatch => {
  const actionBundle = {
    hideValidationModal,
  };

  return bindActionCreators(actionBundle, dispatch);
};

/**
 * Provide unit details as props.
 */
const bindStateToProps = state => {
  return {
    isOpen: state.UI.showingValidationModal,
    isLoading: state.Validation.courseErrorsLoading,
    isError: !!state.Validation.courseErrorsError,
    courseErrors: state.Validation.courseErrors,
  };
};

export default connect(
  bindStateToProps,
  bindDispatchToProps,
)(withStyles(styles)(withMobileDialog()(ValidationModal)));
