import CoursePlanEvent from '../CoursePlanEvent';
import { ENQUIRY_STATUSES } from '../../constants';

export default class CoursePlanEnquiryEvent extends CoursePlanEvent {
  constructor({
    eventType,
    timestamp,
    snapshotId,
    snapshotTimestamp,
    version,
    versionDelta,
    isCurrentVersion,
    user,
    id,
    publicCaseId,
    submissionState,
    enquiryMessage,
  }) {
    super({
      eventType,
      timestamp,
      snapshotId,
      snapshotTimestamp,
      version,
      versionDelta,
      isCurrentVersion,
      user,
    });

    // ordering of these is important, as validation of case id is dependent
    // on submission state
    this.submissionState = submissionState;
    this.id = id;
    this.publicCaseId = publicCaseId;
    this.enquiryMessage = enquiryMessage;
  }

  get id() {
    return this._id;
  }

  /**
   * The case id of the salesforce ticket if it has been
   * created
   */
  get publicCaseId() {
    return this._publicCaseId;
  }

  /**
   * The current submission state of the enquiry,
   * this can be
   */
  get submissionState() {
    return this._submissionState;
  }

  /**
   * The student provided message when the enquiry was
   * submitted
   */
  get enquiryMessage() {
    return this._enquiryMessage;
  }

  set id(id) {
    this._validateId(id);
    this._id = id;
  }

  set publicCaseId(publicCaseId) {
    this._validatePublicCaseId(publicCaseId);
    this._publicCaseId = publicCaseId;
  }

  set submissionState(submissionState) {
    this._validateSubmissionState(submissionState);
    this._submissionState = submissionState;
  }

  set enquiryMessage(enquiryMessage) {
    this._validateEnquiryMessage(enquiryMessage);
    this._enquiryMessage = enquiryMessage;
  }

  _validateId(id) {
    if (!id) {
      throw new Error(
          `CoursePlanEnquiryEvent was not givena an id value, received: ${id}`
      )
    }
  }

  _validatePublicCaseId(publicCaseId) {
    if (
      this._submissionState ===
      ENQUIRY_STATUSES.ENQUIRY_PUBLIC_CASE_ID_AVAILABLE
    ) {
      if (!publicCaseId) {
        throw new Error(
          `CoursePlanEnquiryEvent was not given a publicCaseId value, received: ${publicCaseId}`,
        );
      }
    }
  }

  _validateSubmissionState(submissionState) {
    if (!submissionState) {
      throw new Error(
        `CoursePlanEnquiryEvent was not given a submissionState value, received: ${submissionState}`,
      );
    }

    const statusValues = Object.values(ENQUIRY_STATUSES);
    if (!statusValues.includes(submissionState)) {
      throw new Error(
        `CoursePlanEnquiryEvent was not given a valid submissionState value, expected one of ${statusValues.join(
          ', ',
        )} but received ${submissionState}`,
      );
    }
  }

  _validateEnquiryMessage(enquiryMessage) {
    if (!enquiryMessage) {
      throw new Error(
        `CoursePlanEnquiryEvent was not given an enquiryMessage value, received: ${enquiryMessage}`,
      );
    }
  }
}
