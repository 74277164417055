/**
 * Because we use inline styling as well, it would be helpful if there is some
 * way to access colors in one spot (besides Semantic UI's site global less
 * file)
 */
import { Faculty } from '../constants';

const Colors = {
  MonashBlue: '#006CAB',

  red: '#DD341E',
  yellow: '#FDB515',
  green: '#96CA4F',
  teal: '#6AA324',
  blue: '#009FD8',
  purple: '#44477A',
  pink: '#B6006A',
  grey: '#423F40',
  black: '#231F20',
  white: '#FFFFFF',

  cyan: '#43C5E4',
  lightGrey: '#939598',
  lighterGrey: '#D1D3D4',
  lightestGrey: '#F6F6F6',

  ada: '#455A64',
  arts: '#B71C1C',
  buseco: '#007E8F', //'#00BCD4',
  edu: '#D81B60',
  eng: '#A86500', //'#FF9800',
  fit: '#7B1FA2',
  law: '#776C55',
  med: '#0B78D0', //'#2196f3',
  pha: '#6D7500', //'#7C8500',
  sci: '#008577', //'#009688',

  dialogGrey: '#F9FAFB',
};

export const facultyColors = {
  [Faculty.LAW]: Colors.law,
  [Faculty.ENG]: Colors.eng,
  [Faculty.ART]: Colors.arts,
  [Faculty.BUS]: Colors.buseco,
  [Faculty.FIT]: Colors.fit,
  [Faculty.MHS]: Colors.med,
  [Faculty.PHA]: Colors.pha,
  [Faculty.ADA]: Colors.arts,
  [Faculty.EDU]: Colors.edu,
  [Faculty.SCI]: Colors.sci,
  [Faculty.ALL]: '#006CAB',
};

export const facultyFontColorMap = {
  [Faculty.LAW]: '#ffffff',
  [Faculty.ENG]: '#ffffff',
  [Faculty.ART]: '#ffffff',
  [Faculty.BUS]: '#ffffff',
  [Faculty.FIT]: '#ffffff',
  [Faculty.MHS]: '#ffffff',
  [Faculty.PHA]: '#ffffff',
  [Faculty.ADA]: '#ffffff',
  [Faculty.EDU]: '#ffffff',
  [Faculty.SCI]: '#ffffff',
  [Faculty.ALL]: '#ffffff',
};

export default Colors;
