/**
 * Gets the next semester in the list of teaching periods.
 *
 * @author Saurabh Joshi, JXNS
 */
import EnrolmentYear from './EnrolmentYear';
import CoreUtils from './CoreUtils';
import EnrolmentUtils from './EnrolmentUtils';

export default class TeachingPeriodUtils {
  /**
   * Given a list of teaching periods, predicts which teaching period the student will likely to add next.
   *
   * TODO: Extend this prediction from semesters to any teaching period that has a cyclic component to it (e.g. Terms).
   *
   * @param teachingPeriods
   * @param teachingPeriodData
   * @returns {{index: *, year: number, code: string, teachingPeriodData: *}}
   */
  static nextTeachingPeriod = (teachingPeriods, teachingPeriodData) => {
    const { length } = teachingPeriods;
    let year = EnrolmentYear();

    const s1Code = 'S1-01';
    const s2Code = 'S2-01';

    let code = s1Code;

    if (length > 0) {
      if (teachingPeriodData) {
        const startIndex = teachingPeriodData.findIndex(
          ele => ele.code === teachingPeriods[length - 1].code,
        );
        const s1middleIndex = teachingPeriodData.findIndex(
          ele => ele.code === s1Code,
        );
        const s2middleIndex = teachingPeriodData.findIndex(
          ele => ele.code === s2Code,
        );

        // Get year from the last teaching period
        year = teachingPeriods[length - 1].year;

        if (startIndex >= s1middleIndex) {
          if (startIndex < s2middleIndex) {
            code = s2Code;
          } else {
            year++;
          }
        }
      } else {
        // Get year and code from last teaching period
        year = teachingPeriods[length - 1].year;
        code = teachingPeriods[length - 1].code;

        if (code === s1Code) {
          code = s2Code;
        } else {
          // We don't know if there is a semester two missing if we don't have the teaching periods data
          year++;
          code = s1Code;
        }
      }
    }

    return { index: length, year, code, teachingPeriodData };
  };

  /**
   * Converts a teaching period (by academic year and type code) into a human-friendly string format.
   *
   * @author Saurabh Joshi, JXNS
   * @param year
   * @param teachingPeriodData
   * @param code
   * @returns {string}
   */
  static getTeachingPeriodString = ({ year, teachingPeriodData, code }) => {
    let teachingPeriodName = code;

    if (teachingPeriodData) {
      const teachingPeriod = teachingPeriodData.find(
        element => element.code === code,
      );

      if (teachingPeriod !== undefined) {
        teachingPeriodName = teachingPeriod.name || code;
      }
    }

    return `${teachingPeriodName}, ${year}`;
  };

  static addUnit(
    placeholdersMap,
    teachingPeriods,
    tpIndex,
    unitIndex,
    unitToAdd,
  ) {
    // prevent units being added to preloaded teaching periods
    if (this.isPreloadedTeachingPeriod(teachingPeriods, tpIndex)) {
      return {
        teachingPeriods,
        unitToAdd: undefined,
      };
    }

    const hidingPlaceholders = [...placeholdersMap];
    const targetUnitSpace = teachingPeriods[tpIndex].units[unitIndex];
    if (targetUnitSpace && targetUnitSpace.placeholder) {
      hidingPlaceholders.push({
        coordinate: [tpIndex, unitIndex],
        unit: targetUnitSpace,
      });
    }

    return {
      unitToAdd: undefined, //reset unit after adding
      hidingPlaceholders,
      teachingPeriods: [
        ...teachingPeriods.slice(0, tpIndex),
        {
          ...teachingPeriods[tpIndex],
          units: [
            ...teachingPeriods[tpIndex].units.slice(0, unitIndex),
            unitToAdd,
            ...teachingPeriods[tpIndex].units.slice(unitIndex + 1),
          ],
        },
        ...teachingPeriods.slice(tpIndex + 1),
      ],
    };
  }

  static removeUnit(placeholdersMap, teachingPeriods, tpIndex, unitIndex) {
    // prevent units being deleted if preloaded
    if (this.isPreloadedTeachingPeriod(teachingPeriods, tpIndex)) {
      return {
        teachingPeriods,
      };
    }

    const unitPlaceholderIndex = placeholdersMap.findIndex(
      unitPlaceholder =>
        unitPlaceholder.coordinate[0] === tpIndex &&
        unitPlaceholder.coordinate[1] === unitIndex,
    );
    let unitPlaceholder;
    if (unitPlaceholderIndex > -1) {
      unitPlaceholder = {
        ...placeholdersMap[unitPlaceholderIndex].unit,
      };
    }

    return {
      hidingPlaceholders:
        unitPlaceholderIndex > -1
          ? [
              ...placeholdersMap.slice(0, unitPlaceholderIndex),
              ...placeholdersMap.slice(unitPlaceholderIndex + 1),
            ]
          : [...placeholdersMap],

      teachingPeriods: teachingPeriods.map((tp, index) => {
        if (index === tpIndex) {
          return {
            ...tp,
            units: [
              ...tp.units.slice(0, unitIndex),
              unitPlaceholder || null,
              ...tp.units.slice(unitIndex + 1),
            ],
          };
        }

        return tp;
      }),
    };
  }

  static moveUnit(
    placeholdersMap,
    teachingPeriods,
    tpIndexToMoveTo,
    tpIndexToMoveFrom,
    newUnitIndex,
    oldUnitIndex,
    unitToBeMoved,
  ) {
    // prevent units being moved to/from preloaded teaching periods
    if (
      this.isPreloadedTeachingPeriod(teachingPeriods, tpIndexToMoveFrom) ||
      this.isPreloadedTeachingPeriod(teachingPeriods, tpIndexToMoveTo)
    ) {
      return {
        teachingPeriods,
        unitToBeMoved: undefined,
        tpIndexOfUnitToBeMoved: 0,
        unitsIndexOfUnitToBeMoved: 0,
      };
    }

    const hidingPlaceholders = [...placeholdersMap];

    const teachingPeriodToMoveTo = teachingPeriods[tpIndexToMoveTo];
    const teachingPeriodToMoveFrom = teachingPeriods[tpIndexToMoveFrom];
    const unitToMoveTo = teachingPeriodToMoveTo.units[newUnitIndex];

    // if a unit exists in that spot and the unit is a placeholder, hide that placeholder
    if (unitToMoveTo && unitToMoveTo.placeholder) {
      hidingPlaceholders.push({
        coordinate: [tpIndexToMoveTo, newUnitIndex],
        unit: unitToMoveTo,
      });
    }

    // checks whether a placeholder existed under the unit to be moved
    const unitPlaceholderIndex = hidingPlaceholders.findIndex(
      unitPlaceholder =>
        unitPlaceholder.coordinate[0] === tpIndexToMoveFrom &&
        unitPlaceholder.coordinate[1] === oldUnitIndex,
    );

    const unitWasOnPlaceholder = unitPlaceholderIndex > -1;

    // if the unit being moved did have a placeholder under it, then we select the
    // unit placeholder
    let unitPlaceholder = null;
    if (unitWasOnPlaceholder) {
      unitPlaceholder = {
        ...hidingPlaceholders[unitPlaceholderIndex].unit,
      };
    }

    // if the unit was on a placeholder then remove it from the unit placeholder co-ordinate array,
    // otherwise just return the original array
    const newPlaceholderPositions = unitWasOnPlaceholder
      ? [
          ...hidingPlaceholders.slice(0, unitPlaceholderIndex),
          ...hidingPlaceholders.slice(unitPlaceholderIndex + 1),
        ]
      : [...hidingPlaceholders];

    const newStateOfTeachingPeriodToMoveFrom = {
      ...teachingPeriodToMoveFrom,
      units: [
        ...teachingPeriodToMoveFrom.units.slice(0, oldUnitIndex),
        unitPlaceholder,
        ...teachingPeriodToMoveFrom.units.slice(oldUnitIndex + 1),
      ],
    };

    const newStateOfTeachingPeriodToMoveTo = {
      ...teachingPeriodToMoveTo,
      units: [
        ...teachingPeriodToMoveTo.units.slice(0, newUnitIndex),
        unitToBeMoved,
        ...teachingPeriodToMoveTo.units.slice(newUnitIndex + 1),
      ],
    };

    if (tpIndexToMoveTo === tpIndexToMoveFrom) {
      return {
        hidingPlaceholders: newPlaceholderPositions,
        teachingPeriods: [
          ...teachingPeriods.slice(0, tpIndexToMoveTo),
          {
            ...teachingPeriodToMoveTo,
            units: CoreUtils.immutableSwap(
              teachingPeriodToMoveTo.units,
              newUnitIndex,
              oldUnitIndex,
              unitPlaceholder,
              unitToBeMoved,
            ),
          },
          ...teachingPeriods.slice(tpIndexToMoveTo + 1),
        ],
        unitToBeMoved: undefined,
        tpIndexOfUnitToBeMoved: 0,
        unitsIndexOfUnitToBeMoved: 0,
      };
    }

    return {
      hidingPlaceholders: newPlaceholderPositions,
      teachingPeriods: CoreUtils.immutableSwap(
        teachingPeriods,
        tpIndexToMoveTo,
        tpIndexToMoveFrom,
        newStateOfTeachingPeriodToMoveFrom,
        newStateOfTeachingPeriodToMoveTo,
      ),
      unitToBeMoved: undefined,
      tpIndexOfUnitToBeMoved: 0,
      unitsIndexOfUnitToBeMoved: 0,
    };
  }

  static swapUnit(
    teachingPeriods,
    tpIndexToMoveTo,
    tpIndexToMoveFrom,
    newUnitIndex,
    oldUnitIndex,
    unitToBeMoved,
    unitToSwapWith,
  ) {
    // prevent units being moved to/from preloaded teaching periods
    if (
      this.isPreloadedTeachingPeriod(teachingPeriods, tpIndexToMoveFrom) ||
      this.isPreloadedTeachingPeriod(teachingPeriods, tpIndexToMoveTo)
    ) {
      return {
        teachingPeriods,
        unitToBeMoved: undefined,
        tpIndexOfUnitToBeMoved: 0,
        unitsIndexOfUnitToBeMoved: 0,
      };
    }

    const teachingPeriodToMoveTo = teachingPeriods[tpIndexToMoveTo];
    const teachingPeriodToMoveFrom = teachingPeriods[tpIndexToMoveFrom];

    const newStateOfTeachingPeriodToMoveFrom = {
      ...teachingPeriodToMoveFrom,
      units: [
        ...teachingPeriodToMoveFrom.units.slice(0, oldUnitIndex),
        unitToSwapWith,
        ...teachingPeriodToMoveFrom.units.slice(oldUnitIndex + 1),
      ],
    };

    const newStateOfTeachingPeriodToMoveTo = {
      ...teachingPeriodToMoveTo,
      units: [
        ...teachingPeriodToMoveTo.units.slice(0, newUnitIndex),
        unitToBeMoved,
        ...teachingPeriodToMoveTo.units.slice(newUnitIndex + 1),
      ],
    };

    if (tpIndexToMoveTo === tpIndexToMoveFrom) {
      return {
        teachingPeriods: [
          ...teachingPeriods.slice(0, tpIndexToMoveTo),
          {
            ...teachingPeriodToMoveTo,
            units: CoreUtils.immutableSwap(
              teachingPeriodToMoveTo.units,
              oldUnitIndex,
              newUnitIndex,
              unitToBeMoved,
              unitToSwapWith,
            ),
          },
          ...teachingPeriods.slice(tpIndexToMoveTo + 1),
        ],
        unitToBeMoved: undefined,
        tpIndexOfUnitToBeMoved: 0,
        unitsIndexOfUnitToBeMoved: 0,
      };
    }

    return {
      teachingPeriods: CoreUtils.immutableSwap(
        teachingPeriods,
        tpIndexToMoveFrom,
        tpIndexToMoveTo,
        newStateOfTeachingPeriodToMoveTo,
        newStateOfTeachingPeriodToMoveFrom,
      ),
      unitToBeMoved: undefined,
      tpIndexOfUnitToBeMoved: 0,
      unitsIndexOfUnitToBeMoved: 0,
    };
  }
  static isPreloadedTeachingPeriod = (teachingPeriods, teachingPeriodIndex) => {
    return teachingPeriods[teachingPeriodIndex].isPreloaded;
  };

  /**
   * Based on a course structure will return an credit object of form:
   * {
   *    completed: the number of credit points that have be completed in the plan, either
   *                through enrolment data showing that it has been completed or through
   *                advanced standing credit
   *    planned:  the number of credit points that are in the plan through planned units,
   *                but without official enrolment data backing it up
   *    failed:   the number of credit points that were taken but failed
   *    total:    completed + planned credit
   * }
   */
  static getCreditPoints = (teachingPeriods, advancedStanding) => {
    let credit = {
      completed: 0,
      planned: 0,
      failed: 0,
      total: 0,
    };

    // get credit points from advanced standing
    if (advancedStanding) {
      credit.completed = advancedStanding.reduce((prev, cur) => {
        if (cur && cur.creditPoints) {
          return prev + cur.creditPoints;
        }

        return prev;
      }, credit.completed);
    }

    if (teachingPeriods) {
      teachingPeriods.forEach(tp => {
        const isPreloaded = tp.isPreloaded;
        tp.units.forEach(unit => {
          if (unit && unit.creditPoints) {
            if (isPreloaded && unit.grade) {
              if (EnrolmentUtils.isNonFailGrade(unit.grade)) {
                credit.completed += unit.creditPoints;
              }
              // Collect failed unit credit points
              else credit.failed += unit.creditPoints;
            } else {
              credit.planned += unit.creditPoints;
            }
          }
        });
      });
    }

    credit.total = credit.completed + credit.planned;

    return credit;
  };

  /**
   * Calculates the start year of the course based on the
   * teaching periods in the course plan,
   * if the course plan is empty it defaults to the
   * current year until the teaching periods are added
   */
  static getStartYear = teachingPeriods => {
    if (teachingPeriods && teachingPeriods.length > 0) {
      const firstTeachingPeriod = teachingPeriods[0];
      if (firstTeachingPeriod.year) {
        return firstTeachingPeriod.year;
      }
    }

    return EnrolmentYear();
  };
}
