const styles = theme => ({
  actionButton: {
    color: 'white',
    background: '#4caf50',
    '&:hover': {
      background: '#388e3c',
    },
  },
});

export default styles;
