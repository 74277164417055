import CoreUtils from '../../utils/CoreUtils';

export default class CoursePlanEvent {
  constructor({
    eventType,
    timestamp,
    snapshotId,
    snapshotTimestamp,
    version,
    versionDelta,
    isCurrentVersion,
    user,
  }) {
    this.eventType = eventType;
    this.timestamp = timestamp;
    this.snapshotId = snapshotId;
    this.snapshotTimestamp = snapshotTimestamp;
    this.version = version;
    this.versionDelta = versionDelta;
    this.isCurrentVersion = isCurrentVersion;
    this.user = user;

    // change to true for debug
    const DEBUG_FLAG = false;
    CoreUtils.classLogger(DEBUG_FLAG, this);
  }

  static TYPE() {
    return {
      ENQUIRY: 'ENQUIRY',
      FEEDBACK: 'FEEDBACK',
      STATUS_UPDATE: 'STATUS_UPDATE',
    };
  }

  /**
   * The event type indicates what type of event
   * it is, currently we have feedback events, status update events
   * and enquiry events
   */
  get eventType() {
    return this._eventType;
  }

  /**
   * timestamp is a Unix timestamp representation
   * of when the event occured
   */
  get timestamp() {
    return this._timestamp;
  }

  /**
   * Indicates which snapshot for the user's course plan this
   * event occured for
   */
  get snapshotId() {
    return this._snapshotId;
  }

  /**
   * The unix timestamp representation of the creation date of the snapshot the
   * event occured for
   */
  get snapshotTimestamp() {
    return this._snapshotTimestamp;
  }

  /**
   * The version of the courseplan that this event occured for
   * (version ranges from 1 to N, 1 is first, N is latest)
   */
  get version() {
    return this._version;
  }

  /**
   * The offset number from the version this event occured for
   * to the current version. i.e. if there are 3 versions,
   * and version = 1, then the delta to the latest version is 2
   */
  get versionDelta() {
    return this._versionDelta;
  }

  /**
   *  Boolean that lets indicates whether this event occured
   * in the latest version of the course plan
   */

  get isCurrentVersion() {
    return this._isCurrentVersion;
  }

  /**
   * A print friendly str indicating who was responsible for the event,
   * often a user's name or their authcate
   */
  get user() {
    return this._user;
  }

  set eventType(eventType) {
    this._validateEventType(eventType);
    this._eventType = eventType;
  }

  set timestamp(timestamp) {
    this._validateTimestamp(timestamp);
    this._timestamp = timestamp;
  }

  set snapshotId(snapshotId) {
    this._validateSnapshotId(snapshotId);
    this._snapshotId = snapshotId;
  }

  set snapshotTimestamp(snapshotTimestamp) {
    this._validatesnapshotTimestamp(snapshotTimestamp);
    this._snapshotTimestamp = snapshotTimestamp;
  }

  set version(version) {
    this._validateVersion(version);
    this._version = version;
  }

  set versionDelta(versionDelta) {
    this._validateVersionDelta(versionDelta);
    this._versionDelta = versionDelta;
  }

  set isCurrentVersion(isCurrentVersion) {
    this._validateIsCurrentVersion(isCurrentVersion);
    this._isCurrentVersion = isCurrentVersion;
  }

  set user(user) {
    this._validateUser(user);
    this._user = user;
  }

  _validateEventType(eventType) {
    if (!eventType) {
      throw new Error(
        `${
          this.constructor.name
        } was not given an eventType value, received: ${eventType}`,
      );
    }

    const eventTypes = Object.values(CoursePlanEvent.TYPE());
    if (!eventTypes.includes(eventType)) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a valid eventType value, expected one of ${eventTypes.join(
          ', ',
        )} but received ${eventType}`,
      );
    }
  }

  _validateTimestamp(timestamp) {
    if (!timestamp) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a timestamp value, received: ${timestamp}`,
      );
    }
  }

  _validateSnapshotId(snapshotId) {
    if (!snapshotId) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a snapshotId value, received: ${snapshotId}`,
      );
    }
  }

  _validatesnapshotTimestamp(snapshotTimestamp) {
    if (!snapshotTimestamp) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a snapshotTimestamp value, received: ${snapshotTimestamp}`,
      );
    }
  }

  _validateVersion(version) {
    if (isNaN(version) || version <= 0) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a valid version value, expected a number > 0, received: ${version}`,
      );
    }
  }

  _validateVersionDelta(versionDelta) {
    if (isNaN(versionDelta) || versionDelta < 0) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a valid version delta value, expected a number ≥ 0, received: ${versionDelta}`,
      );
    }
  }

  _validateIsCurrentVersion(isCurrentVersion) {
    if (
      isCurrentVersion === undefined ||
      isCurrentVersion === null ||
      typeof isCurrentVersion !== 'boolean'
    ) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a isCurrentVersion value, received: ${isCurrentVersion}`,
      );
    }
  }

  _validateUser(user) {
    if (!user) {
      throw new Error(
        `${
          this.constructor.name
        } was not given a user value, received: ${user}`,
      );
    }
  }
}
