import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

// MUI Icons
import { default as ArrowIcon } from '@material-ui/icons/ArrowForward';

// Components
import SmallLoader from '../../components/Base/SmallLoader';
import SearchSelect from '../../components/Base/SearchSelect';
import TextFieldValidator, {
  getErrorMessage,
} from '../../components/Text/TextFieldValidator';
import LIMITS from '../../constants/limits';

class PreloadCourseForm extends React.Component {
  state = {
    selectedAOSes: [],
    selectedUnconfirmedCourse: false,
    selectedCourseIndex: '',
  };

  handleSubmit = () => {
    const { onSubmit, coursePlanName } = this.props;
    const { selectedCourseIndex, selectedAOSes } = this.state;

    const selectedCourseCode = this.getSelectedCourse(selectedCourseIndex).id;

    onSubmit(
      selectedCourseCode,
      selectedAOSes,
      coursePlanName,
      this.getSelectedCourse(selectedCourseIndex),
    );
  };

  inputValid = () => {
    const { selectedCourseIndex } = this.state;
    const { coursePlanName } = this.props;

    if (selectedCourseIndex === '') {
      return false;
    }

    const selectedCourseCode = this.getSelectedCourse(selectedCourseIndex).id;
    return (
      coursePlanName !== '' &&
      !getErrorMessage(
        coursePlanName,
        TextFieldValidator.CharacterCount(
          LIMITS.CHARACTER_LIMITS.COURSE_PLAN_NAME,
        ).validators,
      ) &&
      !!selectedCourseCode
    );
  };

  getValidCourses = courseEnrolments => {
    return courseEnrolments.filter(
      course =>
        course.status === 'ENROLLED' ||
        course.status === 'INACTIVE' ||
        course.status === 'INTERMIT' ||
        course.status === 'UNCONFIRM',
    );
  };

  getSelectedCourse = index => {
    const { courseEnrolments } = this.props;
    const enrolledCourses = this.getValidCourses(courseEnrolments);
    const selectedCourse = enrolledCourses[index];

    return selectedCourse;
  };

  handleCourseSelect = e => {
    const { fetchAreasOfStudy } = this.props;

    const index = e.target.value;
    if (index === '') {
      // reset
      this.setState({
        selectedCourseIndex: '',
        selectedAOSes: [],
      });
    } else {
      const selectedCourse = this.getSelectedCourse(index);
      const selectedCourseCode = selectedCourse.id;

      this.setState(
        {
          selectedCourseIndex: index,
          selectedAOSes: [],
          selectedUnconfirmedCourse: selectedCourse.status === 'UNCONFIRM',
        },
        fetchAreasOfStudy(selectedCourseCode),
      );
    }
  };

  handleSelectedAosChange = selectedAOSes => {
    this.setState({
      selectedAOSes,
    });
  };

  courseEnrolmentsTransform = courseEnrolments => {
    const enrolledCourses = this.getValidCourses(courseEnrolments);

    return enrolledCourses.map(course => {
      if (course.course && course.course.title) {
        return `${course.id} - ${course.course.title}`;
      }

      return course.id;
    });
  };

  /**
   * This function filters down the list of available areas of study to
   * only the ones that are not already selected, this is so you do not
   * see already selected AOSes as options in the multi select component
   */
  getNonSelectedAOSObjs = courseAOSes => {
    const { selectedAOSes } = this.state;
    if (!courseAOSes) {
      return [];
    }

    const aosCodeList = selectedAOSes.map(aos => aos.code);
    const nonSelectedAOSes = courseAOSes.filter(aos => {
      return !aosCodeList.includes(aos.code);
    });

    return nonSelectedAOSes;
  };

  courseAOSesTransform = courseAOSes => {
    const nonSelectedAOSNames = this.getNonSelectedAOSObjs(courseAOSes).map(
      aos => ({ value: aos.code, label: aos.name }),
    );

    return nonSelectedAOSNames;
  };

  render() {
    const { selectedUnconfirmedCourse, selectedCourseIndex } = this.state;

    const {
      classes,
      courseEnrolmentsLoading,
      courseEnrolments,
      areasOfStudyLoading,
      areasOfStudy,
      areasOfStudyError,
    } = this.props;

    const selectedCourseCode =
      selectedCourseIndex === ''
        ? null
        : this.getSelectedCourse(selectedCourseIndex).id;

    const selectCourseDisabled = courseEnrolmentsLoading;
    const courseAOSValues = this.courseAOSesTransform(areasOfStudy);

    const renderAOSDropdown =
      courseAOSValues.length > 0 &&
      !areasOfStudyLoading &&
      !areasOfStudyError &&
      !!selectedCourseCode;

    let key = 0;
    return (
      <div id="preload-course-form">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="enrolled-course-helper">
            Select the course you want to plan for *
          </InputLabel>
          <Select
            disabled={selectCourseDisabled}
            value={this.state.selectedCourseIndex}
            onChange={this.handleCourseSelect}
            input={
              <Input
                name="Select from your enrolled courses"
                id="enrolled-course-helper"
                aria-label="Select the course you want to plan for"
              />
            }>
            <MenuItem key={`first-val`} value={''}>
              None
            </MenuItem>
            {this.courseEnrolmentsTransform(courseEnrolments).map((name, i) => {
              return (
                <MenuItem key={key++} value={i}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {selectedUnconfirmedCourse && (
          <Typography gutterBottom className={classes.unconfirmedStatusText}>
            You have selected an <b>UNCONFIRMED</b> course - this usually means
            that you haven't accepted your offer yet. To accept your offer
            please ensure you go to{' '}
            <a
              href="https://www.monash.edu/get-started"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.helperText}>
              Monash University Getting Started website
            </a>{' '}
            to complete your enrolment and accept your offer.
          </Typography>
        )}
        {areasOfStudyError && (
          <p className={classes.errorText} tabIndex="0">
            We could not load the areas of study for your course. This may be
            due to your course being too old. You can still create a course plan
            without selecting an area of study.
          </p>
        )}
        {areasOfStudyLoading && (
          <SmallLoader message="Checking for area of study options" />
        )}
        {renderAOSDropdown && (
          <SearchSelect
            isMulti
            classes={classes}
            textFieldProps={{
              label: 'Select your areas of study',
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={courseAOSValues}
            value={this.state.selectedAOSes}
            onChange={this.handleSelectedAosChange}
            placeholder="Search for areas of study"
            isDisabled={
              areasOfStudyLoading ||
              !selectedCourseCode ||
              courseAOSValues.length === 0
            }
          />
        )}
        <p className={classes.helperText} tabIndex="0">
          Fields marked with * are required
        </p>
        <div className={classes.submitArrowContainer}>
          <IconButton
            tabIndex={0}
            disabled={!this.inputValid()}
            className={
              this.inputValid()
                ? classes.submitArrowButton
                : `${classes.submitArrowButton} ${
                    classes.submitArrowButtonDisabled
                  }`
            }
            aria-label="Next"
            onClick={this.handleSubmit}>
            <ArrowIcon
              className={
                this.inputValid()
                  ? classes.submitArrow
                  : `${classes.submitArrow} ${classes.submitArrowDisabled}`
              }
            />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default PreloadCourseForm;

PreloadCourseForm.propTypes = {
  classes: PropTypes.object,
  areasOfStudy: PropTypes.array,
  coursePlanName: PropTypes.string,
  fetchAreasOfStudy: PropTypes.func,
  areasOfStudyError: PropTypes.bool,
  courseEnrolments: PropTypes.array,
  areasOfStudyLoading: PropTypes.bool,
  courseEnrolmentsLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};
