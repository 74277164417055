import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

// Components
import Unit from '../Unit';

/**
 * Implements the drag source contract.
 *
 * IMPORTANT: if the parent component height changes, the drag preview rendering
 * bugs out. Ensure that the parent component does not change height when the
 * drag preview is active.
 */
const unitSource = {
  beginDrag(props) {
    if (props.isSearchResult) {
      props.beginAddingUnit(props.unitToAdd);
    } else {
      props.movingUnit(props.unit, props.index, props.teachingPeriodIndex);
    }

    return {
      id: props.code + props.teachingPeriodIndex + 'u' + props.unitIndex,
      code: props.code,
      name: props.name,
      faculty: props.faculty,
    };
  },

  endDrag(props, monitor) {
    if (!props.isSearchResult && !monitor.didDrop() && props.cancelMovingUnit) {
      props.cancelMovingUnit(props.index, props.teachingPeriodIndex);
    }
  },
};

/**
 * Specifies the props to inject into your component.
 */
function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

/**
 * Hide original unit from view when it is being dragged.
 */
function getStyles(props) {
  const { isDragging } = props;

  return {
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '100%',
  };
}

/**
 * A wrapper for Unit, which uses React DnD's drag source to make the
 * unit message draggable.
 */
class UnitDragPreview extends React.Component {
  /**
   * Wrap Unit as a draggable source component.
   */
  render() {
    const { connectDragSource } = this.props;

    return connectDragSource(
      <div style={getStyles(this.props)}>
        <Unit {...this.props} />
      </div>,
    );
  }
}

UnitDragPreview.propTypes = {
  connectDragSource: PropTypes.func,
};

export default DragSource('unit', unitSource, collectSource)(UnitDragPreview);
