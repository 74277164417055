import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// MUI Components
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextFieldValidator, {
  getErrorMessage,
} from '../../Text/TextFieldValidator';

// MUI Icons
import { default as SubmitIcon } from '@material-ui/icons/Send';
import { default as ShowMoreIcon } from '@material-ui/icons/ExpandMore';
import { default as ShowLessIcon } from '@material-ui/icons/ExpandLess';
import { default as FeedbackIcon } from '@material-ui/icons/Chat';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Constants
import { ENQUIRY_STATUSES, EVENT_STATUSES, LIMITS } from '../../../constants';

// Models
import CoursePlanEvent from '../../../models/CoursePlanEvent';

class CoursePlanEventLog extends React.Component {
  getVersionStr = feedbackItem => {
    if (feedbackItem.isCurrentVersion) {
      return 'Current';
    }

    return `v${feedbackItem.version} (created ${moment(
      feedbackItem.snapshotTimestamp,
    ).format('DD/MM/YYYY')})`;
  };

  renderStatusUpdateRow = (statusUpdateEvent, i) => {
    const { isStudent } = this.props;

    const dateStr = `${moment(statusUpdateEvent.timestamp).format(
      'h:mmA DD/MM/YYYY',
    )}`;

    let statusStr = '';
    switch (statusUpdateEvent.statusType) {
      case EVENT_STATUSES.APPROVED:
        statusStr = 'approved course plan';
        break;
      case EVENT_STATUSES.FORCED_REVIEW:
        statusStr = 'begun review of course plan';
        break;
      case EVENT_STATUSES.REJECTED:
        statusStr = `sent course plan back to ${isStudent ? 'you' : 'student'}`;
        break;
      default:
        break;
    }

    const statusUpdateStr = `${statusUpdateEvent.user} ${statusStr}`;

    return (
      <TableRow key={`status-update-${i}`}>
        <TableCell>{dateStr}</TableCell>
        <TableCell>{statusUpdateStr}</TableCell>
        <TableCell>{this.getVersionStr(statusUpdateEvent)}</TableCell>
      </TableRow>
    );
  };

  renderEnquiryRow = (enquiry, isStudent, i) => {
    const { classes } = this.props;
    const userText = isStudent ? 'You' : 'The student';
    const requestText = `${userText} requested course advice for this course map.`;
    const text =
      enquiry.submissionState ===
      ENQUIRY_STATUSES.ENQUIRY_PUBLIC_CASE_ID_AVAILABLE
        ? `${requestText} Enquiry number ${enquiry.publicCaseId}. `
        : `${requestText} The enquiry number is not yet available. Enquiry id: ${enquiry.id}.`;

    return (
      <TableRow key={`enquiry-item-${i}`}>
        <TableCell>{`${moment(enquiry.timestamp).format(
          'h:mmA DD/MM/YYYY',
        )}`}</TableCell>
        <TableCell>
          {text}
          <span className={classes.feedbackItemQuotedText}>
            "{`${enquiry.enquiryMessage}`}"
          </span>
        </TableCell>
        <TableCell>{this.getVersionStr(enquiry)}</TableCell>
      </TableRow>
    );
  };

  renderFeedbackRow = (feedbackItem, i) => {
    const { classes } = this.props;

    return (
      <TableRow key={`feedback-item-${i}`}>
        <TableCell>{`${moment(feedbackItem.timestamp).format(
          'h:mmA DD/MM/YYYY',
        )}`}</TableCell>
        <TableCell style={{ minWidth: '400px' }}>
          {`${feedbackItem.user} said `}
          <span className={classes.feedbackItemQuotedText}>
            "{`${feedbackItem.feedbackText}`}"
          </span>
        </TableCell>
        <TableCell>{this.getVersionStr(feedbackItem)}</TableCell>
      </TableRow>
    );
  };

  renderEventRows = (events, showHidden, isStudent) => {
    let shownEventRows = events;
    if (shownEventRows[0].statusType === EVENT_STATUSES.APPROVED) {
      shownEventRows = [events[0]];
      if (events[1]) {
        shownEventRows.push(events[1]);
      }
    } else if (!showHidden) {
      shownEventRows = [events[0]];
    }

    return shownEventRows.map((event, i) => {
      switch (event.eventType) {
        case CoursePlanEvent.TYPE().FEEDBACK:
          return this.renderFeedbackRow(event, i);
        case CoursePlanEvent.TYPE().ENQUIRY:
          return this.renderEnquiryRow(event, isStudent, i);
        case CoursePlanEvent.TYPE().STATUS_UPDATE:
          return this.renderStatusUpdateRow(event, i);
        default:
          return null;
      }
    });
  };

  /**
   * Renders each of the individual feedback items, these include a timestamp
   * for when the feedback was provided, the person that submitted the feedback
   * and the actual feedback text
   */
  renderEventTable(showHidden) {
    const { eventItems, isStudent, isLoading, error } = this.props;

    let body = null;

    if (!error && !isLoading && eventItems && eventItems.length > 0) {
      body = (
        <React.Fragment>
          {this.renderEventRows(eventItems, showHidden, isStudent)}
        </React.Fragment>
      );
    }

    return (
      <div style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead style={{ whiteSpace: 'nowrap' }}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Feedback</TableCell>
              <TableCell>Plan Version</TableCell>
            </TableRow>
          </TableHead>
          {body && <TableBody>{body}</TableBody>}
        </Table>
      </div>
    );
  }

  inputValid() {
    const { value } = this.props;
    return (
      value !== '' &&
      !getErrorMessage(
        value,
        TextFieldValidator.CharacterCount(LIMITS.CHARACTER_LIMITS.FEEDBACK)
          .validators,
      )
    );
  }

  /**
   * Renders the feedback form along with a cancel and submit button
   */
  renderAddFeedback() {
    const { onChange, value, onCloseFeedback, onSubmit, classes } = this.props;
    return (
      <div>
        <TextFieldValidator
          value={value}
          {...TextFieldValidator.CharacterCount(
            LIMITS.CHARACTER_LIMITS.FEEDBACK,
          )}>
          <TextField
            className={classes.feedbackTextInput}
            multiline
            autoFocus
            label="Course Plan Feedback"
            onChange={onChange}
            value={value}
            FormHelperTextProps={{ className: classes.validationHelperText }}
          />
        </TextFieldValidator>

        <div className={classes.feedbackButtons}>
          <Button onClick={onCloseFeedback}>Cancel</Button>
          <Button
            disabled={!this.inputValid()}
            aria-label="Submit Feedback"
            onClick={onSubmit}>
            Submit&nbsp;
            <SubmitIcon />
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Renders the expand/collapse button that renders at the top of the
   * feedback section
   */
  renderExpandCollapse() {
    const {
      onExpand,
      onCollapse,
      editMode,
      isExpanded,
      classes,
      eventItems,
    } = this.props;
    const noMoreFeedback = eventItems.length <= 1;

    if (editMode || isExpanded) {
      return (
        <Button
          disabled={editMode || noMoreFeedback}
          className={classes.expandCollapseButton}
          aria-label="Hide Feedback"
          onClick={onCollapse}>
          <ShowLessIcon /> Hide Feedback
        </Button>
      );
    }

    return (
      <Button
        disabled={noMoreFeedback}
        className={classes.expandCollapseButton}
        aria-label="Show All Feedback"
        onClick={onExpand}>
        <ShowMoreIcon /> Show All Feedback
      </Button>
    );
  }

  renderBody() {
    const {
      editMode,
      isExpanded,
      classes,
      error,
      isLoading,
      eventItems,
    } = this.props;

    const showHidden = isExpanded || editMode;

    let statusMessage = null;

    if (error) {
      statusMessage = (
        <p className={classes.errorText}>
          Error loading feedback, please refresh the page or try again later.
        </p>
      );
    } else if (isLoading) {
      statusMessage = (
        <p className={classes.feedbackItemText}>Loading feedback...</p>
      );
    } else if (!eventItems || eventItems.length === 0) {
      statusMessage = (
        <p className={classes.feedbackItemText}>No feedback recorded</p>
      );
    }

    return (
      <React.Fragment>
        {editMode && this.renderAddFeedback()}
        {this.renderEventTable(showHidden)}
        {statusMessage}
      </React.Fragment>
    );
  }

  renderAddFeedbackButton() {
    const { classes, onOpenFeedback, editMode } = this.props;
    return (
      <div className={classes.addFeedbackButtonContainer}>
        <Button
          disabled={editMode}
          aria-label="Add Feedback"
          onClick={onOpenFeedback}>
          Add Feedback&nbsp;
          <FeedbackIcon />
        </Button>
      </div>
    );
  }

  render() {
    const { classes, canAddFeedback } = this.props;

    return (
      <div tabIndex="0" className={classes.feedbackContainer}>
        {canAddFeedback && this.renderAddFeedbackButton()}
        {this.renderBody()}
        {this.renderExpandCollapse()}
      </div>
    );
  }
}

export default withStyles(styles)(CoursePlanEventLog);

CoursePlanEventLog.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  editMode: PropTypes.bool,
  canAddFeedback: PropTypes.bool,
  onExpand: PropTypes.func,
  isLoading: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onCollapse: PropTypes.func,
  onOpenFeedback: PropTypes.func,
  onCloseFeedback: PropTypes.func,
  eventItems: PropTypes.array,
  enquiry: PropTypes.object,
  user: PropTypes.object,
};
