import NetworkService from './NetworkService';
import { MOCK_USER_RESP, MOCK_STUDENT_RESP } from './data/mockData';

import CoreUtils from '../utils/CoreUtils';
import { MOCK_RESPONSE_TIME } from './constants';

/**
 */
export default class UserService {
  // will return the user details of the currently logged in user
  static getUser() {
    const requestURI = `${window.location.origin}/api/profile`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('UserService.getUser()', requestURI, 'GET');
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_USER_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  static getStudentDetails(studentId) {
    const requestURI = `${window.location.origin}/api/students/${studentId}`;

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger(
        'CoursePlanService.getStudentDetails()',
        requestURI,
        'GET',
      );
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_STUDENT_RESP,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.get(requestURI, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
}
