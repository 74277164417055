import * as actions from '../../actions';
import { ERRORS } from '../../constants';

export const defaultState = {
  courseErrorsLoading: false,
  courseErrorsError: false,
  courseErrors: {
    other: [],
    unit: {},
    teachingPeriod: {},
    course: [],
    hasErrors: false,
    courseErrorCount: 0,
    teachingPeriodErrorCount: 0,
    unitErrorCount: 0,
    totalErrorCount: 0,
  },
};

/**
 * 
 example course errors object
const blah = {  
  "courseErrors":[  
     {  
        "type":"UNIT_VERSION_RULES",
        "level":"error",
        "title":"Have not passed enough units",
        "description":"Please enrol in one of these units: FIT3161",
        "references":[  
           {  
              "teachingPeriodStartingYear":2018,
              "teachingPeriodCode":"S1-01",
              "unitCode":"FIT3162"
           }
        ]
     },
     {  
        "type":"UNIT_VERSION_RULES",
        "level":"error",
        "title":"Have not passed enough units",
        "description":"Please enrol in one of these units: FIT2004",
        "references":[  
           {  
              "teachingPeriodStartingYear":2018,
              "teachingPeriodCode":"S2-01",
              "unitCode":"FIT3143"
           }
        ]
     },
     {  
        "type":"UNIT_VERSION_RULES",
        "level":"error",
        "title":"Have not passed enough units",
        "description":"Please enrol in one of these units: ENG1003, FIT1045, FIT1048, FIT1051, FIT1053",
        "references":[  
           {  
              "teachingPeriodStartingYear":2020,
              "teachingPeriodCode":"S1-01",
              "unitCode":"FIT3171"
           }
        ]
     },
     {  
        "type":"UNIT_VERSION_RULES",
        "level":"error",
        "title":"Have not passed enough units",
        "description":"Please enrol in one of these units: ENG1003, FIT1045, FIT1048, FIT1051, FIT1053",
        "references":[  
           {  
              "teachingPeriodStartingYear":2020,
              "teachingPeriodCode":"S1-01",
              "unitCode":"FIT3171"
           }
        ]
     },
     {  
        "type":"COURSE_VERSION_RULES",
        "level":"completion",
        "title":"Not enough credit points to complete course",
        "description":"You will need to complete 54 more credit points before you can complete this course.",
        "references":[  

        ]
     }
  ]
}
*/

/**
 * this helper function is used to process the course errors
 * into a format that is useful for the front-end. it also
 * keeps track of things like how many errors in each category exist
 * and whether any errors were detected
 */
const processCourseErrors = (courseErrors, actualTeachingPeriods) => {
  let errors = {
    unit: {},
    teachingPeriod: {},
    course: [],
    others: [], // should not fall through, but helps identify errors that we have missed the handling of
    hasErrors: false,
    courseErrorCount: 0,
    teachingPeriodErrorCount: 0,
    unitErrorCount: 0,
    totalErrorCount: 0,
  };

  courseErrors.forEach(error => {
    let errorCountUpdated = false;
    switch (error.type) {
      case ERRORS.UNIT_RULES:
      case ERRORS.UNIT_OFFERINGS:
      case ERRORS.DUPLICATE:
        error.references.forEach(ref => {
          const refYear = ref.teachingPeriodStartingYear;
          const refCode = ref.teachingPeriodCode;
          const refUnitCode = ref.unitCode;

          let validFlag = true;
          for (let i = 0; i < actualTeachingPeriods.length; i++) {
            const currentTp = actualTeachingPeriods[i];
            if (
              refYear === currentTp.year &&
              refCode === currentTp.code &&
              currentTp.isPreloaded
            ) {
              for (let k = 0; k < currentTp.units.length; k++) {
                const currentUnit = currentTp.units[k];
                if (
                  currentUnit &&
                  !currentUnit.isPlaceholder &&
                  currentUnit.unitCode === refUnitCode
                ) {
                  validFlag = false;
                }
              }
            }
          }

          if (validFlag) {
            if (!errorCountUpdated) {
              errors.unitErrorCount += 1;
              errorCountUpdated = true;
            }

            if (errors.unit[refYear] === undefined) {
              errors.unit[refYear] = {};
            }

            if (errors.unit[refYear][refCode] === undefined) {
              errors.unit[refYear][refCode] = {};
            }

            if (errors.unit[refYear][refCode][refUnitCode] === undefined) {
              errors.unit[refYear][refCode][refUnitCode] = [];
            }

            const errorObj = {
              level: error.level,
              title: error.title,
              description: error.description,
            };
            errors.unit[refYear][refCode][refUnitCode].push(errorObj);
          }
        });
        break;
      case ERRORS.NON_STANDARD_TEACHING_PERIODS:
      case ERRORS.INTERMISSION:
      case ERRORS.STUDY_LOAD:
        error.references.forEach(ref => {
          const refYear = ref.teachingPeriodStartingYear;
          const refCode = ref.teachingPeriodCode;

          let validFlag = true;
          for (let i = 0; i < actualTeachingPeriods.length; i++) {
            const currentTp = actualTeachingPeriods[i];
            if (
              refYear === currentTp.year &&
              refCode === currentTp.code &&
              currentTp.isPreloaded
            ) {
              validFlag = false;
            }
          }

          if (validFlag) {
            if (!errorCountUpdated) {
              errors.teachingPeriodErrorCount += 1;
              errorCountUpdated = true;
            }

            if (errors.teachingPeriod[refYear] === undefined) {
              errors.teachingPeriod[refYear] = {};
            }

            if (errors.teachingPeriod[refYear][refCode] === undefined) {
              errors.teachingPeriod[refYear][refCode] = [];
            }

            const errorObj = {
              level: error.level,
              title: error.title,
              description: error.description,
            };

            errors.teachingPeriod[refYear][refCode].push(errorObj);
          }
        });
        break;
      case ERRORS.COURSE_RULES:
      case ERRORS.TIME_LIMIT:
        errors.courseErrorCount += 1;
        const errorObj = {
          level: error.level,
          title: error.title,
          description: error.description,
        };
        errors.course.push(errorObj);
        break;
      default:
        errors.other.push(error);
    }
  });

  errors.totalErrorCount =
    errors.unitErrorCount +
    errors.teachingPeriodErrorCount +
    errors.courseErrorCount;

  if (errors.totalErrorCount > 0) {
    errors.hasErrors = true;
  }

  return errors;
};

/**
 * Handles the storage of the errors returned by MSCV
 */
const ValidationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSE_ERRORS_PENDING:
      return {
        ...state,
        courseErrorsLoading: true,
        courseErrorsError: false,
      };

    case actions.FETCH_COURSE_ERRORS_FULFILLED:
      return {
        ...state,
        courseErrorsLoading: false,
        courseErrors: processCourseErrors(
          action.payload,
          action.teachingPeriods,
        ),
      };

    case actions.FETCH_COURSE_ERRORS_REJECTED:
      return {
        ...state,
        courseErrorsLoading: false,
        courseErrorsError: action.payload,
      };

    // If the course plan is updated reset the errors
    case actions.UPDATE_COURSE_PLAN:
      return defaultState;

    default:
      return state;
  }
};

export default ValidationReducer;
