import React from 'react';

// Components
import EnquiryTypesConfig from '../../../components/Admin/EnquiryTypesConfig';

// Services
import AdminService from '../../../services/AdminService';

const MAX_NUMBER_OF_CHARACTERS = 100;

const generateValidator = enquiryTypes => ({
  validators: [
    value => {
      if (value.length > MAX_NUMBER_OF_CHARACTERS) {
        return `Exceed the maximum number of characters (${
          value.length
        }/${MAX_NUMBER_OF_CHARACTERS})`;
      }
      return false;
    },
    value => {
      if (value.toLowerCase().trim() === 'other') {
        return "Cannot add type 'Other' as it is not allowed";
      }
      return false;
    },
    value => {
      if (
        enquiryTypes.filter(str => {
          return str.toLowerCase() === value.toLowerCase().trim();
        }).length > 0
      ) {
        return `Cannot add type '${value.trim()}' as it already exists`;
      }
      return false;
    },
  ],
  generateHelperText: value => {
    return `Type a new enquiry type that a student can select. Current character count (${
      value.length
    }/${MAX_NUMBER_OF_CHARACTERS})`;
  },
});

class EnquiryTypesConfigContainer extends React.Component {
  state = {
    lastModifiedBy: null,
    lastModified: null,
    enquiryTypes: null,
    newType: '',
    isLoading: true,
    isError: false,
    readOnlyMode: true,
    loadedState: null,
    showModal: false,
    latestMoveIndex: null,
  };

  componentDidMount() {
    this.fetchConfiguration();
  }

  fetchConfiguration = () => {
    this.setState({ isLoading: true });
    AdminService.getEnquiryTypeStatus()
      .then(data => {
        const { value } = data;
        const enquiryTypes = JSON.parse(value);
        const pageState = {
          isLoading: false,
          isError: false,
        };
        this.updateStateWithConfiguration(data, enquiryTypes, pageState);
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          isError: true,
        });
      });
  };

  handleSubmit = () => {
    this.setState({
      modalLoading: true,
      hasSuccesfullyUpdatedConfiguration: true,
    });
    const { enquiryTypes } = this.state;
    AdminService.updateEnquiryTypes(enquiryTypes)
      .then(data => {
        const enquiryTypes = JSON.parse(data.value);
        const pageState = {
          readOnlyMode: true,
          modalLoading: false,
          modalError: false,
        };
        this.updateStateWithConfiguration(data, enquiryTypes, pageState);
      })
      .catch(err => {
        this.setState({
          modalLoading: false,
          modalError: true,
        });
      });
  };

  updateStateWithConfiguration = (data, enquiryTypes, pageState) => {
    const { lastModified, lastModifiedBy } = data;

    const loadedState = {
      lastModified: lastModified,
      lastModifiedBy: lastModifiedBy,
      enquiryTypes,
    };

    this.setState({ ...loadedState, ...pageState, loadedState });
  };

  deleteItemFromArray = index => {
    const { enquiryTypes } = this.state;
    this.setState({
      enquiryTypes: [
        ...enquiryTypes.slice(0, index),
        ...enquiryTypes.slice(index + 1),
      ],
    });
  };

  enableWriteMode = () => {
    this.setState({ readOnlyMode: false });
  };

  cancelWriteMode = () => {
    this.setState({
      readOnlyMode: true,
      latestMoveIndex: null,
      ...this.state.loadedState,
    });
  };

  handleNewEnquiryTypeChange = event => {
    this.setState({
      newType: event.target.value,
    });
  };

  handleSubmitNewType = () => {
    const { newType, enquiryTypes } = this.state;
    this.setState({
      enquiryTypes: [...enquiryTypes, newType],
    });
    this.setState({
      newType: '',
    });
  };

  openWarningModal = () => {
    this.setState({
      hasSuccesfullyUpdatedConfiguration: false,
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  moveEnquiryTypeUpward = index => {
    this.setState({
      latestMoveIndex: index - 1,
    });
    const { enquiryTypes } = this.state;
    const tempArray = [...enquiryTypes];
    const tempItem = tempArray[index - 1];
    tempArray[index - 1] = tempArray[index];
    tempArray[index] = tempItem;
    this.setState({
      enquiryTypes: tempArray,
    });
  };

  moveEnquiryDownward = index => {
    this.setState({
      latestMoveIndex: index + 1,
    });
    const { enquiryTypes } = this.state;
    const tempArray = [...enquiryTypes];
    const tempItem = tempArray[index + 1];
    tempArray[index + 1] = tempArray[index];
    tempArray[index] = tempItem;
    this.setState({
      enquiryTypes: tempArray,
    });
  };

  render() {
    const {
      lastModifiedBy,
      lastModified,
      enquiryTypes,
      isLoading,
      readOnlyMode,
      newType,
      showModal,
      modalLoading,
      modalError,
      hasSuccesfullyUpdatedConfiguration,
      latestMoveIndex,
    } = this.state;
    return (
      <div>
        <EnquiryTypesConfig
          lastModified={lastModified}
          lastModifiedBy={lastModifiedBy}
          enquiryTypes={enquiryTypes}
          isLoading={isLoading}
          readOnlyMode={readOnlyMode}
          newType={newType}
          showModal={showModal}
          modalLoading={modalLoading}
          modalError={modalError}
          latestMoveIndex={latestMoveIndex}
          hasSuccesfullyUpdatedConfiguration={
            hasSuccesfullyUpdatedConfiguration
          }
          cancelWriteMode={this.cancelWriteMode}
          enableWriteMode={this.enableWriteMode}
          deleteItemFromArray={this.deleteItemFromArray}
          onNewEnquiryTypeChange={this.handleNewEnquiryTypeChange}
          onSubmitNewType={this.handleSubmitNewType}
          openWarningModal={this.openWarningModal}
          onMoveEnquiryTypeUpward={this.moveEnquiryTypeUpward}
          onMoveEnquiryTypeDownward={this.moveEnquiryDownward}
          onSubmit={this.handleSubmit}
          onClose={this.handleModalClose}
          validatorProps={generateValidator(enquiryTypes)}
        />
      </div>
    );
  }
}

export default EnquiryTypesConfigContainer;

export { generateValidator };
