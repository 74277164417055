import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Selectors
import { getCreditPoints } from '../../selectors/selectors';

// Actions
import * as courseActions from '../../actions/CourseActions';

// Components
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CourseOverview from '../../components/Course/CourseOverview';
import AddingUnitIndicator from '../../components/Status/AddingUnitIndicator';
import MovingUnitIndicator from '../../components/Status/MovingUnitIndicator';
import SmallLoader from '../../components/Base/SmallLoader';

// Utils
import { createEditableTextField } from '../../components/Text/EditableTextField';
import TextFieldValidator from '../../components/Text/TextFieldValidator';
import { LIMITS } from '../../constants';

// Course plan name components
const coursePlanNameValidators = TextFieldValidator.mergeValidators([
  TextFieldValidator.NotEmpty('Course plan name cannot be empty.'),
  TextFieldValidator.CharacterCount(LIMITS.CHARACTER_LIMITS.COURSE_PLAN_NAME),
]);

const H2EditableTextField = createEditableTextField({
  fontSize: '1.5em',
  fontWeight: 'bold',
});

/**
 * Container for passing in props from redux to CourseOverview component
 */
class CourseOverviewContainer extends React.Component {
  state = {
    isEditingCoursePlanName: false,
    coursePlanName: this.props.coursePlanName,
  };

  startEditCoursePlanName = () => {
    this.setState({
      isEditingCoursePlanName: true,
      // Reset course plan name to value received from props
      coursePlanName: this.props.coursePlanName,
    });
  };

  updateCoursePlanName = () => {
    const { setCoursePlanName } = this.props;
    setCoursePlanName(this.state.coursePlanName.trim());
    this.setState({ isEditingCoursePlanName: false });
  };

  cancelEditCoursePlanName = () => {
    this.setState({ isEditingCoursePlanName: false });
  };

  setCoursePlanName = evt => {
    this.setState({ coursePlanName: evt.target.value });
  };

  render() {
    const {
      readOnly,
      courseName,
      courseCode,
      courseFaculty,
      shouldDisplayAdding,
      shouldDisplayMoving,
      unitToAdd,
      unitToBeMoved,
      cancelAddingUnit,
      creditPoints,
      requiredCreditPoints,
      isError,
      isLoading,
      weightedAverageMark,
      showGrades,
    } = this.props;

    let mainContent = null;

    if (isLoading) {
      mainContent = (
        <SmallLoader sizeOverride={40} message={'Loading Course Information'} />
      );
    } else if (shouldDisplayAdding) {
      mainContent = (
        <AddingUnitIndicator
          unitCode={unitToAdd && unitToAdd.unitCode}
          onCancelAddingUnit={cancelAddingUnit}
          creditPoints={creditPoints.total}
          requiredCreditPoints={requiredCreditPoints}
        />
      );

      //prefers adding over moving although ideally they would never be true at same time
    } else if (shouldDisplayMoving && !shouldDisplayAdding) {
      mainContent = (
        <MovingUnitIndicator
          unitCode={unitToBeMoved && unitToBeMoved.unitCode}
          creditPoints={creditPoints.total}
          requiredCreditPoints={requiredCreditPoints}
        />
      );
    } else {
      const courseSelected =
        !!courseCode && !shouldDisplayAdding && !shouldDisplayMoving;

      mainContent = (
        <CourseOverview
          readOnly={readOnly}
          courseSelected={courseSelected}
          courseName={courseName}
          courseCode={courseCode}
          courseFaculty={courseFaculty}
          creditPoints={creditPoints.total}
          requiredCreditPoints={requiredCreditPoints}
          weightedAverageMark={weightedAverageMark}
          isError={isError}
          showGrades={showGrades}
        />
      );
    }

    return (
      <>
        <div style={{ padding: '0.5em 1em', margin: '0.5em 0 0.3em 0' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <H2EditableTextField
              readOnly={readOnly}
              ariaName="course plan name"
              {...coursePlanNameValidators}
              value={
                this.state.isEditingCoursePlanName
                  ? this.state.coursePlanName
                  : this.props.coursePlanName
              }
              editing={this.state.isEditingCoursePlanName}
              onChange={this.setCoursePlanName}
              onStartEdit={this.startEditCoursePlanName}
              onCancelEdit={this.cancelEditCoursePlanName}
              onSubmitEdit={this.updateCoursePlanName}
            />
          </div>
          {/* NOTE: studentName will only be populated if an ADVISER navigates to this view from the list plans page. */}
          {/* (i.e. when the API call to grab the student's profile has been made) */}
          {this.props.studentName && (
            <Typography variant="caption" color="textSecondary">
              {this.props.studentName} ({this.props.studentId})
            </Typography>
          )}
        </div>
        <Divider />
        {mainContent}
      </>
    );
  }
}

/**
 * The Course overview just displays some simple info for the students about the course they have
 * currently selected
 */
const mapStateToProps = state => {
  return {
    courseName: state.Handbook.courseInfo.courseName,
    courseCode: state.Handbook.courseInfo.courseCode,
    courseFaculty: state.Handbook.courseInfo.faculty,
    coursePlanName: state.CoursePlan.coursePlanName,
    studentId: state.CoursePlan.currentStudentId,
    studentName: state.CoursePlan.currentStudentName,
    readOnly: state.UI.readOnly,
    shouldDisplayMoving: state.UI.showingMovingUnitUI,
    shouldDisplayAdding: state.UI.showingAddingUnitUI,
    unitToBeMoved: state.PlanInstance.unitToBeMoved,
    unitToAdd: state.PlanInstance.unitToAdd,
    weightedAverageMark: state.PlanInstance.weightedAverageMark,
    creditPoints: getCreditPoints(state),
    requiredCreditPoints: state.Handbook.courseInfo.creditPoints,
    isError: state.Handbook.courseInfoLoadError,
    isLoading: state.Handbook.courseInfoLoading,
    showGrades: state.UI.showingGrades,
  };
};

/**
 * Need access to cancelAddingUnit function
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    ...courseActions,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseOverviewContainer);

CourseOverviewContainer.propTypes = {
  coursePlanName: PropTypes.string,
  coursePlanNameLoading: PropTypes.bool,
  courseName: PropTypes.string,
  courseCode: PropTypes.string,
  courseFaculty: PropTypes.string,
  shouldDisplayAdding: PropTypes.bool,
  shouldDisplayMoving: PropTypes.bool,
  unitToBeMoved: PropTypes.object,
  unitToAdd: PropTypes.object,
  cancelAddingUnit: PropTypes.func,
  weightedAverageMark: PropTypes.string,
  showGrades: PropTypes.bool,
  creditPoints: PropTypes.shape({
    completed: PropTypes.number,
    planned: PropTypes.number,
    failed: PropTypes.number,
    total: PropTypes.number,
  }),
  requiredCreditPoints: PropTypes.number,
};
