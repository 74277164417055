import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Icons
import { default as InfoIcon } from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';

// Actions
import { hidePreferDesktopBanner } from '../../../actions/UIActions';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

/**
 * PreferDesktopBanner shows a warning on mobile-size screens.
 */
export class PreferDesktopBanner extends Component {
  render() {
    const { showNotification, hidePreferDesktopBanner, classes } = this.props;
    return (
      <div
        className={classes.root}
        style={{
          transition: 'all 0.4s ease-in-out',
          transform: showNotification ? null : 'scale(0)',
          height: showNotification ? '3rem' : '0rem',
        }}>
        <div
          style={{
            minWidth: '100%',
            background: '#ffd223',
            padding: '1em',
            zIndex: '99999999',
            fontSize: '14px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <InfoIcon
            style={{
              marginRight: '0.4rem',
              marginTop: '0.01rem',
              width: 20,
              height: 20,
            }}
          />
          <span>
            MonPlan is better on a desktop device.&nbsp;
            <button
              aria-label="Got it, close this banner."
              style={{
                border: 'none',
                backgroundColor: 'inherit',
                padding: 0,
                fontSize: 'inherit',
                cursor: 'pointer',
                display: 'inline-block',
                textDecoration: 'underline',
              }}
              onClick={hidePreferDesktopBanner}>
              Got it!
            </button>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showNotification: state.UI.showPreferDesktopBanner,
});

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    hidePreferDesktopBanner,
  };
  return bindActionCreators(actionBundle, dispatch);
};

PreferDesktopBanner.propTypes = {
  classes: PropTypes.object,
  showNotification: PropTypes.bool,
  dispatchHidePreferDesktopBanner: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PreferDesktopBanner));
