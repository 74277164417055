import NetworkService from './NetworkService';
import { MONPLAN_API_URL } from '../constants';

import { MOCK_UNIT_SEARCH_RESPONSE } from './data/mockData';

import CoreUtils from '../utils/CoreUtils';
import { MOCK_RESPONSE_TIME } from './constants';

export default class UnitService {
  static searchUnits(searchText, filters) {
    const requestURI = `${MONPLAN_API_URL}/api/search/units`;
    const body = {
      search: searchText,
      filters: filters,
      from: 0,
      size: 30,
    };

    if (process.env.NODE_ENV !== 'production') {
      CoreUtils.logger('UnitService.searchUnits()', requestURI, 'POST', body);
      return CoreUtils.createMockRequest(
        'resolve',
        MOCK_UNIT_SEARCH_RESPONSE,
        MOCK_RESPONSE_TIME,
      );
    }

    return NetworkService.post(requestURI, body);
  }
}
