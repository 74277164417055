import React from 'react';
import PropTypes from 'prop-types';

import BasicActionModal from '../BasicActionModal';

class RejectCourseModal extends React.Component {
  render() {
    const { open, onClose, onReject } = this.props;
    return (
      <BasicActionModal
        open={open}
        onClose={onClose}
        onAction={onReject}
        title={'Send Back To Student'}
        body={
          'Are you sure that you want to send this course plan back to the student? This will let the student edit the plan again, and will lock you from editing the plan until you review it again.'
        }
        action={'Send back to student'}
      />
    );
  }
}

export default RejectCourseModal;

RejectCourseModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onReject: PropTypes.func,
};
