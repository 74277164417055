import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Moment from 'moment';

// Components
import Button from '@material-ui/core/Button';

import { loadCoursePlan } from '../actions/CoursePlanActions';

class Print extends Component {
  componentDidMount = () => {
    if (this.props.params.coursePlanId) {
      this.props.loadCoursePlan(this.props.params.coursePlanId);
    }
  };

  extractCourseMap = (teachingPeriods, teachingPeriodData) => {
    let mapCodesToName = {};
    if (!teachingPeriods || !teachingPeriodData) {
      return null;
    }
    teachingPeriodData.forEach(data => {
      mapCodesToName[data.code] = data.name;
    });
    let courseMap = {
      student: {},
      teachingPeriods: [],
    };
    teachingPeriods.forEach(teachingPeriod => {
      const knownPeriodCode = mapCodesToName[teachingPeriod.code];
      courseMap.teachingPeriods.push({
        // "Summer Semester 1, 2016" VS "2016-SSA-01"
        // if we know the period code, it can be made more human-readable, otherwise serialise it
        period: knownPeriodCode
          ? `${knownPeriodCode}, ${teachingPeriod.year}`
          : `${teachingPeriod.year}-${teachingPeriod.code}`,
        // sometimes units can be null, so they are converted to placeholder units
        units: teachingPeriod.units.map(unit => {
          return unit || { placeholder: true };
        }),
      });
    });
    return courseMap;
  };

  render = () => {
    const { teachingPeriods, teachingPeriodData, courseInfo, studentName, approvalAdviserName } = this.props;
    const courseMap = this.extractCourseMap(
      teachingPeriods,
      teachingPeriodData,
    );
    return (
      courseMap && (
        <div style={{ backgroundColor: '#fff' }}>
          <div
            style={{
              padding: '20px',
              maxWidth: '1100px',
              margin: '0 auto',
              backgroundColor: 'white',
            }}>
            <style type="text/css">
              {/* forces portrait mode on page print */}
              {/* https://medium.com/@cristiansima/controlling-print-orientation-in-react-in-javascript-78768ffe5f38 */}
              {'@media print{@page {size: portrait}}'}
            </style>
            <div
              className="hide-on-print"
              style={{
                textAlign: 'center',
                paddingBottom: '5vh',
                borderBottom: '1px solid rgba(0,0,0,0.4)',
              }}>
              <h1 style={{ fontWeight: 300 }}>
                {' '}
                Print this page and take it to your course adviser!
              </h1>
              <Button variant="raised" primary onClick={window.print}>
                Print Course Plan
              </Button>
            </div>
            <img src="/img/monashlogo.png" alt="logo" height="100px" />
            <img
              src="/img/logo_black.svg"
              alt="logo"
              height="100px"
              style={{ padding: '20px 0', float: 'right' }}
            />
            <p style={{ textAlign: 'center', fontWeight: '700' }}>
              Generated by MonPlan. Plan your course by visiting
              monplan.apps.monash.edu
            </p>
            <p
              style={{
                textAlign: 'center',
                fontWeight: '700',
                fontSize: '12px',
              }}>
              MonPlan is a planning tool allowing you to interact with your
              course and a course adviser. Selecting units in MonPlan does not
              enrol you in your selected course. To enrol in units, you must go
              to the Web Enrolment System (WES). You should consider the
              appropriateness of the information in MonPlan to your individual
              educational objectives before making any enrolment variations via
              WES. Every effort has been made to ensure the course and unit
              information in MonPlan is correct, however, we make no
              representation or warranty with regards to its accuracy,
              completeness or reliability.
            </p>
            {courseInfo.courseCode && courseInfo.courseName &&
              <h2 style={{ textAlign: 'center' }}>
                Course: {courseInfo.courseCode + ' - ' + courseInfo.courseName}
              </h2>
            }
            {studentName &&
              <h3 style={{ textAlign: 'center' }}>
                  Student Name: {studentName}
              </h3>}
            {approvalAdviserName &&
              <h3 style={{ textAlign: 'center' }}>
                Approved by Course Adviser: {approvalAdviserName}
              </h3>}
            {courseMap.teachingPeriods.map(teachingPeriod => {
              return (
                <div
                  style={{ margin: '0 auto 24px', pageBreakInside: 'avoid' }}>
                  <h1 style={{ fontSize: '18px', margin: '4px 8px' }}>
                    {teachingPeriod.period}
                  </h1>
                  <div style={{ display: 'flex' }}>
                    {teachingPeriod.units.map(
                      unit =>
                        unit.placeholder ? (
                          // placeholder units should still occupy space, but not be visible
                          <div
                            style={{
                              flex: `${6}`,
                              border: '1px dotted rgba(0,0,0,0.5)',
                              padding: 8,
                              minWidth: '150px',
                              minHeight: 100,
                              color: 'rgba(0,0,0,0.2)',
                              fontStyle: 'italic',
                            }}>
                            {unit.unitCode || 'Empty'}
                          </div>
                        ) : (
                          <div
                            style={{
                              flex: `${unit.creditPoints}`,
                              border: '1px solid rgba(0,0,0,0.2)',
                              minWidth: 150,
                              padding: 8,
                              minHeight: 100,
                            }}>
                            <span style={{ fontSize: '16px' }}>
                              {unit.unitCode}
                            </span>
                            <br />
                            <span style={{ fontSize: '13px' }}>
                              {unit.unitName}
                            </span>
                            <br />
                            <span style={{ fontSize: '13px' }}>
                              Credit points: {unit.creditPoints}
                            </span>
                          </div>
                        ),
                    )}
                  </div>
                </div>
              );
            })}

            <div>
              <i style={{ color: '#999', fontSize: 14 }}>
                This course map was generated at{' '}
                {Moment().format('DD MMM YYYY h:mm:ss a')}
              </i>
            </div>
          </div>
        </div>
      )
    );
  };
}

const mapStateToProps = state => {
  return {
    courseInfo: state.Handbook.courseInfo,
    teachingPeriods: state.PlanInstance.teachingPeriods,
    teachingPeriodData: state.TeachingPeriods.data,
    studentName: state.CoursePlan.studentName,
    approvalAdviserName: state.CoursePlan.approvalAdviserName,
  };
};

const mapDispatchToProps = dispatch => {
  const actionBundle = {
    loadCoursePlan,
  };

  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Print);
