import React from 'react';

import moment from 'moment';

// MUI Components
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

// Components
import SmallLoader from '../../components/Base/SmallLoader';

// Services
import AdminService from '../../services/AdminService';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import { configurationTypes } from '../../constants';

class AdminHistoryContainer extends React.Component {
  state = {
    history: [],
    selectedItem: '',
    isLoading: false,
    hasErrored: false,
  };

  componentDidMount = () => {
    const { query } = this.props;
    if (
      query !== null &&
      query !== '' &&
      configurationTypes.indexOf(query.toLowerCase()) > -1
    ) {
      this.setState({
        selectedItem: query,
      });
      this.fetchData(query);
    }
  };

  fetchData = selectedItem => {
    this.setState({ isLoading: true });
    AdminService.getHistoryForMaintenanceType(selectedItem)
      .then(resp => {
        this.setState({
          history: resp,
          isLoading: false,
          hasErrored: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          hasErrored: true,
        });
      });
  };

  handleChangeType = event => {
    this.setState({ selectedItem: event.target.value });
    this.fetchData(event.target.value);
  };

  renderTable = () => {
    const { classes } = this.props;
    const { history } = this.state;
    return (
      <div style={{ overflowX: 'auto' }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Configuration Status</TableCell>
              <TableCell className={classes.configurationCol}>
                Configuration Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  {moment(row.timestamp).format('DD/MM/YYYY hh:mm a')}
                </TableCell>
                <TableCell>{row.user}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell className={classes.configurationCol}>
                  {row.newValue}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { isLoading, hasErrored, selectedItem } = this.state;

    let content = null;
    if (selectedItem === '') {
      content = <div>Select something above to continue!</div>;
    } else if (isLoading) {
      content = <SmallLoader message="Loading history..." />;
    } else if (hasErrored) {
      content = <div>Something went wrong please try again later!</div>;
    } else {
      content = this.renderTable();
    }

    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="selected-history-type">
            Select History Type
          </InputLabel>
          <Select
            value={selectedItem}
            onChange={this.handleChangeType}
            inputProps={{
              name: 'selectedItem',
              id: 'selected-history-type',
            }}>
            {configurationTypes.map((type, index) => {
              return (
                <MenuItem value={type} key={index}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          <Divider />
          {content}
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(AdminHistoryContainer);
