import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

// MUI Components
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';

// MUI Icons
import { default as DeleteIcon } from '@material-ui/icons/Delete';
import { default as MoreVertIcon } from '@material-ui/icons/MoreVert';
import { default as InfoIcon } from '@material-ui/icons/Info';
import { default as MoveIcon } from '@material-ui/icons/OpenWith';
import { default as WarningIcon } from '@material-ui/icons/Warning';
import { default as CloseIcon } from '@material-ui/icons/Close';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Utils
import FacultyUtils from '../../../utils/FacultyUtils';

class UnitDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unitActionsMenuOpen: false,
      unitActionsAnchorEl: null,
      unitErrorOpen: false,
      unitErrorAnchorEl: null,
      contextMenuOpen: false,
      anchorPositionTop: 0,
      anchorPositionLeft: 0,
    };
  }

  static SUBTITLE_CHARACTER_THRESHOLD(numberOfUnits) {
    if (numberOfUnits <= 4) {
      return 40;
    } else if (numberOfUnits === 5) {
      return 30;
    } else if (numberOfUnits === 6) {
      return 20;
    }

    return 0; // at 7+ units, you will need to rely on unit codes
  }

  handleErrorClick = e => {
    e.stopPropagation();

    this.setState({
      unitErrorOpen: true,
      unitErrorAnchorEl: e.currentTarget,
    });
  };

  handleRequestErrorClose = e => {
    e.stopPropagation();
    this.setState({
      unitErrorOpen: false,
    });
  };

  // we stop propagation for all of these so that the
  // event doesnt bubble up to the unit onClick handler
  handleUnitMenuClick = e => {
    e.stopPropagation();
    this.setState({
      unitActionsMenuOpen: true,
      unitActionsAnchorEl: e.currentTarget,
    });
  };

  handleUnitMenuClose = e => {
    e && e.stopPropagation && e.stopPropagation();
    this.setState({
      unitActionsMenuOpen: false,
    });
  };

  handleContextMenuClose = e => {
    e && e.stopPropagation && e.stopPropagation();
    this.setState({
      contextMenuOpen: false,
      anchorPositionTop: 0,
      anchorPositionLeft: 0,
    });
  };

  handleRemoveUnitClick = e => {
    e.stopPropagation();
    this.props.onRemoveUnitRequest();
    this.handleUnitMenuClose();
    this.handleContextMenuClose();
  };

  handleUnitInformationClick = e => {
    e.stopPropagation();
    this.props.onUnitInformationRequest();
    this.handleUnitMenuClose();
    this.handleContextMenuClose();
  };

  handleMoveUnitClick = e => {
    e.stopPropagation();
    this.props.onMoveUnitRequest();
    this.handleUnitMenuClose();
    this.handleContextMenuClose();
  };

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.setState({
        unitErrorOpen: false,
      });
    }
  };

  handleOnClick = e => {
    e.stopPropagation();
    this.setState({
      unitErrorOpen: false,
    });
  };

  handleOnContextMenu = e => {
    e.preventDefault();
    this.setState({
      contextMenuOpen: true,
      anchorPositionLeft: e.clientX,
      anchorPositionTop: e.clientY,
    });
  };

  renderSubtitle = subtitle => {
    const { numberOfUnits, isSearchResult } = this.props;

    if (isSearchResult) {
      return subtitle;
    }

    const threshold = UnitDisplay.SUBTITLE_CHARACTER_THRESHOLD(numberOfUnits);

    return subtitle.length <= threshold
      ? subtitle
      : subtitle.slice(0, threshold) + '...';
  };

  renderErrorButton = () => {
    const { classes, errors } = this.props;
    const { unitErrorOpen, unitErrorAnchorEl } = this.state;

    return (
      <div>
        <IconButton
          onClick={this.handleErrorClick}
          className={'unitError ' + classes.unitErrorButton}
          aria-label={
            "View errors detected with this unit's placement within plan"
          }>
          <WarningIcon style={{ width: 20, height: 20 }} />
        </IconButton>
        <Popover
          open={unitErrorOpen}
          anchorEl={unitErrorAnchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          anchorPosition={{ horizontal: 'left', vertical: 'top' }}
          onKeyPress={this.handleKeyDown.bind(this)}
          onClose={this.handleRequestErrorClose}>
          <div
            style={{ maxWidth: 350, paddingRight: 15 }}
            onKeyPress={this.handleKeyDown.bind(this)}>
            <CloseIcon
              tabIndex={0}
              style={{ float: 'right', marginBottom: '5px' }}
              onClick={this.handleOnClick.bind(this)}
              onKeyDown={this.handleKeyDown.bind(this)}
            />
            <p
              tabIndex={0}
              style={{
                fontWeight: '550',
                paddingLeft: 15,
                fontSize: '13px',
                paddingTop: '5px',
              }}>
              The following issues were identified:{' '}
            </p>
            <ul style={{ fontSize: '12px' }} tabIndex={0}>
              {errors.map((d, i) => {
                return <li key={i}>{d.description}</li>;
              })}
            </ul>
          </div>
        </Popover>
      </div>
    );
  };

  renderGrade = () => {
    const { numberOfUnits, classes, grade, accessibleUnitCode } = this.props;

    /**
     * Offset is how far from the right
     * we want the grade to display on the card
     * if there are 6 or more units then the right
     * offset is 0px, because it is quite squished,
     * for teaching periods containing 5 or less unit
     * slots we base the offset on the length of the
     * grade string we have. if it is 1 character long,
     * we center it via an 8px offset, otherwise 4px does
     * the trick.
     */
    let rightOffset = 0;
    if (numberOfUnits < 6) {
      if (grade.length === 1) {
        rightOffset = 8;
      } else {
        rightOffset = 4;
      }
    }

    return (
      <span
        tabIndex={0}
        aria-label={`The grade of ${accessibleUnitCode} is ${grade
          .split('')
          .join(' ')}`}
        className={`unit-display-grade ${classes.unitCardGrade}`}
        style={{
          right: rightOffset,
        }}>
        {grade}
      </span>
    );
  };

  renderContextMenu = () => {
    const {
      isSearchResult,
      accessibleUnitCode,
      viewOnly,
      isPreloaded,
    } = this.props;
    const {
      anchorPositionTop,
      anchorPositionLeft,
      contextMenuOpen,
    } = this.state;
    if (isSearchResult) {
      return null;
    }
    return (
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={{ top: anchorPositionTop, left: anchorPositionLeft }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={contextMenuOpen}
        onClose={this.handleContextMenuClose}>
        <MenuItem
          aria-label={`View Information for unit ${accessibleUnitCode}`}
          onClick={this.handleUnitInformationClick}>
          <InfoIcon />
          <ListItemText>{`Unit Information`}</ListItemText>
        </MenuItem>
        {!viewOnly && !isPreloaded && this.renderQuickActionsForMovableUnits()}
      </Menu>
    );
  };

  renderQuickActionsForMovableUnits = () => {
    const { accessibleUnitCode } = this.props;
    return [
      <MenuItem
        key={'move-unit-button'}
        aria-label={`Move Unit ${accessibleUnitCode}`}
        onClick={this.handleMoveUnitClick}>
        <MoveIcon />
        <ListItemText>{`Move Unit`}</ListItemText>
      </MenuItem>,
      <Divider key={`divider`} />,
      <MenuItem
        key={'remove-unit-button'}
        aria-label={`Remove Unit ${accessibleUnitCode}`}
        onClick={this.handleRemoveUnitClick}>
        <DeleteIcon style={{ color: '#DD341E' }} />
        <ListItemText primaryTypographyProps={{ style: { color: '#DD341E' } }}>
          Remove Unit
        </ListItemText>
      </MenuItem>,
    ];
  };

  renderActions = () => {
    const {
      isError,
      isSearchResult,
      accessibleUnitCode,
      // title,
      classes,
      viewOnly,
      grade,
      onUnitInformationRequest,
      isPreloaded,
    } = this.props;
    const { unitActionsAnchorEl, unitActionsMenuOpen } = this.state;
    if (isSearchResult) {
      return (
          <div className={classes.searchResultActionContainer}>
            <IconButton
                style={{
                  height: 24,
                  width: 24,
                }}
                onClick={e => {
                  e.stopPropagation();
                  onUnitInformationRequest();
                }}
                onKeyDown={e => {
                  switch (e.keyCode) {
                    case 13: // Enter
                      e.preventDefault();
                      onUnitInformationRequest();
                      break;
                    case 32: // Space
                      e.preventDefault();
                      onUnitInformationRequest();
                      break;
                    default:
                      e.preventDefault();
                      break;
                  }
                }}
                aria-label={`View details for unit ${accessibleUnitCode}`}>
              <InfoIcon />
            </IconButton>
          </div>
      );
    }

    return (
      <div className={classes.unitCardActionContainer}>
        {grade && this.renderGrade()}
        <IconButton
          style={{
            color: isError ? 'white' : 'rgba(0, 0, 0, 0.54)',
            width: '24px',
            height: '24px',
          }}
          aria-label={`Open action menu for unit ${accessibleUnitCode}`}
          onClick={this.handleUnitMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={unitActionsAnchorEl}
          open={unitActionsMenuOpen}
          onClose={this.handleUnitMenuClose}>
          <MenuItem
            aria-label={`View Information for unit ${accessibleUnitCode}`}
            onClick={this.handleUnitInformationClick}>
            <InfoIcon />
            <ListItemText>{`Unit Information`}</ListItemText>
          </MenuItem>
          {!viewOnly &&
            !isPreloaded &&
            this.renderQuickActionsForMovableUnits()}
        </Menu>
        {isError && this.renderErrorButton()}
      </div>
    );
  };

  render() {
    const {
      classes,
      isError,
      isPlaceholder,
      title,
      subtitle,
      faculty,
      numberOfUnits,
      points,
      isPreloaded,
      viewOnly,
      accessibleUnitCode,
      isSearchResult,
      ariaResultMessage,
      ariaOverride,
    } = this.props;

    const facultyStripeColor = isError
      ? 'rgba(0, 0, 0, 0.2)'
      : FacultyUtils.getFacultyColour(faculty);

    let aria = isPlaceholder
      ? `${title}, ${subtitle}`
      : `Unit ${accessibleUnitCode}, ${subtitle}. Managed by ${faculty}. Worth ${points} credit points`;

    if (isSearchResult) {
      aria = `${ariaResultMessage} ${aria}. Press enter to add unit to your course plan.`;
    }
    if (ariaOverride) {
      aria = ariaOverride;
    }

    return (
      <div
        tabIndex={0}
        aria-label={aria}
        className={
          classes.unitCardContainer +
          (isPreloaded || viewOnly ? ` ${classes.readOnly}` : '') +
          (isPreloaded ? ` ${classes.unitCardPreloaded}` : '') +
          (isError ? ` ${classes.unitCardContainerError}` : '') +
          (isPlaceholder ? ` ${classes.unitCardContainerPlaceholder}` : '')
        }
        onContextMenu={this.handleOnContextMenu}>
        {!isPlaceholder && (
          <div
            className={classes.facultyStripe}
            style={{ background: facultyStripeColor }}>
            <div className={classes.facultyStripeText}>
              {FacultyUtils.getFacultyKey(faculty)}
            </div>
          </div>
        )}
        <div className={classes.unitCardContent}>
          <div>
            <b>{title}</b>
            <p className={classes.marginReset} style={{ marginTop: '5px' }}>
              {this.renderSubtitle(subtitle)}
            </p>
          </div>
          {!isPlaceholder && (
            <p
              className={classes.marginReset}
              style={{ marginTop: '5px', fontSize: '11px' }}>{`${points} ${
              numberOfUnits >= 6 ? 'CP' : 'Credit Points'
            }`}</p>
          )}
        </div>
        {!isPlaceholder ? this.renderActions() : <div />}
        <MediaQuery minDeviceWidth={1025}>
          {!isPlaceholder && this.renderContextMenu()}
        </MediaQuery>
      </div>
    );
  }
}

export default withStyles(styles)(UnitDisplay);

UnitDisplay.propTypes = {
  accessibleUnitCode: PropTypes.string,
  ariaOverride: PropTypes.string,
  ariaResultMessage: PropTypes.string,
  classes: PropTypes.object,
  errors: PropTypes.array,
  faculty: PropTypes.string,
  grade: PropTypes.string,
  isError: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  isPreloaded: PropTypes.bool,
  isSearchResult: PropTypes.bool,
  numberOfUnits: PropTypes.number,
  onMoveUnitRequest: PropTypes.func,
  onRemoveUnitRequest: PropTypes.func,
  points: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  viewOnly: PropTypes.bool,
};
