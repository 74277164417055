export default class CoreUtils {
  static immutableSwap(
    list,
    indexA,
    indexB,
    itemAValue = null,
    itemBValue = null,
  ) {
    const itemA = itemAValue || list[indexA];
    const itemB = itemBValue || list[indexB];

    if (indexA > indexB) {
      return [
        ...list.slice(0, indexB),
        itemA,
        ...list.slice(indexB + 1, indexA),
        itemB,
        ...list.slice(indexA + 1),
      ];
    }

    if (indexB > indexA) {
      return [
        ...list.slice(0, indexA),
        itemB,
        ...list.slice(indexA + 1, indexB),
        itemA,
        ...list.slice(indexB + 1),
      ];
    }

    return list;
  }

  static logger(serviceStr, requestURI, method, body = null) {
    const codeStyleStr =
      'padding: 1px 3px; border: 1px solid #DFE1E6; border-radius: 3px; background: #F4F5F7;';
    const url = requestURI.replace(`${window.location.origin}`, '');

    let minBody = body;
    // The blobs are generally quite huge so here we simplify it
    // to make the logs more readable
    if (minBody && minBody.blob) {
      minBody.blob = '{{CourseDataBlob}}';
    }

    if (minBody) {
      // eslint-disable-next-line no-console
      console.log(
        `%c${serviceStr}\n${method} -> ${url}\nbody:`,
        codeStyleStr,
        minBody,
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(`%c${serviceStr}\n${method} -> ${url}`, codeStyleStr);
    }
  }

  static objectLogger(str, obj) {
    const codeStyleStr =
      'padding: 1px 3px; border: 1px solid #DFE1E6; border-radius: 3px; background: #F4F5F7;';

    // eslint-disable-next-line no-console
    console.log(`%c${str}\n`, codeStyleStr, obj);
  }

  static classLogger(debugMode, classInstance) {
    if (process.env.NODE_ENV !== 'production' && debugMode) {
      CoreUtils.objectLogger(
        `${classInstance.constructor.name} created with properties:`,
        classInstance,
      );
    }
  }

  static createMockRequest(resolveOrReject, mockData, millisecondDelay) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        switch (resolveOrReject) {
          case 'resolve':
            return resolve(mockData);
          case 'reject':
            return reject(mockData);
          default:
            console.error(
              "CoreUtils.mockRequest() not passed a resolve/reject string, please specify either 'resolve' or 'reject'",
            );
            return resolve('Error!!!');
        }
      }, millisecondDelay);
    });
  }
}
