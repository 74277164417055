import React, { Component } from 'react';
import { Link } from 'react-router';

// MUI Components
import { Button, Typography, withStyles } from '@material-ui/core';

// Components
import Header from '../components/Base/Header';

// Constants
import { CORE } from '../constants';

// Styling
const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: `${4 * theme.spacing.unit}px 0`,
    minHeight: '100vh',
  },
  button: {
    display: 'block',
    margin: `${2 * theme.spacing.unit}px auto`,
    maxWidth: 600,
    textAlign: 'center',
  },
});

class Landing extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Header />
        <div className={classes.root}>
          <Typography variant="display3" align="center" gutterBottom>
            Page Not Found
          </Typography>
          <Typography variant="subheading" align="center" gutterBottom>
            This page may have been moved or no longer exists. If you believe
            this is an issue, feel free to report it below.
          </Typography>
          <Button
            fullWidth
            variant="raised"
            color="primary"
            component={Link}
            to="/"
            style={{ color: 'white' }}
            classes={{ root: classes.button }}>
            Return to the Homepage
          </Button>
          <Button
            fullWidth
            variant="raised"
            color="error"
            component="a"
            href={CORE.EXTERNAL_LINKS.MONPLAN.ISSUE}
            classes={{ root: classes.button }}>
            Report an Issue
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Landing);
