// Actions
import * as actions from './index';

// Services
import EnrolmentService from '../services/EnrolmentService';

/**
 * Fetches the Course enrolments for a given student,
 * If a studentId is given then we use the advisor enabled API
 * for fetching student enrolments. If no student ID is provided
 * we use the student enabled enrolment api
 */
export const fetchCourseEnrolments = (studentId = null) => dispatch => {
  dispatch({
    type: actions.FETCH_COURSE_ENROLMENTS_PENDING,
  });

  let request;
  if (studentId) {
    request = EnrolmentService.getStudentCourseEnrolments(studentId);
  } else {
    request = EnrolmentService.getCourseEnrolments();
  }

  request
    .then(resp => {
      dispatch({
        type: actions.FETCH_COURSE_ENROLMENTS_FULFILLED,
        payload: resp,
      });
    })
    .catch(err => {
      dispatch({
        type: actions.FETCH_COURSE_ENROLMENTS_REJECTED,
        payload: err,
      });
    });
};
