import CoursePlanEvent from '../CoursePlanEvent';
import { EVENT_STATUSES } from '../../constants';

export default class CoursePlanStatusUpdateEvent extends CoursePlanEvent {
  constructor({
    eventType,
    timestamp,
    snapshotId,
    snapshotTimestamp,
    version,
    versionDelta,
    isCurrentVersion,
    user,
    eventId,
    coursePlanId,
    statusType,
  }) {
    super({
      eventType,
      timestamp,
      snapshotId,
      snapshotTimestamp,
      version,
      versionDelta,
      isCurrentVersion,
      user,
    });

    this.eventId = eventId;
    this.coursePlanId = coursePlanId;
    this.statusType = statusType;
  }

  /**
   * The id of the associated status update event entity
   */
  get eventId() {
    return this._eventId;
  }

  /**
   * The id of the courseplan associated with this update event
   */
  get coursePlanId() {
    return this._coursePlanId;
  }

  /**
   * The type of status update, currently covers 3 different cases:
   * 1. When a course plan is approved
   * 2. When an advisor 'forces' review of a course plan
   * 3. When an advisor sends a course plan back to a student to edit
   */
  get statusType() {
    return this._statusType;
  }

  set eventId(eventId) {
    this._validateEventId(eventId);
    this._eventId = eventId;
  }

  set coursePlanId(coursePlanId) {
    this._validateCoursePlanId(coursePlanId);
    this._coursePlanId = coursePlanId;
  }

  set statusType(statusType) {
    this._validateStatusType(statusType);
    this._statusType = statusType;
  }

  _validateEventId(eventId) {
    if (!eventId) {
      throw new Error(
        `CoursePlanStatusUpdateEvent was not given an eventId value, received: ${eventId}`,
      );
    }
  }

  _validateCoursePlanId(coursePlanId) {
    if (!coursePlanId) {
      throw new Error(
        `CoursePlanStatusUpdateEvent was not given a coursePlanId value, received: ${coursePlanId}`,
      );
    }
  }

  _validateStatusType(statusType) {
    if (!statusType) {
      throw new Error(
        `CoursePlanStatusUpdateEvent was not given a statusType value, received: ${statusType}`,
      );
    }

    const statusTypes = Object.values(EVENT_STATUSES);
    if (!statusTypes.includes(statusType)) {
      throw new Error(
        `CoursePlanEvent was not given a valid statusType value, expected one of ${statusTypes.join(
          ', ',
        )} but received ${statusType}`,
      );
    }
  }
}
