import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';

// MUI Components
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// MUI Icons
import { Clear as ClearIcon } from '@material-ui/icons';
import { default as HomeIcon } from '@material-ui/icons/Home';
import { default as SearchIcon } from '@material-ui/icons/Search';
import { default as LogInIcon } from '@material-ui/icons/Person';
import { default as LogOutIcon } from '@material-ui/icons/ExitToApp';
import { default as FeedbackIcon } from '@material-ui/icons/Feedback';
import { default as InfoIcon } from '@material-ui/icons/Info';
import { default as ConfigIcon } from '@material-ui/icons/Build';
import { default as HistoryIcon } from '@material-ui/icons/History';

// Styling
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

// Constants
import { ROUTES, CORE } from '../../../constants';

class AccountDrawer extends Component {
  hideDrawerAndRedirect(link) {
    //this.props.closeAccountDrawer();

    if (link) {
      // handle login/logout separately from usual redirects
      if (link === ROUTES.LOGOUT || link === ROUTES.LOGIN) {
        // behaves strangely if browserHistory.push() is used
        window.location.href = `${window.location.origin}${link}`;
      } else {
        browserHistory.push(link);
      }
    }
  }

  generateAccountBackgroundUrl = (authcate = '') => {
    const code = ((authcate.charCodeAt(0) || 0) % 25) + 1;
    return `https://storage.googleapis.com/monplan-fonts/media/${code}.jpg`;
    // `linear-gradient(to top, rgba(46, 49, 65, 0.6), rgba(46, 49, 65, 0.6)), url(${this.generateAccountBackgroundUrl(
    //   user ? user.firstName : '',
    // )})`
  };

  render() {
    const { isAuth, user, onClose, classes, open, children } = this.props;

    return (
      <Drawer open={open} onClose={onClose}>
        <div className={classes.account}>
          <IconButton
            style={{
              color: 'white',
              height: 10,
              width: 10,
              position: 'absolute',
              top: 10,
              right: 17,
            }}
            aria-label="Close action menu sidebar"
            onClick={onClose}>
            <ClearIcon />
          </IconButton>
          <Typography color="inherit" variant="title">
            Action Menu
          </Typography>
        </div>
        <List
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{ flex: 1 }}>
            {children && (
              <div>
                {children}
                <Divider />
              </div>
            )}

            {/* HOME PAGE (CONTEXTUAL TO USER ROLE) */}
            {isAuth &&
              ((user.isStudent && (
                <ListItem
                  button
                  component={Link}
                  to={ROUTES.LIST_PLANS}
                  onClick={this.props.onClose}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Plans" />
                </ListItem>
              )) ||
                (user.isStaff && (
                  <ListItem
                    button
                    component={Link}
                    to={ROUTES.STUDENT_SEARCH}
                    onClick={this.props.onClose}>
                    <ListItemIcon>
                      <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Find a student by ID" />
                  </ListItem>
                )) ||
                (user.isAdmin && (
                  <div>
                    <ListItem
                      button
                      component={Link}
                      to={ROUTES.ADMIN_DASHBOARD}
                      onClick={this.props.onClose}>
                      <ListItemIcon>
                        <ConfigIcon />
                      </ListItemIcon>
                      <ListItemText primary="Configure MonPlan" />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to={ROUTES.ADMIN_HISTORY}
                      onClick={this.props.onClose}>
                      <ListItemIcon>
                        <HistoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Config History" />
                    </ListItem>
                    <Divider />
                  </div>
                )))}

            {/* LOGIN/LOGOUT BUTTON */}
            {isAuth ? (
              <ListItem
                button
                component={Link}
                to={ROUTES.LOGOUT}
                onClick={this.props.onClose}>
                <ListItemIcon>
                  <LogOutIcon />
                </ListItemIcon>
                <ListItemText primary={'Log Out'} secondary={''} />
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => this.hideDrawerAndRedirect(ROUTES.LOGIN)}>
                <ListItemIcon>
                  <LogInIcon />
                </ListItemIcon>
                <ListItemText
                  primary={'Log In'}
                  secondary={'Use your Monash Account!'}
                />
              </ListItem>
            )}
          </div>
          {/* ABOUT BUTTON */}
          <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={CORE.EXTERNAL_LINKS.MONPLAN.HOME}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="About MonPlan" />
          </ListItem>

          {/* FEEDBACK BUTTON */}
          <ListItem
            button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={CORE.EXTERNAL_LINKS.MONASH.SERVICE_DESK}>
            <ListItemIcon>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText primary="Experiencing problems?" />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

/**
 * map state to props redux
 * @param state
 * @returns {{Account: *}}
 */
const mapStateToProps = state => {
  return {
    isAuth: state.User.isAuth,
    user: state.User.user,
  };
};

AccountDrawer.propTypes = {
  children: PropTypes.node,
  closeAccountDrawer: PropTypes.func,
  isAuth: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(withStyles(styles)(AccountDrawer));
