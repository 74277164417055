import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Range } from 'rc-slider';

// MUI Components
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// Styling
import 'rc-slider/assets/index.css';

// Local data
import teachingPeriods from './teachingPeriods';
import faculties from './faculties';
import locations from './locations';
import unitTypes from './unitTypes';

// Actions
import {
  updateFacultyFilter,
  updateLocationFilter,
  updateCreditPointRangeFilter,
  updateTeachingPeriodFilter,
  updateUnitTypeFilter,
} from '../../actions/FilterActions';

class UnitSearchFilterContainer extends Component {
  state = {
    showFilter: false,
    selectedLocations: [],
    selectedFaculties: [],
    selectedTeachingPeriods: [],
    selectedUnitTypes: [],
  };

  toggleShowFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  handleLocationChange = event => {
    this.setState({ selectedLocations: event.target.value });
    this.props.updateLocationFilter(event.target.value);
    this.props.onFilterChange();
  };

  handleFacultyChange = event => {
    this.setState({ selectedFaculties: event.target.value });
    this.props.updateFacultyFilter(event.target.value);
    this.props.onFilterChange();
  };

  handleCreditPointChange = e => {
    this.props.updateCreditPointRangeFilter(e[0], e[1]);
    this.props.onFilterChange();
  };

  handleTeachingPeriodChange = event => {
    this.setState({ selectedTeachingPeriods: event.target.value });
    this.props.updateTeachingPeriodFilter(event.target.value);
    this.props.onFilterChange();
  };

  handleUnitTypeChange = event => {
    this.setState({ selectedUnitTypes: event.target.value });
    this.props.updateUnitTypeFilter(event.target.value);
    this.props.onFilterChange();
  };

  render() {
    const {
      showFilter,
      selectedFaculties,
      selectedLocations,
      selectedTeachingPeriods,
      selectedUnitTypes,
    } = this.state;

    return (
      <div>
        <Button
          onClick={this.toggleShowFilter}
          disabled={this.props.unitSearchIsLoading}
          style={{ backgroundColor: 'rgb(209, 211, 212)' }}
          aria-label={this.state.showFilter ? 'Hide filters' : 'Show filters'}
          fullWidth>
          {this.state.showFilter ? 'Hide Filters' : 'Show Filters'}
        </Button>
        {showFilter && (
          <div style={{ marginTop: '10px' }}>
            <FormControl style={{ margin: 8, width: '100%' }}>
              <InputLabel>Locations</InputLabel>
              <Select
                disabled={!showFilter}
                multiple
                value={selectedLocations}
                aria-label={'Select location'}
                onChange={this.handleLocationChange}
                style={{ width: '100%', display: 'block' }}>
                {locations.map((location, i) => (
                  <MenuItem
                    key={i}
                    value={location.value}
                    style={
                      selectedLocations.indexOf(location.value) >= 0
                        ? { fontWeight: 'bold' }
                        : {}
                    }>
                    {location.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ margin: 8, width: '100%' }}>
              <InputLabel>Faculties</InputLabel>
              <Select
                disabled={!showFilter}
                multiple
                value={selectedFaculties}
                onChange={this.handleFacultyChange}
                aria-label={'select faculty'}
                style={{ width: '100%', display: 'block' }}>
                {faculties.map((faculty, i) => (
                  <MenuItem
                    key={i}
                    value={faculty.value}
                    style={
                      selectedFaculties.indexOf(faculty.value) >= 0
                        ? { fontWeight: 'bold' }
                        : {}
                    }>
                    {faculty.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ margin: 8, width: '100%' }}>
              <InputLabel>Teaching Periods</InputLabel>
              <Select
                disabled={!showFilter}
                multiple
                value={selectedTeachingPeriods}
                onChange={this.handleTeachingPeriodChange}
                aria-label={'select teaching period'}
                style={{ width: '100%', display: 'block' }}>
                {teachingPeriods.map((teachingPeriod, i) => (
                  <MenuItem
                    key={i}
                    value={teachingPeriod.value}
                    style={
                      selectedTeachingPeriods.indexOf(teachingPeriod.value) >= 0
                        ? { fontWeight: 'bold' }
                        : {}
                    }>
                    {teachingPeriod.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ margin: 8, width: '100%' }}>
              <InputLabel>Unit Types</InputLabel>
              <Select
                disabled={!showFilter}
                multiple
                value={selectedUnitTypes}
                onChange={this.handleUnitTypeChange}
                aria-label={'select unit type'}
                style={{ width: '100%', display: 'block' }}>
                {unitTypes.map((unitType, i) => (
                  <MenuItem
                    key={i}
                    value={unitType.value}
                    style={
                      selectedUnitTypes.indexOf(unitType.value) >= 0
                        ? { fontWeight: 'bold' }
                        : {}
                    }>
                    {unitType.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div aria-label={'select credit points'}>
              <h4>Credit Points</h4>
              <Range
                onChange={this.handleCreditPointChange}
                disabled={!showFilter}
                min={0}
                max={48}
                step={null}
                defaultValue={[0, 48]}
                marks={{
                  0: '0',
                  3: '3',
                  6: '6',
                  12: '12',
                  18: '18',
                  24: '24',
                  36: '36',
                  48: '48',
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

/**
 * Mapping state to props
 */
const mapStateToProps = state => {
  return {
    unitSearchIsLoading: state.UnitSearch.isLoading,
    courseInfo: state.Handbook.courseInfo,
  };
};
/**
 * Mapping dispatch to props
 */
const mapDispatchToProps = dispatch => {
  const actionBundle = {
    updateFacultyFilter,
    updateLocationFilter,
    updateCreditPointRangeFilter,
    updateTeachingPeriodFilter,
    updateUnitTypeFilter,
  };
  return bindActionCreators(actionBundle, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnitSearchFilterContainer);

UnitSearchFilterContainer.propTypes = {
  unitSearchIsLoading: PropTypes.bool,
  updateFacultyFilter: PropTypes.func,
  updateLocationFilter: PropTypes.func,
  updateCreditPointRangeFilter: PropTypes.func,
  updateTeachingPeriodFilter: PropTypes.func,
  updateUnitTypeFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
};
