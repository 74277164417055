import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// MUI Icons
import { default as AddIcon } from '@material-ui/icons/AddCircleOutline';

const OverloadButton = ({
  closeMenu,
  numberOfUnits,
  isIntermission,
  isStudyAbroad,
  increaseStudyLoad,
  teachingPeriodIndex,
}) => (
  <MenuItem
    disabled={numberOfUnits >= 7 || isIntermission || isStudyAbroad}
    onClick={() => {
      increaseStudyLoad(teachingPeriodIndex);
      closeMenu();
    }}
    aria-label="Add overload column">
    <ListItemIcon>
      <AddIcon />
    </ListItemIcon>
    <ListItemText>Add Overloading Unit</ListItemText>
  </MenuItem>
);

export default OverloadButton;

OverloadButton.propTypes = {
  closeMenu: PropTypes.func,
  isIntermission: PropTypes.bool,
  numberOfUnits: PropTypes.number,
  increaseStudyLoad: PropTypes.func,
  teachingPeriodIndex: PropTypes.number,
};
